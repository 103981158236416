import { Box, Show } from "@chakra-ui/react";

import { CompleteProfileBanner } from "@/components/common";
import { useCurrentActor } from "@/hooks";
import { getIsUnaccreditedSeller } from "@/utils";
import { getIsProfileIncomplete } from "@/utils/user";

import {
  DesktopNavBar,
  UnaccreditedSellerDesktopNavBarV2,
} from "./DesktopNavBar";
import { MobileNavBar } from "./MobileNavBar";
import UnaccreditedSellerMobileNavBar from "./MobileNavBar/UnaccreditedSellerMobileNavBar";
import NavSpacer from "./NavSpacer";

const NavBar = () => {
  const actor = useCurrentActor();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);
  const isOnboardingComplete = actor?.onboardingComplete;

  const shouldRenderCompleteProfileBanner = !!(
    isOnboardingComplete && getIsProfileIncomplete(actor)
  );

  const renderDesktopNavBar = () => {
    if (isUnaccreditedSeller) {
      return <UnaccreditedSellerDesktopNavBarV2 />;
    }
    return <DesktopNavBar />;
  };

  const renderMobileNavBar = () => {
    if (isUnaccreditedSeller) {
      return <UnaccreditedSellerMobileNavBar />;
    }
    return <MobileNavBar />;
  };

  return (
    <>
      <Box position="fixed" top={0} left={0} right={0} zIndex="modal">
        {shouldRenderCompleteProfileBanner && <CompleteProfileBanner />}
        <Show above="lg" ssr={false}>
          {renderDesktopNavBar()}
        </Show>
        <Show below="lg" ssr={false}>
          {renderMobileNavBar()}
        </Show>
      </Box>
      <NavSpacer shouldRenderExtraSpace={shouldRenderCompleteProfileBanner} />
    </>
  );
};

export default NavBar;
