import { useTranslation } from "react-i18next";

import { Alert, AlertIcon, Box, AlertDescription } from "@chakra-ui/react";

type CompanyRestrictedAlertProps = {
  companyName: string;
};

export function CompanyRestrictedAlert({
  companyName,
}: CompanyRestrictedAlertProps) {
  const { t } = useTranslation(`company`);
  return (
    <Alert alignItems="flex-start" status="info" variant="infoGrey">
      <AlertIcon />
      <Box>
        <AlertDescription>
          {t(`only_allows_transfers_specific_circumstances`, {
            companyName,
          })}
        </AlertDescription>
      </Box>
    </Alert>
  );
}
