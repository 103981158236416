import { Info } from "@phosphor-icons/react";
import { useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  ModalBody,
  VStack,
  Text,
  Link,
  Card,
  Box,
  Flex,
} from "@chakra-ui/react";

import {
  ExecutionTaskActionType,
  TransactionExecutionPageTaskFragment,
  useExecutionTaskActionMutation,
} from "@/gql";
import {
  useCustomToast,
  useModernTreasuryExecutionEmbeddableFlow,
  useMutationWithError,
} from "@/hooks";

import MTDisclaimer from "./MTDisclaimer";
import { CollectBankAccountSteps } from "./types";

const AccountCollectionContent = ({
  clientToken,
  setStep,
  task,
}: {
  clientToken: string;
  setStep: (step: CollectBankAccountSteps) => void;
  task: TransactionExecutionPageTaskFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const { errorToast } = useCustomToast();

  const [executionTaskActionMutation, _] = useMutationWithError(
    useExecutionTaskActionMutation(),
    `executionTaskAction`,
  );

  const mtContainerId = `#mt-div`;

  // this would mount twice in dev mode
  const isMountedRef = useRef(false);

  const { mountEmbeddableFlow, unmountEmbeddableFlow } =
    useModernTreasuryExecutionEmbeddableFlow({
      clientToken,
      onFlowSuccess: async () => {
        executionTaskActionMutation({
          variables: {
            id: task.id,
            type: ExecutionTaskActionType.CollectBankAccountMarkCollected,
          },
        });

        unmountEmbeddableFlow();
        setStep(`collection_success`);
      },
      onFlowError: (error) => {
        unmountEmbeddableFlow();
        errorToast(error.message);
      },
    });

  useEffect(() => {
    if (!isMountedRef.current) {
      mountEmbeddableFlow(mtContainerId);
      isMountedRef.current = true;
    }

    return () => {
      unmountEmbeddableFlow();
    };
  }, []);

  return (
    <ModalBody>
      <VStack alignItems="start" spacing={4} w="full">
        <Text textStyle="heading-xl">{t(`add_your_account_details`)}</Text>
        <Card
          px={4}
          py={3}
          as={Flex}
          flexDirection="row"
          gap={3}
          variant="flat"
          alignItems={{ base: `start`, md: `center` }}
          w="full"
          borderColor="grey.800"
          borderWidth="1px"
          bg="grey.50"
        >
          <Box>
            <Info size={24} weight="fill" />
          </Box>
          <Text textStyle="text-sm">
            <Trans i18nKey="account_details_disclaimer" ns="execution" />
          </Text>
        </Card>
        <Text textStyle="text-sm">
          <Trans
            i18nKey="account_details_additional"
            components={{
              a: (
                <Link
                  href="mailto:execution@hiive.com"
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
            ns="execution"
          />
        </Text>
      </VStack>
      {/* offset mt padding */}
      <Box mx="-24px">
        <div id="mt-div" />
      </Box>
      <MTDisclaimer />
    </ModalBody>
  );
};

export default AccountCollectionContent;
