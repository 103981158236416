import { useTranslation } from "react-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import {
  Badge,
  Card,
  CardFooter,
  HStack,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { getTransactionIsBuySide } from "@/components/transactions";
import {
  ExecutionStepStatus,
  ExecutionStepVisibility,
  TransactionExecutionPageTransactionFragment,
  UserWithInstitutionFragment,
} from "@/gql";

type TransactionExecutionPageTabNavLinkProps = {
  transaction: TransactionExecutionPageTransactionFragment;
  actor: UserWithInstitutionFragment;
};

type TabNavLinkProps = {
  activeBadge?: boolean;
  label: React.ReactNode;
  count?: number;
  url: string;
};

const TabNavLink = ({
  activeBadge = false,
  label,
  count,
  url,
}: TabNavLinkProps) => {
  const { asPath } = useRouter();

  const isActive = url === asPath;

  return (
    <ListItem
      _active={{ bg: `grey.50` }}
      _after={
        isActive
          ? {
              content: `""`,
              position: `absolute`,
              left: `50%`,
              transform: `translateX(-50%)`,
              bottom: `0px`,
              width: `100%`,
              height: `4px`,
              backgroundColor: `grey.900`,
              borderTopLeftRadius: `4px`,
              borderTopRightRadius: `4px`,
            }
          : {}
      }
      _focus={{
        boxShadow: `focus`,
      }}
      _hover={{
        bg: `grey.25`,
        borderColor: `grey.25`,
      }}
      as={Link}
      borderColor="grey.15"
      color={isActive ? `grey.900` : `grey.500`}
      height="100%"
      href={url}
      position="relative"
      px={6}
      textStyle="heading-md"
      scroll={false}
    >
      <HStack h="full">
        <Text>{label}</Text>
        {count && (
          <Badge
            border={!activeBadge ? `.25px solid` : undefined}
            borderColor={!activeBadge ? `grey.200` : undefined}
            bg={!activeBadge ? `grey.50` : `salmon.900`}
            color={!activeBadge ? `grey.900` : `white`}
            py={0}
          >
            <Text textStyle="heading-xs">{count}</Text>
          </Badge>
        )}
      </HStack>
    </ListItem>
  );
};

const TransactionExecutionPageTabNavLink = withCurrentActor(
  ({ transaction, actor }: TransactionExecutionPageTabNavLinkProps) => {
    const { t } = useTranslation();

    const { workflow } = transaction;
    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    const userType = isBuySide
      ? ExecutionStepVisibility.Buyer
      : ExecutionStepVisibility.Seller;

    const numInProgressSteps = !!workflow
      ? workflow.steps.filter(
          (step) =>
            step.status === ExecutionStepStatus.InProgress &&
            step.visibility.includes(userType),
        ).length
      : 0;
    const documentsCount = transaction?.fileUploads?.length;

    return (
      <Card
        variant="no-border-radius-base"
        borderRadius={0}
        borderBottomRadius="md"
        borderTop="none"
        position={{ base: `sticky`, md: `unset` }}
        top="var(--chakra-sizes-mobile-navbar-height)"
        zIndex="sticky"
      >
        <CardFooter
          overflow="auto"
          py={0}
          px={0}
          bg="grey.15"
          css={{
            "&::-webkit-scrollbar": {
              display: `none`,
            },
          }}
        >
          <List as={HStack} h="12" gap={0}>
            <TabNavLink
              activeBadge
              count={numInProgressSteps}
              label={t(`tasks`)}
              url={`/transactions/${transaction.id}/tasks`}
            />
            <TabNavLink
              label={t(`details`)}
              url={`/transactions/${transaction.id}/details`}
            />
            <TabNavLink
              count={documentsCount}
              label={t(`documents`)}
              url={`/transactions/${transaction.id}/documents`}
            />
          </List>
        </CardFooter>
      </Card>
    );
  },
);

export default TransactionExecutionPageTabNavLink;
