const locale = {
  add_trusted_contact_person: `Add a Trusted Contact Person`,
  add_trusted_contact_person_disclaimer: `By adding a Trusted Contact Person, you authorize Hiive to contact this person to address possible financial exploitation, confirm the specifics of your current contact information, health status, or the identity of any legal guardian, executor, trustee or holder of a power of attorney, or as otherwise permitted by applicable law and regulation.`,
  add_trusted_contact_person_question: `Add a Trusted Contact Person to your account?`,
  answer: `Answer`,
  can_complete_later: `You can complete this step later if you need to.`,
  cancel: `Cancel`,
  modify: `Modify`,
  next: `Next`,
  no: `No`,
  save: `Save`,
  saved_trusted_contact_person: `Trusted contact person information saved`,
  trusted_contact_person: `Trusted Contact Person`,
  updated_trusted_contact_person: `Trusted contact person information updated`,
  who_is_a_trusted_contact_person: `A Trusted Contact Person is an individual aged 18 or older that you authorize us to contact about your account in certain circumstances. Adding one is entirely optional, and will not affect your ability to access and use Hiive's platform and services.`,
  yes: `Yes`,
};

export default locale;
