import { ArrowSquareOut, Bank, Info } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Badge,
  Button,
  Card,
  Center,
  HStack,
  Text,
  VStack,
  CardBody,
  CardHeader,
  Image,
  Box,
  Flex,
} from "@chakra-ui/react";

import {
  BankAccountVerificationStatus,
  ExecutionTaskStatus,
  useExecutionTaskActionMutation,
  TransactionExecutionPageTaskFragment,
  ExecutionTaskActionType,
} from "@/gql";
import { useColors, useCustomToast } from "@/hooks";

const CollectOffPlatformTaskCard = ({
  assignedBankAccount,
  task,
}: {
  task: TransactionExecutionPageTaskFragment;
  assignedBankAccount?: {
    last4: string;
    verificationStatus: BankAccountVerificationStatus;
  };
}) => {
  const { t } = useTranslation(`execution`);
  const [grey800] = useColors([`grey.800`]);
  const { errorToast } = useCustomToast();

  const [executionTaskAction, { loading }] = useExecutionTaskActionMutation();

  const generateFormLinkAndOpen = async () => {
    const { data } = await executionTaskAction({
      variables: {
        id: task.id,
        type: ExecutionTaskActionType.CollectBankAccountGenerateOffPlatformLink,
      },
    });

    if (
      data?.executionTaskAction?.result?.__typename ===
        `ExecutionTaskActionCollectBankAccountGenerateOffPlatformLink` &&
      data?.executionTaskAction?.result.offPlatformLink
    ) {
      window.open(data?.executionTaskAction?.result.offPlatformLink, `_blank`);
    } else {
      errorToast(t(`off_platform_generate_link_error`));
    }
  };

  const hasAssignedBankAccount = !!assignedBankAccount;
  const showManualVerificationDisclaimer =
    assignedBankAccount?.verificationStatus ===
    BankAccountVerificationStatus.Manual;
  const showConnectButton =
    task.status === ExecutionTaskStatus.InProgress && !hasAssignedBankAccount;

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <Card variant="flat" bg="grey.15">
        <CardHeader
          px={4}
          py={3}
          as={HStack}
          w="full"
          justifyContent="space-between"
          bg="white"
          borderBottom="none"
          borderBottomRadius={6}
        >
          <HStack gap={{ base: 3, md: 4 }}>
            {match(hasAssignedBankAccount)
              .with(true, () => (
                <>
                  <Center>
                    <Bank width="24px" height="24px" />
                  </Center>
                  <HStack>
                    <Text textStyle="heading-md">{t(`bank_account`)}</Text>
                    <Text textStyle="text-md">
                      {t(`bank_accounts.redacted`, { ns: `postings` })}
                    </Text>
                    <Text textStyle="text-md">
                      {assignedBankAccount!.last4}
                    </Text>
                  </HStack>
                </>
              ))
              .otherwise(() => (
                <>
                  <Center bg="mt" w="24px" h="24px" borderRadius="md">
                    <Image
                      src="/assets/mt-icon.png"
                      w="14px"
                      objectFit="contain"
                    />
                  </Center>
                  <VStack alignItems="start" spacing={0}>
                    <Text textStyle="heading-md">
                      {t(`connect_bank_account`)}
                    </Text>
                    <Text textStyle="text-xs" color="grey.700">
                      {t(`connect_bank_account_description`)}
                    </Text>
                  </VStack>
                </>
              ))}
          </HStack>
          {showConnectButton && (
            <Button
              isLoading={loading}
              variant="rounded-solid-grey"
              size={{ base: `sm`, md: `md` }}
              onClick={generateFormLinkAndOpen}
              rightIcon={
                <Box position="relative" bottom={0.5}>
                  <ArrowSquareOut size={16} />
                </Box>
              }
            >
              {t(`connect_account`)}
            </Button>
          )}
          {hasAssignedBankAccount && (
            <Badge variant="rounded-light-grey">
              <Text textStyle="text-sm" lineHeight="unset">
                {t(`unverified`)}
              </Text>
            </Badge>
          )}
        </CardHeader>
      </Card>
      {showManualVerificationDisclaimer && (
        <Card
          variant="flat"
          bg="grey.50"
          borderColor="grey.800"
          borderWidth="1.5px"
        >
          <CardBody p={4} as={HStack} w="full">
            <Info size={16} weight="fill" color={grey800} />
            <Text textStyle="text-sm">
              {t(`manual_verification_disclaimer`)}
            </Text>
          </CardBody>
        </Card>
      )}
    </Flex>
  );
};

export default CollectOffPlatformTaskCard;
