import { useTranslation } from "react-i18next";

import {
  ActionsTileButtonWrapper,
  HiiveButton,
  InternalLink,
  Tile,
  TileHeader,
} from "@/components/common";
import { PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment } from "@/gql";
import { useModal } from "@/hooks";
import { useRemoveClientWithdrawFunction } from "@/hooks/featureFlags";
import {
  appendSellerCompanyIdToUrl,
  getAvailableListingActions,
} from "@/utils";

interface ListingActionsTileProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment;
}

export const ListingActionsTileV2 = ({ listing }: ListingActionsTileProps) => {
  const { t } = useTranslation();
  const { modals, onOpenModal } = useModal();

  const { canWithdrawListing, canModifyListing } =
    getAvailableListingActions(listing);

  const removeClientWithdrawFunctionEnabled = useRemoveClientWithdrawFunction();

  return (
    <Tile bg="white" justifyContent="space-between" w="full">
      <TileHeader whiteSpace="nowrap">{t(`adjust_your_listing`)}</TileHeader>
      <ActionsTileButtonWrapper>
        {canModifyListing && (
          <InternalLink
            href={appendSellerCompanyIdToUrl(
              `/modify-listing/${listing.id}`,
              listing.companyId,
            )}
          >
            <HiiveButton
              size="md"
              maxW="unset"
              variant="rounded-outline-grey"
              observabilityLabel="[UnaccreditedSellerListingActions] Modify Listing"
            >
              {t(`modify`)}
            </HiiveButton>
          </InternalLink>
        )}

        {!removeClientWithdrawFunctionEnabled && canWithdrawListing && (
          <HiiveButton
            size="md"
            maxW="unset"
            variant="rounded-outline-grey"
            onClick={onOpenModal(
              modals.unaccreditedSellerWithdrawListingV2(listing),
            )}
            observabilityLabel="[UnaccreditedSellerListingActions] Withdraw Listing"
          >
            {t(`withdraw`)}
          </HiiveButton>
        )}
      </ActionsTileButtonWrapper>
    </Tile>
  );
};
