import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { StandingBidLatestPostingCardFooterFragment } from "@/gql";
import { toTimestamp } from "@/utils";

type StandingBidLatestPostingCardFooterProps = {
  standingBid: StandingBidLatestPostingCardFooterFragment;
};

export function StandingBidLatestPostingCardFooter({
  standingBid: { placedAt },
}: StandingBidLatestPostingCardFooterProps) {
  const { t } = useTranslation(`standingBids`);

  const placedAtFormatted = toTimestamp(placedAt);

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm" whiteSpace="nowrap">
        {t(`placed_timestamp`, {
          date: placedAtFormatted,
        })}
      </Text>
    </PostingCard.Footer>
  );
}
