import isEmpty from "lodash/isEmpty";

import { GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragment } from "@/gql";

export const getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2 =
  ({
    activity,
  }: GetCanAccessUnaccreditedSellerPostStandingBidActivityDashboardCompanyActivityFragment) =>
    !isEmpty(activity.myCompletedTransactions) ||
    !isEmpty(activity.myOpenTransactions) ||
    !isEmpty(activity.standingBidsWithDiscussions);
