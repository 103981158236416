import { useTranslation } from "react-i18next";

import { useBreakpointValue } from "@chakra-ui/react";

import { Listbox } from "@/components/form";
import { TransactingEntityItemEntityFragment } from "@/gql";
import { useListbox } from "@/hooks";

import EntityLineItem from "./EntitySelectorItem";

const EntitySelector = ({
  availableEntities,
  currentEntityId,
  onEntitySelected,
}: {
  readonly availableEntities: TransactingEntityItemEntityFragment[];
  currentEntityId?: TransactingEntityItemEntityFragment["id"] | null;
  onEntitySelected: (entity: TransactingEntityItemEntityFragment) => void;
}) => {
  const { t } = useTranslation();
  const currentEntity = availableEntities.find(
    (entity) => entity.id === currentEntityId,
  );

  const selectedOption = (value: TransactingEntityItemEntityFragment) =>
    value && <EntityLineItem entity={value} />;

  const isMobileView = useBreakpointValue(
    {
      base: true,
      sm: false,
      xs: false,
    },
    { ssr: false },
  );

  const { buttonProps, menuProps, itemProps } = useListbox({
    onClickItem: onEntitySelected,
    items: availableEntities,
    itemToString: (unit) => unit?.legalName ?? ``,
    selectedItem: currentEntity,
    buttonStyle: {
      height: `inherit`,
      minHeight: isMobileView ? 64 : 48,
    },
    itemRenderer: () => selectedOption(currentEntity!),
  });

  return (
    <Listbox.Container>
      <Listbox.Button
        {...buttonProps}
        placeholder={t(`select_transacting_party`)}
      />
      <Listbox.Menu {...menuProps} mt={[6, 2]}>
        {availableEntities.map((entity, index) => (
          <Listbox.Item
            key={entity.id}
            item={entity}
            index={index}
            {...itemProps}
          >
            <EntityLineItem entity={entity} />
          </Listbox.Item>
        ))}
      </Listbox.Menu>
    </Listbox.Container>
  );
};

export default EntitySelector;
