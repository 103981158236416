import { useTranslation } from "react-i18next";

import { OtherDetailsInput } from "@/components/form";

export function ExplainSellSharesDirectlyInput() {
  const { t } = useTranslation(`company`);

  return (
    <OtherDetailsInput
      name="userPlacedExplanation"
      label={t(`please_explain_how_direct`)}
      placeholder={t(`you_are_not_authorized_to_sell_directly`)}
      helperText={t(`not_visible_to_other_parties`)}
    />
  );
}
