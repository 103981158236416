import { Trans } from "react-i18next";
import { match } from "ts-pattern";

import { Text } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";
import {
  findHighestAcceptedBidSharePrice,
  findLowestAcceptedBidSharePrice,
  hasMultipleAcceptedBidSharePrices,
} from "@/components/companies";
import { ListingPostingCardBodyFragment } from "@/gql";
import {
  calculateListingNumSharesSoldConditionally,
  formatPricePerShare,
  formatShares,
} from "@/utils";

type ListingPostingCardBodyAcceptedSharesProps = {
  listing: ListingPostingCardBodyFragment;
};

type ListingPostingCardBodyAcceptedSharesContentProps = {
  hasMultipleAcceptedBids: boolean;
  numSharesSoldConditionally: number;
  acceptedBidPrices: number[];
};

const COMPONENTS = {
  b: <Text as="span" textStyle="heading-3xs" color="grey.900" />,
};

function ListingPostingCardBodyAcceptedSharesContent({
  hasMultipleAcceptedBids,
  numSharesSoldConditionally,
  acceptedBidPrices,
}: ListingPostingCardBodyAcceptedSharesContentProps) {
  const [acceptedBidPrice] = acceptedBidPrices;

  const numSharesSoldConditionallyFormatted = formatShares(
    numSharesSoldConditionally,
  );
  const minPricePerShareFormatted = formatPricePerShare(
    findLowestAcceptedBidSharePrice(acceptedBidPrices),
  );
  const maxPricePerShareFormatted = formatPricePerShare(
    findHighestAcceptedBidSharePrice(acceptedBidPrices),
  );
  const pricePerShareFormatted = formatPricePerShare(acceptedBidPrice);

  return match({ hasMultipleAcceptedBids })
    .with({ hasMultipleAcceptedBids: true }, () => (
      <Trans
        ns="transactions"
        i18nKey="shares_sold_from_v2"
        values={{
          numShares: numSharesSoldConditionallyFormatted,
          minPps: minPricePerShareFormatted,
          maxPps: maxPricePerShareFormatted,
        }}
        components={COMPONENTS}
      />
    ))
    .otherwise(() => (
      <Trans
        ns="transactions"
        i18nKey="shares_sold_at_v2"
        values={{
          numShares: numSharesSoldConditionallyFormatted,
          pps: pricePerShareFormatted,
        }}
        components={COMPONENTS}
      />
    ));
}

export function ListingPostingCardBodyAcceptedShares({
  listing,
}: ListingPostingCardBodyAcceptedSharesProps) {
  const { acceptedBidPrices } = listing;
  const { length: acceptedBidPricesLength } = acceptedBidPrices;

  const numSharesSoldConditionally =
    calculateListingNumSharesSoldConditionally(listing);

  const hasMultipleAcceptedBids =
    hasMultipleAcceptedBidSharePrices(acceptedBidPrices);

  const showAcceptedSharesContent =
    numSharesSoldConditionally > 0 && acceptedBidPricesLength > 0;

  if (!showAcceptedSharesContent) {
    return null;
  }

  return (
    <SensitiveText textStyle="text-xs" color="grey.700">
      <ListingPostingCardBodyAcceptedSharesContent
        hasMultipleAcceptedBids={hasMultipleAcceptedBids}
        acceptedBidPrices={acceptedBidPrices}
        numSharesSoldConditionally={numSharesSoldConditionally}
      />
    </SensitiveText>
  );
}
