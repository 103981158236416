import React, { ReactNode } from "react";

import { HStack, ModalFooter, ModalFooterProps } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";

interface ExecutionModalFooterProps extends ModalFooterProps {
  readonly children: ReactNode;
}

const ExecutionModalFooter = ({
  children,
  ...footerProps
}: ExecutionModalFooterProps) => {
  const isDesktop = useIsDesktop();

  return (
    <ModalFooter border="none" p="0" px={6} py={4} {...footerProps}>
      <HStack
        gap={2}
        w="full"
        justifyContent="flex-end"
        sx={{
          "& > *": { flexGrow: { base: 1, md: `inherit` } },
          "& > :only-child": {
            minWidth: !isDesktop ? `100%` : undefined,
          },
        }}
      >
        {children}
      </HStack>
    </ModalFooter>
  );
};

export default ExecutionModalFooter;
