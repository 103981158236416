import { useTranslation } from "react-i18next";

import { Box, HStack, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import {
  ConditionallySoldOutBadge,
  OpenBidsBadge,
  PartiallySoldBadge,
  calculateTotalOpenBids,
} from "@/components/companies";
import { ListingPostingCardFragment, ListingState } from "@/gql";
import { toTimestamp } from "@/utils";

type ListingPostingCardBrokerFooterProps = {
  listing: ListingPostingCardFragment;
};

export function ListingPostingCardBrokerFooter({
  listing: {
    acceptedBidPrices: { length: acceptedBidPricesLength },
    transferMethod,
    numActiveBids,
    numCounteredBids,
    state,
    bids,
    insertedAt,
    placedAt,
  },
}: ListingPostingCardBrokerFooterProps) {
  const { t } = useTranslation();

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const isInReview = state === ListingState.InReview;
  const isConditionallySold = state === ListingState.ConditionallySold;
  const isPartiallySold =
    state === ListingState.Open && acceptedBidPricesLength >= 1;
  const isPlaced = state === ListingState.Open;

  const hasOpenBids = totalOpenBids >= 1;

  const insertedAtFormatted = toTimestamp(insertedAt);
  const placedAtFormatted = toTimestamp(placedAt);

  return (
    <PostingCard.Footer>
      {isInReview && (
        <Text>
          {t(`listing_submitted_in_review`, { date: insertedAtFormatted })}
        </Text>
      )}
      {isPlaced && (
        <Text>{t(`listing_placed_status`, { date: placedAtFormatted })}</Text>
      )}

      <Box flexGrow={1} />

      <HStack wrap="wrap" align="flex-end" spacing={{ base: 2, md: 3 }}>
        {isConditionallySold && (
          <ConditionallySoldOutBadge transferMethod={transferMethod} />
        )}
        {isPartiallySold && (
          <PartiallySoldBadge transferMethod={transferMethod} />
        )}
        {hasOpenBids && (
          <OpenBidsBadge
            totalOpenBids={totalOpenBids}
            bids={bids}
            transferMethod={transferMethod}
          />
        )}
      </HStack>
    </PostingCard.Footer>
  );
}
