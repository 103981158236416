import { DotOutline } from "@phosphor-icons/react";

import { HStack, Flex, Text, FlexProps } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";

import { EntityType, TransactionExecutionPageTransactionFragment } from "@/gql";
import { useTranslatedEntityNames, useCountryList } from "@/hooks";

type EntityDetailsProps = FlexProps & {
  entity: TransactionExecutionPageTransactionFragment["sellerEntity"];
};

const EntityDetails = ({ entity, ...props }: EntityDetailsProps) => {
  const { getFormattedEntityName } = useTranslatedEntityNames();
  const { getName } = useCountryList();
  const isNotIndividual =
    entity?.type && entity?.type !== EntityType.Individual;

  return (
    <Flex
      flexDirection={{ base: `column`, md: `row` }}
      textStyle="text-sm"
      gap={1}
      {...props}
    >
      <Text as={chakra.span}>
        {entity?.legalName}, {` `}
      </Text>
      <HStack gap={0}>
        {entity?.jurisdictionOfFormation && (
          <Text as={chakra.span}>
            {getName(entity.jurisdictionOfFormation.name)}
          </Text>
        )}
        {isNotIndividual && (
          <>
            <DotOutline size={20} weight="fill" />
            <Text as={chakra.span}>{getFormattedEntityName(entity.type)}</Text>
          </>
        )}
      </HStack>
    </Flex>
  );
};

export default EntityDetails;
