import { Trans, useTranslation } from "react-i18next";

import {
  Alert,
  AlertIcon,
  Box,
  AlertDescription,
  AlertTitle,
  Card,
  CardBody,
  Text,
  VStack,
} from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { ListingPageSellerActivityListingFragment } from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";

type InReviewActivityAlertProps = {
  companyName: string;
};

type InReviewActivityProps = {
  listing: ListingPageSellerActivityListingFragment;
};

function InReviewActivityAlert({ companyName }: InReviewActivityAlertProps) {
  const { t } = useTranslation(`company`);
  return (
    <Alert alignItems="flex-start" status="info" variant="infoGrey">
      <AlertIcon />
      <Box>
        <AlertTitle>
          {t(`company_in_review`, {
            companyName,
          })}
        </AlertTitle>
        <AlertDescription>
          {t(`let_you_know_when_live`, {
            companyName,
          })}
        </AlertDescription>
      </Box>
    </Alert>
  );
}

export function InReviewActivity({
  listing: { company },
}: InReviewActivityProps) {
  const { t } = useTranslation(`company`);
  const { name: companyName } = company;
  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <Card>
      <CardBody w="full">
        <VStack alignItems="flex-start" gap={6}>
          <InReviewActivityAlert companyName={companyName} />
          <Text>
            <Trans
              i18nKey="for_support_contact"
              ns="company"
              components={[
                <MailtoLink
                  key="mail_to_link"
                  email={securitySpecialistEmail}
                  subject={t(`contact_company_security_specialist`, {
                    companyName,
                  })}
                  fontWeight={500}
                />,
              ]}
              values={{ email: securitySpecialistEmail }}
            />
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
}
