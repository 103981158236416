import { useTranslation } from "react-i18next";

import { Box, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import {
  ConditionallyCompletedBadge,
  PartiallyAcceptedBadge,
} from "@/components/companies";
import { StandingBidPostingCardFragment, StandingBidState } from "@/gql";
import {
  getIsConditionallyCompletedStandingBid,
  getIsPartiallyAcceptedStandingBid,
  toTimestamp,
} from "@/utils";

type StandingBidPostingCardFooterProps = {
  standingBid: StandingBidPostingCardFragment;
};

export function StandingBidPostingCardFooter({
  standingBid,
}: StandingBidPostingCardFooterProps) {
  const { t } = useTranslation(`standingBids`);
  const { state, insertedAt } = standingBid;

  const isPartiallyAccepted = getIsPartiallyAcceptedStandingBid(standingBid);
  const isConditionallyCompleted =
    getIsConditionallyCompletedStandingBid(standingBid);

  const isInReview = state === StandingBidState.InReview;

  const insertedAtFormatted = toTimestamp(insertedAt);

  const hasFooter =
    isInReview || isPartiallyAccepted || isConditionallyCompleted;

  if (!hasFooter) {
    return null;
  }

  return (
    <PostingCard.Footer>
      {isInReview && (
        <Text color="grey.700" textStyle="text-sm">
          {t(`standing_bid_in_review_status`, { date: insertedAtFormatted })}
        </Text>
      )}

      <Box flexGrow={1} />

      {isPartiallyAccepted && <PartiallyAcceptedBadge />}
      {isConditionallyCompleted && <ConditionallyCompletedBadge />}
    </PostingCard.Footer>
  );
}
