import { useRouter } from "next/router";

import {
  ActionsTile,
  ActionsTileButtonWrapper,
  HiiveButton,
  TileHeader,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  ListingPageListingActionsTileListingFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal } from "@/hooks";
import { useRemoveClientWithdrawFunction } from "@/hooks/featureFlags";
import {
  appendSellerCompanyIdToUrl,
  getAvailableListingActions,
  getIsSellerForListing,
  getIsUnaccreditedSeller,
} from "@/utils";

export const ListingActionsTile = withCurrentActor(
  ({
    listing,
    actor,
  }: {
    readonly listing: ListingPageListingActionsTileListingFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { modals, onOpenModal } = useModal();
    const router = useRouter();

    const { canWithdrawListing, canModifyListing } =
      getAvailableListingActions(listing);

    const { isSuperadmin } = actor;
    const isSeller = getIsSellerForListing(actor, listing);

    const canWithdrawAsSuperadmin =
      !isSeller && isSuperadmin && canWithdrawListing;

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    const handleClickModifyListing = () => {
      if (isUnaccreditedSeller) {
        router.push(
          appendSellerCompanyIdToUrl(
            `/modify-listing/${listing.id}`,
            listing.companyId,
          ),
        );

        return;
      }

      onOpenModal(modals.modifyListing(listing))();
    };

    const removeClientWithdrawFunctionEnabled =
      useRemoveClientWithdrawFunction();

    return (
      <ActionsTile>
        <TileHeader whiteSpace="nowrap">
          {canWithdrawAsSuperadmin ? `Admin Actions` : `Adjust your Listing`}
        </TileHeader>
        <ActionsTileButtonWrapper>
          {canModifyListing && (
            <HiiveButton
              size="md"
              maxW="unset"
              variant="rounded-outline-grey"
              onClick={handleClickModifyListing}
              observabilityLabel="[ListingActions] Modify Listing"
            >
              Modify
            </HiiveButton>
          )}
          {!removeClientWithdrawFunctionEnabled && canWithdrawListing && (
            <HiiveButton
              size="md"
              maxW="unset"
              variant="rounded-outline-grey"
              onClick={onOpenModal(modals.withdrawListing(listing))}
              observabilityLabel="[ListingActions] Withdraw Listing"
            >
              Withdraw
            </HiiveButton>
          )}
        </ActionsTileButtonWrapper>
      </ActionsTile>
    );
  },
);
