import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { useRouter } from "next/router";

import { BasicUserFragment, useCurrentContextQuery } from "@/gql";
import { createApolloClient } from "@/hooks/useApolloClient";
import { useServerPreview } from "@/hooks/useServerPreview";
import { useToken } from "@/hooks/useToken";

type SessionState = BasicUserFragment | null;

type SessionProviderProps = {
  children: ReactNode;
};

const DEFAULT_SESSION = null;

export const Session = createContext<SessionState>(DEFAULT_SESSION);

export function SessionProvider({ children }: SessionProviderProps) {
  const apiUrl = useServerPreview() ?? ``;
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const token = useToken();
  const router = useRouter();

  const { data, refetch } = useCurrentContextQuery({
    skip: !token && !!client,
    client,
  });

  const value = useMemo(
    () => data?.currentContext?.currentActor || null,
    [data],
  );

  useEffect(() => {
    setClient((prevClient) => {
      // Return undefined if no token is present to prevent triggering the current context query with a stale apollo client on sign-out and caching stale data.
      if (!token) return undefined;
      return prevClient || createApolloClient({ apiUrl, sessionToken: token });
    });
  }, [token, apiUrl]);

  useEffect(() => {
    refetch();
  }, [router, refetch]);

  return <Session.Provider value={value}>{children}</Session.Provider>;
}
