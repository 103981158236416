import { Bank, Info } from "@phosphor-icons/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Badge,
  Button,
  Card,
  Center,
  HStack,
  Text,
  VStack,
  CardBody,
  CardHeader,
  Flex,
} from "@chakra-ui/react";

import {
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  ExecutionCollectBankAccountTaskDataFragment,
  ExecutionEntityBankAccountFragment,
  ExecutionBankAccountCollectionMode,
  BankAccountVerificationStatus,
} from "@/gql";
import { useColors, useModal } from "@/hooks";

import CollectExistingAccountTaskCard from "./CollectExistingAccountTaskCard";
import CollectOffPlatformTaskCard from "./CollectOffPlatformTaskCard";

const TaskHeaderContent = ({
  task,
  data,
  assignedBankAccount,
}: {
  task: TransactionExecutionPageTaskFragment;
  data: ExecutionCollectBankAccountTaskDataFragment;
  assignedBankAccount?: ExecutionEntityBankAccountFragment;
}) => {
  const { t } = useTranslation(`execution`);

  const showAddAccountButton = task.status === ExecutionTaskStatus.InProgress;

  const showBankAccountStatus = !!task.completedAt && !!assignedBankAccount;

  const { onOpenModal, modals } = useModal();

  const { entity } = data;

  return (
    <>
      <HStack gap={{ base: 3, md: 4 }}>
        <Center>
          <Bank width="24px" height="24px" />
        </Center>
        {assignedBankAccount ? (
          <HStack>
            <Text textStyle="heading-md">{t(`bank_account`)}</Text>
            <Text textStyle="text-md">
              {t(`bank_accounts.redacted`, {
                ns: `postings`,
              })}
            </Text>
            <Text textStyle="text-md">{assignedBankAccount.last4}</Text>
          </HStack>
        ) : (
          <VStack alignItems="start" spacing={0.5}>
            <Text textStyle="heading-md">{t(`add_bank_account`)}</Text>
            <Text
              textStyle="text-xs"
              color="grey.700"
              display={{ base: `none`, md: `block` }}
            >
              {t(`add_bank_account_description`)}
            </Text>
          </VStack>
        )}
      </HStack>
      {showAddAccountButton && (
        <Button
          isLoading={false}
          variant="rounded-solid-grey"
          size={{ base: `sm`, md: `md` }}
          onClick={onOpenModal(modals.collectBankAccount({ task, entity }))}
        >
          {t(`add_account`)}
        </Button>
      )}
      {showBankAccountStatus &&
        match(assignedBankAccount?.verificationStatus)
          .with(BankAccountVerificationStatus.Verified, () => (
            <Badge variant="status-active">
              <Text textStyle="text-sm" lineHeight="unset">
                {t(`verified`)}
              </Text>
            </Badge>
          ))
          .otherwise(() => (
            <Badge variant="rounded-light-grey">
              <Text textStyle="text-sm" lineHeight="unset">
                {t(`unverified`)}
              </Text>
            </Badge>
          ))}
    </>
  );
};

const CollectBankAccountTaskCard = ({
  task,
  data,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionCollectBankAccountTaskDataFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const [grey800] = useColors([`grey.800`]);

  const { entity, bankAccount, mode } = data;

  const isOffPlatform = mode === ExecutionBankAccountCollectionMode.OffPlatform;
  const bankAccounts =
    entity?.bankAccounts.filter(
      (account) =>
        account.verificationStatus === BankAccountVerificationStatus.Verified,
    ) ?? [];

  const hasBankAccounts = bankAccounts.length > 0;

  const assignedBankAccount = useMemo(
    () =>
      entity?.bankAccounts.find((account) => account.id === bankAccount?.id),
    [bankAccounts, bankAccount],
  );

  const showManualVerificationDisclaimer =
    assignedBankAccount?.verificationStatus ===
    BankAccountVerificationStatus.Manual;

  const isInProgress = task.status === ExecutionTaskStatus.InProgress;

  if (data.collected && isInProgress) {
    return (
      <Card variant="flat" bg="grey.15">
        <CardBody>
          <Text>{t(`submitting_your_details`)}</Text>
        </CardBody>
      </Card>
    );
  }

  if (isOffPlatform) {
    return (
      <CollectOffPlatformTaskCard
        assignedBankAccount={assignedBankAccount}
        task={task}
      />
    );
  }

  if (hasBankAccounts) {
    return (
      <CollectExistingAccountTaskCard
        task={task}
        data={data}
        assignedBankAccount={assignedBankAccount}
      />
    );
  }

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <Card variant="flat" bg={assignedBankAccount ? `grey.15` : `inherit`}>
        <CardHeader
          px={4}
          py={3}
          as={HStack}
          w="full"
          justifyContent="space-between"
          bg={assignedBankAccount ? `inherit` : `white`}
          borderBottom="none"
          borderBottomRadius={6}
        >
          <TaskHeaderContent
            task={task}
            data={data}
            assignedBankAccount={assignedBankAccount}
          />
        </CardHeader>
      </Card>
      {showManualVerificationDisclaimer && (
        <Card
          variant="flat"
          bg="grey.50"
          borderColor="grey.800"
          borderWidth="1.5px"
        >
          <CardBody p={4} as={HStack} w="full">
            <Info size={16} weight="fill" color={grey800} />
            <Text textStyle="text-sm">
              {t(`manual_verification_disclaimer`)}
            </Text>
          </CardBody>
        </Card>
      )}
    </Flex>
  );
};

export default CollectBankAccountTaskCard;
