import {
  GetIsBrokerCounterpartyForStandingBidStandingBidFragment,
  GetIsBrokerCounterpartyForStandingBidUserFragment,
  GetIsBuyerForStandingBidStandingBidFragment,
  GetIsBuyerForStandingBidUserFragment,
  GetIsConditionallyCompletedStandingBidStandingBidFragment,
  GetIsPartiallyAcceptedStandingBidStandingBidFragment,
  GetIsSolicitedStandingBidStandingBidFragment,
  NormalizeTruncatedStandingBidsFragment,
  StandingBid,
  StandingBidAcceptsTransferMethodsOptions,
  StandingBidState,
} from "@/gql";
import { getIsBroker, getIsBrokerForStandingBid } from "@/utils";

export const getIsPartiallyAcceptedStandingBid = (
  standingBid: GetIsPartiallyAcceptedStandingBidStandingBidFragment,
): boolean =>
  standingBid.state === StandingBidState.Open &&
  !!standingBid.allowPartialAccept &&
  standingBid.acceptedBidPrices.length >= 1;

export const getIsConditionallyCompletedStandingBid = (
  standingBid: GetIsConditionallyCompletedStandingBidStandingBidFragment,
): boolean => standingBid.state === StandingBidState.ConditionallyCompleted;

export const getIsBuyerForStandingBid = (
  user: GetIsBuyerForStandingBidUserFragment,
  standingBid: GetIsBuyerForStandingBidStandingBidFragment,
) =>
  standingBid.buyerId === user.id ||
  (!!standingBid.buyerInstitutionId &&
    standingBid.buyerInstitutionId === user.institutionId);

export const getIsBrokerCounterpartyForStandingBid = ({
  user,
  standingBid,
}: {
  readonly user: GetIsBrokerCounterpartyForStandingBidUserFragment;
  readonly standingBid: GetIsBrokerCounterpartyForStandingBidStandingBidFragment;
}) => getIsBroker(user) && !getIsBrokerForStandingBid({ user, standingBid });

export const getIsSolicitedStandingBid = (
  standingBid: GetIsSolicitedStandingBidStandingBidFragment,
) => !!standingBid.solicited;

export const isStandingBidInTerminalState = (state: StandingBidState) =>
  [
    StandingBidState.ConditionallyCompleted,
    StandingBidState.Closed,
    StandingBidState.Expired,
    StandingBidState.Withdrawn,
  ].includes(state);

export function normalizeTruncatedStandingBids<T extends StandingBid[]>({
  truncated,
  remaining,
}: NormalizeTruncatedStandingBidsFragment): T {
  return [...truncated, ...remaining] as T;
}

export function acceptsAllTransferMethods(
  acceptsTransferMethods: readonly StandingBidAcceptsTransferMethodsOptions[],
) {
  const allTransferMethodOptions = [
    StandingBidAcceptsTransferMethodsOptions.Fund,
    StandingBidAcceptsTransferMethodsOptions.Direct,
    StandingBidAcceptsTransferMethodsOptions.Other,
  ];

  return allTransferMethodOptions.every((transferMethodOption) =>
    acceptsTransferMethods.includes(transferMethodOption),
  );
}
