import { useState } from "react";

import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";

import { TransactionExecutionPageTaskFragment } from "@/gql";
import { useModal } from "@/hooks";

import NewEntityModalConfirmation from "./NewEntityModalConfirmation";
import NewEntityModalForm from "./NewEntityModalForm";

const NewEntityModal = ({
  task,
  isRepresented = false,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly isRepresented?: boolean;
}) => {
  const [completed, setCompleted] = useState(false);
  const { closeModal, currentModal } = useModal();

  return (
    <Modal isOpen={!!currentModal} onClose={closeModal} size="2xl">
      <ModalOverlay />
      <ModalContent height={{ base: `full`, md: `auto` }}>
        {completed && <NewEntityModalConfirmation onClose={closeModal} />}
        {!completed && (
          <NewEntityModalForm
            onClose={closeModal}
            onCompleted={() => {
              setCompleted(true);
            }}
            task={task}
            isRepresented={isRepresented}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default NewEntityModal;
