import { ListItem, HStack, Text, ListItemProps } from "@chakra-ui/react";

type PaymentItemProps = ListItemProps & {
  label: React.ReactNode;
  amount: string;
};

const PaymentItem = ({ amount, label }: PaymentItemProps) => (
  <ListItem as={HStack} justifyContent="space-between" h={20} px={0}>
    {label}
    <Text textStyle="text-md">{amount}</Text>
  </ListItem>
);

export default PaymentItem;
