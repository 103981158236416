import { CheckCircle, Circle, WarningCircle, X } from "@phosphor-icons/react";
import currency from "currency.js";
import pluralize from "pluralize";
import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Card,
  CardBody,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { HiiveButton, MailtoLink, Tile } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  TransactionPageTransactionByIdDocument,
  TransactionModificationAcknowledgmentStatus,
  TransactionModificationTransactionFragment,
  TransactionModificationTransactionModificationAcknowledgmentFragment,
  UpdateTransactionModificationAcknowledgmentStatus,
  UserWithInstitutionFragment,
  useTransactionModificationOnTransactionModificationUpdatedSubscription,
  useUpdateTransactionModificationAcknowledgmentMutation,
} from "@/gql";
import { useColors, useModal, useMutationWithError } from "@/hooks";
import { useApolloClient } from "@/hooks/useApolloClient";
import { TranslationKey } from "@/i18n/";
import { ToastDuration } from "@/types/toast";
import {
  formatShares,
  getLongDocumentTitleByTransferMethod,
  transferMethodToString,
} from "@/utils";

import TransactionModificationFeeBreakdown from "./TransactionModificationFeeBreakdown";

const getIsHiiveSellerForAcknowledgment = (
  actor: UserWithInstitutionFragment,
  transaction: TransactionModificationTransactionFragment,
  acknowledgment: TransactionModificationTransactionModificationAcknowledgmentFragment,
) =>
  actor.id === transaction.sellerId &&
  acknowledgment.userId === transaction.sellerBrokerId;

const getIsHiiveBuyerForAcknowledgment = (
  actor: UserWithInstitutionFragment,
  transaction: TransactionModificationTransactionFragment,
  acknowledgment: TransactionModificationTransactionModificationAcknowledgmentFragment,
) =>
  actor.id === transaction.buyerId &&
  acknowledgment.userId === transaction.buyerBrokerId;

const getShouldShowAcknowledgment = (
  actor: UserWithInstitutionFragment,
  transaction: TransactionModificationTransactionFragment,
  acknowledgment: TransactionModificationTransactionModificationAcknowledgmentFragment,
) => {
  const isHiiveBuyerForAcknowledgment = getIsHiiveBuyerForAcknowledgment(
    actor,
    transaction,
    acknowledgment,
  );
  const isHiiveSellerForAcknowledgment = getIsHiiveSellerForAcknowledgment(
    actor,
    transaction,
    acknowledgment,
  );
  const isAcknowledger = acknowledgment.userId === actor.id;

  return (
    isHiiveBuyerForAcknowledgment ||
    isHiiveSellerForAcknowledgment ||
    isAcknowledger
  );
};

const TransactionModificationAcknowledgment = withCurrentActor(
  ({
    actor,
    transaction,
    acknowledgment,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly transaction: TransactionModificationTransactionFragment;
    readonly acknowledgment: TransactionModificationTransactionModificationAcknowledgmentFragment;
  }) => {
    const { t } = useTranslation();

    const { status, userId: acknowledgmentUserId } = acknowledgment;

    const [grey400, grey900] = useColors([`grey.400`, `grey.900`]);

    const statusTextColor = match(status)
      .with(TransactionModificationAcknowledgmentStatus.Approved, () => grey900)
      .with(TransactionModificationAcknowledgmentStatus.Pending, () => grey400)
      .otherwise(() => grey400);

    const isHiiveSellerForAcknowledgment = getIsHiiveSellerForAcknowledgment(
      actor,
      transaction,
      acknowledgment,
    );

    const isHiiveBuyerForAcknowledgment = getIsHiiveBuyerForAcknowledgment(
      actor,
      transaction,
      acknowledgment,
    );

    const statusText = match({
      isHiiveBuyerForAcknowledgment,
      isHiiveSellerForAcknowledgment,
      acknowledgmentUserId,
    })
      .with({ acknowledgmentUserId: actor.id }, () => t(`your_approval`))
      .with({ isHiiveBuyerForAcknowledgment: true }, () => t(`broker_approval`))
      .with({ isHiiveSellerForAcknowledgment: true }, () =>
        t(`broker_approval`),
      )
      .otherwise(() => t(`counterparty_approval`));

    return (
      <HStack key={acknowledgment.id}>
        {match(status)
          .with(TransactionModificationAcknowledgmentStatus.Approved, () => (
            <CheckCircle weight="fill" size={20} />
          ))
          .with(TransactionModificationAcknowledgmentStatus.Pending, () => (
            <Circle size={20} color={grey400} />
          ))
          .otherwise(() => null)}
        <Text color={statusTextColor} textStyle="heading-xs">
          {statusText}
        </Text>
      </HStack>
    );
  },
);

const TransactionModificationStatus = withCurrentActor(
  ({
    modificationAcknowledgments,
    transaction,
    actor,
  }: {
    readonly modificationAcknowledgments: readonly TransactionModificationTransactionModificationAcknowledgmentFragment[];
    readonly transaction: TransactionModificationTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();

    // Place the actor's approval first
    const sortedAcknowledgments = modificationAcknowledgments
      .slice()
      .sort((acknowledgment, _) => {
        const shouldShowAcknowledgment = getShouldShowAcknowledgment(
          actor,
          transaction,
          acknowledgment,
        );

        return shouldShowAcknowledgment ? -1 : 1;
      });

    return (
      <Tile py={4} gap={4} w="full" alignItems="baseline">
        <VStack alignItems="flex-start">
          <Text textStyle="heading-2xs" lineHeight="inherit" mb={2}>
            {t`approval_status`}
          </Text>
          <VStack
            alignItems="flex-start"
            justifyContent="center"
            spacing={3}
            pb={2}
          >
            {sortedAcknowledgments.map((acknowledgment) => (
              <TransactionModificationAcknowledgment
                key={acknowledgment.id}
                acknowledgment={acknowledgment}
                transaction={transaction}
              />
            ))}
          </VStack>
        </VStack>
      </Tile>
    );
  },
);

const TransactionModificationCTA = ({
  onReject,
  onAccept,
}: {
  readonly onReject: () => void;
  readonly onAccept: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <HStack w="full" justifyContent="space-between" gap={4} pt=".5em">
      <HiiveButton
        size="xl"
        w="full"
        whiteSpace="normal"
        variant="rounded-outline-grey"
        onClick={onReject}
        observabilityLabel="[TransactionModification] Reject"
      >
        {t`reject`}
      </HiiveButton>

      <HiiveButton
        size="xl"
        w="full"
        whiteSpace="normal"
        variant="rounded-solid-salmon"
        onClick={onAccept}
        observabilityLabel="[TransactionModification] Accept"
      >
        {t`accept`}
      </HiiveButton>
    </HStack>
  );
};

const TransactionModification = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionModificationTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();
    const toastRef = useRef();
    const [red600, white, green400] = useColors([
      `red.600`,
      `white`,
      `green.400`,
    ]);
    const { modals, onOpenModal, closeModal } = useModal();
    const transactionTransferMethod = transaction.transferMethod;

    const currentNumberOfShares = transaction.numShares;
    const currentPricePerShare = transaction.pricePerShare;
    const transactionModification = transaction.pendingModification;
    const proposedNumberOfShares =
      transactionModification?.numShares || currentNumberOfShares;
    const proposedPricePerShare =
      transactionModification?.pricePerShare || currentPricePerShare;
    const pendingFeeDiscountApplications =
      transactionModification?.feeDiscountApplications;

    const [updateTransactionModificationAcknowledgment] = useMutationWithError(
      useUpdateTransactionModificationAcknowledgmentMutation(),
      `updateTransactionModificationAcknowledgment`,
    );

    const apolloClient = useApolloClient();

    useTransactionModificationOnTransactionModificationUpdatedSubscription({
      variables: {
        transactionModificationId: transactionModification?.id || ``,
      },
      onSubscriptionData: () => {
        apolloClient.refetchQueries({
          include: [TransactionPageTransactionByIdDocument],
        });
      },
    });

    const toast = useToast();

    const persona = {
      isBuyer: transaction.buyerId === actor.id,
      isBrokerSeller: transaction.sellerBrokerId === actor.id,
      isBrokerBuyer: transaction.buyerBrokerId === actor.id,
      isSeller: transaction.sellerId === actor.id,
    };

    const isSecuritySpecialist =
      !!(persona.isSeller && transaction.sellerBrokerId) ||
      !!(persona.isBuyer && transaction.buyerBrokerId);

    const transactionModificationAcknowledgmentForUser =
      transactionModification?.acknowledgments.find(
        (ack) => ack.userId === actor.id,
      );

    const proposedTransactionUpdate = `${t`transaction_modification_confirm_modal_proposed_update`}: ${formatShares(
      proposedNumberOfShares,
    )} ${pluralize(`share`, proposedNumberOfShares)} of ${
      transaction?.company?.name
    } @${currency(proposedPricePerShare || 0, {
      fromCents: true,
    })}/sh ${transferMethodToString(transactionTransferMethod)}.`;
    const supportEmail = `execution@hiive.com`;

    const isUserActionRequired =
      transactionModificationAcknowledgmentForUser?.status ===
      TransactionModificationAcknowledgmentStatus.Pending;

    const hasCounterpartyCompletedAcknowledgment = () => {
      const counterPartyAcknowledgment =
        transactionModification?.acknowledgments.find(
          (ack) => ack.id !== transactionModificationAcknowledgmentForUser?.id,
        );

      if (!counterPartyAcknowledgment) {
        return false;
      }

      return (
        counterPartyAcknowledgment.status !==
        TransactionModificationAcknowledgmentStatus.Pending
      );
    };

    const updateAcknowledgment = async (
      status: UpdateTransactionModificationAcknowledgmentStatus,
    ) => {
      const shouldShowToast = hasCounterpartyCompletedAcknowledgment();
      await updateTransactionModificationAcknowledgment({
        variables: {
          transactionId: transaction.id || ``,
          input: {
            status,
          },
        },
      });

      if (shouldShowToast) {
        const hasModificationBeenApproved =
          status === UpdateTransactionModificationAcknowledgmentStatus.Approved;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toastRef.current = toast({
          duration: ToastDuration.Short,
          isClosable: true,
          render: () => (
            <HStack
              p={3}
              borderRadius="md"
              bg={hasModificationBeenApproved ? green400 : red600}
              flexDirection="row"
              justifyContent="space-between"
            >
              <HStack>
                <CheckCircle size={20} weight="fill" color={white} />
                <Text textStyle="text-sm" fontWeight="medium" color={white}>
                  {t(
                    hasModificationBeenApproved
                      ? `modification_applied`
                      : `modification_rejected`,
                  )}
                </Text>
              </HStack>
              <X
                color={white}
                size={15}
                onClick={() => toast.close(toastRef.current!)} // eslint-disable-line @typescript-eslint/no-non-null-assertion
              />
            </HStack>
          ),
        });
      }
    };

    const onOpenConfirmTransactionModificationModal = () => {
      onOpenModal(
        modals.transactionModification({
          name: t(`transaction_modification_confirm_modal_name`),
          title: t(`transaction_modification_confirm_modal_title`),
          description: t(
            persona.isBrokerBuyer || persona.isBrokerSeller
              ? `transaction_modification_confirm_modal_description_broker`
              : `transaction_modification_confirm_modal_description`,
            {
              signingProcedure: getLongDocumentTitleByTransferMethod(
                transactionTransferMethod,
              ),
            },
          ),
          proposedUpdate: proposedTransactionUpdate,
          onCancel: closeModal,
          onConfirm: async () => {
            await updateAcknowledgment(
              UpdateTransactionModificationAcknowledgmentStatus.Approved,
            );
            closeModal();
          },
        }),
      )();
    };

    const onOpenRejectTransactionModificationModal = () => {
      onOpenModal(
        modals.transactionModification({
          name: t(`transaction_modification_reject_modal_name`),
          title: t(`transaction_modification_reject_modal_title`),
          description: (
            <Trans
              i18nKey={
                persona.isBrokerBuyer || persona.isBrokerSeller
                  ? `transaction_modification_reject_modal_description_broker`
                  : `transaction_modification_reject_modal_description`
              }
              t={t}
              components={[
                <MailtoLink
                  key="contact"
                  textDecoration="underline"
                  email={supportEmail}
                />,
              ]}
              values={{ supportEmail }}
            />
          ),
          proposedUpdate: proposedTransactionUpdate,
          onCancel: closeModal,
          onConfirm: async () => {
            await updateAcknowledgment(
              UpdateTransactionModificationAcknowledgmentStatus.Rejected,
            );
            closeModal();
          },
        }),
      )();
    };

    const getDescriptionKey: (value: {
      readonly isBrokerSeller: boolean;
      readonly isBrokerBuyer: boolean;
      readonly isSeller: boolean;
      readonly isBuyer: boolean;
      readonly isSecuritySpecialist: boolean;
    }) => string = (value) =>
      match(value)
        .with(
          {
            isSecuritySpecialist: true,
          },
          () => `transaction_modification_body_security_specialist`,
        )
        .with(
          {
            isBrokerSeller: true,
            isBrokerBuyer: false,
            isSeller: false,
            isBuyer: false,
          },
          () => `transaction_modification_body_broker`,
        )
        .with(
          {
            isBrokerSeller: false,
            isBrokerBuyer: true,
            isSeller: false,
            isBuyer: false,
          },
          () => `transaction_modification_body_broker`,
        )
        .with(
          {
            isBrokerSeller: false,
            isBrokerBuyer: false,
            isSeller: false,
            isBuyer: true,
          },
          () => `transaction_modification_body`,
        )
        .with(
          {
            isBrokerSeller: false,
            isBrokerBuyer: false,
            isSeller: true,
            isBuyer: false,
          },
          () => `transaction_modification_body`,
        )
        .otherwise(() => {
          throw new Error(`Unhandled value`);
        });

    const descriptionKey = getDescriptionKey({
      ...persona,
      isSecuritySpecialist,
    });

    const shouldShowModification =
      transactionModification?.acknowledgments.some((acknowledgment) =>
        getShouldShowAcknowledgment(actor, transaction, acknowledgment),
      );

    if (!shouldShowModification) return null;

    return (
      <Card w="full">
        <CardBody>
          <VStack gap={2}>
            <HStack
              alignItems="flex-start"
              justifyContent="flex-start"
              w="full"
            >
              <WarningCircle fill={red600} weight="fill" size="2em" />
              <Text
                textStyle={{
                  base: `heading-xl`,
                  md: `heading-2xl`,
                }}
              >{t`transaction_modification_header`}</Text>
            </HStack>
            <VStack alignItems="flex-start" spacing={4}>
              <Text>{t(descriptionKey as TranslationKey)}</Text>
              <VStack gap={1} w="full">
                <TransactionModificationFeeBreakdown
                  numberOfShares={proposedNumberOfShares}
                  outdatedNumberOfShares={currentNumberOfShares}
                  isBroker={persona.isBrokerSeller}
                  isBuyer={persona.isBuyer || persona.isBrokerBuyer}
                  pricePerShare={proposedPricePerShare}
                  outdatedPricePerShare={currentPricePerShare}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  commission={transactionModification!.sellerCommission!}
                  pendingFeeDiscountApplications={
                    pendingFeeDiscountApplications
                  }
                />
                <TransactionModificationStatus
                  transaction={transaction}
                  modificationAcknowledgments={
                    transactionModification!.acknowledgments // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  }
                />
              </VStack>
            </VStack>
            {isUserActionRequired && (
              <TransactionModificationCTA
                onAccept={onOpenConfirmTransactionModificationModal}
                onReject={onOpenRejectTransactionModificationModal}
              />
            )}
          </VStack>
        </CardBody>
      </Card>
    );
  },
);

export default TransactionModification;
