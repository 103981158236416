import currency from "currency.js";
import { useTranslation } from "react-i18next";

import {
  Card,
  Divider,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ExecutionSendFundsTaskData } from "@/gql";
import * as datetime from "@/utils/datetime";

import PaymentItem from "./PaymentItem";

type FundReceiptsProps = Pick<
  ExecutionSendFundsTaskData,
  "fundsReceipts" | "totalFundsSent"
>;

const FundReceipts = ({ fundsReceipts, totalFundsSent }: FundReceiptsProps) => {
  const { t } = useTranslation(`execution`);
  const showTotal = fundsReceipts?.length > 1;

  return (
    <List
      as={Card}
      boxShadow="none"
      bgColor="grey.50"
      border={0}
      variant="flat"
      px={4}
    >
      {fundsReceipts.map((fundsReceipt, key) => (
        <>
          <PaymentItem
            amount={currency(fundsReceipt.amount / 100).format()}
            label={
              <VStack alignItems="start" gap={1}>
                <Text textTransform="capitalize" textStyle="heading-xs">
                  {datetime.format(`Do MMMM, YYYY`, fundsReceipt.insertedAt)}
                </Text>
                <Text textStyle="text-xs">{t(`received_by_hiive`)}</Text>
              </VStack>
            }
            key={fundsReceipt.id}
          />
          {fundsReceipts[key + 1] && <Divider bgColor="grey.200" />}
        </>
      ))}
      {showTotal && (
        <>
          <Divider bgColor="grey.200" />
          <ListItem as={HStack} justifyContent="space-between" h={20} px={0}>
            <Text textStyle="heading-xs">{t(`total_paid`)}</Text>
            <Text textStyle="heading-md">
              {currency(totalFundsSent / 100).format()}
            </Text>
          </ListItem>
        </>
      )}
    </List>
  );
};

export default FundReceipts;
