import { Info } from "@phosphor-icons/react";
import { Trans } from "react-i18next";

import { Box, Card, CardBody, HStack, Link, Text } from "@chakra-ui/react";

const IndividualEntityWarningCard = () => (
  <Card
    variant="flat"
    p={0}
    w="full"
    bg="grey.50"
    borderWidth={1}
    borderColor="grey.800"
  >
    <CardBody p={0} px={6} py={4}>
      <HStack gap={4}>
        <Box p={1}>
          <Info size={24} weight="fill" />
        </Box>
        <Text color="grey.700" textStyle="text-md">
          <Trans
            components={{
              a: (
                <Link
                  textDecoration="underline"
                  href="mailto:support@hiive.com"
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
            i18nKey="entities.individual_entity_warning"
            ns="account"
          />
        </Text>
      </HStack>
    </CardBody>
  </Card>
);

export default IndividualEntityWarningCard;
