import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text, VStack } from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { StandingBidRestrictedCardStandingBidFragment } from "@/gql";
import { constants } from "@/utils";

export const StandingBidRestrictedCard = ({
  standingBid,
}: {
  readonly standingBid: StandingBidRestrictedCardStandingBidFragment;
}) => {
  const { t } = useTranslation(`standingBids`);

  const { company } = standingBid;

  const contactEmail =
    company.securitySpecialist?.user.email ||
    company.securitySpecialist?.marketAnalyst?.email ||
    constants.email_security_specialist;

  return (
    <Flex w="full" direction="column" gap={4}>
      <Card>
        <CardBody as={VStack} spacing={3} alignItems="flex-start">
          <Text textStyle="heading-sm">{t(`accept_standing_bid`)}</Text>
          <Text>
            <Trans
              i18nKey="if_you_are_interested_in_accepting"
              ns="standingBids"
              values={{ contactEmail }}
              components={{
                mailto: (
                  <MailtoLink
                    key="inquiry_on_standing_bid"
                    fontWeight={500}
                    email={contactEmail}
                    subject={t(`inquiry_on_standing_bid`, {
                      companyName: company.name,
                    })}
                  />
                ),
              }}
            />
          </Text>
        </CardBody>
      </Card>
    </Flex>
  );
};
