import { ListingState } from "@/gql";
import { useModal } from "@/hooks";
import { useRestrictiveInReview } from "@/hooks/featureFlags";

import { CreateListingInReviewSuccessModal } from "./CreateListingInReviewSuccessModal";
import { useCreateListingSequenceModalStepFormContext } from "./CreateListingSequenceModalStepFormContext";
import CreateListingSuccessModal from "./CreateListingSuccessModal";

export function CreateListingCompleteModal() {
  const restrictiveInReview = useRestrictiveInReview();

  const { mutationData: response } =
    useCreateListingSequenceModalStepFormContext();

  const { closeModal } = useModal();

  if (!response) {
    return null;
  }

  const {
    createListing: { listing, errors },
  } = response;

  if (errors || !listing) {
    closeModal();
    return null;
  }

  const { state } = listing;

  const listinInReview = state === ListingState.InReview;
  const isInReview = restrictiveInReview && listinInReview;

  if (isInReview) {
    return <CreateListingInReviewSuccessModal listing={listing} />;
  }

  return <CreateListingSuccessModal listing={listing} />;
}
