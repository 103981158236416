import { useTranslation } from "react-i18next";

import { SimpleGrid, Text } from "@chakra-ui/react";

import useTransactionExecution from "@/hooks/useTransactionExecution";
import * as datetime from "@/utils/datetime";

import CounterpartyDetails from "./CounterpartyDetails";

const TransactionExecutionPageInfo = () => {
  const transaction = useTransactionExecution();
  const { t } = useTranslation(`execution`);

  return (
    <SimpleGrid
      gridTemplateColumns={{ base: `1fr 2fr`, md: `1fr 5fr` }}
      spacing={2}
    >
      <Text w={{ md: 24 }} textStyle="heading-xs">
        {t(`accepted`)}
      </Text>
      <Text textStyle="text-sm">
        {datetime.format(`Do MMM (H:MM A z)`, transaction?.bid.acceptedAt)}
      </Text>
      <Text w={{ md: 24 }} textStyle="heading-xs">
        {t(`share_type`)}
      </Text>
      <Text overflowX="hidden" textStyle="text-sm" textTransform="capitalize">
        {transaction?.shareType?.toLowerCase()}
      </Text>
      <Text w={{ md: 24 }} textStyle="heading-xs">
        {t(`counter_party`)}
      </Text>
      <CounterpartyDetails />
    </SimpleGrid>
  );
};

export default TransactionExecutionPageInfo;
