import { match } from "ts-pattern";

import {
  ExecutionAnvilSignDocumentTaskData,
  ExecutionTaskStatus,
  ExecutionTaskType,
  TransactionExecutionPageTaskFragment,
  TransactionExecutionPageTransactionFragment,
  ExecutionAnvilFillDocumentTaskData,
} from "@/gql";

import AnvilFillDocumentTaskCard from "./AnvilDocumentTaskCard/AnvilFillDocumentTaskCard";
import AnvilSignDocumentTaskCard from "./AnvilDocumentTaskCard/AnvilSignDocumentTaskCard";
import CollectBankAccountTaskCard from "./CollectBankAccountTaskCard/CollectBankAccountTaskCard";
import ConfirmTransactionModificationTaskCard from "./ConfirmTransactionModificationTaskCard";
import EntityCollectionTaskCardWrapper from "./EntityCollectionTaskCard/EntityCollectionTaskCardWrapper";
import SendFundsTaskCard from "./SendFundsTaskCard/SendFundsTaskCard";
import UploadFilesTaskCard from "./UploadFilesTaskCard";
import VerifyBankAccountTaskCard from "./VerifyBankAccountTaskCard";

const excludeTasks = (task: TransactionExecutionPageTaskFragment) => {
  // if sign task is upcoming, we're gonna show it as pending
  if (task.type === ExecutionTaskType.AnvilSignDocument) {
    return task.status !== ExecutionTaskStatus.Terminated;
  }

  return ![
    ExecutionTaskStatus.Pending,
    ExecutionTaskStatus.Terminated,
  ].includes(task.status);
};

const Tasks = ({
  tasks,
  isBuySide,
  transaction,
}: {
  readonly tasks: TransactionExecutionPageTaskFragment[];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => (
  <>
    {tasks
      .filter((task) => excludeTasks(task))
      .map((task) =>
        match(task)
          .with(
            {
              type: ExecutionTaskType.AnvilFillDocument,
              data: {
                __typename: `ExecutionAnvilFillDocumentTaskData`,
              },
            },
            (task) => (
              <AnvilFillDocumentTaskCard
                data={task.data as ExecutionAnvilFillDocumentTaskData}
                transaction={transaction}
                key={task.id}
                task={task}
                isBuySide={isBuySide}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.AnvilSignDocument,
              data: { __typename: `ExecutionAnvilSignDocumentTaskData` },
            },
            (task) => (
              <AnvilSignDocumentTaskCard
                data={task.data as ExecutionAnvilSignDocumentTaskData}
                transaction={transaction}
                isBuySide={isBuySide}
                key={task.id}
                task={task}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.UploadFiles,
              data: { __typename: `ExecutionUploadFilesTaskData` },
            },
            (task) => <UploadFilesTaskCard key={task.id} task={task} />,
          )
          .with(
            {
              type: ExecutionTaskType.CollectEntity,
              data: { __typename: `ExecutionCollectEntityTaskData` },
            },
            (task) => (
              <EntityCollectionTaskCardWrapper
                transaction={transaction}
                data={task.data}
                key={task.id}
                task={task}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.ConfirmTransactionModification,
              data: {
                __typename: `ExecutionConfirmTransactionModificationTaskData`,
              },
            },
            (task) => (
              <ConfirmTransactionModificationTaskCard
                data={task.data}
                key={task.id}
                isBuySide={isBuySide}
                task={task}
                transaction={transaction}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.CollectBankAccount,
              data: { __typename: `ExecutionCollectBankAccountTaskData` },
            },
            (task) => (
              <CollectBankAccountTaskCard
                data={task.data}
                key={task.id}
                task={task}
              />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.VerifyBankAccount,
              data: { __typename: `ExecutionVerifyBankAccountTaskData` },
            },
            (task) => (
              <VerifyBankAccountTaskCard task={task} data={task.data} />
            ),
          )
          .with(
            {
              type: ExecutionTaskType.SendFunds,
              data: { __typename: `ExecutionSendFundsTaskData` },
            },
            (task) => (
              <SendFundsTaskCard
                data={task.data}
                key={task.id}
                task={task}
                wireAmount={transaction.grossProceeds}
              />
            ),
          )
          .otherwise(() => null),
      )}
  </>
);

export default Tasks;
