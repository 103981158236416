import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { BidWithdrawnPostingCardFragment } from "@/gql";
import { toTimestamp } from "@/utils";

type BidWithdrawnPostingCardUnaccreditedSellerFooterProps = {
  bid: BidWithdrawnPostingCardFragment;
};

export function BidWithdrawnPostingCardUnaccreditedSellerFooter({
  bid: { insertedAt, withdrawnAt },
}: BidWithdrawnPostingCardUnaccreditedSellerFooterProps) {
  const { t } = useTranslation(`bids`);

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm" whiteSpace="nowrap">
        {t(`placed`, {
          timestamp: toTimestamp(insertedAt),
        })}
      </Text>
      {withdrawnAt && (
        <Text textStyle="text-sm" whiteSpace="nowrap">
          {t(`withdrawn_timestamp`, {
            timestamp: toTimestamp(withdrawnAt),
          })}
        </Text>
      )}
    </PostingCard.Footer>
  );
}
