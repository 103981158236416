import { ArrowRight, X } from "@phosphor-icons/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardProps,
  Circle,
  Flex,
  Grid,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";

import { FlowKind } from "@/components/providers";
import { UnaccreditedSellerDashboardByIdPageCompanyFragment } from "@/gql";
import { useFlowLauncher } from "@/hooks";

const Step = ({
  index,
  children,
}: {
  readonly index: number;
  readonly children: ReactNode;
}) => (
  <Grid templateColumns="auto 1fr" gap={{ base: 4, md: 6 }}>
    <Circle size={8} bg="grey.100">
      <Text textStyle="heading-2xl">{index}</Text>
    </Circle>
    <Text textStyle="text-sm">{children}</Text>
  </Grid>
);

interface GettingStartedWithYourSharesBannerProps extends CardProps {
  readonly company: UnaccreditedSellerDashboardByIdPageCompanyFragment;
  readonly onClose: () => void;
}

const GettingStartedWithYourSharesBanner = ({
  company,
  onClose,
}: GettingStartedWithYourSharesBannerProps) => {
  const { t } = useTranslation();
  const { showFlow } = useFlowLauncher();

  const onClickWatchVideo = () => {
    showFlow(FlowKind.U16ROverview);
  };

  return (
    <Card variant="darkened-footer" position="relative">
      <IconButton
        aria-label="Close banner"
        onClick={onClose}
        variant="icon-card-close"
        icon={<X size={24} />}
        position="absolute"
        right={2}
        top={2}
      />
      <CardBody>
        <Flex direction="column" gap={{ base: 6, md: 8 }} mb={2}>
          <Text textStyle="heading-xl" pr={6}>
            {t(`u16r_intro_banner_title`)}
          </Text>
          <Grid templateColumns="repeat(auto-fit, minmax(260px, 1fr))" gap={8}>
            <Step index={1}>
              {t(`u16r_intro_banner_company_overview_step`, {
                companyName: company.name,
              })}
            </Step>
            <Step index={2}>{t(`u16r_intro_banner_listing_step`)}</Step>
            <Step index={3}>{t(`u16r_intro_banner_notifications_step`)}</Step>
            <Step index={4}>{t(`u16r_intro_banner_transaction_step`)}</Step>
          </Grid>
        </Flex>
      </CardBody>
      <CardFooter justifyContent="center">
        <Flex as={Text} gap={1} wrap="wrap">
          {t(`u16r_intro_banner_learn_more_prompt`)}
          {` `}
          <HStack
            as="button"
            fontWeight="medium"
            _hover={{ textDecoration: `underline` }}
            spacing={1}
            onClick={onClickWatchVideo}
          >
            <Text as="span">{t(`watch_video`)}</Text>
            <Box as={ArrowRight} size={18} />
          </HStack>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export default GettingStartedWithYourSharesBanner;
