import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardFooter, CardHeader, VStack, Text } from "@chakra-ui/react";

import {
  TransferTypeChoiceInput,
  ShareSeriesInput,
  transferTypeChoices,
} from "@/components/listings";
import { UnaccreditedSellerCreateListingPageByIdCompanyFragment } from "@/gql";
import { useRestrictiveInReview } from "@/hooks/featureFlags";
import { Nullable } from "@/utils";

import { CompanyUnaccreditedSellerRestrictedAlert } from "./CompanyUnaccreditedSellerRestrictedAlert";
import { ExplainSellSharesDirectlyInput } from "./ExplainSellSharesDirectlyInput";
import { CreateListingFormValues } from "./types";

type TransferTypeCardProps = {
  company: UnaccreditedSellerCreateListingPageByIdCompanyFragment;
  values: Nullable<CreateListingFormValues>;
};

export const TransferTypeCard = forwardRef<
  HTMLDivElement,
  TransferTypeCardProps
>(
  (
    {
      company: { name: companyName, restricted },
      values: { transferTypeChoice },
    },
    ref,
  ) => {
    const restrictiveInReview = useRestrictiveInReview();
    const { t } = useTranslation(`listings`);
    const isRestricted = !!restrictiveInReview && restricted;

    const canSellSharesDirectlyOnRestrictedCompany =
      isRestricted && transferTypeChoice === transferTypeChoices.Yes;

    return (
      <Card w="full" ref={ref}>
        <CardHeader>
          <Text textStyle="heading-sm">
            {t(`transfer_type_label`, {
              index: 2,
            })}
          </Text>
        </CardHeader>
        <CardFooter>
          <VStack spacing={5}>
            {isRestricted && (
              <CompanyUnaccreditedSellerRestrictedAlert
                companyName={companyName}
              />
            )}
            <TransferTypeChoiceInput isRestricted={isRestricted} />
            {canSellSharesDirectlyOnRestrictedCompany && (
              <ExplainSellSharesDirectlyInput />
            )}
            <ShareSeriesInput />
          </VStack>
        </CardFooter>
      </Card>
    );
  },
);
