import { CreateListingSuccessModalListingFragment } from "@/gql";
import { useRestrictiveInReview } from "@/hooks/featureFlags";

import { UnaccreditedSellerCreateListingSuccessInReviewModalContent } from "./UnaccreditedSellerCreateListingSuccessInReviewModalContent";
import { UnaccreditedSellerCreateListingSuccessModalContent } from "./UnaccreditedSellerCreateListingSuccessModalContent";

type UnaccreditedSellerCreateListingSuccessModalProps = {
  readonly listing: CreateListingSuccessModalListingFragment;
};

function UnaccreditedSellerCreateListingSuccessModal({
  listing,
}: UnaccreditedSellerCreateListingSuccessModalProps) {
  const {
    company: { restricted },
  } = listing;

  const restrictiveInReview = useRestrictiveInReview();
  const isRestricted = !!restrictiveInReview && restricted;

  return isRestricted ? (
    <UnaccreditedSellerCreateListingSuccessInReviewModalContent
      listing={listing}
    />
  ) : (
    <UnaccreditedSellerCreateListingSuccessModalContent listing={listing} />
  );
}

export default UnaccreditedSellerCreateListingSuccessModal;
