import { useTranslation } from "react-i18next";

import { Box, HStack, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import {
  ConditionallySoldOutBadge,
  OpenBidsBadge,
  InquiriesBadge,
  PartiallySoldBadge,
  calculateTotalOpenBids,
} from "@/components/companies";
import { ListingYourActivityPostingCardFragment, ListingState } from "@/gql";
import { toTimestamp } from "@/utils";
import { countTotalUnreadBidDiscussionMessages } from "@/utils/bid";

type ListingYourActivityPostingCardBrokerFooterProps = {
  listing: ListingYourActivityPostingCardFragment;
};

export function ListingYourActivityPostingCardBrokerFooter({
  listing: {
    acceptedBidPrices: { length: acceptedBidPricesLength },
    transferMethod,
    numActiveBids,
    numCounteredBids,
    state,
    bids,
    insertedAt,
    placedAt,
    numInquiries,
    numUnreadMessages,
  },
}: ListingYourActivityPostingCardBrokerFooterProps) {
  const { t } = useTranslation();

  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);
  const totalUnreadBidDiscussionMessages =
    countTotalUnreadBidDiscussionMessages(bids);
  const numUnreadMessagesAsideFromBids =
    numUnreadMessages - totalUnreadBidDiscussionMessages;

  const isInReview = state === ListingState.InReview;
  const isConditionallySold = state === ListingState.ConditionallySold;
  const isPartiallySold =
    state === ListingState.Open && acceptedBidPricesLength >= 1;
  const isPlaced = state === ListingState.Open;

  const hasOpenBids = totalOpenBids >= 1;
  const hasInquiries = numInquiries > 0;

  const insertedAtFormatted = toTimestamp(insertedAt);
  const placedAtFormatted = toTimestamp(placedAt);

  return (
    <PostingCard.Footer>
      {isInReview && (
        <Text>
          {t(`listing_submitted_in_review`, { date: insertedAtFormatted })}
        </Text>
      )}
      {isPlaced && (
        <Text>{t(`listing_placed_status`, { date: placedAtFormatted })}</Text>
      )}

      <Box flexGrow={1} />

      <HStack wrap="wrap" align="flex-end" spacing={{ base: 2, md: 3 }}>
        {isConditionallySold && (
          <ConditionallySoldOutBadge transferMethod={transferMethod} />
        )}
        {isPartiallySold && (
          <PartiallySoldBadge transferMethod={transferMethod} />
        )}
        {hasInquiries && (
          <InquiriesBadge
            numInquiries={numInquiries}
            numUnreadMessages={numUnreadMessagesAsideFromBids}
          />
        )}
        {hasOpenBids && (
          <OpenBidsBadge
            totalOpenBids={totalOpenBids}
            bids={bids}
            transferMethod={transferMethod}
          />
        )}
      </HStack>
    </PostingCard.Footer>
  );
}
