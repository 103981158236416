export default {
  highest_bid: `Highest Bid`,
  highest_bid_tooltip: `Bid is the highest priced bid on {{companyName}} listings or {{companyName}} standing bids that was opened this week.`,
  lowest_ask: `Lowest Ask`,
  lowest_ask_tooltip: `Ask is the lowest priced listing for {{companyName}} that was open this week.`,
  last_accepted_bid: `Last Accepted Bid`,
  last_accepted_bid_tooltip: `The most recent price at which a bid was accepted for {{companyName}}.`,
  overview: `Overview`,
  your_activity: `Your Activity`,
  market_activity: `Market Activity`,
  sellers: `Sellers`,
  buyers: `Buyers`,
  listings: `Listings`,
  bids: `Bids`,
  standing_bids: `Standing Bids`,
  inquiries: `Inquiries`,
  company_title: `Company - {{companyName}}`,
  your_activity_on_behalf_of_institution: `Your Activity on Behalf of {{legalName}}`,
  other_institution_activity: `Other {{legalName}} Activity`,
  institution_activity: `{{legalName}} Activity`,
  currently_no_activity: `You currently have no activity for {{name}}.`,
  no_active_standing_bids_orderbook: `No active standing bids for {{companyName}}. Add this company to your watchlist to get notified about new standing bids.`,
  no_active_listings_orderbook: `No active listings for {{companyName}}. Add this company to your watchlist to get notified about new listings.`,
  view_listings_over: `View listings over {{pricePerShare}}/sh`,
  view_standing_bids_under: `View standing bids under {{pricePerShare}}/sh`,
  any: `Any`,
  standing_bid: `Standing Bid`,
  posting_num_shares_price: `<b>{{ numSharesOriginal }} @ {{ pricePerShare }}<r>/sh</r></b>`,
  posting_partially_sold_num_shares_price: `<u><b>{{ numSharesOriginal }}</b></u><spacer/><b>{{ numSharesAvailable }} @ {{ pricePerShare }}<r>/sh</r></b>`,
  posting_sold_out_num_shares_price: `<u><b>{{ numSharesOriginal }} @ {{ pricePerShare }}</b>/sh<b></b></u>`,
  posting_lot_price: `<b>{{lot}}</b>`,
  posting_lot_price_sold_out: `<u><b>{{lot}}</b></u>`,
  standing_bid_shares_purchased: `{{ numSharesAccepted }} shares purchased`,
  transfer_method: `Transfer type`,
  share_type: `Share type`,
  only_allows_transfers_specific_circumstances: `{{ companyName }} only allow direct transfers of their shares in specific circumstances. If you do not have prior consent from {{ companyName }} to sell your shares, please select a transfer type of “Other”.`,
  please_explain_how_direct: `Please explain how you are able to sell your shares directly.`,
  please_explain_how_direct_example: `For example, you have written authorization to sell directly or you have preferred shares`,
  not_visible_to_other_parties: `This information is not visible to other parties on the platform.`,
  company_in_review: `Your {{ companyName }} listing is in review!`,
  let_you_know_when_live: `We’ll let you know when it’s live on the platform. If we need more information, a member of our team will reach out to you.`,
  for_support_contact: `For support with your listing, contact <0>{{email}}</0>.`,
  contact_company_security_specialist: `Contact {{ companyName }} Security Specialist`,
  does_not_allow_most_direct_transfers: `Figma does not allow most direct transfers of shares. If you cannot transfer directly, we’ll adjust your transfer method to accommodate this.`,
  you_are_not_authorized_to_sell_directly: `Eg. you are authorized to sell directly by the company `,
};
