import { useTranslation } from "react-i18next";

import { PostingCard, PostingCardCompanyTile } from "@/components/common";
import { BidYourActivityDashboardPostingCardFragment } from "@/gql";
import { getIsHiiveFund } from "@/utils";

type BidYourActivityDashboardPostingCardHeaderProps = {
  bid: BidYourActivityDashboardPostingCardFragment;
};

export function BidYourActivityDashboardPostingCardHeader({
  bid: {
    displayId,
    listing: { transferMethod },
    company,
  },
}: BidYourActivityDashboardPostingCardHeaderProps) {
  const { t } = useTranslation(`bids`);

  const isHiiveFund = getIsHiiveFund(transferMethod);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="bid">
            {isHiiveFund ? t(`order`) : t(`bid`)}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{displayId}</PostingCard.HeaderTitle>
        </>
      }
    >
      <PostingCardCompanyTile company={company} />
    </PostingCard.Header>
  );
}
