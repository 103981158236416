import { ReactNode } from "react";

import { AutosizeTextAreaInput } from "@/components/form";

const OtherDetailsInput = ({
  name,
  label,
  placeholder,
  tooltipContent,
  helperText,
}: {
  readonly name: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly tooltipContent?: ReactNode;
  readonly helperText?: string;
}) => (
  <AutosizeTextAreaInput
    name={name}
    label={label}
    placeholder={placeholder}
    helperText={helperText}
    tooltipSize="lg"
    maxLength={1000}
    tooltipContent={tooltipContent}
  />
);

export default OtherDetailsInput;
