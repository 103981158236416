import { PostingCard } from "@/components/common";
import { StandingBidPostingCardBodyFragment } from "@/gql";

import { StandingBidPostingCardBodyAcceptedShares } from "./StandingBidPostingCardBodyAcceptedShares";
import { StandingBidPostingCardBodyPricing } from "./StandingBidPostingCardBodyPricing";

type StandingBidPostingCardBodyProps = {
  standingBid: StandingBidPostingCardBodyFragment;
};

export function StandingBidPostingCardBody({
  standingBid,
}: StandingBidPostingCardBodyProps) {
  return (
    <PostingCard.Body>
      <StandingBidPostingCardBodyPricing standingBid={standingBid} />
      <StandingBidPostingCardBodyAcceptedShares standingBid={standingBid} />
    </PostingCard.Body>
  );
}
