import { useTranslation } from "react-i18next";

import {
  Text,
  SimpleGrid,
  FormLabel,
  GridItem,
  SimpleGridProps,
} from "@chakra-ui/react";

import {
  FormPhoneNumberInput,
  FormRadioTile,
  FormTextInput,
} from "@/components/react-hook-form";
import type { TrustedContactFormValues } from "@/components/trusted-contact-form";
import { useFormQL } from "@/hooks/react-hook-form";

type TrustedContactFormProps = {
  readonly trustedContactForm: Pick<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReturnType<typeof useFormQL<TrustedContactFormValues, any, any>>,
    `watch` | `handleSubmit` | `control`
  >;
  width?: SimpleGridProps["width"];
};

const TrustedContactForm = ({
  trustedContactForm,
  width = { base: `full`, lg: `70%` },
}: TrustedContactFormProps) => {
  const { t } = useTranslation();
  const { control, watch } = trustedContactForm;
  const shouldShowTrustedPersonForm = !!watch(`collectTrustedContactPerson`);
  return (
    <SimpleGrid gridTemplateColumns="1fr 1fr" mt={5} rowGap={6} width={width}>
      <GridItem colSpan={2}>
        <FormRadioTile.Group
          size="md"
          px={0}
          gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
        >
          <FormRadioTile.Tile
            control={control}
            name="collectTrustedContactPerson"
            value
            alignItems="center"
          >
            <Text textStyle="text-md">{t`yes`}</Text>
          </FormRadioTile.Tile>
          <FormRadioTile.Tile
            control={control}
            name="collectTrustedContactPerson"
            value={false}
            alignItems="center"
          >
            <Text textStyle="text-md">{t`no`}</Text>
          </FormRadioTile.Tile>
        </FormRadioTile.Group>
      </GridItem>
      {shouldShowTrustedPersonForm && (
        <>
          <GridItem colSpan={2}>
            <FormLabel>{t`name`} *</FormLabel>
            <SimpleGrid
              gridTemplateColumns={{ base: `1fr`, lg: `1fr 1fr` }}
              rowGap={{ base: 2, lg: 0 }}
              columnGap={2}
            >
              <GridItem colSpan={1}>
                <FormTextInput
                  control={control}
                  name="firstName"
                  placeholder={t`first`}
                  label=""
                  labelSrOnly
                />
              </GridItem>
              <GridItem colSpan={1}>
                <FormTextInput
                  control={control}
                  name="lastName"
                  placeholder={t`last`}
                  label=""
                  labelSrOnly
                />
              </GridItem>
            </SimpleGrid>
          </GridItem>

          <GridItem colSpan={2}>
            <FormPhoneNumberInput
              control={control}
              isRequired
              name="phoneNumber"
              label={t(`telephone`)}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FormTextInput
              type="email"
              isRequired
              name="email"
              label={t(`email`)}
              placeholder={t(`email_address`)}
              control={control}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <FormTextInput
              isRequired
              name="relationship"
              label={t(`relationship`)}
              placeholder={t(`relationship_placeholder`)}
              control={control}
            />
          </GridItem>
        </>
      )}
    </SimpleGrid>
  );
};

export default TrustedContactForm;
