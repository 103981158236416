import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import { ReactElement } from "react";

import { Flex } from "@chakra-ui/react";

import { Loader } from "@/components/common";
import { FeatureFlagsHelper } from "@/components/helpers";
import { NavBar } from "@/components/nav";
import {
  FrontChatProvider,
  KnockProvider,
  ModalProvider,
  CurrentActorProvider,
} from "@/components/providers";
import { useCurrentContextQuery } from "@/gql";
import {
  useCheckAuthentication,
  useCheckOnboarding,
  useCorrectPortal,
  useIdentifyUser,
} from "@/hooks";
import { AuthenticatedLayoutHookProps } from "@/hooks/authenticated-layout";
import { constants } from "@/utils";

import Layout from "./Layout";

interface AuthenticatedLayoutProps {
  readonly children: JSX.Element | readonly JSX.Element[];
  readonly title?: string;
  readonly renderNavBar?: boolean;
}

const { isAuth0Enabled } = constants;

const AuthenticatedProviders = ({
  children,
}: {
  readonly children: ReactElement;
}) => (
  <CurrentActorProvider>
    <ModalProvider>
      <FeatureFlagsHelper />
      <KnockProvider>
        <FrontChatProvider>{children}</FrontChatProvider>
      </KnockProvider>
    </ModalProvider>
  </CurrentActorProvider>
);

const useAuthenticationFlow = ({
  actor,
  loading,
}: AuthenticatedLayoutHookProps & { readonly loading: boolean }) => {
  useCheckAuthentication({ actor, loading });
  useIdentifyUser({ actor });
  useCorrectPortal({ actor });
  const { redirectingToOnboarding } = useCheckOnboarding({ actor });
  return { redirectingToOnboarding };
};

const AuthenticatedLayout = ({
  children,
  title,
  renderNavBar = true,
}: AuthenticatedLayoutProps) => {
  const { data, loading } = useCurrentContextQuery();
  const actor = data?.currentContext?.currentActor;

  const { redirectingToOnboarding } = useAuthenticationFlow({ actor, loading });

  if (loading || redirectingToOnboarding) {
    return <Loader minHeight="100vh" />;
  }

  return (
    <AuthenticatedProviders>
      <Layout title={title}>
        <Flex
          direction="column"
          minH="100vh"
          flex="1 1 auto"
          position="relative"
          {...(!renderNavBar && { as: `main`, id: `main-element` })}
        >
          {renderNavBar ? (
            <>
              <NavBar />
              <Flex
                as="main"
                id="main-element"
                flex="1 1 auto"
                direction="column"
                align="center"
              >
                {children}
              </Flex>
            </>
          ) : (
            children
          )}
        </Flex>
      </Layout>
    </AuthenticatedProviders>
  );
};
const AuthenticatedLayoutWrapper = ({ ...props }: AuthenticatedLayoutProps) => {
  if (isAuth0Enabled) {
    return withPageAuthRequired(AuthenticatedLayout)({ ...props });
  }

  return <AuthenticatedLayout {...props} />;
};

export default AuthenticatedLayoutWrapper;
