import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  Skeleton,
  WithQuery,
  BidPostingCard,
  InReviewActivity,
} from "@/components/common";
import { checkListingTerminalState } from "@/components/postings";
import {
  BidState,
  ListingState,
  PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment,
  PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment,
  usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery,
} from "@/gql";
import {
  useCardUpdates,
  useRemoveClientWithdrawFunction,
  useRestrictiveInReview,
} from "@/hooks/featureFlags";
import { getAvailableListingActions } from "@/utils";

import { ActiveBidCard } from "./ActiveBidCard";
import { ListingActionsTileV2 } from "./ListingActionsTileV2";
import { ListingInfoCard } from "./ListingInfoCard";
import { ProceedsDetailsCard } from "./ProceedsDetailsCard";

const ActiveBidsEmptyState = () => {
  const { t } = useTranslation();
  return <ActivityGroupEmptyState message={t(`no_active_bids_on_listing`)} />;
};

interface ActiveBidsProps {
  readonly activeBids: readonly PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment[];
}

const ActiveBids = ({ activeBids }: ActiveBidsProps) => {
  const cardUpdatesEnabled = useCardUpdates();
  const { t } = useTranslation();

  return (
    <VStack alignItems="flex-start" spacing={4} w="full">
      <Text textStyle="heading-sm" textTransform="uppercase">
        {t(`active_bids`)}
      </Text>
      <Text textStyle="text-md">{t(`click_on_bid_to_respond`)}</Text>
      <ActivityGroup emptyState={<ActiveBidsEmptyState />}>
        {activeBids.map((activeBid) =>
          cardUpdatesEnabled ? (
            <BidPostingCard key={activeBid.id} bid={activeBid} />
          ) : (
            <ActiveBidCard key={activeBid.id} activeBid={activeBid} />
          ),
        )}
      </ActivityGroup>
    </VStack>
  );
};

interface MyListingProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment;
}

const MyListing = ({ listing }: MyListingProps) => {
  const { canWithdrawListing, canModifyListing } =
    getAvailableListingActions(listing);

  const removeClientWithdrawFunctionEnabled = useRemoveClientWithdrawFunction();

  const isListingInTerminalState = checkListingTerminalState(listing.state);

  return (
    <VStack spacing={4} w="full">
      <ListingInfoCard listing={listing} />
      {!isListingInTerminalState && <ProceedsDetailsCard listing={listing} />}
      {!removeClientWithdrawFunctionEnabled &&
        (canWithdrawListing || canModifyListing) && (
          <ListingActionsTileV2 listing={listing} />
        )}
    </VStack>
  );
};

const MyListingActivity = ({
  activeBids,
}: {
  readonly activeBids: readonly PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment[];
}) => (
  <ActivitySection>
    <ActiveBids activeBids={activeBids} />
  </ActivitySection>
);

const ActiveBidsPageContent = ({
  listing,
  activeBids,
}: {
  readonly listing: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment;
  readonly activeBids: readonly PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment[];
}) => {
  const { state } = listing;
  const restrictiveInReview = useRestrictiveInReview();

  const isInReview = !!restrictiveInReview && state === ListingState.InReview;

  return (
    <VStack
      spacing={8}
      transform={{ base: `none`, lg: `translateY(-48px)` }}
      pt={{ base: 7, lg: 0 }}
    >
      <MyListing listing={listing} />
      {isInReview ? (
        <InReviewActivity listing={listing} />
      ) : (
        <MyListingActivity activeBids={activeBids} />
      )}
    </VStack>
  );
};

const ActiveBidsPageSkeleton = () => (
  <VStack
    spacing={8}
    transform={{ base: `none`, lg: `translateY(-48px)` }}
    pt={{ base: 7, lg: `none` }}
  >
    <Card w="full">
      <Card borderBottomRadius="30px" h="248px" variant="no-border">
        <CardBody>
          <VStack spacing={6}>
            <VStack w="full" spacing={4} alignItems="flex-start">
              <Skeleton h="28px" w="196px" />
              <Skeleton h="110px" w="full" />
            </VStack>
            <Skeleton h="18px" w="full" maxW="388px" />
          </VStack>
        </CardBody>
      </Card>
      <CardBody>
        <SimpleGrid columnGap={6} gridTemplateColumns="auto 1fr" rowGap={6}>
          <GridItem>
            <Skeleton h={4} w="132px" maxW="132px" />
          </GridItem>
          <GridItem>
            <Skeleton h="12px" maxW="64px" />
          </GridItem>
          <GridItem>
            <Skeleton h={4} w="124px" maxW="124px" />
          </GridItem>
          <GridItem>
            <VStack gap={3} w="full" alignItems="flex-start" spacing={0}>
              <Skeleton h="12px" w="100%" />
              <Skeleton h="12px" w="90%" />
            </VStack>
          </GridItem>
        </SimpleGrid>
      </CardBody>
    </Card>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" maxW="10%" />
      <Skeleton h="16px" maxW="50%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </VStack>
);

const ActiveBidsPageV2 = ({ id }: { id: string }) => {
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageQuery({
      variables: { id },
    });
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<ActiveBidsPageSkeleton />}>
      {({ data: { companyById: company } }) => {
        const listing = company.activity.myListings[0];

        if (!listing) {
          router.replace(`/dashboard/${id}`);
          return null;
        }

        const activeBids = listing.bids.filter(
          (bid) => bid.state === BidState.Active,
        );

        return (
          <ActiveBidsPageContent listing={listing} activeBids={activeBids} />
        );
      }}
    </WithQuery>
  );
};

export default ActiveBidsPageV2;
