import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import {
  InvestorType,
  StandingBidYourActivityPostingCardFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { appendSellerCompanyIdToUrl } from "@/utils";

import { StandingBidPostingCardBody } from "./StandingBidPostingCardBody";
import { StandingBidPostingCardBrokerBody } from "./StandingBidPostingCardBrokerBody";
import { StandingBidPostingCardHeader } from "./StandingBidPostingCardHeader";
import { StandingBidYourActivityPostingCardFooter } from "./StandingBidYourActivityPostingCardFooter";

type StandingBidYourActivityPostingCardProps = {
  standingBid: StandingBidYourActivityPostingCardFragment;
};

function StandingBidYourActivityTraderPostingCard({
  standingBid,
}: StandingBidYourActivityPostingCardProps) {
  const { id } = standingBid;
  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidYourActivityPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

function StandingBidYourActivityBrokerPostingCard({
  standingBid,
}: StandingBidYourActivityPostingCardProps) {
  const { id } = standingBid;

  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBrokerBody standingBid={standingBid} />
      <StandingBidYourActivityPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

function StandingBidUnaccreditedSellerYourActivityPostingCard({
  standingBid,
}: StandingBidYourActivityPostingCardProps) {
  const {
    id,
    company: { id: companyId },
  } = standingBid;

  const url = appendSellerCompanyIdToUrl(`/standing-bids/${id}`, companyId);

  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidYourActivityPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

export function StandingBidYourActivityPostingCard({
  standingBid,
}: StandingBidYourActivityPostingCardProps) {
  const { investorType } = useCurrentActor();
  return match(investorType)
    .with(InvestorType.Broker, () => (
      <StandingBidYourActivityBrokerPostingCard standingBid={standingBid} />
    ))
    .with(InvestorType.UnaccreditedSeller, () => (
      <StandingBidUnaccreditedSellerYourActivityPostingCard
        standingBid={standingBid}
      />
    ))
    .otherwise(() => (
      <StandingBidYourActivityTraderPostingCard standingBid={standingBid} />
    ));
}
