import { useTranslation } from "react-i18next";

import { Text, VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  TransactionExecutionPageTransactionFragment,
  UserWithInstitutionFragment,
} from "@/gql";

import {
  BuySideTransactionBreakdown,
  SellSideTransactionBreakdown,
} from "./Task/components";
import { getTransactionIsBuySide } from "./utils";

const TransactionSummaryCard = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionExecutionPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`execution`);
    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    return (
      <VStack alignItems="flex-start" spacing={4}>
        <Text textStyle="heading-md">{t(`transaction_summary`)}</Text>
        {isBuySide ? (
          <BuySideTransactionBreakdown
            numShares={transaction.numShares}
            pricePerShare={transaction.pricePerShare}
          />
        ) : (
          <SellSideTransactionBreakdown
            newShares={transaction.numShares}
            newPricePerShare={transaction.pricePerShare}
            numShares={transaction.numShares}
            pricePerShare={transaction.pricePerShare}
            commission={transaction.sellerCommission}
            feeDiscountApplications={transaction.feeDiscountApplications || []}
          />
        )}
      </VStack>
    );
  },
);

export default TransactionSummaryCard;
