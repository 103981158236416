import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { InternalLink } from "@/components/common";
import {
  UnaccreditedSellerActiveBidNotificationsCountV2,
  UnaccreditedSellerInquiryNotificationsCountV2,
  UnaccreditedSellerPastBidNotificationsCountV2,
  UnaccreditedSellerStandingBidNotificationsCountV2,
  UnaccreditedSellerTransactionNotificationsCountV2,
} from "@/components/unaccredited-seller";
import {
  ListingState,
  PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompanyFragment,
} from "@/gql";
import { useRestrictiveInReview } from "@/hooks/featureFlags";

import { UnaccreditedDashboardRoutesV2 } from "./types";

interface NavItemProps {
  readonly id: string;
  readonly path: string;
  readonly children: ReactNode;
}

const NavItem = ({ id, path, children }: NavItemProps) => {
  const { asPath } = useRouter();
  const href = `/dashboard/${id}${path}`;
  const isActive = asPath === href;

  return (
    <InternalLink
      scroll={false}
      href={href}
      bg={isActive ? `grey.50` : `white`}
      borderColor={isActive ? `grey.100` : `white`}
      variant="menu"
    >
      {children}
    </InternalLink>
  );
};

export const NavigationCardV2 = ({
  company,
}: {
  company: PostActivityUnaccreditedSellerDashboardByIdPageSidebarCompanyFragment;
}) => {
  const { t } = useTranslation();
  const restrictedInReview = useRestrictiveInReview();

  const {
    id,
    name,
    activity: { myListings },
  } = company;

  const listing = myListings[0];
  const hasListing = myListings.length > 0;

  const isInReview =
    !!restrictedInReview && listing?.state === ListingState.InReview;

  return (
    <Card w="full">
      <CardHeader>
        <Text textStyle="heading-sm">{t(`activity`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack spacing={2} alignItems="flex-start">
          {hasListing && (
            <NavItem id={id} path={UnaccreditedDashboardRoutesV2.ActiveBids}>
              <HStack justifyContent="space-between">
                <Text>{t(`active_bids`)}</Text>
                <UnaccreditedSellerActiveBidNotificationsCountV2 id={id} />
              </HStack>
            </NavItem>
          )}
          {hasListing && !isInReview && (
            <NavItem id={id} path={UnaccreditedDashboardRoutesV2.Inquiries}>
              <HStack justifyContent="space-between">
                <Text>{t(`inquiries`)}</Text>
                <UnaccreditedSellerInquiryNotificationsCountV2
                  listingId={listing.id}
                />
              </HStack>
            </NavItem>
          )}
          <NavItem id={id} path={UnaccreditedDashboardRoutesV2.StandingBids}>
            <HStack justifyContent="space-between">
              <Text>
                {name} {t(`standing_bids`)}
              </Text>
              <UnaccreditedSellerStandingBidNotificationsCountV2 id={id} />
            </HStack>
          </NavItem>
          {!isInReview && (
            <NavItem id={id} path={UnaccreditedDashboardRoutesV2.Transactions}>
              <HStack justifyContent="space-between">
                <Text>{t(`transactions`)}</Text>
                <UnaccreditedSellerTransactionNotificationsCountV2 id={id} />
              </HStack>
            </NavItem>
          )}
          {hasListing && !isInReview && (
            <NavItem id={id} path={UnaccreditedDashboardRoutesV2.PastBids}>
              <HStack justifyContent="space-between">
                <Text>{t(`past_bids`)}</Text>
                <UnaccreditedSellerPastBidNotificationsCountV2 id={id} />
              </HStack>
            </NavItem>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};
