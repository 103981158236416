import i18next from "i18next";
import { P, match } from "ts-pattern";

import {
  GetListingStatusTextFragment,
  ListingState,
  TransferMethod,
  UserWithInstitutionFragment,
} from "@/gql";
import {
  bidLabel,
  getIsSellerForListing,
  getLongDocumentTitleByTransferMethod,
  getShortDocumentTitleByTransferMethod,
  hoursToDays,
  isHiiveFund,
  toTimestamp,
  getIsBrokerForListing,
  getIsBroker,
} from "@/utils";

type GetListingStatusTextOpts = {
  actor: UserWithInstitutionFragment;
  stnLoiHoursValidHours?: number;
};

function getConditionalText(
  { transferMethod }: GetListingStatusTextFragment,
  stnLoiHoursValidHours?: number,
) {
  const documentTypeLongText =
    getLongDocumentTitleByTransferMethod(transferMethod);
  const documentTypeShortText =
    getShortDocumentTitleByTransferMethod(transferMethod);

  const hoursToSign = hoursToDays(stnLoiHoursValidHours ?? 0);
  const conditionalText = i18next.t(`listing_conditionally_sold_status`, {
    documentTypeLongText,
    documentTypeShortText,
    hoursToSign,
  });

  return conditionalText;
}

function getListingLiveStatusText(listing: GetListingStatusTextFragment) {
  return isHiiveFund(listing)
    ? i18next.t(`listing_live_status_hiive`)
    : i18next.t(`listing_live_status`);
}

function getSellerListingStatusText(
  listing: GetListingStatusTextFragment,
  isBroker: boolean,
  stnLoiHoursValidHours?: number,
) {
  const conditionalText = getConditionalText(listing, stnLoiHoursValidHours);
  const orderOrBidText = bidLabel(listing).toLowerCase();
  const listingLiveStatusText = getListingLiveStatusText(listing);

  return match(listing)
    .with({ state: ListingState.InReview }, () =>
      isBroker
        ? i18next.t(`listing_submitted_in_review`, {
            date: toTimestamp(listing.insertedAt),
          })
        : i18next.t(`submitted_in_review_date`, {
            date: toTimestamp(listing.insertedAt),
          }),
    )
    .with({ state: ListingState.Open }, () => listingLiveStatusText)
    .with(
      {
        state: ListingState.ConditionallySold,
        transferMethod: TransferMethod.Unknown,
      },
      () => `${i18next.t(`conditionally_sold`)} - ${conditionalText}`,
    )
    .with(
      {
        state: ListingState.ConditionallySold,
      },
      () =>
        `${i18next.t(`conditionally_sold`)} - ${i18next.t(`pending_closing`)}`,
    )
    .with({ state: P.union(ListingState.Closed, ListingState.Withdrawn) }, () =>
      i18next.t(`listing_closed_status`, { orderOrBid: orderOrBidText }),
    )
    .otherwise(() => null);
}

function getBuyerListingStatusText(
  listing: GetListingStatusTextFragment,
  stnLoiHoursValidHours?: number,
) {
  const conditionalText = getConditionalText(listing, stnLoiHoursValidHours);
  const orderOrBidText = bidLabel(listing).toLowerCase();

  return match(listing)
    .with(
      {
        state: ListingState.ConditionallySold,
        transferMethod: TransferMethod.Unknown,
      },
      () => `${i18next.t(`conditionally_sold`)} - ${conditionalText}`,
    )
    .with(
      {
        state: ListingState.ConditionallySold,
      },
      () =>
        `${i18next.t(`conditionally_sold`)} - ${i18next.t(`pending_closing`)}`,
    )
    .with({ state: P.union(ListingState.Closed, ListingState.Withdrawn) }, () =>
      i18next.t(`listing_closed_status`, { orderOrBid: orderOrBidText }),
    )
    .otherwise(() => null);
}

function getHiiveUserListingStatusText(
  listing: GetListingStatusTextFragment,
  stnLoiHoursValidHours?: number,
) {
  return getSellerListingStatusText(listing, false, stnLoiHoursValidHours);
}

export function getListingStatusText(
  listing: GetListingStatusTextFragment,
  { actor, stnLoiHoursValidHours }: GetListingStatusTextOpts,
) {
  const { isHiiveUser } = actor;
  const isBroker = getIsBroker(actor);

  const isSeller =
    getIsSellerForListing(actor, listing) ||
    getIsBrokerForListing(actor, listing);

  if (isHiiveUser) {
    return getHiiveUserListingStatusText(listing, stnLoiHoursValidHours);
  }

  if (isSeller) {
    return getSellerListingStatusText(listing, isBroker, stnLoiHoursValidHours);
  }

  return getBuyerListingStatusText(listing, stnLoiHoursValidHours);
}
