const AUTH0_ROUTES = {
  AUTH0_LOGIN: `/api/auth/login`,
  AUTH0_LOGOUT: `/api/auth/logout`,
};

export const ROUTES = {
  ...AUTH0_ROUTES,
  ACCOUNT_PASSWORD_UPDATE_CONFIRMED: `/account/password-update-confirmed`,
  ACCOUNT_REQUEST_UPDATED_EMAIL_VERIFICATION: `/account/request-updated-email-verification`,
  ACCOUNT_SETTINGS_CHANGE_EMAIL: `/account/settings/change-email`,
  ACCOUNT_SETTINGS_CONTACT: `/account/settings/contact`,
  DASHBOARD: `/dashboard`,
  DEFAULT_LOGIN: `/login`,
  FORGOT_PASSWORD: `/forgot-password`,
  INDEX: `/`,
  REQUEST_EMAIL_VERIFICATION: `/request-email-verification`,
  WELCOME: `/welcome`,
};
