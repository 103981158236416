import { CounterBidFeeBreakdownBidFragment, TransactionState } from "@/gql";

import { BidFeeBreakdown } from "./BidFeeBreakdown";

export const CounterBidFeeBreakdown = ({
  bid: { counterBid, transaction },
}: {
  readonly bid: CounterBidFeeBreakdownBidFragment;
}) => {
  if (!counterBid) return null;

  const {
    numShares,
    pricePerShare,
    sellerCommission,
    feeDiscountApplications,
  } = counterBid;

  return (
    <BidFeeBreakdown
      numberOfShares={numShares}
      pricePerShare={pricePerShare}
      commission={sellerCommission}
      transactionState={transaction?.state as TransactionState}
      feeDiscountApplications={feeDiscountApplications}
    />
  );
};
