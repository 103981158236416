import {
  CheckCircle,
  Circle,
  CaretUp,
  CaretRight,
} from "@phosphor-icons/react";
import { sum } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Card,
  HStack,
  Text,
  Badge,
  Center,
  VStack,
  Divider,
  CardHeader,
  CardBody,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link,
  Box,
} from "@chakra-ui/react";

import { CollapsibleInlineText, SharePrice } from "@/components/common";
import {
  getTransactionModificationStatus,
  useTransactionModificationStatusTitle,
  TransactionModificationStatus,
} from "@/components/transactions";
import {
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  ExecutionTaskActorType,
  TransactionExecutionPageTransactionFragment,
  ExecutionConfirmTransactionModificationTaskDataFragment,
  ExecutionVirtualFeeDiscountApplicationFragment,
  FeeDiscountType,
  ExecutionConfirmTransactionModificationTaskDataCommissionFragment,
} from "@/gql";
import { useColors } from "@/hooks";
import { formatCurrency, formatShares } from "@/utils";
import { toFullDate } from "@/utils/datetime";

import ConfirmTransactionModificationAcceptAction from "./ConfirmTransactionModificationAcceptAction";
import ConfirmTransactionModificationDeclineAction from "./ConfirmTransactionModificationDeclineAction";

function FeeDiscountSection({
  virtualFeeDiscountApplications,
}: {
  readonly virtualFeeDiscountApplications: ExecutionVirtualFeeDiscountApplicationFragment[];
}) {
  const { t } = useTranslation(`execution`);

  const nonCommissionDiscounts =
    virtualFeeDiscountApplications.filter(
      (discount) =>
        ![FeeDiscountType.OverrideCommission].includes(discount.type),
    ) || [];

  if (nonCommissionDiscounts.length === 0) {
    return null;
  }

  const discountCount = virtualFeeDiscountApplications.length;
  const totalDiscounts = virtualFeeDiscountApplications.reduce(
    (acc, discount) => acc + discount.amount,
    0,
  );

  const feeDiscountAmountFormatted = formatCurrency(totalDiscounts, {
    fromCents: true,
  });

  return (
    <Accordion allowToggle w="full">
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              as={HStack}
              w="full"
              justifyContent="space-between"
              p={0}
              border="none"
              bg="transparent"
              _hover={{ bg: `grey.15`, cursor: `pointer` }}
              _focus={{ boxShadow: `none` }}
            >
              <VStack alignItems="start" gap={1}>
                <Text textStyle="heading-xs" color="grey.500">
                  {t(`total_discounts`)}
                </Text>
                <Text textStyle="text-sm">
                  {discountCount}
                  {` `}
                  {discountCount > 1 ? t(`discounts`) : t(`discount`)}
                </Text>
              </VStack>
              <HStack>
                <Text>+{feeDiscountAmountFormatted}</Text>
                {isExpanded ? (
                  <CaretUp width="16" height="16" />
                ) : (
                  <CaretRight width="16" height="16" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0} pt={4} border="none" bg="transparent">
              <Card variant="flat" bg="grey.50" border="none" w="full">
                <CardBody p={4} pr={6}>
                  <VStack spacing={4}>
                    {virtualFeeDiscountApplications.map((discount) => (
                      <HStack
                        key={discount.name}
                        w="full"
                        justifyContent="space-between"
                      >
                        <Text color="grey.700">{discount.name}</Text>
                        <Text color="grey.700">
                          +
                          {formatCurrency(discount.amount, {
                            fromCents: true,
                          })}
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

function FeesSection({
  commission,
  virtualFeeDiscountApplications,
}: {
  commission?: ExecutionConfirmTransactionModificationTaskDataCommissionFragment;
  virtualFeeDiscountApplications?: ExecutionVirtualFeeDiscountApplicationFragment[];
}) {
  const { t } = useTranslation(`execution`);

  if (!commission) {
    return null;
  }

  const { commissionAmount = 0, flatFeeAmount = 0, netFees = 0 } = commission;

  const formattedClosingFee = formatCurrency(flatFeeAmount, {
    fromCents: true,
  });
  const formattedCommissionAmount = formatCurrency(commissionAmount, {
    fromCents: true,
  });

  const mappedFeeDiscountApplications =
    virtualFeeDiscountApplications?.filter(
      (discount) => discount.type === FeeDiscountType.OverrideCommission,
    ) || [];

  const nonOverrideDiscountTotals = sum(
    virtualFeeDiscountApplications
      ?.filter((x) =>
        [FeeDiscountType.FlatFee, FeeDiscountType.PercentageFee].includes(
          x.type,
        ),
      )
      .map(({ amount }) => amount || 0),
  );

  const isOverride = (mappedFeeDiscountApplications || [])?.length > 0;
  const totalBeforeOverride = commissionAmount + flatFeeAmount;
  const isOverrideHigherFee = isOverride && netFees > totalBeforeOverride;

  const feeItems = isOverrideHigherFee
    ? []
    : [
        {
          label: t(`base_fee`),
          value: formattedClosingFee,
          lineThrough: isOverride,
        },
        {
          label: t(`hiive_commission`),
          value: formattedCommissionAmount,
          lineThrough: isOverride,
        },
      ];

  const feeLines = [
    ...feeItems,
    ...mappedFeeDiscountApplications.map((discount) => ({
      label: discount.name,
      lineThrough: false,
      tooltip: ``,
      value: formatCurrency(netFees, {
        fromCents: true,
      }),
    })),
  ];
  const feesCount = feeItems.length + mappedFeeDiscountApplications.length;

  const netFeesWithoutNonOverrideDiscounts = formatCurrency(
    netFees + nonOverrideDiscountTotals,
    {
      fromCents: true,
    },
  );

  return (
    <Accordion allowToggle w="full">
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              as={HStack}
              w="full"
              justifyContent="space-between"
              p={0}
              bg="transparent"
              _hover={{ bg: `grey.25`, cursor: `pointer` }}
              _focus={{ boxShadow: `none` }}
            >
              <VStack alignItems="start" gap={1}>
                <Text textStyle="heading-xs" color="grey.500">
                  {t(`total_hiive_fees`)}
                </Text>
                <Text textStyle="text-sm">
                  {feesCount} {feesCount > 1 ? t(`fees`) : t(`fee`)}
                </Text>
              </VStack>
              <HStack>
                <Text>-{netFeesWithoutNonOverrideDiscounts}</Text>
                {isExpanded ? (
                  <CaretUp width="16" height="16" />
                ) : (
                  <CaretRight width="16" height="16" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0} pt={4} border="none" bg="transparent">
              <Card variant="flat" bg="grey.50" border="none" w="full">
                <CardBody p={4} pr={6}>
                  <VStack spacing={4}>
                    {feeLines.map(({ label, value, lineThrough }) => (
                      <HStack
                        key={label as string}
                        w="full"
                        justifyContent="space-between"
                      >
                        <Text color="grey.700">{label}</Text>
                        <Box color="grey.700">
                          <Text as={lineThrough ? `del` : undefined}>
                            -{value}
                          </Text>
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}

function NetProceedsSection({
  numShares,
  netProceeds,
}: {
  readonly numShares: number;
  readonly netProceeds: number;
}) {
  const { t } = useTranslation(`execution`);

  const formattedNumShares = formatShares(numShares);

  const netPricePerShare = Math.trunc(netProceeds / numShares);

  const formattedNetPricePerShare = formatCurrency(netPricePerShare, {
    fromCents: true,
  });
  const formattedNetProceeds = formatCurrency(netProceeds, {
    fromCents: true,
  });

  return (
    <HStack w="full" alignItems="end" justifyContent="space-between" pr={6}>
      <VStack alignItems="start" gap={1}>
        <Text textStyle="heading-xs">{t(`net_proceeds`)}</Text>
        <Text
          textStyle="heading-2xs"
          textTransform="capitalize"
          color="grey.700"
        >
          {formattedNumShares} {t(`shares`)} @ {formattedNetPricePerShare}
        </Text>
      </VStack>
      <Text textStyle="heading-md">{formattedNetProceeds}</Text>
    </HStack>
  );
}

const ConfirmTransactionModificationTaskCard = ({
  task,
  data,
  isBuySide,
  transaction,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionConfirmTransactionModificationTaskDataFragment;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const statusTitles = useTransactionModificationStatusTitle();
  const [grey900] = useColors([`grey.900`]);

  const status = getTransactionModificationStatus(data.acknowledgments);
  const showAcknowledgments = status === TransactionModificationStatus.Pending;
  // sorting to make sure current user's acknowledgment is always on top
  const sortedAcknowledgments = data.acknowledgments.slice().sort((a, b) => {
    const getPriority = (actorType: ExecutionTaskActorType) => {
      if (
        (actorType === ExecutionTaskActorType.Seller && !isBuySide) ||
        (actorType === ExecutionTaskActorType.Buyer && isBuySide)
      ) {
        return 0;
      }
      return 1;
    };

    return getPriority(a.actorType) - getPriority(b.actorType);
  });

  const { numShares: oldShares, pricePerShare: oldPricePerShare } = transaction;
  const {
    numShares: newShares,
    pricePerShare: newPricePerShare,
    virtualFeeDiscountApplications,
    commission,
  } = data;

  const grossProceeds = newShares * newPricePerShare;

  const netFees = commission?.netFees || 0;

  const netProceeds = grossProceeds - netFees;

  const formattedGrossProceeds = formatCurrency(grossProceeds, {
    fromCents: true,
  });

  return (
    <>
      <Card variant="flat" borderColor="grey.400" bg="grey.15">
        <CardHeader bg="white" p={0} px={4} py={5}>
          <VStack alignItems="start" gap={4}>
            <HStack w="full" justifyContent="space-between">
              <Text textStyle="heading-md">{t(`approval_status`)}</Text>
              <Badge variant={statusTitles[status].variant} px={2} py={1}>
                <Text>{statusTitles[status].title}</Text>
              </Badge>
            </HStack>
            {showAcknowledgments &&
              sortedAcknowledgments.map((acknowledgment) => (
                <HStack
                  w="full"
                  key={acknowledgment.actorType}
                  justifyContent="space-between"
                >
                  <HStack gap={4}>
                    <Center width="24px" height="24px">
                      {!!acknowledgment.acknowledgedAt ? (
                        <CheckCircle
                          color={grey900}
                          weight="fill"
                          size="18px"
                        />
                      ) : (
                        <Circle color={grey900} size="18px" />
                      )}
                    </Center>
                    <Text textStyle="heading-md" color="grey.700">
                      {match(acknowledgment.actorType)
                        .with(ExecutionTaskActorType.Seller, () =>
                          isBuySide
                            ? t(`counterparty_approval`)
                            : t(`your_approval`),
                        )
                        .with(ExecutionTaskActorType.Buyer, () =>
                          isBuySide
                            ? t(`your_approval`)
                            : t(`counterparty_approval`),
                        )
                        .otherwise(() => ``)}
                    </Text>
                  </HStack>
                  {!!acknowledgment.acknowledgedAt && (
                    <Text textStyle="text-xs">
                      {t(`approved`)}
                      {` `}
                      {toFullDate(acknowledgment.acknowledgedAt)}
                    </Text>
                  )}
                </HStack>
              ))}
          </VStack>
        </CardHeader>
        <CardBody p={0} px={4} py={5}>
          <VStack alignItems="start" gap={4}>
            {isBuySide ? (
              <HStack w="full" justifyContent="space-between" pr={6}>
                <VStack alignItems="start" gap={1}>
                  <Text textStyle="heading-xs">
                    {t(isBuySide ? `gross_value` : `net_proceeds`)}
                  </Text>
                  <Text textStyle="heading-2xs" textTransform="capitalize">
                    <SharePrice
                      numShares={newShares}
                      pricePerShare={newPricePerShare}
                    />
                  </Text>
                </VStack>
                <Text color="grey.700">{formattedGrossProceeds}</Text>
              </HStack>
            ) : (
              <>
                <HStack w="full" justifyContent="space-between" pr={6}>
                  <VStack alignItems="start" gap={1}>
                    <Text textStyle="heading-xs" color="grey.500">
                      {t(`gross_sale_amount`)}
                    </Text>
                    <Text textStyle="heading-2xs" textTransform="capitalize">
                      <SharePrice
                        numShares={oldShares}
                        pricePerShare={oldPricePerShare}
                        newNumShares={newShares}
                        newPricePerShare={newPricePerShare}
                      />
                    </Text>
                  </VStack>
                  <Text color="grey.700">{formattedGrossProceeds}</Text>
                </HStack>
                <FeesSection
                  virtualFeeDiscountApplications={
                    virtualFeeDiscountApplications
                  }
                  commission={commission ?? undefined}
                />
                <FeeDiscountSection
                  virtualFeeDiscountApplications={
                    virtualFeeDiscountApplications
                  }
                />
                <Divider borderColor="grey.200" />
                <NetProceedsSection
                  netProceeds={netProceeds}
                  numShares={newShares}
                />
                <CollapsibleInlineText
                  collapsedText={
                    <Text
                      as="span"
                      textStyle="text-xs"
                      lineHeight="inherit"
                      color="grey.500"
                    >
                      {t(`proceeds_description_collapsed`)}
                    </Text>
                  }
                  expandedText={
                    <>
                      <Text
                        as="span"
                        textStyle="text-xs"
                        lineHeight="inherit"
                        color="grey.500"
                        mb={1}
                      >
                        <Trans
                          i18nKey="proceeds_description_expanded"
                          ns="execution"
                        />
                      </Text>
                      <Text
                        as="span"
                        textStyle="text-xs"
                        lineHeight="inherit"
                        color="grey.500"
                      >
                        <Trans
                          i18nKey="proceeds_description_terms"
                          ns="execution"
                          components={{
                            a: (
                              <Link
                                textDecoration="underline"
                                href="/terms-and-conditions"
                                target="_blank"
                              />
                            ),
                          }}
                        />
                      </Text>
                    </>
                  }
                  label={{ collapsed: t(`read_more`), expanded: t(`see_less`) }}
                />
              </>
            )}
          </VStack>
        </CardBody>
      </Card>
      {task.status === ExecutionTaskStatus.InProgress && (
        <HStack w="full" justifyContent="flex-end" mt={2}>
          <ConfirmTransactionModificationDeclineAction
            taskId={task.id}
            data={data}
            companyName={transaction.company.name}
            transferMethod={transaction.bid.listing.transferMethod}
          />
          <ConfirmTransactionModificationAcceptAction
            taskId={task.id}
            data={data}
            companyName={transaction.company.name}
            transferMethod={transaction.bid.listing.transferMethod}
          />
        </HStack>
      )}
    </>
  );
};

export default ConfirmTransactionModificationTaskCard;
