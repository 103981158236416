import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import {
  InvestorType,
  ListingYourActivityDashboardPostingCardFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";

import { ListingPostingCardBody } from "./ListingPostingCardBody";
import { ListingYourActivityDashboardPostingCardFooter } from "./ListingYourActivityDashboardPostingCardFooter";
import { ListingYourActivityDashboardPostingCardHeader } from "./ListingYourActivityDashboardPostingCardHeader";
import { ListingYourActivityPostingCardBrokerFooter } from "./ListingYourActivityPostingCardBrokerFooter";

type ListingYourActivityDashboardPostingCardProps = {
  listing: ListingYourActivityDashboardPostingCardFragment;
};

function ListingYourActivityDashboardBrokerPostingCard({
  listing,
}: ListingYourActivityDashboardPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url}>
      <ListingYourActivityDashboardPostingCardHeader listing={listing} />
      <ListingPostingCardBody listing={listing} />
      <ListingYourActivityPostingCardBrokerFooter listing={listing} />
    </PostingCard>
  );
}

function ListingYourActivityTraderPostingCard({
  listing,
}: ListingYourActivityDashboardPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url}>
      <ListingYourActivityDashboardPostingCardHeader listing={listing} />
      <ListingPostingCardBody listing={listing} />
      <ListingYourActivityDashboardPostingCardFooter listing={listing} />
    </PostingCard>
  );
}

export function ListingYourActivityDashboardPostingCard({
  listing,
}: ListingYourActivityDashboardPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.Broker, () => (
      <ListingYourActivityDashboardBrokerPostingCard listing={listing} />
    ))
    .otherwise(() => (
      <ListingYourActivityTraderPostingCard listing={listing} />
    ));
}
