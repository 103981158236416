import { Circle } from "@phosphor-icons/react";
import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ExecutionStepStatus,
  ExecutionStepVisibility,
  ExecutionWorkflowStatus,
  TransactionExecutionPageTransactionFragment,
  TransactionExecutionPageWorkflowFragment,
} from "@/gql";
import { useColors, useIsDesktop } from "@/hooks";

import CompletedStep from "./Step/CompletedStep";
import { Tasks } from "./Task";

const NoStepsCard = () => (
  <Card w="full" variant="no-border-radius-base">
    <CardBody p={3}>
      <Text px={3}>
        <Trans i18nKey="no_tasks_explainer" />
      </Text>
    </CardBody>
  </Card>
);

const PendingStep = ({
  step,
}: {
  readonly step: TransactionExecutionPageWorkflowFragment["steps"][0];
}) => {
  const { t } = useTranslation();

  return (
    <Card
      w="full"
      variant="no-border-radius-base"
      borderColor="grey.100"
      boxShadow="none"
    >
      <CardBody p={0} px={{ base: 4, md: 6 }} py={{ base: 2, md: 4 }}>
        <HStack justifyContent="space-between" w="full">
          <Text textStyle="heading-md">{step.name}</Text>
          <Text textStyle="text-xs">{t(`upcoming_task`)}</Text>
        </HStack>
      </CardBody>
    </Card>
  );
};

const PendingStepCards = ({
  steps,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
}) => (
  <Stack direction="column" spacing={2}>
    {steps.map((step) => (
      <PendingStep key={step.id} step={step} />
    ))}
  </Stack>
);

const CompletedStepsCard = ({
  steps,
  isBuySide,
  transaction,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);

  const numCompletedSteps = steps.length;

  if (numCompletedSteps === 0)
    return (
      <Card w="full" boxShadow="none" variant="no-border-radius-base">
        <CardBody>
          <Text>{t(`no_completed_tasks`)}</Text>
        </CardBody>
      </Card>
    );

  return (
    <Stack direction="column" spacing={2}>
      {steps.map((step) => (
        <CompletedStep
          key={step.id}
          step={step}
          isBuySide={isBuySide}
          transaction={transaction}
        />
      ))}
    </Stack>
  );
};

const InProgressStepCards = ({
  steps,
  isBuySide,
  transaction,
}: {
  readonly steps: TransactionExecutionPageWorkflowFragment["steps"];
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const [salmon900] = useColors([`salmon.900`]);

  return steps.map((step) => {
    const copy = isBuySide ? step.instructions.buyer : step.instructions.seller;

    return (
      <Card w="full" key={step.id} variant="no-border-radius-base">
        <CardHeader as={HStack} py={4}>
          <Circle size={8} weight="fill" color={salmon900} />
          <Text textStyle="heading-md">{step.name}</Text>
        </CardHeader>
        <CardBody p={0} px={{ base: 4, md: 6 }} py={{ base: 4, md: 6 }}>
          <Flex gap={{ base: 4, md: 6 }} direction="column">
            {!!copy && <Text whiteSpace="pre-line">{copy}</Text>}
            {step.tasks.length > 0 && (
              <Tasks
                tasks={step.tasks}
                isBuySide={isBuySide}
                transaction={transaction}
              />
            )}
          </Flex>
        </CardBody>
      </Card>
    );
  });
};

const StepTabs = ({
  workflow,
  isBuySide,
  transaction,
}: {
  readonly workflow?: TransactionExecutionPageWorkflowFragment | null;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  if (!workflow || workflow.status === ExecutionWorkflowStatus.Pending) {
    return <NoStepsCard />;
  }

  const userType = isBuySide
    ? ExecutionStepVisibility.Buyer
    : ExecutionStepVisibility.Seller;

  const inProgressSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.InProgress &&
      step.visibility.includes(userType),
  );

  const pendingSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.Pending &&
      step.visibility.includes(userType),
  );

  const completedSteps = workflow.steps.filter(
    (step) =>
      step.status === ExecutionStepStatus.Completed &&
      step.visibility.includes(userType),
  );

  const tabList = (
    <TabList>
      <Tab>{t(`now`)}</Tab>
      <Tab>{t(`next`)}</Tab>
      <Tab>{t(`done`)}</Tab>
    </TabList>
  );

  return (
    <Tabs variant="boxed-teal" w="full">
      {isDesktop ? (
        <Box mb={4}>{tabList}</Box>
      ) : (
        <Card variant="no-border-radius-base" top={-4} border="none">
          <CardBody>{tabList}</CardBody>
        </Card>
      )}
      <TabPanels>
        <TabPanel>
          <Stack direction="column" spacing={4}>
            <Text textStyle="text-xs" px={{ base: 4, md: 0 }}>
              {t(`in_progress_tasks_description`)}
            </Text>
            {inProgressSteps.length === 0 &&
            workflow.status !== ExecutionWorkflowStatus.Completed ? (
              <NoStepsCard />
            ) : (
              <VStack spacing={{ base: 2, md: 4 }}>
                <InProgressStepCards
                  steps={inProgressSteps}
                  isBuySide={isBuySide}
                  transaction={transaction}
                />
              </VStack>
            )}
          </Stack>
        </TabPanel>
        <TabPanel>
          <Stack direction="column" spacing={4}>
            <Text textStyle="text-xs" px={{ base: 4, md: 0 }}>
              {t(`pending_tasks_description`)}
            </Text>
            <PendingStepCards steps={pendingSteps} />
          </Stack>
        </TabPanel>
        <TabPanel>
          <CompletedStepsCard
            steps={completedSteps}
            isBuySide={isBuySide}
            transaction={transaction}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default StepTabs;
