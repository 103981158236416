import { CaretRight } from "@phosphor-icons/react";
import { sentenceCase } from "change-case";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { HStack, VStack, Text, Box } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";

import { WorkflowConditionCard } from "@/components/transactions";
import {
  ExecutionMilestone,
  TransactionExecutionPageWorkflowFragment,
} from "@/gql";

import MilestoneRow from "./MilestoneRow";
import { getMilestoneCopy } from "./utils";

const MilestonesMobile = ({
  workflow,
  isBuySide = false,
}: {
  readonly workflow: TransactionExecutionPageWorkflowFragment;
  readonly isBuySide?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const { milestoneCompletions, conditions, milestoneOrder } = workflow;

  const lastCompletedMilestone =
    milestoneCompletions[milestoneCompletions.length - 1]?.milestone;

  const activeMilestone =
    milestoneOrder[milestoneOrder.indexOf(lastCompletedMilestone) + 1];

  const isCompleted = (milestone: ExecutionMilestone) =>
    milestoneCompletions.map((m) => m.milestone).includes(milestone);

  const getCompletedAt = (milestone: ExecutionMilestone) =>
    milestoneCompletions[
      milestoneCompletions.findIndex((m) => m.milestone === milestone)
    ]?.completedAt || null;

  const hasConditions = conditions ? conditions?.length > 0 : false;

  const { t } = useTranslation(`execution`);

  return (
    <Box pb={4}>
      <HStack
        w="full"
        as={chakra.button}
        onClick={() => setCollapsed(!collapsed)}
        textAlign="left"
        px={6}
        py={4}
        justify="space-between"
        flexWrap="wrap"
        borderY="1px solid"
        borderColor="grey.50"
      >
        <Text textStyle="heading-md">
          <Trans i18nKey="transaction_status" />
        </Text>
        <HStack>
          <Text textStyle="heading-md" color="teal.900">
            {sentenceCase(lastCompletedMilestone)}
          </Text>
          <CaretRight
            width="16px"
            height="16px"
            style={{
              transform: collapsed
                ? `rotate(0deg) scaleX(1)`
                : `rotate(-90deg) scaleX(-1)`,
              transition: `transform 0.2s ease-in-out`,
            }}
          />
        </HStack>
      </HStack>
      <AnimatePresence initial={false}>
        {!collapsed && (
          <Box
            as={motion.div}
            initial={{ maxHeight: 0, opacity: 0 }}
            animate={{ maxHeight: `550px`, opacity: 1 }}
            exit={{ maxHeight: 0, opacity: 0 }}
            // @ts-expect-error chakra/motion props mismatch
            transition={{ duration: 0.3, ease: `easeInOut` }}
            borderBottom="1px solid"
            borderColor="grey.50"
            py={2}
          >
            <VStack spacing={0} gap={2}>
              {milestoneOrder.map((milestone, index) => (
                <MilestoneRow
                  key={milestone}
                  milestone={milestone}
                  copy={t(getMilestoneCopy(milestone))}
                  showLine={index !== milestoneOrder.length - 1}
                  isCompleted={isCompleted(milestone)}
                  isActive={activeMilestone === milestone}
                  completedAt={getCompletedAt(milestone)}
                  conditions={conditions}
                  isBuySide={isBuySide}
                />
              ))}
            </VStack>
            {hasConditions && (
              <>
                {conditions.map(({ condition }) => (
                  <WorkflowConditionCard
                    key={condition}
                    condition={condition}
                    isBuySide={isBuySide}
                  />
                ))}
              </>
            )}
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default MilestonesMobile;
