import { CheckCircle } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  Circle,
  Text,
  Button,
} from "@chakra-ui/react";

import {
  PoweredByModernTreasury,
  ExecutionModalHeader,
  ExecutionModalFooter,
} from "@/components/common";
import { useColors, useModal } from "@/hooks";

const CollectExistingBankAccountSuccessModal = () => {
  const { t } = useTranslation(`execution`);
  const { closeModal, currentModal } = useModal();
  const [emerald600] = useColors([`emerald.600`]);

  return (
    <Modal
      variant="texas"
      isOpen={!!currentModal}
      onClose={closeModal}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent w="2xl" height={{ base: `full`, md: `auto` }}>
        <ExecutionModalHeader>
          <Text>{t(`add_bank_account`)}</Text>
        </ExecutionModalHeader>
        <ModalBody>
          <VStack spacing={4} py={4}>
            <Circle size="108" bgColor="emerald.100">
              <CheckCircle size={64} weight="fill" color={emerald600} />
            </Circle>
            <Text textStyle="heading-3xl" color="grey.700">
              {t(`bank_account_added`)}!
            </Text>
            <Text textStyle="text-sm">{t(`bank_account_connected`)}</Text>
            <PoweredByModernTreasury />
          </VStack>
        </ModalBody>
        <ExecutionModalFooter>
          <Button variant="rounded-solid-grey" size="xl" onClick={closeModal}>
            {t(`done`)}
          </Button>
        </ExecutionModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CollectExistingBankAccountSuccessModal;
