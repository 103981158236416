import { useTranslation } from "react-i18next";

import { FeeBreakdown } from "@/components/common";
import { BrokerSellerTransactionFeeBreakdownTransactionFragment } from "@/gql";

export const BrokerSellerTransactionFeeBreakdown = ({
  transaction: {
    sellerCommission,
    feeDiscountApplications,
    numShares,
    pricePerShare,
  },
}: {
  readonly transaction: BrokerSellerTransactionFeeBreakdownTransactionFragment;
}) => {
  const { t } = useTranslation();
  const { flatFeeAmount, commissionAmount, feeDiscountAmount, netFees } =
    sellerCommission || {};

  return (
    <FeeBreakdown
      numShares={numShares}
      pricePerShare={pricePerShare}
      feeDiscountApplications={feeDiscountApplications}
      flatFeeAmount={flatFeeAmount}
      commissionAmount={commissionAmount}
      feeDiscountAmount={feeDiscountAmount}
      netFees={netFees}
      combineHiiveFeesLabel={t(`hiive_co_broker_fee`)}
      combineHiiveFeesTooltip={t(`hiive_co_broker_fee_tooltip`)}
      netProceedsTitle={t(`broker_seller_proceeds`)}
      netProceedsSubtitle={t(`gross_of_your_brokerage_fees`)}
      netProceedsTooltip={t(`broker_seller_proceeds_tooltip`)}
      disclaimerVariant="brokerSeller"
    />
  );
};
