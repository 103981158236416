import { useTranslation } from "react-i18next";

import { Box, Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import {
  ConditionallyCompletedBadge,
  InquiriesBadge,
  PartiallyAcceptedBadge,
} from "@/components/companies";
import {
  StandingBidYourActivityPostingCardFragment,
  StandingBidState,
} from "@/gql";
import {
  getIsConditionallyCompletedStandingBid,
  getIsPartiallyAcceptedStandingBid,
  toTimestamp,
} from "@/utils";

type StandingBidYourActivityPostingCardFooterProps = {
  standingBid: StandingBidYourActivityPostingCardFragment;
};

export function StandingBidYourActivityPostingCardFooter({
  standingBid,
}: StandingBidYourActivityPostingCardFooterProps) {
  const { t } = useTranslation(`standingBids`);
  const { state, insertedAt, numDiscussions, numUnreadMessages } = standingBid;

  const isPartiallyAccepted = getIsPartiallyAcceptedStandingBid(standingBid);
  const isConditionallyCompleted =
    getIsConditionallyCompletedStandingBid(standingBid);
  const hasDiscussions = numDiscussions >= 1;

  const isInReview = state === StandingBidState.InReview;

  const insertedAtFormatted = toTimestamp(insertedAt);

  const hasFooter =
    isInReview ||
    isPartiallyAccepted ||
    isConditionallyCompleted ||
    hasDiscussions;

  if (!hasFooter) {
    return null;
  }

  return (
    <PostingCard.Footer>
      {isInReview && (
        <Text color="grey.700" textStyle="text-sm">
          {t(`standing_bid_in_review_status`, { date: insertedAtFormatted })}
        </Text>
      )}

      <Box flexGrow={1} />

      {isPartiallyAccepted && <PartiallyAcceptedBadge />}
      {isConditionallyCompleted && <ConditionallyCompletedBadge />}
      {hasDiscussions && (
        <InquiriesBadge
          numInquiries={numDiscussions}
          numUnreadMessages={numUnreadMessages}
        />
      )}
    </PostingCard.Footer>
  );
}
