import { match } from "ts-pattern";

import { VStack } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  StandingBidActivityStandingBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useRestrictiveInReview } from "@/hooks/featureFlags";
import { getAvailableCompanyActions, getIsBrokerForStandingBid } from "@/utils";
import {
  getIsBrokerCounterpartyForStandingBid,
  getIsBuyerForStandingBid,
} from "@/utils/standing-bid";

import { StandingBidBrokerActivity } from "./StandingBidBrokerActivity";
import { StandingBidBrokerCounterpartyActivity } from "./StandingBidBrokerCounterpartyActivity";
import { StandingBidBuyerActivity } from "./StandingBidBuyerActivity";
import { StandingBidRestrictedCard } from "./StandingBidRestrictedCard";
import { StandingBidSellerActivity } from "./StandingBidSellerActivity";

const StandingBidActivityCards = ({
  standingBid,
  actor,
}: {
  readonly standingBid: StandingBidActivityStandingBidFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  if (getIsBrokerForStandingBid({ user: actor, standingBid }))
    return <StandingBidBrokerActivity standingBid={standingBid} />;

  if (getIsBrokerCounterpartyForStandingBid({ user: actor, standingBid }))
    return <StandingBidBrokerCounterpartyActivity standingBid={standingBid} />;

  if (getIsBuyerForStandingBid(actor, standingBid))
    return <StandingBidBuyerActivity standingBid={standingBid} />;

  return <StandingBidSellerActivity actor={actor} standingBid={standingBid} />;
};

const StandingBidActivityCardsV2 = ({
  standingBid,
  actor,
}: {
  readonly standingBid: StandingBidActivityStandingBidFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { company } = standingBid;
  const { canAcceptStandingBid: canAcceptStandingBidOnCompany } =
    getAvailableCompanyActions(company);

  const isBrokerForStandingBid = getIsBrokerForStandingBid({
    user: actor,
    standingBid,
  });
  const isBrokerCounterpartyForStandingBid =
    getIsBrokerCounterpartyForStandingBid({ user: actor, standingBid });
  const isBuyerForStandingBid = getIsBuyerForStandingBid(actor, standingBid);
  const isSellerForStandingBid =
    !isBrokerForStandingBid &&
    !isBrokerCounterpartyForStandingBid &&
    !isBuyerForStandingBid;

  return match({
    canAcceptStandingBidOnCompany,
    isBrokerForStandingBid,
    isBrokerCounterpartyForStandingBid,
    isBuyerForStandingBid,
    isSellerForStandingBid,
  })
    .with({ isBuyerForStandingBid: true }, () => (
      <StandingBidBuyerActivity standingBid={standingBid} />
    ))
    .with({ canAcceptStandingBidOnCompany: false }, () => (
      <StandingBidRestrictedCard standingBid={standingBid} />
    ))
    .with({ isBrokerForStandingBid: true }, () => (
      <StandingBidBrokerActivity standingBid={standingBid} />
    ))
    .with({ isBrokerCounterpartyForStandingBid: true }, () => (
      <StandingBidBrokerCounterpartyActivity standingBid={standingBid} />
    ))
    .with({ isSellerForStandingBid: true }, () => (
      <StandingBidSellerActivity actor={actor} standingBid={standingBid} />
    ))
    .otherwise(() => null);
};

export const StandingBidActivity = withCurrentActor(
  ({
    standingBid,
    actor,
  }: {
    readonly standingBid: StandingBidActivityStandingBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const isRestrictiveInReviewEnabled = useRestrictiveInReview();
    return (
      <VStack spacing={4}>
        {isRestrictiveInReviewEnabled ? (
          <StandingBidActivityCardsV2 actor={actor} standingBid={standingBid} />
        ) : (
          <StandingBidActivityCards actor={actor} standingBid={standingBid} />
        )}
      </VStack>
    );
  },
);
