import { t } from "i18next";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  ListingYourActivityPostingCard,
  WithQuery,
} from "@/components/common";
import { CompanyYourActivityListingCard } from "@/components/companies";
import {
  UnaccreditedSellerCompanyByIdYourActivityCompanyFragment,
  UnaccreditedSellerCompanyByIdYourActivityListingFragment,
  useUnaccreditedSellerCompanyByIdYourActivityQuery,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { useCardUpdates } from "@/hooks/featureFlags";

import { YourActivitySkeleton } from "./YourActivitySkeleton";

const MyListing = ({
  listing,
  company,
}: {
  readonly listing: UnaccreditedSellerCompanyByIdYourActivityListingFragment;
  readonly company: UnaccreditedSellerCompanyByIdYourActivityCompanyFragment;
}) => {
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivityGroup title={t(`your_activity`)}>
      {[listing].map((listing) =>
        cardUpdatesEnabled ? (
          <ListingYourActivityPostingCard
            key={listing.id}
            listing={{ ...listing, company }}
          />
        ) : (
          <CompanyYourActivityListingCard
            key={listing.id}
            listing={listing}
            company={company}
            dataTestId="your-listing-card"
          />
        ),
      )}
    </ActivityGroup>
  );
};

const YourActivityV2 = ({ id }: { id: string }) => {
  const query = useUnaccreditedSellerCompanyByIdYourActivityQuery({
    variables: { id },
  });
  const isDesktopView = useIsDesktop();

  return (
    <WithQuery query={query} fallback={<YourActivitySkeleton />}>
      {({ data: { companyById: company } }) => {
        const { myListings } = company.activity;
        const showEmptyState = !isDesktopView && myListings.length === 0;
        const listing = myListings[0];

        return (
          <>
            {!!listing && (
              <ActivitySection title={t(`your_activity`)}>
                <MyListing listing={listing} company={company} />
              </ActivitySection>
            )}
            {showEmptyState && (
              <ActivitySection title={t(`your_activity`)}>
                <ActivityGroupEmptyState
                  w="full"
                  message={t(`no_activity_for_company`, {
                    companyName: company.name,
                    defaultValue: ``,
                  })}
                />
              </ActivitySection>
            )}
          </>
        );
      }}
    </WithQuery>
  );
};

export default YourActivityV2;
