import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import {
  InvestorType,
  StandingBidYourActivityDashboardPostingCardFragment,
} from "@/gql";
import { useCurrentActor } from "@/hooks";

import { StandingBidPostingCardBody } from "./StandingBidPostingCardBody";
import { StandingBidPostingCardBrokerBody } from "./StandingBidPostingCardBrokerBody";
import { StandingBidYourActivityDashboardPostingCardHeader } from "./StandingBidYourActivityDashboardPostingCardHeader";
import { StandingBidYourActivityPostingCardFooter } from "./StandingBidYourActivityPostingCardFooter";

type StandingBidYourActivityDashboardPostingCardProps = {
  standingBid: StandingBidYourActivityDashboardPostingCardFragment;
};

function StandingBidYourActivityDashboardTraderPostingCard({
  standingBid,
}: StandingBidYourActivityDashboardPostingCardProps) {
  const { id } = standingBid;

  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url}>
      <StandingBidYourActivityDashboardPostingCardHeader
        standingBid={standingBid}
      />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidYourActivityPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

function StandingBidYourActivityDashboardBrokerPostingCard({
  standingBid,
}: StandingBidYourActivityDashboardPostingCardProps) {
  const { id } = standingBid;

  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url}>
      <StandingBidYourActivityDashboardPostingCardHeader
        standingBid={standingBid}
      />
      <StandingBidPostingCardBrokerBody standingBid={standingBid} />
      <StandingBidYourActivityPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

export function StandingBidYourActivityDashboardPostingCard({
  standingBid,
}: StandingBidYourActivityDashboardPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.Broker, () => (
      <StandingBidYourActivityDashboardBrokerPostingCard
        standingBid={standingBid}
      />
    ))
    .otherwise(() => (
      <StandingBidYourActivityDashboardTraderPostingCard
        standingBid={standingBid}
      />
    ));
}
