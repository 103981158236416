import { useContext } from "react";

import { LDStatusContext } from "@/components/async-with-ld-provider";

export type FlagValue = boolean | string | string[];

export type FeatureFlags = {
  readonly "compsec.new_auth_system": FlagValue;
  readonly "hiive.enable_maintenance": FlagValue;
  readonly "marketplace.sell_side_broker_portal": FlagValue;
  readonly "issuer.fee_discount.new_proceeds_section": FlagValue;
  readonly "execution.buyer_account_information_transfer": FlagValue;
  readonly "execution.internal_multi_entity": FlagValue;
  readonly "execution.transaction_execution_automation_system": FlagValue;
  readonly "marketplace.suitability_upfront": FlagValue;
  readonly "marketplace.suppress_notifications": FlagValue;
  readonly "marketplace.suitability_v3": FlagValue;
  readonly "marketplace.front_chat_u16r": FlagValue;
  readonly "marketplace.front_chat_trader": FlagValue;
  readonly "marketplace.pricing_chart_disclaimer_updates": FlagValue;
  readonly "marketplace.rsu_restriction_and_eligible_buyers": FlagValue;
  readonly "marketplace.slugs_as_company_url": FlagValue;
  readonly "marketplace.card_updates": FlagValue;
  readonly "marketplace.restrictive_in_review": FlagValue;
  readonly "marketplace.base_fee_removal": FlagValue;
  readonly "marketplace.remove_client_withdraw_function": FlagValue;
};

export const useFeatureFlags = (): FeatureFlags => {
  const { flags } = useContext(LDStatusContext);

  return (flags as FeatureFlags) || {};
};

export const useMaintenanceMode = () =>
  useFeatureFlags()[`hiive.enable_maintenance`];

export const useFeeDiscountNewProceedsSectionEnabled = () =>
  useFeatureFlags()[`issuer.fee_discount.new_proceeds_section`];

export const useTEXASEnabled = () =>
  useFeatureFlags()[`execution.transaction_execution_automation_system`];

export const useSuitabilityUpfront = () =>
  useFeatureFlags()[`marketplace.suitability_upfront`];

export const useSuppressNotifications = () =>
  useFeatureFlags()[`marketplace.suppress_notifications`];

export const useFrontChatU16r = () =>
  useFeatureFlags()[`marketplace.front_chat_u16r`];

export const useFrontChatTrader = () =>
  useFeatureFlags()[`marketplace.front_chat_trader`];

export const useBAITEnabled = () =>
  useFeatureFlags()[`execution.buyer_account_information_transfer`];

export const useUpdatedPricingChartCopy = () =>
  useFeatureFlags()[`marketplace.pricing_chart_disclaimer_updates`];

export const useRsuRestrictionAndEligibleBuyers = () =>
  useFeatureFlags()[`marketplace.rsu_restriction_and_eligible_buyers`];

export const useSlugsAsCompanyUrl = () =>
  useFeatureFlags()[`marketplace.slugs_as_company_url`];

export const useCardUpdates = () =>
  useFeatureFlags()[`marketplace.card_updates`];

export const useRestrictiveInReview = () =>
  useFeatureFlags()[`marketplace.restrictive_in_review`];

export const useRemoveClientWithdrawFunction = () =>
  useFeatureFlags()[`marketplace.remove_client_withdraw_function`];

// TODO: uncomment when needed for feature work
// export const useBaseFeeRemoval = () =>
//   useFeatureFlags()[`marketplace.base_fee_removal`];
