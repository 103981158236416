import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { InvestorType, StandingBidLatestPostingCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { StandingBidLatestPostingCardFooter } from "./StandingBidLatestPostingCardFooter";
import { StandingBidLatestPostingCardHeader } from "./StandingBidLatestPostingCardHeader";
import { StandingBidPostingCardBody } from "./StandingBidPostingCardBody";
import { StandingBidPostingCardBrokerBody } from "./StandingBidPostingCardBrokerBody";

type StandingBidLatestPostingCardProps = {
  standingBid: StandingBidLatestPostingCardFragment;
};

function StandingBidLatestBrokerPostingCard({
  standingBid,
}: StandingBidLatestPostingCardProps) {
  const { id } = standingBid;
  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url} variant="posting-latest">
      <StandingBidLatestPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBrokerBody standingBid={standingBid} />
      <StandingBidLatestPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

function StandingBidLatestTraderPostingCard({
  standingBid,
}: StandingBidLatestPostingCardProps) {
  const { id } = standingBid;
  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url} variant="posting-latest">
      <StandingBidLatestPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidLatestPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

export function StandingBidLatestPostingCard({
  standingBid,
}: StandingBidLatestPostingCardProps) {
  const { investorType } = useCurrentActor();
  return match(investorType)
    .with(InvestorType.Broker, () => (
      <StandingBidLatestBrokerPostingCard standingBid={standingBid} />
    ))
    .otherwise(() => (
      <StandingBidLatestTraderPostingCard standingBid={standingBid} />
    ));
}
