import { Diamond, WarningCircle } from "@phosphor-icons/react";
import { match } from "ts-pattern";

import { Box } from "@chakra-ui/react";

import { ExecutionStepStatus } from "@/gql";
import { useColors } from "@/hooks";

export default function MilestoneStatusIcon({
  status,
}: {
  status: ExecutionStepStatus;
}) {
  const [red700] = useColors([`red.700`]);

  return match(status)
    .with(ExecutionStepStatus.Terminated, () => (
      <Box
        background="white"
        borderRadius="50%"
        position="relative"
        width="100%"
        height="100%"
      >
        <WarningCircle
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
          width="100%"
          height="100%"
          color={red700}
          weight="fill"
        />
      </Box>
    ))
    .with(ExecutionStepStatus.Completed, () => (
      <Box position="relative" width="100%" height="100%">
        <Diamond
          width="100%"
          height="100%"
          weight="fill"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        />
      </Box>
    ))
    .with(ExecutionStepStatus.Pending, () => (
      <Box position="relative" width="100%" height="100%">
        <Diamond
          width="100%"
          height="100%"
          weight="fill"
          fill="white"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        />
        <Diamond
          width="100%"
          height="100%"
          weight="bold"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        />
      </Box>
    ))
    .otherwise(() => (
      // in progress
      <Box position="relative" width="100%" height="100%">
        <Diamond
          width="100%"
          height="100%"
          weight="fill"
          fill="white"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        />
        <Diamond
          width="100%"
          height="100%"
          weight="bold"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        />
        <Diamond
          width="45%"
          height="45%"
          weight="fill"
          style={{
            position: `absolute`,
            top: `50%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
          }}
        />
      </Box>
    ));
}
