import { PostingCard } from "@/components/common";
import { ListingPostingCardBodyFragment } from "@/gql";

import { ListingPostingCardBodyAcceptedShares } from "./ListingPostingCardBodyAcceptedShares";
import { ListingPostingCardBodyPricing } from "./ListingPostingCardBodyPricing";

type ListingPostingCardBodyProps = {
  listing: ListingPostingCardBodyFragment;
};

export function ListingPostingCardBody({
  listing,
}: ListingPostingCardBodyProps) {
  return (
    <PostingCard.Body>
      <ListingPostingCardBodyPricing listing={listing} />
      <ListingPostingCardBodyAcceptedShares listing={listing} />
    </PostingCard.Body>
  );
}
