import { ArrowSquareOut } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import Link from "next/link";

import {
  Card,
  CardBody,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";

import useTransactionExecution from "@/hooks/useTransactionExecution";
import { formatNumber, formatPricePerShare, lot } from "@/utils";

const ListingDetailsCard = () => {
  const { t } = useTranslation(`execution`);
  const transaction = useTransactionExecution()!;
  const { numShares, pricePerShare } = transaction;
  const { logoUrl, name } = transaction!.company;

  return (
    <VStack alignItems="flex-start" spacing={4}>
      <Text textStyle="heading-md">{t(`original_listing`)}</Text>

      <Card
        w="full"
        _hover={{
          bg: `grey.50`,
        }}
        as={Link}
        boxShadow="none"
        href={`/listings/${transaction.bid.listingId}`}
        transition=".1s background-color ease-in-out"
      >
        <CardBody color="grey.900" as={HStack} justifyContent="space-between">
          <HStack>
            {logoUrl && (
              <VStack h="6" minW="6">
                <Image h="full" src={logoUrl} alt={name} />
              </VStack>
            )}
            <Text textStyle="text-sm">
              {transaction?.bid.listing.displayId}
            </Text>
          </HStack>
          <HStack textStyle="text-sm" color="grey.700" gap={4}>
            <Flex
              direction={{ base: `column`, md: `row` }}
              alignItems="end"
              textStyle="text-sm"
              color="grey.700"
              gap={1}
            >
              <Text fontWeight={500}>
                {formatNumber(numShares!)} @{` `}
                {formatPricePerShare(pricePerShare)}
              </Text>
              <Text fontWeight={400}>
                {lot(numShares!, pricePerShare, `$0,0.00`)}
              </Text>
            </Flex>
            <ArrowSquareOut size={16} />
          </HStack>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default ListingDetailsCard;
