import { useTranslation } from "react-i18next";

import { HStack, Show, Text, VStack } from "@chakra-ui/react";

import { LastTransactionBadge } from "@/components/postings";
import { ExecutionEntityBankAccountFragment } from "@/gql";
import { toShortDate } from "@/utils/datetime";

type BankAccountSimpleListboxElementProps = {
  readonly bankAccount: ExecutionEntityBankAccountFragment;
};

const BankAccountSimpleListboxElementDesktop = ({
  bankAccount,
}: BankAccountSimpleListboxElementProps) => {
  const { t } = useTranslation();

  const { last4, lastUsedTransaction } = bankAccount;

  return (
    <HStack justifyContent="space-between" w="full">
      <HStack gap={2}>
        <Text textStyle="heading-md" color="grey.900">{t`account`}</Text>
        <Text textStyle="text-md" color="grey.700">
          {t(`bank_accounts.redacted`, {
            ns: `postings`,
          })}
        </Text>
        <Text textStyle="text-md" color="grey.700">
          {last4}
        </Text>
      </HStack>
      {lastUsedTransaction ? (
        <HStack>
          <Text color="grey.500" textStyle="text-sm">
            {t(`used_for_transaction`, {
              ns: `execution`,
            })}
          </Text>
          <LastTransactionBadge
            transaction={{
              friendlyId: lastUsedTransaction.displayId,
              company: {
                logoUrl: lastUsedTransaction.company.logoUrl || ``,
                name: lastUsedTransaction.company.name,
              },
            }}
          />
        </HStack>
      ) : (
        <Text textStyle="text-sm" color="grey.500">{`${t`added`} ${toShortDate(
          bankAccount.insertedAt,
        )}`}</Text>
      )}
    </HStack>
  );
};

const BankAccountSimpleListboxElementMobile = ({
  bankAccount,
}: BankAccountSimpleListboxElementProps) => {
  const { t } = useTranslation();

  const { last4, lastUsedTransaction } = bankAccount;

  return (
    <HStack justifyContent="space-between" w="full">
      <VStack alignItems="start" gap={0}>
        <Text textStyle="heading-md" color="grey.900">{t`account`}</Text>
        <HStack gap={1}>
          <Text textStyle="text-md" color="grey.700">
            {t(`bank_accounts.redacted`, {
              ns: `postings`,
            })}
          </Text>
          <Text textStyle="text-md" color="grey.700">
            {last4}
          </Text>
        </HStack>
      </VStack>
      {lastUsedTransaction ? (
        <VStack alignItems="end" gap={0}>
          <Text color="grey.500" textStyle="text-sm">
            {t(`used_for_transaction`, {
              ns: `execution`,
            })}
          </Text>
          <LastTransactionBadge
            transaction={{
              friendlyId: lastUsedTransaction.displayId,
              company: {
                logoUrl: lastUsedTransaction.company.logoUrl || ``,
                name: lastUsedTransaction.company.name,
              },
            }}
          />
        </VStack>
      ) : (
        <Text textStyle="text-sm" color="grey.500">{`${t`added`} ${toShortDate(
          bankAccount.insertedAt,
        )}`}</Text>
      )}
    </HStack>
  );
};

export const BankAccountSimpleListboxElement = ({
  bankAccount,
}: BankAccountSimpleListboxElementProps) => (
  <>
    <Show below="sm" ssr={false}>
      <BankAccountSimpleListboxElementMobile bankAccount={bankAccount} />
    </Show>
    <Show above="sm" ssr={false}>
      <BankAccountSimpleListboxElementDesktop bankAccount={bankAccount} />
    </Show>
  </>
);
