import { HStack, Text, Badge, Box, Image } from "@chakra-ui/react";

import { useColors } from "@/hooks";

const LastTransactionBadge = ({
  transaction,
}: {
  readonly transaction: {
    readonly friendlyId: string;
    readonly company: {
      readonly logoUrl?: string;
      readonly name: string;
    };
  };
}) => {
  const [grey900] = useColors([`grey.900`]);
  return (
    <Badge
      as={HStack}
      variant="ghost"
      bg="white"
      borderColor="grey.75"
      borderRadius={{ base: `6px`, md: `md` }}
      borderWidth="1px"
      px={{ base: `6px`, md: 2 }}
      py={{ base: `0px`, md: 1 }}
    >
      {transaction.company.logoUrl && (
        <Box h="4" minW="4">
          <Image
            h="full"
            src={transaction.company.logoUrl}
            alt={transaction.company.name}
          />
        </Box>
      )}
      <Text textStyle="text-sm" color={grey900}>
        {transaction.friendlyId}
      </Text>
    </Badge>
  );
};

export default LastTransactionBadge;
