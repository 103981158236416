import { Minus, Plus } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";

import { FeeBreakdown, Tile, TileHeader } from "@/components/common";
import { PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment } from "@/gql";

interface ProceedsDetailsCardProps {
  readonly listing: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageListingFragment;
}

export const ProceedsDetailsCard = ({ listing }: ProceedsDetailsCardProps) => {
  const { t } = useTranslation();
  const {
    numSharesAvailable,
    listingPricePerShare,
    sellerCommission,
    feeDiscountApplications,
  } = listing;
  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    sellerCommission || {};

  return (
    <Tile bg="white" w="full" p={0} borderWidth={0}>
      <Accordion allowToggle borderWidth={0} w="full" defaultIndex={0}>
        <AccordionItem
          borderWidth={0}
          borderBottomWidth={0}
          borderBlockEnd={0}
          p={0}
          m={0}
          w="full"
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                p={0}
                _hover={{ bg: `none` }}
                borderWidth={0.5}
                borderColor="grey.200"
                borderBottomWidth={isExpanded ? 0 : 0.5}
                borderTopRadius="md"
                borderBottomRadius={isExpanded ? `none` : `md`}
              >
                <TileHeader w="full">
                  <HStack px={4} py={6}>
                    <Text>{t(`proceed_details`)}</Text>
                    <Box flexGrow={1} />
                    <Box color="grey.600">
                      {isExpanded ? (
                        <Box as={Minus} size={20} />
                      ) : (
                        <Box as={Plus} size={20} />
                      )}
                    </Box>
                  </HStack>
                </TileHeader>
              </AccordionButton>
              <AccordionPanel p={0}>
                <FeeBreakdown
                  numShares={numSharesAvailable}
                  pricePerShare={listingPricePerShare}
                  commissionAmount={commissionAmount}
                  feeDiscountAmount={feeDiscountAmount}
                  netFees={netFees}
                  flatFeeAmount={flatFeeAmount}
                  feeDiscountApplications={feeDiscountApplications}
                  disclaimerVariant="unaccreditedSeller"
                  isStandaloneTop
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Tile>
  );
};
