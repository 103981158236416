import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { InvestorType, StandingBidPostingCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { appendSellerCompanyIdToUrl } from "@/utils";

import { StandingBidPostingCardBody } from "./StandingBidPostingCardBody";
import { StandingBidPostingCardBrokerBody } from "./StandingBidPostingCardBrokerBody";
import { StandingBidPostingCardFooter } from "./StandingBidPostingCardFooter";
import { StandingBidPostingCardHeader } from "./StandingBidPostingCardHeader";

type StandingBidPostingCardProps = {
  standingBid: StandingBidPostingCardFragment;
};

function StandingBidTraderPostingCard({
  standingBid,
}: StandingBidPostingCardProps) {
  const { id } = standingBid;

  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

function StandingBidBrokerPostingCard({
  standingBid,
}: StandingBidPostingCardProps) {
  const { id } = standingBid;

  const url = `/standing-bids/${id}`;

  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBrokerBody standingBid={standingBid} />
      <StandingBidPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

function StandingBidUnaccreditedSellerPostingCard({
  standingBid,
}: StandingBidPostingCardProps) {
  const {
    id,
    company: { id: companyId },
  } = standingBid;

  const url = appendSellerCompanyIdToUrl(`/standing-bids/${id}`, companyId);

  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidPostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

export function StandingBidPostingCard({
  standingBid,
}: StandingBidPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.UnaccreditedSeller, () => (
      <StandingBidUnaccreditedSellerPostingCard standingBid={standingBid} />
    ))
    .with(InvestorType.Broker, () => (
      <StandingBidBrokerPostingCard standingBid={standingBid} />
    ))
    .otherwise(() => (
      <StandingBidTraderPostingCard standingBid={standingBid} />
    ));
}
