import {
  GridItem,
  SimpleGrid,
  VStack,
  Card,
  CardBody,
  Flex,
} from "@chakra-ui/react";

import { FullContentWrapper, Skeleton } from "@/components/common";
import { InformativeArticlesV2 } from "@/components/dashboard";

import { SharePriceCardSkeleton } from "./SharePriceCardSkeleton";

export const UnaccreditedSellerCreateListingPageContentSkeleton = () => (
  <FullContentWrapper px={{ base: 4, lg: 8 }}>
    <SimpleGrid
      columnGap={6}
      rowGap={4}
      maxW="max-width-lg"
      gridTemplateColumns={{ base: `1fr`, lg: `416px 1fr` }}
      w="full"
    >
      <GridItem display={{ base: `none`, lg: `block` }}>
        <Skeleton h={5} w="4rem" mb={6} />
        <Flex direction="column" gap={3}>
          <Card w="full">
            <CardBody>
              <Flex direction="column" gap={3}>
                <Skeleton h={10} count={4} />
              </Flex>
            </CardBody>
          </Card>
          <InformativeArticlesV2.Skeleton />
        </Flex>
      </GridItem>
      <GridItem>
        <VStack spacing={4} alignItems="flex-start">
          <Skeleton h="24px" w="256px" mb="5px" />
          <SharePriceCardSkeleton />
        </VStack>
      </GridItem>
    </SimpleGrid>
  </FullContentWrapper>
);
