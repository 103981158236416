import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  CardBody,
  VStack,
  CardFooter,
  Flex,
  Text,
  Link,
} from "@chakra-ui/react";

import { MailtoLink } from "@/components/common";
import { TransactionExecutionPageTransactionFragment } from "@/gql";
import { constants, getMarketAnalystContact, getCC } from "@/utils";

const marketingWebsiteLinks = [
  constants.marketing_website_links.calculate_price_of_shares,
  constants.marketing_website_links.dive_into_vc_secondaries,
  constants.marketing_website_links.how_to_sell_private_shares,
];

const InformativeArticlesCard = ({
  transaction,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation();

  const marketAnalystEmail = getMarketAnalystContact(transaction.company);
  const cc = getCC(transaction.company, true);

  return (
    <Card w="full" variant="no-border-radius-base">
      <CardHeader py={4}>
        <Text textStyle="heading-md">{t(`informative_articles`)}</Text>
      </CardHeader>
      <CardBody>
        <VStack alignItems="flex-start">
          {marketingWebsiteLinks.map(({ title, url }) => (
            <Link
              target="_blank"
              textDecoration="underline"
              href={url}
              key={url}
            >
              {title}
            </Link>
          ))}
        </VStack>
      </CardBody>
      <CardFooter bg="grey.25">
        <Flex direction="column" gap={4}>
          <Text>{t(`for_assistance`)}:</Text>
          <MailtoLink
            email={marketAnalystEmail}
            cc={cc}
            subject={`Contact ${transaction.company.name} Security Specialist`}
            fontWeight="medium"
          >
            {marketAnalystEmail}
          </MailtoLink>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export default InformativeArticlesCard;
