import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import { Flex, Text, VStack } from "@chakra-ui/react";

import {
  ActivityCardSkeleton,
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  Skeleton,
  WithQuery,
} from "@/components/common";
import {
  PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment,
  usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery,
} from "@/gql";
import { getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2 } from "@/utils";

import { CompletedSaleCard } from "./CompletedSaleCard";
import { PendingSaleCard } from "./PendingSaleCard";

const CompletedSalesEmptyState = () => {
  const { t } = useTranslation();
  return <ActivityGroupEmptyState message={t(`completed_sales_empty_state`)} />;
};

const CompletedSales = ({
  completedSales,
}: {
  readonly completedSales: readonly PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment[];
}) => (
  <ActivityGroup emptyState={<CompletedSalesEmptyState />}>
    {completedSales
      .filter(
        (completedSale) =>
          !completedSale.cancelledAt &&
          !completedSale.issuerApprovalDeclinedAt &&
          !completedSale.rofrAt,
      )
      .map((completedSale) => (
        <CompletedSaleCard
          key={completedSale.id}
          completedSale={completedSale}
        />
      ))}
  </ActivityGroup>
);

const PendingSalesEmptyState = () => {
  const { t } = useTranslation();
  return <ActivityGroupEmptyState message={t(`accepted_bids_empty_state`)} />;
};

const PendingSales = ({
  pendingSales,
}: {
  readonly pendingSales: readonly PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment[];
}) => (
  <ActivityGroup emptyState={<PendingSalesEmptyState />}>
    {pendingSales.map((pendingSale) => (
      <PendingSaleCard key={pendingSale.id} pendingSale={pendingSale} />
    ))}
  </ActivityGroup>
);

interface TransactionsPageContentProps {
  readonly myOpenTransactions: readonly PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment[];
  readonly myCompletedTransactions: readonly PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment[];
}

const TransactionsPageContent = ({
  myOpenTransactions,
  myCompletedTransactions,
}: TransactionsPageContentProps) => {
  const { t } = useTranslation();

  return (
    <ActivitySection pt={{ base: 4, lg: 8 }}>
      <VStack alignItems="flex-start" spacing={4}>
        <Text textStyle="heading-sm" textTransform="uppercase">
          {t(`your_pending_sales`)}
        </Text>
        <Text textStyle="text-md"> {t(`status_accepted_bids`)}</Text>
        <PendingSales pendingSales={myOpenTransactions} />
      </VStack>
      <VStack alignItems="flex-start" spacing={4}>
        <Text textStyle="heading-sm" textTransform="uppercase">
          {t(`your_completed_sales`)}
        </Text>
        <Text textStyle="text-md"> {t(`history_of_transactions`)}</Text>
        <CompletedSales completedSales={myCompletedTransactions} />
      </VStack>
    </ActivitySection>
  );
};

const TransactionsPageSkeleton = () => (
  <VStack spacing={8} w="full" pt={{ base: 4, lg: 8 }}>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="156px" />
      <Skeleton h="16px" maxW="85%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
    <Flex direction="column" gap={4} w="full">
      <Skeleton h="20px" w="full" maxW="144px" />
      <Skeleton h="16px" maxW="75%" />
      <ActivityCardSkeleton includeBody />
    </Flex>
  </VStack>
);

const TransactionsPageV2 = ({ id }: { id: string }) => {
  const query =
    usePostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageQuery({
      variables: { id },
    });
  const router = useRouter();

  return (
    <WithQuery query={query} fallback={<TransactionsPageSkeleton />}>
      {({ data: { companyById: company } }) => {
        const { activity } = company;
        const { myListings } = activity;
        const listing = myListings[0] ?? null;

        if (
          !getCanAccessUnaccreditedSellerPostStandingBidActivityDashboardV2({
            activity,
          }) &&
          !listing
        ) {
          router.replace(`/dashboard/${id}`);
          return null;
        }

        return <TransactionsPageContent {...activity} />;
      }}
    </WithQuery>
  );
};

export default TransactionsPageV2;
