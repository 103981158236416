import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { InvestorType, ListingLatestPostingCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { ListingLatestPostingCardFooter } from "./ListingLatestPostingCardFooter";
import { ListingLatestPostingCardHeader } from "./ListingLatestPostingCardHeader";
import { ListingPostingCardBody } from "./ListingPostingCardBody";
import { ListingPostingCardBrokerBody } from "./ListingPostingCardBrokerBody";

type ListingLatestPostingCardProps = {
  listing: ListingLatestPostingCardFragment;
};

function ListingLatestBrokerPostingCard({
  listing,
}: ListingLatestPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url} variant="posting-latest">
      <ListingLatestPostingCardHeader listing={listing} />
      <ListingPostingCardBrokerBody listing={listing} />
      <ListingLatestPostingCardFooter listing={listing} />
    </PostingCard>
  );
}

function ListingLatestTraderPostingCard({
  listing,
}: ListingLatestPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url} variant="posting-latest">
      <ListingLatestPostingCardHeader listing={listing} />
      <ListingPostingCardBody listing={listing} />
      <ListingLatestPostingCardFooter listing={listing} />
    </PostingCard>
  );
}

export function ListingLatestPostingCard({
  listing,
}: ListingLatestPostingCardProps) {
  const { investorType } = useCurrentActor();
  return match(investorType)
    .with(InvestorType.Broker, () => (
      <ListingLatestBrokerPostingCard listing={listing} />
    ))
    .otherwise(() => <ListingLatestTraderPostingCard listing={listing} />);
}
