import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import * as Yup from "yup";

import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

import {
  ExecutionModalFooter,
  ExecutionModalHeader,
} from "@/components/common";
import { FormTextInput } from "@/components/react-hook-form";
import {
  TransactionExecutionPageTaskFragment,
  useExecutionTaskActionMutation,
  ExecutionTaskActionType,
  ExecutionTaskType,
  ExecutionTaskActionInput,
  TransactionExecutionPageTransactionByIdDocument,
} from "@/gql";
import { useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

type InviteAlternateUserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

function UnInviteAlternateUserModal({
  isOpen,
  onClose,
  task,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly task: TransactionExecutionPageTaskFragment;
}) {
  const altUserUnInviteTaskType = match(task.type)
    .with(
      ExecutionTaskType.AnvilFillDocument,
      () => ExecutionTaskActionType.AnvilFillDocumentUninviteAlternateUser,
    )
    .with(
      ExecutionTaskType.AnvilSignDocument,
      () => ExecutionTaskActionType.AnvilSignDocumentUninviteAlternateUser,
    )
    .otherwise(() => {
      throw new Error(`Unsupported task type: ${task.type}`);
    });

  const [unInviteAlternateUserMutation] = useMutationWithError(
    useExecutionTaskActionMutation({
      variables: {
        id: task.id,
        type: altUserUnInviteTaskType,
      },
      onCompleted: onClose,
      refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
    }),
    `executionTaskAction`,
  );

  const { t } = useTranslation(`execution`);

  const handleSubmit = () => {
    unInviteAlternateUserMutation();
  };

  return (
    <Modal variant="texas" isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent w="2xl" height={{ base: `full`, md: `auto` }}>
        <ExecutionModalHeader closeModal={onClose}>
          <Text textStyle="heading-md">
            {t(`remove_alternate_representative`)}
          </Text>
        </ExecutionModalHeader>
        <ModalBody>
          <VStack spacing={6} align="start">
            <Text textStyle="heading-xl">
              {t(`are_you_sure_remove_alternate`)}
            </Text>
            <Text textStyle="text-md" color="grey.900">
              {t(`remove_alternate_modal_description`)}
            </Text>
          </VStack>
        </ModalBody>
        <ExecutionModalFooter>
          <Button
            onClick={handleSubmit}
            size="xl"
            variant="rounded-outline-salmon"
          >
            {t(`remove`)}
          </Button>
        </ExecutionModalFooter>
      </ModalContent>
    </Modal>
  );
}

type ExecutionTaskActionInputKey = keyof ExecutionTaskActionInput;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
});

function InviteAlternateUserModal({
  isOpen,
  onClose,
  task,
}: {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly task: TransactionExecutionPageTaskFragment;
}) {
  const { t } = useTranslation(`execution`);

  const { type: altUserInviteTaskType, inputKey } = match(task.type)
    .with(ExecutionTaskType.AnvilFillDocument, () => ({
      type: ExecutionTaskActionType.AnvilFillDocumentInviteAlternateUser,
      inputKey:
        `anvilFillDocumentInviteAlternateUser` as ExecutionTaskActionInputKey,
    }))
    .with(ExecutionTaskType.AnvilSignDocument, () => ({
      type: ExecutionTaskActionType.AnvilSignDocumentInviteAlternateUser,
      inputKey:
        `anvilSignDocumentInviteAlternateUser` as ExecutionTaskActionInputKey,
    }))
    .otherwise(() => {
      throw new Error(`Unsupported task type: ${task.type}`);
    });

  const mapVariables =
    (task: TransactionExecutionPageTaskFragment) =>
    (values: InviteAlternateUserFormValues) => ({
      id: task.id,
      type: altUserInviteTaskType,
      input: {
        [inputKey]: values,
      },
    });

  const mutation = useExecutionTaskActionMutation({
    refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
  });

  const initialValues = {
    email: ``,
    firstName: ``,
    lastName: ``,
  };

  const { handleSubmit, control } = useFormQL({
    mutation,
    initialValues,
    validationSchema,
    mapVariables: mapVariables(task),
    onSuccess: onClose,
  });

  return (
    <Modal variant="texas" isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent w="2xl">
        <ExecutionModalHeader closeModal={onClose}>
          <Text textStyle="heading-md">
            {t(`invite_an_alternate_representative`)}
          </Text>
        </ExecutionModalHeader>
        <ModalBody>
          <VStack spacing={6} align="start">
            <VStack spacing={2} align="start">
              <Text textStyle="heading-xl">
                {t(`provide_alternate_representative`)}
              </Text>
              <Text textStyle="text-md" color="grey.900">
                {t(`alternate_representative_instructions`)}
              </Text>
            </VStack>
            <HStack spacing={4} w="full">
              <FormTextInput
                control={control}
                name="firstName"
                label={t(`first_name`)}
              />
              <FormTextInput
                control={control}
                name="lastName"
                label={t(`last_name`)}
              />
            </HStack>
            <FormTextInput control={control} name="email" label={t(`email`)} />
          </VStack>
        </ModalBody>
        <ExecutionModalFooter>
          <Button onClick={onClose} size="xl" variant="rounded-outline-grey">
            {t(`cancel`)}
          </Button>
          <Button onClick={handleSubmit} size="xl" variant="rounded-solid-grey">
            {t(`send_invite`)}
          </Button>
        </ExecutionModalFooter>
      </ModalContent>
    </Modal>
  );
}

function AlternateUserActions({
  task,
  isCompleted = false,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly isCompleted?: boolean;
}) {
  const { t } = useTranslation(`execution`);
  const {
    isOpen: isInviteModalOpen,
    onClose: onInviteModalClose,
    onOpen: onInviteModalOpen,
  } = useDisclosure();

  const {
    isOpen: isUnInviteOpen,
    onClose: onUnInviteClose,
    onOpen: onUnInviteOpen,
  } = useDisclosure();

  const { alternateUser } = task;

  const hasAlternateUser = alternateUser;
  const showCancelAction = !isCompleted;

  return (
    <HStack w="full">
      {!hasAlternateUser ? (
        <Flex
          direction={{ base: `column`, sm: `row` }}
          w="full"
          alignItems={{ base: `start`, sm: `center` }}
          gap={{ base: 0, sm: 1 }}
        >
          <Text color="grey.600" textStyle="text-xs">
            {t(`need_someone_else_to_do_this_task`)}
          </Text>
          <Button
            variant="link"
            size="sm"
            color="grey.900"
            onClick={onInviteModalOpen}
          >
            <Text textStyle="heading-3xs">{t(`send_an_invite`)}</Text>
          </Button>
        </Flex>
      ) : (
        <HStack w="full" justifyContent="space-between" position="relative">
          <VStack alignItems="start" spacing={0.5}>
            <Text textStyle="heading-3xs" color="grey.900">
              {t(`alternate_party`)}
            </Text>
            <HStack w="full">
              <Text color="grey.900" textStyle="text-sm">
                {alternateUser.firstName} {alternateUser.lastName} •{` `}
                {alternateUser.email}
              </Text>
            </HStack>
          </VStack>
          {showCancelAction && (
            <Button
              variant="rounded-outline-salmon"
              size="xs"
              color="red.900"
              borderColor="red.900"
              minWidth="120px"
              width="120px"
              onClick={onUnInviteOpen}
              position={{ base: `absolute`, sm: `relative` }}
              right={{ base: 0, sm: `auto` }}
              top={{ base: 0, sm: `auto` }}
            >
              {t(`cancel_invite`)}
            </Button>
          )}
        </HStack>
      )}
      <InviteAlternateUserModal
        isOpen={isInviteModalOpen}
        onClose={onInviteModalClose}
        task={task}
      />
      <UnInviteAlternateUserModal
        isOpen={isUnInviteOpen}
        onClose={onUnInviteClose}
        task={task}
      />
    </HStack>
  );
}

export default AlternateUserActions;
