export const colors = {
  // used for custom colorSchemes
  // some Chakra-UI components have a `colorScheme` prop
  // which takes a color and by default adds '.500' to the value its passed
  // ex. <Switch colorScheme="salmonPink" /> translates to salmonPink.500

  // New color scheme
  grey: {
    15: `#FCFCFC`,
    25: `#F9F9F9`,
    50: `#F6F6F6`,
    75: `#F0F0F0`,
    100: `#D5D5D5`,
    200: `#C0C0C0`,
    300: `#ABABAB`,
    400: `#969696`,
    500: `#818181`,
    600: `#6C6C6C`,
    700: `#575757`,
    800: `#424242`,
    900: `#323232`,
  },
  salmon: {
    50: `#FEEFEF`,
    100: `#FCDFDF`,
    200: `#FBCFD0`,
    300: `#F9BFC0`,
    400: `#F8AFB0`,
    500: `#F79FA0`,
    600: `#F58F90`,
    700: `#F47F81`,
    800: `#F26F71`,
    900: `#F15F61`,
  },
  red: {
    10: `#FFF2F2`,
    50: `#F8E6E6`,
    100: `#F0CDCE`,
    200: `#E9B5B5`,
    300: `#E29C9D`,
    400: `#DA8384`,
    500: `#D36A6C`,
    600: `#CC5153`,
    700: `#C4383A`,
    800: `#BD2022`,
    900: `#B60709`,
  },
  skyBlue: {
    50: `#EFFAFD`,
    100: `#E0F5FC`,
    200: `#D0F0FA`,
    300: `#C0EBF9`,
    400: `#B0E5F7`,
    500: `#A1E0F5`,
    600: `#91DBF4`,
    700: `#81D6F2`,
    800: `#72D1F1`,
    900: `#62CCEF`,
    1000: `#58B8D7`,
    1100: `#4EA3BF`,
    1200: `#458FA7`,
    1300: `#3B7A8F`,
  },
  teal: {
    25: `#F4F8F9`,
    50: `#EBF2F4`,
    100: `#D6E5EA`,
    200: `#C2D9DF`,
    300: `#ADCCD5`,
    400: `#98BFCA`,
    500: `#84B2BF`,
    600: `#70A5B5`,
    700: `#5B99AA`,
    800: `#478CA0`,
    900: `#2D7386`,
    1000: `#19434F`,
  },
  olive: {
    25: `#F8F8F4`,
    50: `#F2F2EC`,
    100: `#E4E4D9`,
    200: `#D7D7C5`,
    300: `#CAC9B2`,
    400: `#BCBB9F`,
    500: `#AFAE8C`,
    600: `#A2A179`,
    700: `#959365`,
    800: `#878552`,
    900: `#53532D`,
    1000: `#3C3C1D`,
  },
  plum: {
    25: `#FBF7FA`,
    50: `#F5ECF4`,
    100: `#EBD8EA`,
    200: `#E2C5DF`,
    300: `#D8B1D4`,
    400: `#CE9EC9`,
    500: `#C48BBF`,
    600: `#BA77B4`,
    700: `#B164A9`,
    800: `#A7509F`,
    900: `#9D3D94`,
    1000: `#60205A`,
  },
  pewter: {
    50: `#EEF2F3`,
    100: `#DDE4E7`,
    200: `#CDD7DB`,
    300: `#BCC9CF`,
    400: `#ABBBC3`,
    500: `#9AAEB7`,
    600: `#89A1AB`,
    700: `#79939F`,
    800: `#688593`,
    900: `#577887`,
  },
  emerald: {
    100: `#F0FBF6`,
    600: `#25B579`,
    700: `#008044`,
  },
  yellow: {
    100: `#FFF5EB`,
    600: `#FF9431`,
    800: `#A06127`,
  },
  chart: {
    bid: `#D871BC`,
    bidLighter: `#EBD8EA`,
    ask: `#AAD597`,
    askLighter: `#DDF3D2`,
    common: `#E3AD20`,
    commonBid: `#C18E07`,
    direct: `#5F45AA`,
    directBid: `#210867`,
    preferred: `#BCC6FF`,
    preferredBid: `#8797F2`,
    indirect: `#CB425F`,
    indirectBid: `#A2213C`,
    positiveTrend: `#66A16F`,
    negativeTrend: `#D47D62`,
  },
  mt: `#1BA778`,
  /**
   * greys @deprecated
   */
  salmonPink: {
    500: `#F15F61`,
  },
  "h-dark-grey": `#404041`,
  "h-mid-grey": `#818285`,
  "h-light-grey": `#BCBDC0`,
  "h-keyline-grey": `#F1F2F2`,
  "h-salmon-pink": `#F15F61`,
  "h-sky-blue": `#62CCEF`,
  "h-darker-grey": `#323232`,
  "h-teal": `#327F95`,
  "h-olive": `#99975E`,
  "h-plum": `#9D3D94`,
  "h-pewter": `#7C9DAC`,
  "h-white": `#FFFFFF`,
  "deprecated-olive-900": `#7A783F`,
};
