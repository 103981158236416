import { Trans } from "react-i18next";
import { match } from "ts-pattern";

import { HStack, Text } from "@chakra-ui/react";

import { StandingBidPostingCardBodyFragment } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  getIsConditionallyCompletedStandingBid,
  getIsPartiallyAcceptedStandingBid,
  lot,
} from "@/utils";

type StandingBidPostingCardBodyPricingProps = {
  standingBid: StandingBidPostingCardBodyFragment;
};

type StandingBidPostingCardBodyPricingContentProps = {
  isPartiallyAccepted: boolean;
  isConditionallyCompleted: boolean;
  numSharesAvailable: number;
  numSharesOriginal: number;
  pricePerShare: number;
};

const COMPONENTS = {
  b: (
    <Text
      as="span"
      fontWeight={500}
      fontStyle="inherit"
      fontSize={{ base: 14, lg: `inherit` }}
    />
  ),
  r: (
    <Text
      as="span"
      fontWeight={400}
      fontStyle="inherit"
      fontSize={{ base: 14, lg: `inherit` }}
    />
  ),
  u: (
    <Text
      as="span"
      textDecorationLine="line-through"
      color="grey.200"
      fontStyle="inherit"
      fontSize={{ base: 14, lg: `inherit` }}
    />
  ),
  spacer: <Text as="span" mr={1} />,
};

function StandingBidPostingCardBodyPricingContent({
  isPartiallyAccepted,
  isConditionallyCompleted,
  numSharesAvailable,
  numSharesOriginal,
  pricePerShare,
}: StandingBidPostingCardBodyPricingContentProps) {
  const formattedNumSharesOriginal = formatShares(numSharesOriginal);
  const formattedNumSharesAvailable = formatShares(numSharesAvailable);
  const formattedPricePerShare = formatPricePerShare(pricePerShare);

  const formattedNumSharesOriginalLot = lot(numSharesOriginal, pricePerShare);
  const formattedNumSharesAvailableLot = lot(numSharesAvailable, pricePerShare);

  return match({ isPartiallyAccepted, isConditionallyCompleted })
    .with({ isPartiallyAccepted: true }, () => (
      <HStack spacing={3}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_partially_sold_num_shares_price"
            ns="company"
            values={{
              numSharesOriginal: formattedNumSharesOriginal,
              numSharesAvailable: formattedNumSharesAvailable,
              pricePerShare: formattedPricePerShare,
            }}
            components={COMPONENTS}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price"
          ns="company"
          values={{
            lot: formattedNumSharesAvailableLot,
          }}
          components={COMPONENTS}
        />
      </HStack>
    ))
    .with({ isConditionallyCompleted: true }, () => (
      <HStack spacing={3}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_sold_out_num_shares_price"
            ns="company"
            values={{
              numSharesOriginal: formattedNumSharesOriginal,
              numSharesAvailable: formattedNumSharesAvailable,
              pricePerShare: formattedPricePerShare,
            }}
            components={COMPONENTS}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price_sold_out"
          ns="company"
          values={{
            lot: formattedNumSharesOriginalLot,
          }}
          components={COMPONENTS}
        />
      </HStack>
    ))
    .otherwise(() => (
      <HStack spacing={3}>
        <Trans
          i18nKey="posting_num_shares_price"
          ns="company"
          values={{
            numSharesOriginal: formattedNumSharesOriginal,
            numSharesAvailable: formattedNumSharesAvailable,
            pricePerShare: formattedPricePerShare,
          }}
          components={COMPONENTS}
        />
        <Trans
          i18nKey="posting_lot_price"
          ns="company"
          values={{
            lot: formattedNumSharesOriginalLot,
          }}
          components={COMPONENTS}
        />
      </HStack>
    ));
}

export function StandingBidPostingCardBodyPricing({
  standingBid,
}: StandingBidPostingCardBodyPricingProps) {
  const { numSharesAvailable, numSharesOriginal, pricePerShare } = standingBid;
  const isPartiallyAccepted = getIsPartiallyAcceptedStandingBid(standingBid);
  const isConditionallyCompleted =
    getIsConditionallyCompletedStandingBid(standingBid);

  // On a partially complete standing bid the server will return null
  // instead of 0
  const numSharesAvailableComputed = numSharesAvailable ?? 0;

  if (!numSharesOriginal) {
    return null;
  }

  return (
    <StandingBidPostingCardBodyPricingContent
      isPartiallyAccepted={isPartiallyAccepted}
      isConditionallyCompleted={isConditionallyCompleted}
      numSharesAvailable={numSharesAvailableComputed}
      numSharesOriginal={numSharesOriginal}
      pricePerShare={pricePerShare}
    />
  );
}
