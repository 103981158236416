import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveButton,
  WithQuery,
  ListingLatestPostingCard,
  StandingBidLatestPostingCard,
} from "@/components/common";
import {
  LatestActivityListListingFragment,
  LatestActivityListStandingBidFragment,
  useLatestMarketActivityQuery,
} from "@/gql";
import { constants } from "@/utils";

import { DashboardLatestMarketActivitySkeleton } from "./DashboardLatestMarketActivitySkeleton";

type TabKind = `LISTINGS` | `STANDING_BIDS`;

const Tab = ({
  isActive,
  onClick,
  children,
}: {
  readonly isActive: boolean;
  readonly onClick: () => void;
  readonly children: ReactElement | readonly ReactElement[] | string;
}) => (
  <HiiveButton
    _hover={{ color: `grey.900` }}
    _active={{ color: `grey.900` }}
    _focus={{ outline: `none` }}
    color={isActive ? `grey.900` : `grey.500`}
    backgroundColor="transparent"
    rounded="none"
    size="sm"
    fontWeight="normal"
    onClick={onClick}
    paddingLeft={0}
    observabilityLabel="[DashboardLatestMarketActivity] Tab"
  >
    <Box
      borderBottomColor={isActive ? `salmon.900` : `transparent`}
      borderBottomWidth="3px"
      paddingX="3px"
      paddingBottom="8px"
    >
      <Text textStyle="heading-md">{children}</Text>
    </Box>
  </HiiveButton>
);

const LatestListings = ({
  latestListings,
}: {
  readonly latestListings: readonly LatestActivityListListingFragment[];
}) => (
  <VStack spacing={4}>
    {latestListings.map((listing: LatestActivityListListingFragment) => (
      <ListingLatestPostingCard key={listing.id} listing={listing} />
    ))}
  </VStack>
);

const LatestStandingBids = ({
  latestStandingBids,
}: {
  readonly latestStandingBids: readonly LatestActivityListStandingBidFragment[];
}) => (
  <VStack spacing={4}>
    {latestStandingBids.map(
      (standingBid: LatestActivityListStandingBidFragment) => (
        <StandingBidLatestPostingCard
          key={standingBid.id}
          standingBid={standingBid}
        />
      ),
    )}
  </VStack>
);

const RegularUserDashboardLatestMarketActivity = ({
  ...cardProps
}: CardProps) => {
  const { t } = useTranslation(`dashboard`);
  const [currentTab, setCurrentTab] = useState<TabKind>(`LISTINGS`);

  const onClick = (tab: TabKind) => {
    setCurrentTab(tab);
  };

  const query = useLatestMarketActivityQuery({
    variables: {
      numListings: constants.number_of_latest_listings,
      numStandingBids: constants.number_of_latest_standing_bids,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery
      query={query}
      fallback={<DashboardLatestMarketActivitySkeleton {...cardProps} />}
    >
      {({ data: { latestListings, latestStandingBids } }) => (
        <Card
          data-testid="latest-market-activity"
          w="full"
          h="min-content"
          {...cardProps}
        >
          <CardHeader>
            <Text textStyle="heading-sm">Latest Market Activity</Text>
          </CardHeader>
          <HStack px={{ base: 4, md: 6 }} pt={{ base: 4, md: 6 }}>
            <Tab
              isActive={currentTab === `LISTINGS`}
              onClick={() => onClick(`LISTINGS`)}
            >
              {t`listings`}
            </Tab>
            <Tab
              isActive={currentTab === `STANDING_BIDS`}
              onClick={() => onClick(`STANDING_BIDS`)}
            >
              {t`standing_bids`}
            </Tab>
          </HStack>
          <CardBody h="auto" overflowY="auto" maxH="1408px">
            {currentTab === `LISTINGS` && (
              <LatestListings latestListings={latestListings} />
            )}
            {currentTab === `STANDING_BIDS` && (
              <LatestStandingBids latestStandingBids={latestStandingBids} />
            )}
          </CardBody>
        </Card>
      )}
    </WithQuery>
  );
};

export const DashboardLatestMarketActivity = ({ ...cardProps }: CardProps) => (
  <RegularUserDashboardLatestMarketActivity {...cardProps} />
);
