import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Text,
  HStack,
  TextProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import {
  ActivitySection,
  BackToTop,
  ListingPostingCard,
  StandingBidPostingCard,
} from "@/components/common";
import {
  CompanyPageMarketActivityCompanyFragment,
  CompanyPageMarketActivityListingFragment,
  CompanyPageMarketActivityListingsFragment,
  CompanyPageMarketActivityStandingBidFragment,
  CompanyPageMarketActivityStandingBidsFragment,
} from "@/gql";
import { formatPricePerShare } from "@/utils";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";
import {
  getLastTruncatedListingPricePerShare,
  getLastTruncatedStandingBidPricePerShare,
  getTotalPostingsCount,
  useTrackViewMorePostings,
} from "./utils";

type MarketActivityContentTotalCountProps = {
  readonly count: number;
};

type MarketActivityContentListingCardsProps = {
  listings: CompanyPageMarketActivityListingFragment[];
  company: CompanyPageMarketActivityCompanyFragment;
};

type MarketActivityContentListingsProps = {
  listings: CompanyPageMarketActivityListingsFragment;
  company: CompanyPageMarketActivityCompanyFragment;
  listingsCount: number;
  standingBidsCount: number;
};

type MarketActivityContentStandingBidCardsProps = {
  standingBids: CompanyPageMarketActivityStandingBidFragment[];
  company: CompanyPageMarketActivityCompanyFragment;
};

type MarketActivityContentStandingBidsProps = {
  standingBids: CompanyPageMarketActivityStandingBidsFragment;
  company: CompanyPageMarketActivityCompanyFragment;
  listingsCount: number;
  standingBidsCount: number;
};

type MarketActivityContentProps = {
  readonly company: CompanyPageMarketActivityCompanyFragment;
};

function MarketActivityContentViewMorePostingsText(props: TextProps) {
  return (
    <Text
      role="button"
      textStyle="heading-xs"
      textDecoration="underline"
      cursor="pointer"
      pt={6}
      {...props}
    />
  );
}

function MarketActivityContentListingCards({
  company,
  listings,
}: MarketActivityContentListingCardsProps) {
  return listings.map((listing) => {
    const { id } = listing;

    return <ListingPostingCard key={id} listing={{ ...listing, company }} />;
  });
}

function MarketActivityContentListings({
  listings,
  listingsCount,
  standingBidsCount,
  company,
}: MarketActivityContentListingsProps) {
  const { t } = useTranslation(`company`);

  const trackMorePostings = useTrackViewMorePostings();

  const { isOpen: showRemainingListings, onOpen: setShowRemainingListings } =
    useDisclosure();

  const { truncated: truncatedListings, remaining: remainingListings } =
    listings;

  const { length: remainingListingsLength } = remainingListings;

  const lastTruncatedPricePerShare =
    getLastTruncatedListingPricePerShare(truncatedListings);

  const hasListings = listingsCount > 0;
  const hasRemainingListings =
    !showRemainingListings && remainingListingsLength > 0;

  const handleViewMorePostings = useCallback(() => {
    trackMorePostings(`View more listings`, company);

    return setShowRemainingListings();
  }, [trackMorePostings, setShowRemainingListings]);

  return hasListings ? (
    <VStack spacing={4}>
      <MarketActivityContentListingCards
        listings={truncatedListings}
        company={company}
      />

      {showRemainingListings && (
        <>
          <MarketActivityContentListingCards
            listings={remainingListings}
            company={company}
          />
          <BackToTop
            variant={{
              base: `mobile`,
              lg: `desktop`,
            }}
          />
        </>
      )}

      {hasRemainingListings && (
        <MarketActivityContentViewMorePostingsText
          onClick={handleViewMorePostings}
        >
          {t(`view_listings_over`, {
            pricePerShare: formatPricePerShare(lastTruncatedPricePerShare ?? 0),
          })}
        </MarketActivityContentViewMorePostingsText>
      )}
    </VStack>
  ) : (
    <ListingsEmptyState
      company={company}
      numberOfStandingBids={standingBidsCount}
    />
  );
}

function MarketActivityContentStandingBidCards({
  company,
  standingBids,
}: MarketActivityContentStandingBidCardsProps) {
  return standingBids.map((standingBid) => (
    <StandingBidPostingCard
      key={standingBid.id}
      standingBid={{ ...standingBid, company }}
    />
  ));
}

function MarketActivityContentStandingBids({
  standingBids,
  listingsCount,
  standingBidsCount,
  company,
}: MarketActivityContentStandingBidsProps) {
  const { t } = useTranslation(`company`);
  const trackMorePostings = useTrackViewMorePostings();

  const {
    isOpen: showRemainingStandingBids,
    onOpen: setShowRemainingStandingBids,
  } = useDisclosure();
  const { truncated: truncatedStandingBids, remaining: remainingStandingBids } =
    standingBids;

  const { length: remainingStandingBidsLength } = remainingStandingBids;

  const lastTruncatedPricePerShare = getLastTruncatedStandingBidPricePerShare(
    truncatedStandingBids,
  );

  const hasStandingBids = standingBidsCount > 0;
  const hasRemainingStandingBids =
    !showRemainingStandingBids && remainingStandingBidsLength > 0;

  const handleViewMorePostings = useCallback(() => {
    trackMorePostings(`View more standing bids`, company);

    return setShowRemainingStandingBids();
  }, [trackMorePostings, setShowRemainingStandingBids]);

  return hasStandingBids ? (
    <VStack spacing={4}>
      <MarketActivityContentStandingBidCards
        standingBids={truncatedStandingBids}
        company={company}
      />

      {showRemainingStandingBids && (
        <>
          <MarketActivityContentStandingBidCards
            standingBids={remainingStandingBids}
            company={company}
          />
          <BackToTop
            variant={{
              base: `mobile`,
              lg: `desktop`,
            }}
          />
        </>
      )}

      {hasRemainingStandingBids && (
        <MarketActivityContentViewMorePostingsText
          onClick={handleViewMorePostings}
        >
          {t(`view_standing_bids_under`, {
            pricePerShare: formatPricePerShare(lastTruncatedPricePerShare ?? 0),
          })}
        </MarketActivityContentViewMorePostingsText>
      )}
    </VStack>
  ) : (
    <StandingBidsEmptyState
      company={company}
      numberOfListings={listingsCount}
    />
  );
}

function MarketActivityContentTotalCount({
  count,
}: MarketActivityContentTotalCountProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      px="7px"
      py="2px"
      bg="grey.50"
      borderWidth={0.5}
      borderStyle="solid"
      borderColor="grey.200"
      borderRadius="full"
    >
      <Text textStyle="heading-4xs" color="grey.900" fontWeight={500}>
        {count}
      </Text>
    </Flex>
  );
}

export function MarketActivityContent({ company }: MarketActivityContentProps) {
  const { t } = useTranslation(`company`);

  const {
    activity: { othersListings: listings, othersStandingBids: standingBids },
  } = company;

  const listingsCount = getTotalPostingsCount(listings);
  const standingBidsCount = getTotalPostingsCount(standingBids);

  return (
    <ActivitySection title={t(`market_activity`)}>
      <Tabs variant="underline" mt={{ base: 4, md: 0 }}>
        <TabList pb={4}>
          <Tab>
            <HStack gap={2}>
              <Text>{t(`listings`)}</Text>
              <MarketActivityContentTotalCount count={listingsCount} />
            </HStack>
          </Tab>
          <Tab>
            <HStack gap={2}>
              <Text>{t(`standing_bids`)}</Text>
              <MarketActivityContentTotalCount count={standingBidsCount} />
            </HStack>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <MarketActivityContentListings
              company={company}
              listings={listings}
              standingBidsCount={standingBidsCount}
              listingsCount={listingsCount}
            />
          </TabPanel>
          <TabPanel>
            <MarketActivityContentStandingBids
              company={company}
              standingBids={standingBids}
              standingBidsCount={standingBidsCount}
              listingsCount={listingsCount}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ActivitySection>
  );
}
