import { WithQuery } from "@/components/common";
import { useCompanyPageMarketActivityCompanyByIdQuery } from "@/gql";

import { MarketActivityContent } from "./MarketActivityContent";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";

export const MarketActivity = ({
  companyId,
}: {
  readonly companyId: string;
}) => {
  const query = useCompanyPageMarketActivityCompanyByIdQuery({
    variables: {
      id: companyId,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({ data: { companyById: company } }) => (
        <MarketActivityContent company={company} />
      )}
    </WithQuery>
  );
};
