import { useTranslation } from "react-i18next";

import { Box, Fade, Text } from "@chakra-ui/react";

import { ActivityCardNewBadge, PostingCard } from "@/components/common";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { StandingBidAvailablePostingCardFragment } from "@/gql";
import { toTimestamp } from "@/utils";

type StandingBidAvailablePostingCardFooterProps = {
  standingBid: StandingBidAvailablePostingCardFragment;
};

export function StandingBidAvailablePostingCardFooter({
  standingBid,
}: StandingBidAvailablePostingCardFooterProps) {
  const { placedAt } = standingBid;
  const { t } = useTranslation(`standingBids`);
  const { getIsNewStandingBid } = useUnaccreditedSellerInlineNotifications();

  const isNewStandingBid = getIsNewStandingBid(standingBid);
  const placedAtFormatted = toTimestamp(placedAt);

  return (
    <PostingCard.Footer>
      <Text color="grey.700" textStyle="text-sm">
        {t(`placed_timestamp`, {
          date: placedAtFormatted,
        })}
      </Text>
      <Box flexGrow={1} />
      {isNewStandingBid && (
        <Fade in>
          <ActivityCardNewBadge />
        </Fade>
      )}
    </PostingCard.Footer>
  );
}
