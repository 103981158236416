import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";

import { useRouter } from "next/router";

import {
  CurrentContextDocument,
  InvestorStatus,
  UserWithInstitutionFragment,
  useOnIdentityInquiryUpdatedSubscription,
} from "@/gql";

const useVerifyIdentitySubscription = ({
  actor,
}: {
  readonly actor?: UserWithInstitutionFragment;
}) => {
  const client = useApolloClient();
  const shouldSkipVerifiedIdentitySubscription =
    actor?.investorStatus !== InvestorStatus.Individual ||
    actor?.identityVerified;

  const verifiedIdentityResult = useOnIdentityInquiryUpdatedSubscription({
    skip: shouldSkipVerifiedIdentitySubscription,
  });

  useEffect(() => {
    if (verifiedIdentityResult.data) {
      client.refetchQueries({
        include: [CurrentContextDocument],
      });
    }
  }, [verifiedIdentityResult]);
};

const useIsOnboarding = () => {
  const router = useRouter();

  const exemptRoutes = [`/terms-and-conditions`];

  const onboardingAccessibleRoutes = [
    `/welcome`,
    `/terms-and-conditions`,
    `/verify-email`,
    `/something-went-wrong`,
    `/page-not-found`,
  ];

  const isOnboarding =
    onboardingAccessibleRoutes.includes(router.asPath) ||
    exemptRoutes.includes(router.asPath);

  return isOnboarding;
};

export { useVerifyIdentitySubscription, useIsOnboarding };
