import React, { ReactNode, useEffect } from "react";

import { useRouter } from "next/router";

import {
  Box,
  SimpleGrid,
  GridItem,
  Flex,
  VStack,
  Card,
  CardHeader,
} from "@chakra-ui/react";

import { FullContentWrapper } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { TransactionExecutionPageContext } from "@/components/providers";
import {
  getTransactionIsBuySide,
  MilestonesCard,
} from "@/components/transactions";
import {
  ExecutionWorkflowStatus,
  UserWithInstitutionFragment,
  useTransactionExecutionPageOnTransactionUpdatedSubscription,
  useTransactionExecutionPageTransactionByIdQuery,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import useTransactionExecution from "@/hooks/useTransactionExecution";

import InformativeArticlesCard from "./InformativeArticlesCard";
import TransactionExecutionPageCompletedCard from "./TransactionExecutionPageCompletedCard";
import TransactionExecutionPageSkeleton from "./TransactionExecutionPageSkeleton";
import { TransactionExecutionPageSummaryCardBody } from "./TransactionExecutionPageSummaryCardBody";
import TransactionExecutionPageTabNav from "./TransactionExecutionPageTabNav";

type TransactionExecutionPageLayoutProps = {
  readonly children: ReactNode;
  readonly actor: UserWithInstitutionFragment;
};

const TransactionExecutionPageLayoutContent = withCurrentActor(
  ({ children, actor }: TransactionExecutionPageLayoutProps) => {
    const transaction = useTransactionExecution()!;

    const workflow = transaction?.workflow;

    useTransactionExecutionPageOnTransactionUpdatedSubscription({
      variables: { transactionId: transaction?.id },
    });

    const isDesktop = useIsDesktop();

    const workflowCompleted =
      !!workflow &&
      workflow.status === ExecutionWorkflowStatus.Completed &&
      !transaction.cancelledAt &&
      !transaction.issuerApprovalDeclinedAt;

    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    return (
      <FullContentWrapper
        px={{ base: 0, md: 4, lg: 8 }}
        pt={{ base: 4, md: 7 }}
      >
        <Box w="full" maxW={workflowCompleted ? `2xl` : `278`}>
          {workflowCompleted ? (
            <TransactionExecutionPageCompletedCard transaction={transaction} />
          ) : (
            <SimpleGrid columns={workflowCompleted ? 8 : 12} gap={8}>
              <GridItem colSpan={{ base: workflowCompleted ? 8 : 12, lg: 8 }}>
                <Card
                  variant="no-border-radius-base"
                  borderRadius={0}
                  borderTopRadius={{ base: 0, md: `md` }}
                  borderBottomColor="grey.50"
                >
                  <CardHeader bg="teal.50" borderBottomColor="teal.100" />
                  <TransactionExecutionPageSummaryCardBody
                    transaction={transaction}
                  />
                </Card>
                <Flex direction="column" gap={4}>
                  <TransactionExecutionPageTabNav transaction={transaction} />
                  {children}
                </Flex>
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 4 }}>
                <VStack spacing={4}>
                  {isDesktop && !!workflow && (
                    <MilestonesCard workflow={workflow} isBuySide={isBuySide} />
                  )}
                  {isDesktop && (
                    <InformativeArticlesCard transaction={transaction} />
                  )}
                </VStack>
              </GridItem>
            </SimpleGrid>
          )}
        </Box>
      </FullContentWrapper>
    );
  },
);

const TransactionExecutionPageLayout = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const router = useRouter();
  const { id } = router.query;

  const { data, startPolling, stopPolling } =
    useTransactionExecutionPageTransactionByIdQuery({
      variables: {
        id: id as string,
      },
    });

  const transaction = data?.transactionById;

  // This is a workaround for a possible race condition for assigning
  // a workflow to a transaction. Will need to remove polling here in the future.
  useEffect(() => {
    startPolling(1000);
    if (transaction?.workflow) {
      stopPolling();
    }
  }, [transaction?.workflow]);

  if (!transaction) {
    return <TransactionExecutionPageSkeleton />;
  }

  return (
    <TransactionExecutionPageContext.Provider value={transaction}>
      <TransactionExecutionPageLayoutContent>
        {children}
      </TransactionExecutionPageLayoutContent>
    </TransactionExecutionPageContext.Provider>
  );
};

export default TransactionExecutionPageLayout;
