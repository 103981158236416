import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Flex, HStack } from "@chakra-ui/react";

import {
  ExecutionTaskStatus,
  TransactionExecutionPageTaskFragment,
  TransactionExecutionPageTransactionByIdDocument,
  useCompleteExecutionTaskMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

import { TaskFileUpload } from "./TaskFileUpload";
import { Upload, UploadStatus } from "./types";

const UploadFilesTaskCard = ({
  task,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
}) => {
  const { t } = useTranslation();
  const [completeTask] = useMutationWithError(
    useCompleteExecutionTaskMutation({
      refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
    }),
    `completeExecutionTask`,
  );
  const [uploads, setUploads] = useState<Upload[]>(
    task.data?.__typename === `ExecutionUploadFilesTaskData` && task.data?.files
      ? task.data?.files?.map((file) => ({
          id: file.id,
          status: UploadStatus.Uploaded,
          name: file.filename,
          type: file.contentType,
        }))
      : [],
  );

  const handleUploadChange = (
    uploadUpdater: (prevUploads: Upload[]) => Upload[],
  ) => {
    setUploads(uploadUpdater);
  };

  const handleComplete = () => {
    completeTask({
      variables: {
        taskId: task.id,
      },
    });
  };

  const completed = task.status === ExecutionTaskStatus.Completed;

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <TaskFileUpload
        taskId={task.id}
        uploads={uploads}
        onUploadChange={handleUploadChange}
        disabled={task.status !== ExecutionTaskStatus.InProgress}
        hideDropzone={completed}
      />
      {task.status === ExecutionTaskStatus.InProgress && (
        <HStack w="full" justifyContent="end" mt={2}>
          <Button
            variant="rounded-solid-grey"
            size={{ base: `md`, md: `xl` }}
            isDisabled={uploads.length === 0}
            onClick={() => {
              handleComplete();
            }}
          >
            {t(`submit`)}
          </Button>
        </HStack>
      )}
    </Flex>
  );
};

export default UploadFilesTaskCard;
