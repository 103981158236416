import isNil from "lodash/isNil";

import {
  CardBody,
  CardFooter,
  CardHeader,
  Fade,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterCountsWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
  ActivityCardNewBadge,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import { PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment } from "@/gql";
import { appendSellerCompanyIdToUrl, getNumOfShares, bidLabel } from "@/utils";

const ActiveBidCardDetails = ({
  activeBid,
}: {
  readonly activeBid: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment;
}) => {
  const numShares = getNumOfShares(activeBid);

  return !isNil(numShares) ? (
    <HStack justifyContent="space-between" spacing={6}>
      <ActivityCardPricingWrapper>
        <ActivityCardPricingNumSharesAndPrice
          numShares={numShares}
          price={activeBid.pricePerShare}
        />
        <ActivityCardPricingTotal
          numShares={numShares}
          price={activeBid.pricePerShare}
        />
      </ActivityCardPricingWrapper>
    </HStack>
  ) : null;
};

export const ActiveBidCard = ({
  activeBid,
}: {
  readonly activeBid: PostActivityUnaccreditedSellerDashboardByIdPageActiveBidsPageBidFragment;
}) => {
  const { getIsNewBid, getInquiryNumNewMessages } =
    useUnaccreditedSellerInlineNotifications();

  const { discussion } = activeBid;

  const numNewMessages = !!discussion
    ? getInquiryNumNewMessages(discussion)
    : 0;

  const totalNumMessages = !!discussion ? discussion.totalNumMessages : 0;
  const hasMessages = totalNumMessages > 0;
  const isNewBid = getIsNewBid(activeBid);
  const showFooterCounts = isNewBid || hasMessages;
  const title = bidLabel(activeBid.listing);

  const url = `/listings/bids/${activeBid.id}`;

  return (
    <ActivityCard href={appendSellerCompanyIdToUrl(url, activeBid.companyId)}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack>
            <ActivityCardBadge variant="bid" title={title} />
            <Text textStyle="text-lg">{activeBid.displayId}</Text>
          </HStack>
        </HStack>
      </CardHeader>
      <CardBody>
        <ActiveBidCardDetails activeBid={activeBid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTimestamp title="Placed" date={activeBid.placedAt} />
            {!!activeBid.expireAt && (
              <ActivityCardTimestamp
                title="Expires"
                date={activeBid.expireAt}
              />
            )}
          </ActivityCardFooterStatusWrapper>
          {showFooterCounts && (
            <ActivityCardFooterCountsWrapper>
              <Stack flexDirection={{ base: `column`, sm: `row` }}>
                {hasMessages && (
                  <MessagesBadge
                    numUnreadMessages={numNewMessages}
                    totalNumMessages={totalNumMessages}
                  />
                )}
                {isNewBid && (
                  <Fade in>
                    <ActivityCardNewBadge />
                  </Fade>
                )}
              </Stack>
            </ActivityCardFooterCountsWrapper>
          )}
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};
