import { Trans, useTranslation } from "react-i18next";

import { VStack, Text } from "@chakra-ui/react";

import { MailtoLink, PostingCard } from "@/components/common";
import { StandingBidPostingCardBrokerBodyFragment } from "@/gql";
import { getSecuritySpecialistContact } from "@/utils";

import { StandingBidPostingCardBodyAcceptedShares } from "./StandingBidPostingCardBodyAcceptedShares";
import { StandingBidPostingCardBodyPricing } from "./StandingBidPostingCardBodyPricing";

type StandingBidPostingCardBrokerBodyProps = {
  standingBid: StandingBidPostingCardBrokerBodyFragment;
};

function StandingBidPostingCardBrokerSolicitedBody({
  standingBid: { company },
}: StandingBidPostingCardBrokerBodyProps) {
  const { t } = useTranslation();
  const email = getSecuritySpecialistContact(company);

  return (
    <PostingCard.Body>
      <VStack spacing={0}>
        <Text textStyle="heading-md" w="full">
          {t(`solicited_standing_bid_activity_broker_card_body`)}
        </Text>
        <Text w="full">
          <Trans
            i18nKey="solicited_standing_bid_activity_card_contact"
            t={t}
            components={[
              <MailtoLink
                key="contact"
                textDecoration="underline"
                email={email}
                subject="Broker Interest in Solicited Listing"
              />,
            ]}
            values={{ email }}
          />
        </Text>
      </VStack>
    </PostingCard.Body>
  );
}

function StandingBidPostingCardBrokerUnsolicitedBody({
  standingBid,
}: StandingBidPostingCardBrokerBodyProps) {
  return (
    <PostingCard.Body>
      <StandingBidPostingCardBodyPricing standingBid={standingBid} />
      <StandingBidPostingCardBodyAcceptedShares standingBid={standingBid} />
    </PostingCard.Body>
  );
}

export function StandingBidPostingCardBrokerBody({
  standingBid,
}: StandingBidPostingCardBrokerBodyProps) {
  const { solicited } = standingBid;

  if (solicited) {
    return (
      <StandingBidPostingCardBrokerSolicitedBody standingBid={standingBid} />
    );
  }

  return (
    <StandingBidPostingCardBrokerUnsolicitedBody standingBid={standingBid} />
  );
}
