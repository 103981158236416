import { Trans } from "react-i18next";
import { match } from "ts-pattern";

import { HStack, Text } from "@chakra-ui/react";

import { SensitiveText } from "@/components/common";
import { ListingPostingCardBodyFragment } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  getIsConditionallySoldListing,
  getIsPartiallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
  lot,
} from "@/utils";

type ListingPostingCardBodyPricingProps = {
  listing: ListingPostingCardBodyFragment;
};

type ListingPostingCardBodyPricingContentProps = {
  isPartiallyAccepted: boolean;
  isConditionallySold: boolean;
  pricePerShare: number;
  numSharesOriginal: number;
  numSharesAvailable: number;
};

const COMPONENTS = {
  b: (
    <Text
      as="span"
      fontWeight={500}
      fontStyle="inherit"
      fontSize={{ base: 14, lg: `inherit` }}
    />
  ),
  r: (
    <Text
      as="span"
      fontWeight={400}
      fontStyle="inherit"
      fontSize={{ base: 14, lg: `inherit` }}
    />
  ),
  u: (
    <Text
      as="span"
      textDecorationLine="line-through"
      color="grey.200"
      fontStyle="inherit"
      fontSize={{ base: 14, lg: `inherit` }}
    />
  ),
  spacer: <Text as="span" mr={1} />,
};

function ListingPostingCardBodyPricingContent({
  isPartiallyAccepted,
  isConditionallySold,
  pricePerShare,
  numSharesOriginal,
  numSharesAvailable,
}: ListingPostingCardBodyPricingContentProps) {
  const numSharesOriginalFormatted = formatShares(numSharesOriginal);
  const numSharesAvailableFormatted = formatShares(numSharesAvailable);
  const pricePerShareFormatted = formatPricePerShare(pricePerShare);

  const lotAvailableFormatted = lot(numSharesAvailable, pricePerShare);
  const lotOriginalFormatted = lot(numSharesOriginal, pricePerShare);

  return match({ isPartiallyAccepted, isConditionallySold })
    .with({ isPartiallyAccepted: true }, () => (
      <HStack spacing={3}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_partially_sold_num_shares_price"
            ns="company"
            values={{
              numSharesOriginal: numSharesOriginalFormatted,
              numSharesAvailable: numSharesAvailableFormatted,
              pricePerShare: pricePerShareFormatted,
            }}
            components={COMPONENTS}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price"
          ns="company"
          values={{
            lot: lotAvailableFormatted,
          }}
          components={COMPONENTS}
        />
      </HStack>
    ))
    .with({ isConditionallySold: true }, () => (
      <HStack spacing={3}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_sold_out_num_shares_price"
            ns="company"
            values={{
              numSharesOriginal: numSharesOriginalFormatted,
              numSharesAvailable: numSharesAvailableFormatted,
              pricePerShare: pricePerShareFormatted,
            }}
            components={COMPONENTS}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price_sold_out"
          ns="company"
          values={{
            lot: lotOriginalFormatted,
          }}
          components={COMPONENTS}
        />
      </HStack>
    ))
    .otherwise(() => (
      <HStack spacing={3}>
        <HStack spacing={0}>
          <Trans
            i18nKey="posting_num_shares_price"
            ns="company"
            values={{
              numSharesOriginal: numSharesOriginalFormatted,
              numSharesAvailable: numSharesAvailableFormatted,
              pricePerShare: pricePerShareFormatted,
            }}
            components={COMPONENTS}
          />
        </HStack>
        <Trans
          i18nKey="posting_lot_price"
          ns="company"
          values={{
            lot: lotAvailableFormatted,
          }}
          components={COMPONENTS}
        />
      </HStack>
    ));
}

export function ListingPostingCardBodyPricing({
  listing,
}: ListingPostingCardBodyPricingProps) {
  const { listingPricePerShare: pricePerShare } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);

  const isPartiallyAccepted = getIsPartiallySoldListing(listing);
  const isConditionallySold = getIsConditionallySoldListing(listing);

  if (!pricePerShare) {
    return null;
  }

  return (
    <SensitiveText>
      <ListingPostingCardBodyPricingContent
        numSharesOriginal={numSharesOriginal}
        numSharesAvailable={numSharesAvailable}
        isPartiallyAccepted={isPartiallyAccepted}
        isConditionallySold={isConditionallySold}
        pricePerShare={pricePerShare}
      />
    </SensitiveText>
  );
}
