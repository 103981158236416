import { useTranslation } from "react-i18next";

import { GridItem, SimpleGrid } from "@chakra-ui/react";

import { ExecutionFileCard } from "@/components/transactions";
import {
  ExecutionWorkflowActionType,
  TransactionExecutionPageTransactionFragment,
  useExecutionWorkflowActionMutation,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

type TransactionDocumentsCardBodyProps = {
  transaction: TransactionExecutionPageTransactionFragment | undefined;
};

const TransactionDocuments = ({
  transaction,
}: TransactionDocumentsCardBodyProps) => {
  const { t } = useTranslation(`execution`);
  const hasDocuments = transaction && transaction?.fileUploads?.length > 0;
  const [executionWorkflowAction] = useMutationWithError(
    useExecutionWorkflowActionMutation(),
    `executionWorkflowAction`,
  );

  const handleDownload = async (fileUploadId: string) => {
    if (!transaction?.workflow?.id) return;

    const response = await executionWorkflowAction({
      variables: {
        id: transaction?.workflow.id,
        type: ExecutionWorkflowActionType.GenerateFileDownloadUrl,
        input: {
          generateFileDownloadUrl: {
            fileUploadId,
          },
        },
      },
    });

    const result = response?.executionWorkflowAction?.result;
    if (
      result?.__typename === `ExecutionWorkflowActionGenerateFileDownloadUrl` &&
      result.url
    ) {
      window.open(result.url, `_blank`);
    }
  };

  return (
    <>
      {hasDocuments && (
        <SimpleGrid columns={{ sm: 2, base: 1 }} gap={3} w="full">
          {transaction?.fileUploads.map((fileUpload) => (
            <GridItem key={fileUpload.id}>
              <ExecutionFileCard
                file={fileUpload}
                onDownload={handleDownload}
              />
            </GridItem>
          ))}
        </SimpleGrid>
      )}
      {!hasDocuments && t(`no_documents_uploaded`)}
    </>
  );
};

export default TransactionDocuments;
