import { t } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { match } from "ts-pattern";

import {
  Card,
  CardHeader,
  CardBody,
  HStack,
  Box,
  Text,
} from "@chakra-ui/react";

import { ExecutionWorkflowCondition } from "@/gql";

import WorkflowConditionStatusIcon from "./WorkflowConditionStatusIcon";

export default function WorkflowConditionCard({
  condition,
  variant = `compact`,
  isBuySide,
}: {
  condition: ExecutionWorkflowCondition;
  variant?: "compact" | "full";
  isBuySide: boolean;
}) {
  const cardDetails = match({ isBuySide, condition })
    .with(
      { condition: ExecutionWorkflowCondition.IssuerApprovalDeclined },
      () => ({
        borderColor: `red.700`,
        bgColor: `salmon.50`,
        text: (
          <Trans
            i18nKey="issuer_approval_declined"
            components={[
              <Text
                key="issuer_approval_declined"
                display={variant === `full` ? `inline` : `none`}
              />,
            ]}
          />
        ),
        description: t(`issuer_approval_declined_description`),
      }),
    )
    .with({ condition: ExecutionWorkflowCondition.Cancelled }, () => ({
      borderColor: `red.700`,
      bgColor: `salmon.50`,
      text: t(`transaction_cancelled`),
      description: t(`transaction_cancelled_description`),
    }))
    .with(
      { isBuySide: true, condition: ExecutionWorkflowCondition.Rofr },
      () => ({
        borderColor: `red.700`,
        bgColor: `salmon.50`,
        text: (
          <Trans
            i18nKey="issuer_exercised_rofr_buyer"
            components={[
              <Text
                key="issuer_exercised_rofr_buyer"
                display={variant === `full` ? `inline` : `none`}
              />,
            ]}
          />
        ),
        description: t(`issuer_exercised_rofr_buyer_description`),
      }),
    )
    .with(
      { isBuySide: false, condition: ExecutionWorkflowCondition.Rofr },
      () => ({
        borderColor: `teal.900`,
        bgColor: `teal.50`,
        text: (
          <Trans
            i18nKey="issuer_exercised_rofr_seller"
            components={[
              <Text
                key="issuer_exercised_rofr_seller"
                display={variant === `full` ? `inline` : `none`}
              />,
            ]}
          />
        ),
        description: t(`issuer_exercised_rofr_seller_description`),
      }),
    )
    .otherwise(() => ({
      borderColor: `blue.50`,
      bgColor: `blue.50`,
      text: null,
      description: null,
    }));

  // sell side ROFR is shown without icon, evaluating here to avoid outer icon Box
  const showIcon = !(
    condition === ExecutionWorkflowCondition.Rofr && !isBuySide
  );

  return (
    <Card
      variant={variant === `full` ? `no-border-radius-base` : undefined}
      w="full"
      borderWidth={{
        base: variant === `full` ? `1px 0px 1px 0px` : `1px`,
        md: `1px`,
      }}
      borderColor={cardDetails.borderColor}
    >
      <CardHeader
        px={variant === `full` ? 6 : 4}
        py={variant === `full` ? 4 : 2}
        borderBottom="none"
        bgColor={cardDetails.bgColor}
        borderBottomRadius={variant === `full` ? `none` : `md`}
      >
        <HStack>
          {showIcon && (
            <Box w={6} h={6} position="relative">
              <WorkflowConditionStatusIcon
                condition={condition}
                isBuySide={isBuySide}
                background={cardDetails.bgColor}
              />
            </Box>
          )}
          {cardDetails?.text && (
            <Text
              textStyle={variant === `compact` ? `heading-3xs` : `heading-md`}
            >
              {cardDetails.text}
            </Text>
          )}
        </HStack>
      </CardHeader>
      {variant === `full` && cardDetails?.description && (
        <CardBody p={4} px={6}>
          <Text textStyle="text-sm">{cardDetails.description}</Text>
        </CardBody>
      )}
    </Card>
  );
}
