import { Card, CardBody } from "@chakra-ui/react";

import useTransactionExecution from "@/hooks/useTransactionExecution";

import TransactionDocuments from "./TransactionDocuments";

const TransactionDocumentsCard = () => {
  const transaction = useTransactionExecution();

  return (
    <Card w="full" variant="no-border-radius-base">
      <CardBody px={4} py={6}>
        <TransactionDocuments transaction={transaction} />
      </CardBody>
    </Card>
  );
};

export default TransactionDocumentsCard;
