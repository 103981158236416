import { Trans } from "react-i18next";

import { Link, Text, VStack } from "@chakra-ui/react";

export const SellerAgreement = ({
  isClickwrapAgreement = true,
}: {
  readonly isClickwrapAgreement?: boolean;
}) => (
  <VStack spacing={4} alignItems="flex-start">
    <Text>
      <Trans
        i18nKey="seller_ca_first_paragraph_v2"
        components={{
          a1: (
            <Link
              fontWeight={500}
              textDecoration="underline"
              href="/terms-and-conditions"
              target="_blank"
            />
          ),
          a2: (
            <Link
              fontWeight={500}
              textDecoration="underline"
              href="https://www.hiive.com/privacy"
              target="_blank"
            />
          ),
          a3: (
            <Link
              fontWeight={500}
              textDecoration="underline"
              href="https://www.hiive.com/disclosures"
              target="_blank"
            />
          ),
        }}
      />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_second_paragraph_v2" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_third_paragraph_v2" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_fourth_paragraph_v2" />
    </Text>
    <Text>
      <Trans i18nKey="seller_ca_fifth_paragraph_v2" />
    </Text>
    {isClickwrapAgreement && (
      <Text>
        <Trans i18nKey="seller_ca_sixth_paragraph" />
      </Text>
    )}
  </VStack>
);
