import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  VStack,
  Flex,
  Box,
} from "@chakra-ui/react";

import { ActivityCard, Skeleton } from "@/components/common";
import { useCardUpdates } from "@/hooks/featureFlags";

interface ActivityCardSkeletonProps {
  /** Whether to display the card body */
  readonly includeBody?: boolean;
  /** Whether to display the card body */
  readonly includeFooter?: boolean;
  /** Whether to display property pills when the body is present */
  readonly includePropertyPills?: boolean;
}

const ActivityCardSkeleton = ({
  includeBody,
  includePropertyPills,
  includeFooter = true,
}: ActivityCardSkeletonProps) => {
  const areCardUpdatesEnabled = useCardUpdates();
  return (
    <ActivityCard href="#" pointerEvents="none">
      <CardHeader
        px={areCardUpdatesEnabled ? 3 : 4}
        py={areCardUpdatesEnabled ? 2 : 4}
      >
        <HStack>
          <Skeleton h="24px" w="50px" />
          <Skeleton h="20px" w="60px" />
        </HStack>
      </CardHeader>
      {includeBody && (
        <CardBody
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex direction="row" gap={6} width="400px">
            <Skeleton h="24px" maxW="38px" />
            <Skeleton h="24px" maxW="132px" />
            <Skeleton h="24px" maxW="80px" />
          </Flex>
          {includePropertyPills && (
            <VStack justifyContent="flex-end">
              <Skeleton h="35px" w="100px" />
              <Skeleton h="35px" w="100px" />
            </VStack>
          )}
        </CardBody>
      )}
      {includeFooter && (
        <Box w="full">
          {areCardUpdatesEnabled ? (
            <CardFooter py={3} justifyContent="flex-end">
              <Skeleton h="20px" w="5%" />
              <Skeleton h="20px" w="10%" />
            </CardFooter>
          ) : (
            <CardFooter py={3}>
              <Skeleton h="14px" w="35%" />
            </CardFooter>
          )}
        </Box>
      )}
    </ActivityCard>
  );
};

export default ActivityCardSkeleton;
