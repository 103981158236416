import {
  ActivityGroup,
  ActivitySection,
  ListingPostingCard,
  StandingBidPostingCard,
  WithQuery,
} from "@/components/common";
import {
  CompanyMarketActivityListingCard,
  CompanyMarketActivityStandingBidCard,
} from "@/components/companies";
import {
  UnaccreditedSellerCompanyPageMarketActivityCompanyFragment,
  UnaccreditedSellerCompanyPageMarketActivityListingFragment,
  UnaccreditedSellerCompanyPageMarketActivityStandingBidFragment,
  useUnaccreditedSellerCompanyPageMarketActivityQuery,
} from "@/gql";
import { useCardUpdates } from "@/hooks/featureFlags";

import { ListingsEmptyState } from "./ListingsEmptyState";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";
import { StandingBidsEmptyState } from "./StandingBidsEmptyState";

const Listings = ({
  listings,
  standingBids,
  company,
}: {
  readonly listings: readonly UnaccreditedSellerCompanyPageMarketActivityListingFragment[];
  readonly standingBids: readonly UnaccreditedSellerCompanyPageMarketActivityStandingBidFragment[];
  readonly company: UnaccreditedSellerCompanyPageMarketActivityCompanyFragment;
}) => {
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivityGroup
      title="Other Sellers"
      emptyState={
        <ListingsEmptyState
          company={company}
          numberOfStandingBids={standingBids.length}
        />
      }
    >
      {listings.map((listing) =>
        cardUpdatesEnabled ? (
          <ListingPostingCard
            key={listing.id}
            listing={{ ...listing, company }}
          />
        ) : (
          <CompanyMarketActivityListingCard
            key={listing.id}
            listing={listing}
            company={company}
          />
        ),
      )}
    </ActivityGroup>
  );
};
const StandingBids = ({
  standingBids,
  listings,
  company,
}: {
  readonly standingBids: readonly UnaccreditedSellerCompanyPageMarketActivityStandingBidFragment[];
  readonly listings: readonly UnaccreditedSellerCompanyPageMarketActivityListingFragment[];
  readonly company: UnaccreditedSellerCompanyPageMarketActivityCompanyFragment;
}) => {
  const cardUpdatesEnabled = useCardUpdates();

  return (
    <ActivityGroup
      title="Available Standing Bids"
      emptyState={
        <StandingBidsEmptyState
          company={company}
          numberOfListings={listings.length}
        />
      }
    >
      {standingBids.map((standingBid) =>
        cardUpdatesEnabled ? (
          <StandingBidPostingCard
            key={standingBid.id}
            standingBid={{ company, ...standingBid }}
          />
        ) : (
          <CompanyMarketActivityStandingBidCard
            key={standingBid.id}
            standingBid={standingBid}
          />
        ),
      )}
    </ActivityGroup>
  );
};

const MarketActivity = () => {
  const query = useUnaccreditedSellerCompanyPageMarketActivityQuery();
  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({
        data: {
          unaccreditedSellerMyCompanyActivity: {
            otherListings,
            availableStandingBids,
          },
          unaccreditedSellerMyActivity: { myCompany },
        },
      }) => (
        <ActivitySection title="Market Activity">
          <StandingBids
            standingBids={availableStandingBids}
            listings={otherListings}
            company={myCompany}
          />
          <Listings
            listings={otherListings}
            standingBids={availableStandingBids}
            company={myCompany}
          />
        </ActivitySection>
      )}
    </WithQuery>
  );
};

export default MarketActivity;
