import {
  CardFooter,
  CardHeader,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import { ActivityCard } from "@/components/common";
import {
  ActiveTransactionItemTransactionFragment,
  ExecutionMilestone,
} from "@/gql";
import { formatNumber, formatPricePerShare, lot, makeUrl } from "@/utils";

import ActiveTransactionItemStateBadge from "./ActiveTransactionItemStateBadge";
import TaskCountBadge from "./TaskCountBadge";

type ActiveTransactionItemCardProps = {
  transaction: ActiveTransactionItemTransactionFragment;
  taskCount: number;
};

const ActiveTransactionItemCard = ({
  transaction,
  taskCount,
}: ActiveTransactionItemCardProps) => {
  const {
    displayId,
    company: { logoUrl, name },
    numShares,
    pricePerShare,
  } = transaction;
  const showTasksBadge =
    transaction.workflow?.lastCompletedMilestone !==
      ExecutionMilestone.Complete && taskCount > 0;

  return (
    <ActivityCard href={makeUrl(transaction)}>
      <CardHeader
        as={HStack}
        alignItems="start"
        justify="space-between"
        p={0}
        px={4}
        py={3}
        w="full"
      >
        <VStack alignItems="start" gap={0.5}>
          <HStack color="grey.900" gap={1}>
            <Text textStyle="heading-lg">{name}</Text>
            <Text textStyle="heading-md" fontWeight={400}>
              {displayId}
            </Text>
          </HStack>
          <HStack textStyle="text-sm" color="grey.700" gap={1}>
            <Text fontWeight={500}>
              {formatNumber(numShares)} @ {formatPricePerShare(pricePerShare)}
            </Text>
            <Text fontWeight={400}>
              {lot(numShares, pricePerShare, `$0,0.00`)}
            </Text>
          </HStack>
        </VStack>
        {logoUrl && (
          <VStack h="6" minW="6">
            <Image h="full" src={logoUrl} alt={name} />
          </VStack>
        )}
      </CardHeader>
      <CardFooter
        as={HStack}
        justifyContent="space-between"
        p={0}
        px={4}
        py={2}
        w="full"
      >
        <ActiveTransactionItemStateBadge transaction={transaction} />
        {showTasksBadge && <TaskCountBadge taskCount={taskCount} />}
      </CardFooter>
    </ActivityCard>
  );
};

export default ActiveTransactionItemCard;
