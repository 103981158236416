import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import {
  CheckCircle,
  CircleDashed,
  PencilSimpleLine,
} from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ExecutionModalHeader } from "@/components/common";
import {
  ExecutionAnvilFillDocumentTaskData,
  TransactionExecutionPageTaskFragment,
  ExecutionTaskStatus,
  useExecutionTaskActionMutation,
  ExecutionTaskActionType,
  TransactionExecutionPageTransactionFragment,
  ExecutionTaskActorType,
} from "@/gql";
import { useColors, useCurrentActor, useMutationWithError } from "@/hooks";
import { getIsUnaccreditedSeller } from "@/utils";
import { toFormattedDate, toFullDate } from "@/utils/datetime";

import AlternateUserActions from "./AlternateUserActions";
import AnvilDownloadTaskCard from "./AnvilDownloadTaskCard";
import { AnvilEmbedEvent } from "./types";

const YourInformation = ({
  task,
  isCompleted,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly isCompleted: boolean;
}) => {
  const { t } = useTranslation(`execution`);
  if (!!task.alternateUser) {
    return <AlternateUserActions task={task} isCompleted={isCompleted} />;
  }
  return (
    <Text textStyle="heading-md" color="grey.700">
      {t(`your_information`)}
    </Text>
  );
};

const AnvilFillDocumentTaskCard = ({
  task,
  data,
  transaction,
  isBuySide,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionAnvilFillDocumentTaskData;
  readonly transaction: TransactionExecutionPageTransactionFragment;
  readonly isBuySide: boolean;
}) => {
  const [executionTaskActionMutation, isLoading] = useMutationWithError(
    useExecutionTaskActionMutation(),
    `executionTaskAction`,
  );
  const actor = useCurrentActor();
  const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

  const [embedUrl, setEmbedUrl] = useState(``);

  const [grey900] = useColors([`grey.900`]);

  const { t } = useTranslation(`execution`);

  const handleOpen = async () => {
    const response = await executionTaskActionMutation({
      variables: {
        id: task.id,
        type: ExecutionTaskActionType.AnvilFillDocumentGenerateEmbedUrl,
      },
    });

    if (
      !response ||
      response.executionTaskAction.result?.__typename !==
        `ExecutionTaskActionAnvilFillDocumentGenerateEmbedUrl`
    )
      return;

    setEmbedUrl(response.executionTaskAction.result.url);
  };

  const handleClose = () => setEmbedUrl(``);

  const handleEvent = (event: AnvilEmbedEvent) => {
    if (event.action !== `forgeComplete`) return;
    handleClose();
  };

  const hasAlternateUser = !!task.alternateUser;
  const isInProgress = task.status === ExecutionTaskStatus.InProgress;
  const showFillDocumentButton = isInProgress && !hasAlternateUser;
  const isCompleted = !isInProgress && !!task.completedAt;
  const showAlternateSignerCTA =
    isInProgress && !isUnaccreditedSeller && !hasAlternateUser;

  const pendingFillers = data.fillers.find((filler) => !filler.filledAt);
  const sortedFillers = [...data.fillers].sort((a) => {
    const preferredActorType = isBuySide
      ? ExecutionTaskActorType.Buyer
      : ExecutionTaskActorType.Seller;

    return a.actorType === preferredActorType ? -1 : 1;
  });

  const showCompletedAt = isCompleted && !pendingFillers;

  if (isCompleted && data.completedFileUploadId) {
    return (
      <AnvilDownloadTaskCard
        transaction={transaction}
        completedFileUploadId={data.completedFileUploadId}
      />
    );
  }

  return (
    <>
      <Card variant="flat">
        <Box px={4} py={3}>
          <HStack spacing={4} justifyContent="space-between">
            <HStack gap={4}>
              <Center>
                <PencilSimpleLine width="24px" height="24px" />
              </Center>
              <Text textStyle="heading-md">{t(`add_information`)}</Text>
            </HStack>
            {showFillDocumentButton && (
              <Button
                isLoading={isLoading}
                variant="rounded-solid-grey"
                size={{ base: `sm`, md: `md` }}
                onClick={handleOpen}
              >
                {t(`start_form`)}
              </Button>
            )}
            {showCompletedAt && (
              <Text textStyle="text-sm">
                {t(`completed`)}, {toFullDate(task.completedAt)}
              </Text>
            )}
          </HStack>
          {showAlternateSignerCTA && (
            <>
              <Divider w="calc(100% + 1rem)" my={3} />
              <AlternateUserActions task={task} />
            </>
          )}
        </Box>
        {pendingFillers && (
          <>
            {sortedFillers.map((filler) => {
              const isMySide = isBuySide
                ? filler.actorType === ExecutionTaskActorType.Buyer
                : filler.actorType === ExecutionTaskActorType.Seller;
              if (isMySide && !hasAlternateUser && !filler.filledAt)
                return null;

              return (
                <>
                  {isMySide && !!task.alternateUser && (
                    <Divider w="calc(100%-1rem)" ml={4} />
                  )}
                  <Box
                    key={filler.taskId}
                    bg={isMySide ? `inherit` : `grey.15`}
                    borderTop={isMySide ? `none` : `1px solid`}
                    borderTopColor="grey.75"
                    borderBottomRadius="md"
                    px={4}
                    py={3}
                  >
                    <HStack w="full" justifyContent="space-between">
                      <HStack gap={4} w="full">
                        <Center width="24px" height="24px">
                          {!!filler.filledAt ? (
                            <CheckCircle
                              color={grey900}
                              weight="fill"
                              size="18px"
                            />
                          ) : (
                            <CircleDashed color={grey900} size="18px" />
                          )}
                        </Center>

                        {match(filler.actorType)
                          .with(ExecutionTaskActorType.Seller, () =>
                            isBuySide ? (
                              <Text textStyle="heading-3xs" color="grey.700">
                                {t(`counterpartys_information`)}
                              </Text>
                            ) : (
                              <YourInformation
                                task={task}
                                isCompleted={!!filler.filledAt}
                              />
                            ),
                          )
                          .with(ExecutionTaskActorType.Buyer, () =>
                            isBuySide ? (
                              <YourInformation
                                task={task}
                                isCompleted={!!filler.filledAt}
                              />
                            ) : (
                              <Text textStyle="heading-3xs" color="grey.700">
                                {t(`counterpartys_information`)}
                              </Text>
                            ),
                          )
                          .with(ExecutionTaskActorType.Hiive, () => (
                            <Text textStyle="heading-3xs" color="grey.700">
                              {t(`hiive`)}
                            </Text>
                          ))
                          .otherwise(() => ``)}
                      </HStack>
                      {!!filler.filledAt && (
                        <Text textStyle="text-xs" flexShrink={0}>
                          {t(`completed`)} {toFormattedDate(filler.filledAt)}
                        </Text>
                      )}
                      {!filler.filledAt && !isMySide && (
                        <Badge px={2} py={1} variant="rounded-lighter-sky">
                          <Text
                            textStyle="text-sm"
                            lineHeight="unset"
                            textTransform="none"
                          >
                            {t(`pending`)}
                          </Text>
                        </Badge>
                      )}
                    </HStack>
                  </Box>
                </>
              );
            })}
          </>
        )}
      </Card>
      <Modal variant="texas" isOpen={!!embedUrl} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent h="6xl" w="6xl">
          <ExecutionModalHeader closeModal={handleClose}>
            {t(`information_collection`)}
          </ExecutionModalHeader>
          <ModalBody p={0} px={0} overflow="hidden">
            <Box
              as={AnvilEmbedFrame}
              onEvent={(event: AnvilEmbedEvent) => handleEvent(event)}
              iframeURL={embedUrl}
              w="full"
              h="full"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AnvilFillDocumentTaskCard;
