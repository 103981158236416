import { isEmpty } from "lodash";
import { useEffect } from "react";

import { useToken, useSignOut, useIsChangingRoute } from "@/hooks";
import constants from "@/utils/constants";

import { AuthenticatedLayoutHookProps } from "./types";

const { isAuth0Enabled } = constants;

export const useCheckAuthentication = ({
  actor,
  loading,
}: AuthenticatedLayoutHookProps & { readonly loading: boolean }) => {
  const token = useToken();
  const signOut = useSignOut();
  const isChangingRoute = useIsChangingRoute();
  const hasActor = !isEmpty(actor);

  const hasTokenButNoActor =
    token && !hasActor && !isChangingRoute() && !loading;
  useEffect(() => {
    if (!token && !isAuth0Enabled) {
      signOut({ redirectPath: window.location.pathname });
    }
  }, []);

  useEffect(() => {
    if (hasTokenButNoActor) {
      signOut();
    }
  }, [hasTokenButNoActor]);
};
