import { yupResolver } from "@hookform/resolvers/yup";
import { CurrencyCircleDollar } from "@phosphor-icons/react";
import { t } from "i18next";
import { useState } from "react";
import {
  FormProvider,
  Resolver,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  Card,
  HStack,
  Text,
  VStack,
  CardBody,
  Divider,
  GridItem,
  SimpleGrid,
  Button,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";

import { FormMoneyInput } from "@/components/react-hook-form";
import {
  ExecutionTaskStatus,
  ExecutionVerifyBankAccountTaskDataFragment,
  TransactionExecutionPageTaskFragment,
  useCompleteExecutionTaskMutation,
} from "@/gql";
import { useModal } from "@/hooks";

import VerifyBankAccountErrorModal, {
  VerifyBankAccountErrorType,
} from "./VerifyBankAccountModal/VerifyBankAccountErrorModal";

const DepositPlaceholder = () => {
  const { t } = useTranslation(`execution`);

  return (
    <HStack justifyContent="space-between" w="full">
      <HStack spacing={4}>
        <CurrencyCircleDollar size={24} />
        <Text textStyle="heading-md" color="grey.700">
          {t(`hiive_markets_limited`)}
        </Text>
      </HStack>
      <HStack spacing={0} textStyle="heading-md">
        <Text>$0.</Text>
        <Text color="teal.700">XX</Text>
      </HStack>
    </HStack>
  );
};

const validationSchema = Yup.object().shape({
  deposit1Value: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(0.01, t`deposit_min_error`)
    .max(0.99, t`deposit_max_error`)
    .required(t`deposit_one_error`),
  deposit2Value: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(0.01, t`deposit_min_error`)
    .max(0.99, t`deposit_max_error`)
    .required(t`deposit_two_error`),
});

type DepositFormValues = {
  deposit1Value: number;
  deposit2Value: number;
};

const DepositsFormFields = () => {
  const { control } = useFormContext<DepositFormValues>();

  return (
    <SimpleGrid w="full" gap={4} columns={2}>
      <GridItem>
        <FormMoneyInput
          name="deposit1Value"
          control={control}
          placeholder="$0.00"
          decimalScale={2}
          allowNegative={false}
        />
      </GridItem>
      <GridItem>
        <FormMoneyInput
          name="deposit2Value"
          control={control}
          placeholder="$0.00"
          decimalScale={2}
          allowNegative={false}
        />
      </GridItem>
    </SimpleGrid>
  );
};

const VerifyBankAccountTaskCard = ({
  task,
  data,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionVerifyBankAccountTaskDataFragment;
}) => {
  const { t } = useTranslation(`execution`);
  const [errorType, setErrorType] = useState<VerifyBankAccountErrorType | null>(
    null,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onOpenModal: onOpenSuccess, modals } = useModal();

  const methods = useForm<DepositFormValues>({
    resolver: yupResolver(
      validationSchema,
    ) as unknown as Resolver<DepositFormValues>,
  });

  const { bankAccount } = data;

  const [completeTask, { loading }] = useCompleteExecutionTaskMutation();

  const onSubmit = async (values: DepositFormValues) => {
    const { data } = await completeTask({
      variables: {
        taskId: task.id,
        input: {
          verifyBankAccount: {
            depositOneValue: Math.round(values.deposit1Value * 100),
            depositTwoValue: Math.round(values.deposit2Value * 100),
          },
        },
      },
    });

    if (data?.completeExecutionTask.errors) {
      const error = data?.completeExecutionTask.errors[0];

      // TODO:: check multiple incorrect amounts

      if (error.message === `incorrect deposit amount`) {
        setErrorType(`incorrect_amount`);
        onOpen();
      } else {
        setErrorType(`general`);
        onOpen();
      }
    } else {
      onOpenSuccess(modals.verifyBankAccountSuccess());
    }
  };

  const handleClose = () => {
    setErrorType(null);
    onClose();
  };

  if (!!task.completedAt && task.status === ExecutionTaskStatus.Completed)
    return null;

  return (
    <Flex direction="column" gap={{ base: 4, md: 6 }}>
      <Card variant="flat" bg="grey.15">
        <CardBody>
          <VStack spacing={4}>
            <VStack>
              <HStack>
                <Text textStyle="heading-md">
                  {t(`verify_your_bank_account`)}
                </Text>
                <Text textStyle="text-md">
                  {t(`bank_accounts.redacted`, {
                    ns: `postings`,
                  })}
                </Text>
                <Text textStyle="text-md">{bankAccount?.last4}</Text>
              </HStack>
              <Text textAlign="center" px={4} color="grey.700">
                {t(`verify_bank_account_description`)}
              </Text>
            </VStack>
            <Card variant="flat" w="full">
              <CardBody p={0} px={4} py={3}>
                <VStack spacing={4}>
                  <DepositPlaceholder />
                  <Divider />
                  <DepositPlaceholder />
                </VStack>
              </CardBody>
            </Card>
          </VStack>
        </CardBody>
      </Card>
      <VStack alignItems="start" spacing={4}>
        <Text textStyle="heading-md">{t(`enter_micro_deposits`)}</Text>
        <FormProvider {...methods}>
          <DepositsFormFields />
        </FormProvider>
        <Text textStyle="text-sm">{t(`micro_deposits_description`)}</Text>
      </VStack>
      <HStack w="full" justifyContent="flex-end" mt={2}>
        <Button
          variant="rounded-solid-grey"
          size={{ base: `md`, md: `xl` }}
          onClick={methods.handleSubmit(onSubmit)}
          colorScheme="primary"
          isLoading={loading}
        >
          {t(`verify_account`)}
        </Button>
      </HStack>
      {errorType && (
        <VerifyBankAccountErrorModal
          isOpen={isOpen}
          onClose={handleClose}
          type={errorType}
        />
      )}
    </Flex>
  );
};

export default VerifyBankAccountTaskCard;
