import { Control, FieldValues, Path, useController } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  InputProps,
} from "@chakra-ui/react";

import { MoneyInput } from "./MoneyInput";

interface FormMoneyInputProps<TFieldValues extends FieldValues>
  extends InputProps {
  readonly control: Control<TFieldValues>;
  readonly name: Path<TFieldValues>;
  readonly label?: string;
  readonly labelSrOnly?: FormLabelProps["srOnly"];
  readonly allowNegative?: boolean;
  readonly decimalScale?: number;
  readonly isAllowed?: (values: NumberFormatValues) => boolean;
}

const FormMoneyInput = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  labelSrOnly,
  ...inputProps
}: FormMoneyInputProps<TFieldValues>) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({ name, control });

  return (
    <FormControl isInvalid={invalid} isDisabled={inputProps?.isDisabled}>
      {label && <FormLabel srOnly={labelSrOnly}>{label}</FormLabel>}
      <MoneyInput {...inputProps} {...field} />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormMoneyInput;
