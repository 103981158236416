import { useMemo } from "react";

import { client, createClient } from "@/apollo-client";
import constants from "@/utils/constants";

import { getApiUrl } from "./useServerPreview";
import { useToken } from "./useToken";

const { isAuth0Enabled } = constants;

export type ApolloClientConfig = {
  apiUrl: string | null;
  sessionToken?: string;
};

export function createApolloClient({
  apiUrl,
  sessionToken,
}: ApolloClientConfig) {
  return createClient({
    apiUrl: apiUrl || ``,
    sessionToken,
  });
}

export function useApolloClient() {
  const sessionToken = useToken();
  return useMemo(() => {
    const apiUrl = getApiUrl();
    if (sessionToken && !isAuth0Enabled) {
      return createApolloClient({ apiUrl, sessionToken });
    }

    if (isAuth0Enabled) {
      return createApolloClient({ apiUrl });
    }

    return client;
  }, [sessionToken]);
}
