import { useTranslation } from "react-i18next";

import { PostingCard, Logo } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import { ListingPostingCardHeaderFragment } from "@/gql";
import { useIsDesktop } from "@/hooks";
import {
  getIsHiiveFund,
  shareTypeToString,
  transferMethodToString,
} from "@/utils";

type ListingPostingCardHeaderProps = {
  listing: ListingPostingCardHeaderFragment;
};

function ListingPostingCardHeaderHiiveFundIcon() {
  const { t } = useTranslation(`listings`);
  return (
    <PostingCard.TileIcon>
      <CustomTooltip
        placement="bottom"
        tooltipContent={t(`this_fund_is_organized_by_hiive`)}
        pos="absolute"
      >
        <Logo.DarkGreyIcon
          width={{ base: 3, md: 4 }}
          height={{ base: 4, md: 5 }}
        />
      </CustomTooltip>
    </PostingCard.TileIcon>
  );
}

export function ListingPostingCardHeader({
  listing: { displayId, transferMethod, shareTypeV2 },
}: ListingPostingCardHeaderProps) {
  const { t } = useTranslation(`listings`);
  const isDesktop = useIsDesktop();

  const isHiiveFund = getIsHiiveFund(transferMethod);

  const transferMethodText = transferMethodToString(transferMethod);
  const shareTypeText = shareTypeToString(shareTypeV2, true);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="listing">
            {t(`listing`)}
          </PostingCard.Label>
          {isDesktop && (
            <PostingCard.HeaderTitle>{displayId}</PostingCard.HeaderTitle>
          )}
        </>
      }
    >
      {isHiiveFund && <ListingPostingCardHeaderHiiveFundIcon />}
      <PostingCard.Tile title={t(`transfer_method`)}>
        {transferMethodText}
      </PostingCard.Tile>
      <PostingCard.Tile title={t(`share_type`)}>
        {shareTypeText}
      </PostingCard.Tile>
    </PostingCard.Header>
  );
}
