import { useTranslation } from "react-i18next";

import {
  ActivityGroup,
  ActivityGroupEmptyState,
  ActivitySection,
  InquiryEntityPostingCard,
  WithQuery,
  BidPostingCard,
  StandingBidYourActivityPostingCard,
  ListingYourActivityPostingCard,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyPageYourActivityBidFragment,
  CompanyPageYourActivityCompanyFragment,
  CompanyPageYourActivityDiscussionFragment,
  CompanyPageYourActivityListingFragment,
  CompanyPageYourActivityStandingBidFragment,
  useCompanyPageYourActivityCompanyByIdQuery,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { getIsInstitutionUser } from "@/utils";

import { YourActivitySkeleton } from "./YourActivitySkeleton";

const Listings = ({
  listings,
  company,
}: {
  readonly listings: readonly CompanyPageYourActivityListingFragment[];
  readonly company: CompanyPageYourActivityCompanyFragment;
}) => {
  const { t } = useTranslation(`company`);
  return (
    <ActivityGroup title={t`listings`}>
      {listings.map((listing) => (
        <ListingYourActivityPostingCard
          key={listing.id}
          listing={{ ...listing, company }}
        />
      ))}
    </ActivityGroup>
  );
};

const Bids = ({
  bids,
}: {
  readonly bids: readonly CompanyPageYourActivityBidFragment[];
}) => {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t`bids`}>
      {bids
        .filter((bid) => !bid?.transaction?.rofr)
        .map((bid) => {
          const { id } = bid;

          return <BidPostingCard key={id} bid={bid} />;
        })}
    </ActivityGroup>
  );
};

const StandingBids = ({
  standingBids,
  company,
}: {
  readonly standingBids: readonly CompanyPageYourActivityStandingBidFragment[];
  readonly company: CompanyPageYourActivityCompanyFragment;
}) => {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t`standing_bids`}>
      {standingBids.map((standingBid) => (
        <StandingBidYourActivityPostingCard
          key={standingBid.id}
          standingBid={{ ...standingBid, company }}
        />
      ))}
    </ActivityGroup>
  );
};

const Discussions = ({
  discussions,
}: {
  readonly discussions: readonly CompanyPageYourActivityDiscussionFragment[];
}) => {
  const { t } = useTranslation(`company`);

  return (
    <ActivityGroup title={t`inquiries`}>
      {discussions.map((discussion) => (
        <InquiryEntityPostingCard key={discussion.id} discussion={discussion} />
      ))}
    </ActivityGroup>
  );
};
export const YourActivityContent = withCurrentActor(
  ({
    company,
    actor,
  }: {
    readonly company: CompanyPageYourActivityCompanyFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation(`company`);

    const isDesktopView = useIsDesktop();
    const isInstitutionUser = getIsInstitutionUser(actor);

    const { institution: myInstitution } = actor;

    const activities = company.activity;

    const {
      myBids,
      myStandingBids,
      myListings,
      myActiveDiscussions,
      myInstitutionBids,
      myInstitutionStandingBids,
      myInstitutionListings,
    } = activities;

    const hasPersonalActivity =
      myBids.length > 0 ||
      myStandingBids.length > 0 ||
      myListings.length > 0 ||
      myActiveDiscussions.length > 0;

    const hasInstitutionalActivity =
      myInstitutionBids.length > 0 ||
      myInstitutionStandingBids.length > 0 ||
      myInstitutionListings.length > 0;

    const myInstitutionActivityTitle = hasPersonalActivity
      ? t(`other_institution_activity`, {
          legalName: myInstitution?.legalName,
        })
      : t(`institution_activity`, {
          legalName: myInstitution?.legalName,
        });

    const showEmptyState =
      !isDesktopView && !hasPersonalActivity && !hasInstitutionalActivity;

    return (
      <>
        {hasPersonalActivity && (
          <ActivitySection
            title={
              isInstitutionUser
                ? t(`your_activity_on_behalf_of_institution`, {
                    legalName: myInstitution?.legalName,
                  })
                : t`your_activity`
            }
          >
            <Listings listings={myListings} company={company} />
            <Bids bids={myBids} />
            <StandingBids standingBids={myStandingBids} company={company} />
            <Discussions discussions={myActiveDiscussions} />
          </ActivitySection>
        )}
        {hasInstitutionalActivity && (
          <ActivitySection title={myInstitutionActivityTitle}>
            <Listings listings={myInstitutionListings} company={company} />
            <Bids bids={myInstitutionBids} />
            <StandingBids
              standingBids={myInstitutionStandingBids}
              company={company}
            />
          </ActivitySection>
        )}
        {showEmptyState && (
          <ActivitySection title={t`your_activity`}>
            <ActivityGroupEmptyState
              w="full"
              message={t(`currently_no_activity`, { name: company.name })}
            />
          </ActivitySection>
        )}
      </>
    );
  },
);

export const YourActivity = ({ companyId }: { readonly companyId: string }) => {
  const query = useCompanyPageYourActivityCompanyByIdQuery({
    variables: { id: companyId },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<YourActivitySkeleton />}>
      {({ data: { companyById: company } }) => (
        <YourActivityContent company={company} />
      )}
    </WithQuery>
  );
};
