import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import {
  BidYourActivityDashboardPostingCardFragment,
  InvestorType,
} from "@/gql";
import { useCurrentActor } from "@/hooks";
import { makeUrl } from "@/utils";

import { BidPostingCardBody } from "./BidPostingCardBody";
import { BidPostingCardBrokerFooter } from "./BidPostingCardBrokerFooter";
import { BidPostingCardFooter } from "./BidPostingCardFooter";
import { BidYourActivityDashboardPostingCardHeader } from "./BidYourActivityDashboardPostingCardHeader";

type BidYourActivityDashboardPostingCardProps = {
  bid: BidYourActivityDashboardPostingCardFragment;
};

function BidPostingYourActivityDashboardTraderPostingCard({
  bid,
}: BidYourActivityDashboardPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidYourActivityDashboardPostingCardHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
      <BidPostingCardFooter bid={bid} />
    </PostingCard>
  );
}

function BidYourActivityDashboardBrokerPostingCard({
  bid,
}: BidYourActivityDashboardPostingCardProps) {
  const url = makeUrl(bid);

  return (
    <PostingCard href={url}>
      <BidYourActivityDashboardPostingCardHeader bid={bid} />
      <BidPostingCardBody bid={bid} />
      <BidPostingCardBrokerFooter bid={bid} />
    </PostingCard>
  );
}

export function BidYourActivityDashboardPostingCard({
  bid,
}: BidYourActivityDashboardPostingCardProps) {
  const { investorType } = useCurrentActor();

  return match(investorType)
    .with(InvestorType.Broker, () => (
      <BidYourActivityDashboardBrokerPostingCard bid={bid} />
    ))
    .otherwise(() => (
      <BidPostingYourActivityDashboardTraderPostingCard bid={bid} />
    ));
}
