import { Card, CardBody } from "@chakra-ui/react";

import { useTransactionDetails } from "@/hooks";
import useTransactionExecution from "@/hooks/useTransactionExecution";

import BuyerDetails from "./BuyerDetails";
import ListingDetailsCard from "./ListingDetailsCard";
import SellerDetails from "./SellerDetails";
import TransactionExecutionPageInfo from "./TransactionExecutionPageInfo";
import TransactionSummaryCard from "./TransactionSummaryCard";

const TransactionDetailsCard = () => {
  const transaction = useTransactionExecution();
  const { isBuyer } = useTransactionDetails();
  const hasEntities = transaction?.sellerEntity || transaction?.buyerEntity;

  return (
    <Card variant="no-border-radius-base">
      <CardBody p={6}>
        <TransactionExecutionPageInfo />
      </CardBody>
      {hasEntities && (
        <CardBody p={0} px={6} py={4}>
          {isBuyer(transaction!) ? <BuyerDetails /> : <SellerDetails />}
        </CardBody>
      )}
      <CardBody p={6} bgColor="grey.15">
        <TransactionSummaryCard transaction={transaction!} />
      </CardBody>
      <CardBody p={6}>
        <ListingDetailsCard />
      </CardBody>
    </Card>
  );
};

export default TransactionDetailsCard;
