import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Hide,
  HStack,
  ResponsiveValue,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useColors } from "@/hooks";

function useGetDropzoneStyles({
  isDragAccept,
  isDragReject,
  disabled,
}: {
  isDragAccept: boolean;
  isDragReject: boolean;
  disabled: boolean;
}) {
  const [sky900] = useColors([`skyBlue.900`]);
  switch (true) {
    case isDragReject:
      return {
        border: `1px dashed`,
        borderColor: `red.900`,
        background: `red.50`,
        transition: `box-shadow 0.3s ease, border-color 0.3s ease`,
      };

    case isDragAccept:
      return {
        border: `1px solid`,
        borderColor: `grey.900`,
        background: `grey.25`,
        boxShadow: `0px 0px 5px 0.5px ${sky900}`,
        transition: `box-shadow 0.3s ease, border-color 0.3s ease`,
      };
    case disabled:
      return {
        border: `none`,
        background: `grey.50`,
        pointerEvents: `none` as ResponsiveValue<"none" | "auto">,
        opacity: 0.5,
      };

    default:
      return {
        border: `1px dashed`,
        borderColor: `grey.400`,
        background: `grey.15`,
        transition: `box-shadow 0.3s ease, border-color 0.3s ease`,
      };
  }
}

enum FileTypes {
  JPEG = `image/jpeg`,
  PNG = `image/png`,
  HEIC = `image/heic`,
  HEIF = `image/heif`,
  PDF = `application/pdf`,
  MSWORD = `application/msword`,
}

interface DropzoneUploadProps {
  single?: boolean;
  disabled?: boolean;
  acceptedFileTypes?: Record<FileTypes, string[]>;
  onSelectFilesSuccess: (files: File[]) => void;
}

export function DropzoneUpload({
  onSelectFilesSuccess,
  single = false,
  disabled = false,
  acceptedFileTypes = {
    [FileTypes.JPEG]: [`.jpeg`],
    [FileTypes.PDF]: [`.pdf`],
    [FileTypes.MSWORD]: [`.doc`, `.docx`],
    [FileTypes.PNG]: [`.png`],
    [FileTypes.HEIC]: [`.heic`],
    [FileTypes.HEIF]: [`.heif`],
  },
}: DropzoneUploadProps) {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onSelectFilesSuccess(acceptedFiles);
    },
    [onSelectFilesSuccess],
  );

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptedFileTypes,
      maxFiles: single ? 1 : undefined,
      onDrop,
      disabled,
      noClick: true,
      noKeyboard: true,
    });

  const isDefault = !isDragAccept && !isDragReject;

  const dropzoneStyles = useGetDropzoneStyles({
    isDragAccept,
    isDragReject,
    disabled,
  });

  return (
    <Box
      {...dropzoneStyles}
      borderRadius="md"
      px={4}
      py={8}
      w="full"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <VStack minH="14" justifyContent="center">
        {isDefault && (
          <>
            <HStack>
              <Button
                onClick={open}
                size="xs"
                maxW="fit-content"
                variant="outline"
              >
                <Text>{t(single ? `choose_file` : `choose_files`)}</Text>
              </Button>
              <Hide below="sm">
                <Text textStyle="text-sm">{t(`or_drag_it_here`)}</Text>
              </Hide>
            </HStack>
            <Text textStyle="text-xs" color="grey.600">
              {t(`accepts`)}
              {Object.values(acceptedFileTypes).flat().join(`, `)}
            </Text>
          </>
        )}
        {isDragAccept && (
          <Text textStyle="heading-3xs">{t(`drop_files_to_upload`)}</Text>
        )}
        {isDragReject && (
          <Text textStyle="heading-3xs" color="red.900">
            {t(`file_type_is_not_valid`)}
          </Text>
        )}
      </VStack>
    </Box>
  );
}
