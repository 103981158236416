import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";

import {
  ButtonSkeleton,
  FullContentWrapper,
  Skeleton,
} from "@/components/common";

const TransactionExecutionPageSkeleton = () => (
  <FullContentWrapper px={{ base: 4, lg: 8 }}>
    <Box w="full" maxW="278">
      <SimpleGrid columns={12} gap={6}>
        <GridItem colSpan={{ base: 12, lg: 8 }}>
          <Flex direction="column" gap={4}>
            <Card>
              <CardHeader h="58px" />
              <CardBody position="relative" p={0} px={6} pb={6} pt="44px">
                <Skeleton
                  w="14"
                  h="14"
                  position="absolute"
                  top="0"
                  transform="auto"
                  translateY="-50%"
                />
                <HStack justifyContent="space-between" mb={4}>
                  <VStack spacing={1} alignItems="flex-start">
                    <Skeleton h="36px" w="178px" />
                    <Skeleton h="16px" w="121px" />
                  </VStack>
                  <VStack spacing={1} alignItems="flex-end">
                    <Skeleton h="22px" w="104px" />
                    <Skeleton h="16px" w="121px" />
                  </VStack>
                </HStack>
                <Divider mb={4} borderColor="grey.75" />
                <HStack gap={6} rowGap={2} flexWrap="wrap">
                  <Skeleton h="20px" w="162px" />
                  <Skeleton h="20px" w="162px" />
                  <Skeleton h="20px" w="162px" />
                </HStack>
              </CardBody>
              <CardFooter py={0} px={0}>
                <HStack py={3.5} spacing={0}>
                  <Skeleton h={5} w="64px" mx={6} count={4} />
                </HStack>
              </CardFooter>
            </Card>
            <Flex gap={2}>
              <Skeleton h="41px" w="67px" />
              <Skeleton h="41px" w="73px" />
              <Skeleton h="41px" w="72px" />
            </Flex>
            <Card>
              <CardHeader py={4}>
                <Skeleton h="24px" w="108px" />
              </CardHeader>
              <CardBody py={4}>
                <VStack gap={3} spacing={0}>
                  <Skeleton h="14px" w="full" count={3} />
                </VStack>
              </CardBody>
              <CardFooter
                as={Flex}
                direction="row"
                justifyContent="flex-end"
                gap={4}
                py={4}
              >
                <ButtonSkeleton />
                <ButtonSkeleton />
              </CardFooter>
            </Card>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 4 }}>
          <VStack spacing={4}>
            <Card w="full">
              <CardHeader
                py={4}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Skeleton h="24px" w="143px" />
                <Skeleton h="24px" w="128px" />
              </CardHeader>
              <CardBody p={0} px={6} py={4}>
                <VStack spacing={2} w="full">
                  {new Array(6).fill(null).map((_, index) => (
                    <HStack
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      spacing={3}
                      py="8.5px"
                      justifyContent="space-between"
                      w="full"
                    >
                      <HStack>
                        <Skeleton h={4} w={4} />
                        <Skeleton h={4} w="55px" />
                      </HStack>
                      <HStack>
                        {index === 0 && <Skeleton h={4} w="128px" />}
                        <Skeleton h={4} w={4} />
                      </HStack>
                    </HStack>
                  ))}
                </VStack>
              </CardBody>
            </Card>
            <Card w="full">
              <CardHeader py={4}>
                <Skeleton h="24px" w="152px" />
              </CardHeader>
              <CardBody>
                <VStack spacing={0} gap={4} w="full" alignItems="flex-start">
                  <Skeleton h="16px" w="full" />
                  <Skeleton h="16px" w="20%" />
                  <Skeleton h="16px" w="50%" />
                  <Skeleton h="16px" w="80%" />
                </VStack>
              </CardBody>
              <CardFooter>
                <VStack spacing={8} w="full" alignItems="flex-start">
                  <Skeleton h="16px" w="75%" />
                  <Skeleton h="16px" w="60%" />
                </VStack>
              </CardFooter>
            </Card>
          </VStack>
        </GridItem>
      </SimpleGrid>
    </Box>
  </FullContentWrapper>
);

export default TransactionExecutionPageSkeleton;
