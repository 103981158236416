import { CaretUp, CaretRight } from "@phosphor-icons/react";
import sum from "lodash/sum";
import { Trans, useTranslation } from "react-i18next";

import {
  Card,
  HStack,
  Text,
  VStack,
  Divider,
  CardBody,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link,
  Box,
} from "@chakra-ui/react";

import { CollapsibleInlineText, SharePrice } from "@/components/common";
import { CustomTooltip } from "@/components/form";
import {
  ExecutionConfirmTransactionModificationTaskDataCommission,
  ExecutionFeeDiscountApplicationFragment,
  ExecutionCommissionFragment,
  FeeDiscountType,
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  FeeDiscountApplicationState,
} from "@/gql";
import { formatCurrency, formatShares } from "@/utils";

type FeeDiscountApplicationType = Pick<
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  "state" | "feeDiscount" | "feeDiscountApplicationCommission" | "initial"
>;

type VirtualFeeDiscountApplicationType = {
  amount?: number;
  type: string;
  name: string;
};

type FeeDiscountApplicationItems =
  | readonly (FeeDiscountApplicationType | VirtualFeeDiscountApplicationType)[]
  | null;

function activeFeeDiscountApplications(
  feeDiscountApplications?: FeeDiscountApplicationItems,
  types: FeeDiscountType[] = [FeeDiscountType.OverrideCommission],
): readonly VirtualFeeDiscountApplicationType[] {
  if (!feeDiscountApplications?.length) {
    return [];
  }

  return (feeDiscountApplications as readonly FeeDiscountApplicationType[])
    ?.filter((item) => item.state === FeeDiscountApplicationState.Active)
    ?.filter((item) => types.includes(item.feeDiscount.type))
    .map(({ feeDiscount, feeDiscountApplicationCommission }) => ({
      amount: feeDiscountApplicationCommission?.amount || 0,
      name: feeDiscount.name,
      type: feeDiscount.type,
    }));
}

const FeesSection = ({
  commission,
  feeDiscountApplications,
}: {
  commission?:
    | ExecutionConfirmTransactionModificationTaskDataCommission
    | ExecutionCommissionFragment
    | null;
  feeDiscountApplications?: ExecutionFeeDiscountApplicationFragment[];
}) => {
  const { t } = useTranslation();

  if (!commission) {
    return null;
  }

  const { commissionAmount = 0, flatFeeAmount = 0, netFees = 0 } = commission;

  const formattedClosingFee = formatCurrency(flatFeeAmount, {
    fromCents: true,
  });
  const formattedCommissionAmount = formatCurrency(commissionAmount, {
    fromCents: true,
  });

  const mappedFeeDiscountApplications = activeFeeDiscountApplications(
    feeDiscountApplications,
    [FeeDiscountType.OverrideCommission],
  );

  const nonOverrideDiscountTotals = sum(
    activeFeeDiscountApplications(feeDiscountApplications, [
      FeeDiscountType.FlatFee,
      FeeDiscountType.PercentageFee,
    ]).map(({ amount }) => amount || 0),
  );

  const isOverride = mappedFeeDiscountApplications.length > 0;
  const totalBeforeOverride = commissionAmount + flatFeeAmount;
  const isOverrideHigherFee = isOverride && netFees > totalBeforeOverride;

  const feeItems = isOverrideHigherFee
    ? []
    : [
        {
          label: t(`base_fee`),
          tooltip: t(`hiive_base_fee_tooltip`, {
            flatFeeUS: formattedClosingFee,
          }),
          value: formattedClosingFee,
          lineThrough: isOverride,
        },
        {
          label: t(`hiive_commission`),
          tooltip: t(`hiive_commission_tooltip`),
          value: formattedCommissionAmount,
          lineThrough: isOverride,
        },
      ];

  const feeLines = [
    ...feeItems,
    ...mappedFeeDiscountApplications.map((discount) => ({
      label: discount.name,
      lineThrough: false,
      tooltip: ``,
      value: formatCurrency(netFees, {
        fromCents: true,
      }),
    })),
  ];
  const feesCount = feeItems.length + mappedFeeDiscountApplications.length;

  const netFeesWithoutNonOverrideDiscounts = formatCurrency(
    netFees + nonOverrideDiscountTotals,
    {
      fromCents: true,
    },
  );

  return (
    <Accordion allowToggle w="full">
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              as={HStack}
              w="full"
              justifyContent="space-between"
              p={0}
              _hover={{ bg: `grey.25`, cursor: `pointer` }}
              _focus={{ boxShadow: `none` }}
            >
              <VStack alignItems="start" gap={1}>
                <Text textStyle="heading-xs" color="grey.500">
                  {t(`total_hiive_fees`)}
                </Text>
                <Text textStyle="text-sm">
                  {feesCount} {feesCount === 1 ? t(`fee`) : t(`fees`)}
                </Text>
              </VStack>
              <HStack>
                <Text>-{netFeesWithoutNonOverrideDiscounts}</Text>
                {isExpanded ? (
                  <CaretUp width="16" height="16" />
                ) : (
                  <CaretRight width="16" height="16" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0} pt={4}>
              <Card variant="flat" bg="grey.50" border="none" w="full">
                <CardBody p={4} pr={6}>
                  <VStack spacing={4}>
                    {feeLines.map(({ label, value, lineThrough, tooltip }) => (
                      <HStack
                        key={label as string}
                        w="full"
                        justifyContent="space-between"
                      >
                        {tooltip ? (
                          <CustomTooltip
                            tooltipContent={tooltip}
                            placement="top"
                          >
                            <Text variant="tooltip" color="grey.700">
                              {label}
                            </Text>
                          </CustomTooltip>
                        ) : (
                          <Text color="grey.700">{label}</Text>
                        )}
                        <Box color="grey.700">
                          <Text as={lineThrough ? `del` : undefined}>
                            -{value}
                          </Text>
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

const DiscountSection = ({
  feeDiscountApplications,
}: {
  feeDiscountApplications?: ExecutionFeeDiscountApplicationFragment[];
}) => {
  const { t } = useTranslation(`execution`);

  const mappedFeeDiscountApplications = activeFeeDiscountApplications(
    feeDiscountApplications,
    [FeeDiscountType.FlatFee, FeeDiscountType.PercentageFee],
  );
  if (mappedFeeDiscountApplications.length === 0) {
    return null;
  }

  const totalDiscount = mappedFeeDiscountApplications.reduce(
    (acc, { amount }) => acc + (amount ?? 0),
    0,
  );

  const discountCount = mappedFeeDiscountApplications.length;

  const formattedTotalDiscount = formatCurrency(totalDiscount, {
    fromCents: true,
  });

  return (
    <Accordion allowToggle w="full">
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              as={HStack}
              w="full"
              justifyContent="space-between"
              p={0}
              _hover={{ bg: `grey.25`, cursor: `pointer` }}
              _focus={{ boxShadow: `none` }}
            >
              <VStack alignItems="start" gap={1}>
                <Text textStyle="heading-xs" color="grey.500">
                  {t(`total_discounts`)}
                </Text>
                <Text textStyle="text-sm">
                  {discountCount}
                  {` `}
                  {discountCount > 1 ? t(`discounts`) : t(`discount`)}
                </Text>
              </VStack>
              <HStack>
                <Text>+{formattedTotalDiscount}</Text>
                {isExpanded ? (
                  <CaretUp width="16" height="16" />
                ) : (
                  <CaretRight width="16" height="16" />
                )}
              </HStack>
            </AccordionButton>
            <AccordionPanel p={0} pt={4}>
              <Card variant="flat" bg="grey.50" border="none" w="full">
                <CardBody p={4} pr={6}>
                  <VStack spacing={4}>
                    {mappedFeeDiscountApplications.map((discount) => (
                      <HStack
                        key={discount.name}
                        w="full"
                        justifyContent="space-between"
                      >
                        <Text color="grey.700">{discount.name}</Text>
                        <Text color="grey.700">
                          +
                          {formatCurrency(discount.amount, { fromCents: true })}
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

function CollapsableDisclaimer() {
  const { t } = useTranslation(`execution`);
  return (
    <CollapsibleInlineText
      collapsedText={
        <Text
          as="span"
          textStyle="text-xs"
          lineHeight="inherit"
          color="grey.500"
        >
          {t(`proceeds_description_expanded`).slice(0, 130)}
        </Text>
      }
      expandedText={
        <>
          <Text
            as="span"
            textStyle="text-xs"
            lineHeight="inherit"
            color="grey.500"
            mb={1}
          >
            <Trans i18nKey="proceeds_description_expanded" ns="execution" />
          </Text>
          <Text
            as="span"
            textStyle="text-xs"
            lineHeight="inherit"
            color="grey.500"
          >
            <Trans
              i18nKey="proceeds_description_terms"
              ns="execution"
              components={{
                a: (
                  <Link
                    textDecoration="underline"
                    href="/terms-and-conditions"
                    target="_blank"
                  />
                ),
              }}
            />
          </Text>
        </>
      }
      label={{ collapsed: t(`read_more`), expanded: t(`see_less`) }}
    />
  );
}

export function BuySideTransactionBreakdown({
  numShares,
  pricePerShare,
}: {
  numShares: number;
  pricePerShare: number;
}) {
  const grossProceeds = pricePerShare * numShares;
  const { t } = useTranslation(`execution`);
  const formattedGrossProceeds = formatCurrency(grossProceeds, {
    fromCents: true,
  });
  return (
    <VStack alignItems="start" spacing={4} w="full">
      <HStack w="full" justifyContent="space-between" pr={6}>
        <VStack alignItems="start" gap={1}>
          <Text textStyle="heading-xs">{t(`gross_value`)}</Text>
          <Text textStyle="heading-2xs" textTransform="capitalize">
            <SharePrice numShares={numShares} pricePerShare={pricePerShare} />
          </Text>
        </VStack>
        <Text color="grey.700">{formattedGrossProceeds}</Text>
      </HStack>
    </VStack>
  );
}

function NetProceeds({
  numShares,
  netProceeds,
}: {
  readonly numShares: number;
  readonly netProceeds: number;
}) {
  const { t } = useTranslation(`execution`);

  const formattedNumShares = formatShares(numShares);

  const netPricePerShare = Math.trunc(netProceeds / numShares);

  const formattedNetPricePerShare = formatCurrency(netPricePerShare, {
    fromCents: true,
  });
  const formattedNetProceeds = formatCurrency(netProceeds, {
    fromCents: true,
  });

  return (
    <HStack w="full" alignItems="end" justifyContent="space-between" pr={6}>
      <VStack alignItems="start" gap={1}>
        <Text textStyle="heading-xs">{t(`net_proceeds`)}</Text>
        <Text textStyle="heading-2xs" textTransform="capitalize">
          {formattedNumShares} {t(`shares`)} @ {formattedNetPricePerShare}
        </Text>
      </VStack>
      <Text textStyle="text-md">{formattedNetProceeds}</Text>
    </HStack>
  );
}

export function SellSideTransactionBreakdown({
  newShares,
  newPricePerShare,
  numShares,
  pricePerShare,
  commission,
  feeDiscountApplications,
}: {
  newShares: number;
  newPricePerShare: number;
  numShares: number;
  pricePerShare: number;
  discount?: number;
  commission?:
    | ExecutionConfirmTransactionModificationTaskDataCommission
    | ExecutionCommissionFragment
    | null;
  feeDiscountApplications?: ExecutionFeeDiscountApplicationFragment[];
}) {
  const { t } = useTranslation(`execution`);
  const grossProceeds = newShares * newPricePerShare;
  const formattedGrossProceeds = formatCurrency(grossProceeds, {
    fromCents: true,
  });
  const netFees = commission?.netFees || 0;
  const netProceeds = numShares * pricePerShare - netFees;

  return (
    <VStack alignItems="start" spacing={4} w="full">
      <HStack w="full" justifyContent="space-between" pr={6}>
        <VStack alignItems="start" gap={1}>
          <Text textStyle="heading-xs" color="grey.500">
            {t(`gross_sale_amount`)}
          </Text>
          <Text textStyle="heading-2xs" textTransform="capitalize">
            <SharePrice numShares={numShares} pricePerShare={pricePerShare} />
          </Text>
        </VStack>
        <Text color="grey.700">{formattedGrossProceeds}</Text>
      </HStack>
      <FeesSection
        feeDiscountApplications={feeDiscountApplications}
        commission={commission}
      />
      <DiscountSection feeDiscountApplications={feeDiscountApplications} />
      <Divider borderColor="grey.200" />
      <HStack w="full" justifyContent="space-between">
        <NetProceeds numShares={numShares} netProceeds={netProceeds} />
      </HStack>
      <CollapsableDisclaimer />
    </VStack>
  );
}
