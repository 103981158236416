import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { Box, SimpleGrid } from "@chakra-ui/react";

import {
  FeeBreakdown,
  SensitiveText,
  SharePrice,
  TileHeader,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  BidFeeDiscountBreakdownFeeDiscountApplicationFragment,
  BidInfoCardBidTransactionHistoryFragment,
  User,
} from "@/gql";

interface FeeBreakdownProps {
  readonly feeDiscountApplications?:
    | readonly BidFeeDiscountBreakdownFeeDiscountApplicationFragment[]
    | null;
  readonly transactionHistory: BidInfoCardBidTransactionHistoryFragment;
  readonly buyerId: string;
  readonly buyerBrokerId?: string | null;
  readonly brokerCounterpartyId?: string | null;
  readonly actor: User;
}

const InitialTransactionFeeBreakdown = withCurrentActor(
  ({
    actor,
    brokerCounterpartyId: sellerBrokerId,
    buyerBrokerId,
    buyerId,
    feeDiscountApplications,
    transactionHistory,
  }: FeeBreakdownProps) => {
    const { t } = useTranslation();

    const { pricePerShare, numShares, sellerCommission } = transactionHistory;
    const grossProceeds = pricePerShare * numShares;

    const isBuyer = buyerId === actor.id;
    const isSellerBroker = sellerBrokerId === actor.id;
    const isBuyerBroker = buyerBrokerId === actor.id;

    if (isBuyer || isBuyerBroker) {
      return (
        <Box borderColor="grey.200" borderWidth={0.5} borderRadius="md">
          <SimpleGrid
            display="grid"
            gridTemplateColumns="1fr min-content"
            gap={2}
            alignItems="baseline"
            p={6}
          >
            <TileHeader>
              <SharePrice numShares={numShares} pricePerShare={pricePerShare} />
            </TileHeader>
          </SimpleGrid>
          <SimpleGrid
            bg="grey.25"
            display="grid"
            alignItems="baseline"
            borderBottomRadius="md"
            borderTopWidth={0.5}
            borderTopColor="grey.200"
            gridTemplateColumns="1fr min-content"
            px={6}
            py={4}
          >
            <TileHeader>Total</TileHeader>
            <SensitiveText whiteSpace="nowrap">
              {currency(grossProceeds, {
                fromCents: true,
              }).format()}
            </SensitiveText>
          </SimpleGrid>
        </Box>
      );
    }

    return (
      <FeeBreakdown
        numShares={numShares}
        pricePerShare={pricePerShare}
        feeDiscountApplications={feeDiscountApplications}
        feeDiscountApplicationState={null}
        initial
        flatFeeAmount={sellerCommission?.flatFeeAmount}
        commissionAmount={sellerCommission?.commissionAmount}
        feeDiscountAmount={sellerCommission?.feeDiscountAmount}
        netFees={sellerCommission?.netFees}
        {...(isSellerBroker && {
          combineHiiveFeesLabel: t(`hiive_co_broker_fee`),
          combineHiiveFeesTooltip: t(`hiive_co_broker_fee_tooltip`),
          netProceedsTitle: t(`broker_seller_proceeds`),
          netProceedsSubtitle: t(`gross_of_your_brokerage_fees`),
          netProceedsTooltip: t(`broker_seller_proceeds_tooltip`),
        })}
      />
    );
  },
);

export default InitialTransactionFeeBreakdown;
