/* eslint-disable consistent-return */
import { CaretRight } from "@phosphor-icons/react";
import { sentenceCase } from "change-case";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef, useLayoutEffect, Fragment } from "react";
import { match } from "ts-pattern";

import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";

import { WorkflowConditionStatusIcon } from "@/components/transactions";
import {
  TransactionExecutionPageWorkflowConditionFragment,
  ExecutionStepStatus,
  ExecutionMilestone,
} from "@/gql";
import * as datetime from "@/utils/datetime";

import MilestoneStatusIcon from "./MilestoneStatusIcon";
import { hasCondition } from "./utils";

const MilestoneRow = ({
  showLine = true,
  isActive = false,
  isCompleted = false,
  isBuySide = false,
  completedAt,
  milestone,
  copy,
  conditions,
}: {
  readonly milestone: string;
  readonly copy: string;
  readonly showLine?: boolean;
  readonly isActive?: boolean;
  readonly isCompleted?: boolean;
  readonly completedAt?: string;
  readonly isBuySide?: boolean;
  readonly conditions: TransactionExecutionPageWorkflowConditionFragment[];
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const ref = useRef<HTMLDivElement | null>(null);

  const lineRef = useRef<SVGSVGElement | null>(null);

  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const padding = 8;
  const gap = 8;
  const borderWidth = 1;

  useLayoutEffect(() => {
    if (!ref.current || !lineRef.current) {
      if (!resizeObserverRef.current) return;

      resizeObserverRef.current.disconnect();
      return;
    }

    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (!lineRef.current) return;

      const {
        contentRect: { height },
      } = entries[0];

      const h = height + 2 * padding + 2 * borderWidth + gap;

      lineRef.current.style.height = `${h}px`;
      if (!isCompleted) {
        lineRef.current.style.strokeDasharray = `${3 / h}`;
      }
    });

    resizeObserverRef.current.observe(ref.current);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [ref.current]);

  const headingColor = match({ isCompleted, isActive })
    .with(
      {
        isCompleted: true,
      },
      () => `grey.900`,
    )
    .with({ isActive: true }, () => `teal.900`)
    .otherwise(() => `grey.600`);

  const status = match({ isCompleted, isActive })
    .with({ isCompleted: true }, () => ExecutionStepStatus.Completed)
    .with({ isActive: true }, () => ExecutionStepStatus.InProgress)
    .otherwise(() => ExecutionStepStatus.Pending);

  const overrideStatus =
    milestone === ExecutionMilestone.Approved &&
    hasCondition(conditions, isBuySide);

  return (
    <Box
      px={6}
      py={2}
      w="full"
      borderColor={isActive ? `teal.50` : `white`}
      bg={isActive ? `teal.25` : `white`}
      borderBottomWidth={1}
      borderTopWidth={1}
      as={chakra.button}
      textAlign="left"
      onClick={() => setCollapsed(!collapsed)}
      _hover={{
        bg: isActive ? `teal.25` : `grey.25`,
      }}
      _active={{
        bg: isActive ? `teal.50` : `grey.50`,
      }}
      _focusVisible={{
        outline: `none`,
        boxShadow: `focus`,
      }}
      transition="background-color .1s ease-in-out"
    >
      <HStack spacing={0} gap={3} alignItems="flex-start">
        <Box h="full" position="relative">
          {showLine && (
            <chakra.svg
              ref={lineRef}
              position="absolute"
              top="50%"
              left="50%"
              transform="auto"
              translateX="-50%"
              w="2px"
              stroke={isCompleted ? `teal.1000` : `teal.700`}
              viewBox="0 0 2 1"
              preserveAspectRatio="none"
            >
              <chakra.line y1={0} y2={1} x1={1} x2={1} strokeWidth={1} />
            </chakra.svg>
          )}
          <Box w={4} h={4} position="relative" color="teal.1000">
            {overrideStatus ? (
              conditions.map(({ condition }) => (
                <Fragment key={condition}>
                  <WorkflowConditionStatusIcon
                    condition={condition}
                    isBuySide={isBuySide}
                  />
                </Fragment>
              ))
            ) : (
              <MilestoneStatusIcon status={status} />
            )}
          </Box>
        </Box>
        <VStack alignItems="flex-start" ref={ref} w="full">
          <HStack justifyContent="space-between" w="full">
            <Text
              textStyle="heading-3xs"
              lineHeight="16px"
              color={headingColor}
            >
              {sentenceCase(milestone)}
            </Text>
            <HStack>
              <Text textStyle="text-xs" lineHeight="16px">
                {completedAt &&
                  datetime.format(`Do MMM (h:mm A z)`, completedAt)}
              </Text>
              <CaretRight
                width="16px"
                height="16px"
                style={{
                  transform: collapsed
                    ? `rotate(0deg) scaleX(1)`
                    : `rotate(-90deg) scaleX(-1)`,
                  transition: `transform 0.2s ease-in-out`,
                }}
              />
            </HStack>
          </HStack>
          <AnimatePresence initial={false}>
            {!collapsed && (
              <motion.div
                initial={{ maxHeight: 0, opacity: 0 }}
                animate={{ maxHeight: `100px`, opacity: 1 }}
                exit={{ maxHeight: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: `easeInOut` }}
              >
                <Text textStyle="text-xs">{copy}</Text>
              </motion.div>
            )}
          </AnimatePresence>
        </VStack>
      </HStack>
    </Box>
  );
};

export default MilestoneRow;
