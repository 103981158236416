import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
  ExecutionConfirmTransactionModificationApprovalStatus,
  ExecutionConfirmTransactionModificationTaskDataAcknowledgment,
  TransactionExecutionPageTransactionFragment,
  UserWithInstitutionFragment,
} from "@/gql";

export enum TransactionModificationStatus {
  Approved = `APPROVED`,
  Rejected = `REJECTED`,
  Pending = `PENDING`,
}

export const useTransactionModificationStatusTitle = () => {
  const { t } = useTranslation(`execution`);

  return {
    [TransactionModificationStatus.Approved]: {
      title: t(`approved`),
      variant: `status-active`,
    },
    [TransactionModificationStatus.Rejected]: {
      title: t(`rejected`),
      variant: `status-rejected`,
    },
    [TransactionModificationStatus.Pending]: {
      title: t(`pending`),
      variant: `status-pending`,
    },
  };
};

export const getTransactionModificationStatus = (
  acknowledgments: ExecutionConfirmTransactionModificationTaskDataAcknowledgment[],
): TransactionModificationStatus => {
  if (
    acknowledgments.some(
      ({ status }) =>
        status ===
        ExecutionConfirmTransactionModificationApprovalStatus.Declined,
    )
  ) {
    return TransactionModificationStatus.Rejected;
  }

  if (acknowledgments.every(({ status }) => status === `APPROVED`)) {
    return TransactionModificationStatus.Approved;
  }

  return TransactionModificationStatus.Pending;
};

export const getTransactionIsBuySide = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) =>
  actor.id === transaction?.buyerId ||
  actor.id === transaction?.buyerBrokerId ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.buyerInstitutionId);

export const getTransactionIsSellSide = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) =>
  actor.id === transaction.sellerId ||
  actor.id === transaction.sellerBrokerId ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.sellerInstitutionId);

export const getTransactionCounterpartyName = ({
  actor,
  transaction,
}: {
  readonly actor: UserWithInstitutionFragment;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const isSellSide = getTransactionIsSellSide({ actor, transaction });
  const isBuySide = getTransactionIsBuySide({ actor, transaction });

  return match({ isSellSide, isBuySide })
    .with({ isSellSide: true }, () => {
      if (!!transaction.buyerBroker) {
        return transaction.buyerBroker.name;
      }

      if (!!transaction.buyerInstitution) {
        return transaction.buyerInstitution.legalName;
      }

      if (!!transaction.representedBuyer) {
        return `${transaction.representedBuyer.firstName} ${transaction.representedBuyer.lastName}`;
      }

      return transaction.buyer.name;
    })
    .with({ isBuySide: true }, () => {
      if (!!transaction.sellerBroker) {
        return transaction.sellerBroker.name;
      }

      if (!!transaction.sellerInstitution) {
        return transaction.sellerInstitution.legalName;
      }

      if (!!transaction.representedSeller) {
        return `${transaction.representedSeller.firstName} ${transaction.representedSeller.lastName}`;
      }

      return transaction.seller.name;
    })
    .otherwise(() => {
      throw new Error(`Unrecognized party`);
    });
};
