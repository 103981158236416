import { Show } from "@chakra-ui/react";

import { ChartAnnouncement, H50Announcement } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";

import { DashboardWelcomeGuides } from "./DashboardWelcomeGuides";
import { DesktopAccreditedDashboardPage } from "./DesktopAccreditedDashboardPage";
import { MobileAccreditedDashboardPage } from "./MobileAccreditedDashboardPage";

const AccreditedDashboardPage = () => (
  <>
    <DashboardWelcomeGuides />
    <ChartAnnouncement />
    <H50Announcement />
    <Show above="md" ssr={false}>
      <DesktopAccreditedDashboardPage />
    </Show>
    <Show below="md" ssr={false}>
      <MobileAccreditedDashboardPage />
    </Show>
  </>
);

export default withCurrentActor(AccreditedDashboardPage);
