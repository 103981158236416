import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormListBox } from "@/components/react-hook-form";
import { EntityType } from "@/gql";
import { useTranslatedEntityNames } from "@/hooks";

import { EntityFormValues } from "./EntityForm";

type EntityTypeSelectProps = {
  filterIndividualEntityType?: boolean;
  isInstitution: boolean;
};

const EntityTypeSelect = ({
  filterIndividualEntityType,
  isInstitution,
}: EntityTypeSelectProps) => {
  const { t } = useTranslation(`account`);
  const { control } = useFormContext<EntityFormValues>();

  const { getFormattedEntityName } = useTranslatedEntityNames();

  const items = isInstitution
    ? [
        EntityType.Corporation,
        EntityType.Partnership,
        EntityType.Llc,
        EntityType.Trust,
        EntityType.Other,
      ]
    : [
        ...(filterIndividualEntityType ? [] : [EntityType.Individual]),
        EntityType.Trust,
        EntityType.Corporation,
        EntityType.Llc,
        EntityType.Partnership,
        EntityType.Other,
      ];

  return (
    <FormListBox<EntityType, EntityFormValues>
      control={control}
      name="type"
      items={items}
      itemToString={(item) => getFormattedEntityName(item)}
      getItemKey={(item) => item}
      label={t(`entities.entity_type`)}
      placeholder={t(`entities.select_entity_type`)}
    />
  );
};

export default EntityTypeSelect;
