import { HStack, VStack, Image, Text } from "@chakra-ui/react";

import { PostingCardCompanyTileFragment } from "@/gql";

type PostingCardCompanyTileProps = {
  company: PostingCardCompanyTileFragment;
};

export function PostingCardCompanyTile({
  company: { logoUrl, name },
}: PostingCardCompanyTileProps) {
  return (
    <HStack>
      <VStack h={6} minW={6}>
        {logoUrl && <Image h="full" src={logoUrl} alt={name} />}
      </VStack>
      <Text textStyle="heading-md">{name}</Text>
    </HStack>
  );
}
