import { CaretDown, CaretRight } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import {
  CardBody,
  Flex,
  HStack,
  Text,
  useDisclosure,
  chakra,
  Card,
} from "@chakra-ui/react";

import { Tasks } from "@/components/transactions";
import {
  TransactionExecutionPageWorkflowStepFragment,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";
import { toFormattedDate } from "@/utils/datetime";

const CompletedStep = ({
  step,
  isBuySide,
  transaction,
}: {
  readonly step: TransactionExecutionPageWorkflowStepFragment;
  readonly isBuySide: boolean;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { t } = useTranslation(`execution`);

  const { onToggle, isOpen } = useDisclosure();

  const hasTasks = step.tasks.length > 0;

  return (
    <Card
      overflow="hidden"
      w="full"
      variant="no-border-radius-base"
      boxShadow="none"
    >
      <CardBody p={0}>
        <HStack
          spacing={4}
          px={{ base: 4, md: 6 }}
          py={{ base: 2, md: 4 }}
          w="full"
          justifyContent="space-between"
          transition="background-color .1s ease-in-out"
          {...(hasTasks && {
            _hover: {
              bg: `grey.15`,
            },
            _active: {
              bg: `grey.25`,
            },
            onClick: onToggle,
            as: chakra.button,
          })}
        >
          <HStack
            justifyContent="space-between"
            flexWrap="wrap"
            w="full"
            pr={!hasTasks ? 8 : 0}
          >
            <Text textStyle="heading-md">{step.name}</Text>
            <Text textAlign="start" textStyle="text-xs">
              {t(`completed_date`, {
                completedAt: toFormattedDate(step.completedAt),
              })}
            </Text>
          </HStack>
          {hasTasks && (isOpen ? <CaretDown /> : <CaretRight />)}
        </HStack>
        {isOpen && (
          <Flex
            direction="column"
            gap={4}
            py={{ base: 4, md: 6 }}
            px={{ base: 4, md: 6 }}
            borderTop="1px solid"
            borderColor="grey.100"
          >
            <Tasks
              tasks={step.tasks}
              isBuySide={isBuySide}
              transaction={transaction}
            />
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};

export default CompletedStep;
