import { cardAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const Card = defineMultiStyleConfig({
  baseStyle: {
    container: {
      bg: `white`,
      borderWidth: `0.5px`,
      borderColor: `grey.200`,
      borderRadius: `md`,
      boxShadow: `card`,
    },
    body: {
      p: {
        base: 4,
        md: 6,
      },
      borderBottomColor: `grey.200`,
      borderBottomWidth: `0.5px`,
      _last: {
        border: `none`,
      },
    },
    header: {
      borderBottomColor: `grey.200`,
      borderBottomWidth: `0.5px`,
      borderTopRadius: `md`,
      px: {
        base: 4,
        md: 6,
      },
      py: {
        base: 5,
        md: 7,
      },
    },
    footer: {
      px: {
        base: 4,
        md: 6,
      },
      py: {
        base: 5,
        md: 7,
      },
      borderBottomRadius: `md`,
    },
  },
  variants: {
    "no-border-radius-base": {
      container: {
        borderRadius: {
          base: `0`,
          md: `md`,
        },
      },
      footer: {
        borderBottomRadius: {
          base: `0`,
          md: `md`,
        },
      },
    },
    "darkened-footer": {
      footer: {
        bg: `grey.25`,
      },
    },
    teal: {
      container: {
        bg: `teal.50`,
        color: `teal.900`,
        borderColor: `teal.900`,
      },
    },

    "no-border": {
      container: {
        borderWidth: `0px`,
      },
    },
    flat: {
      container: {
        boxShadow: `none`,
      },
    },
    activity: {
      container: {
        w: `full`,
        transition: `.1s background-color ease-in-out, .1s box-shadow ease-in-out`,
        _hover: {
          shadow: `lg`,
        },
        _focus: {
          outline: `none`,
        },
        _focusVisible: {
          outline: `none`,
          boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
        },
        _active: {
          shadow: `lg`,
          bg: `grey.25`,
        },
      },
      header: {
        py: 4,
        px: 4,
        w: `full`,
      },
      body: {
        p: 4,
        w: `full`,
      },
      footer: {
        columnGap: 3,
        rowGap: 1,
        justifyContent: `space-between`,
        flexWrap: `wrap`,
        py: 2,
        px: 4,
        w: `full`,
      },
    },
    posting: {
      container: {
        w: `full`,
        as: `button`,
        display: `flex`,
        textAlign: `left`,
        transition: `.1s background-color ease-in-out, .1s box-shadow ease-in-out`,
        _hover: {
          shadow: `lg`,
        },
        _focus: {
          outline: `none`,
        },
        _focusVisible: {
          outline: `none`,
          boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
        },
        _active: {
          shadow: `lg`,
          bg: `grey.25`,
        },
      },
      header: {
        py: 2,
        px: 3,
        w: `full`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        gap: 2,
      },
      body: {
        p: 3,
        w: `full`,
        display: `flex`,
        gap: 2,
        flexDirection: `column`,
        justify: `space-between`,
      },
      footer: {
        display: `flex`,
        direction: `row`,
        columnGap: 3,
        rowGap: 1,
        alignItems: `center`,
        flexWrap: `wrap`,
        py: 3,
        px: 3,
        w: `full`,
        align: `center`,
      },
    },
    "posting-latest": {
      container: {
        w: `full`,
        boxShadow: `none`,
        as: `button`,
        display: `flex`,
        textAlign: `left`,
        transition: `.1s background-color ease-in-out, .1s box-shadow ease-in-out`,
        _hover: {
          shadow: `lg`,
        },
        _focus: {
          outline: `none`,
        },
        _focusVisible: {
          outline: `none`,
          boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
        },
        _active: {
          shadow: `lg`,
          bg: `grey.25`,
        },
      },
      header: {
        py: 2,
        px: 3,
        w: `full`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        gap: 2,
      },
      body: {
        p: 3,
        w: `full`,
        display: `flex`,
        gap: 2,
        flexDirection: `column`,
        justify: `space-between`,
      },
      footer: {
        display: `flex`,
        direction: `row`,
        columnGap: 3,
        rowGap: 1,
        alignItems: `center`,
        flexWrap: `wrap`,
        py: 3,
        px: 3,
        w: `full`,
        align: `center`,
      },
    },
    "activity-subcard": {
      container: {
        boxShadow: `none`,
        transition: `.1s background-color ease-in-out, .1s box-shadow ease-in-out`,
        _hover: {
          shadow: `lg`,
        },
        _focus: {
          outline: `none`,
        },
        _focusVisible: {
          outline: `none`,
          boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
        },
        _active: {
          shadow: `lg`,
          bg: `grey.25`,
        },
      },
      header: {
        py: 4,
        px: 4,
      },
      body: {
        p: 4,
      },
    },
    "notification-subcard": {
      container: {
        bg: `grey.25`,
        boxShadow: `none`,
        transition: `.1s background-color ease-in-out, .1s box-shadow ease-in-out`,
        _hover: {
          shadow: `lg`,
        },
        _focus: {
          outline: `none`,
        },
        _focusVisible: {
          outline: `none`,
          boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
        },
        _active: {
          shadow: `lg`,
          bg: `grey.50`,
        },
      },
      header: {
        py: 4,
        px: 4,
      },
      body: {
        p: 4,
      },
    },
    subcard: {
      container: {
        boxShadow: `none`,
        transition: `.1s background-color ease-in-out, .1s box-shadow ease-in-out`,
        _hover: {
          shadow: `lg`,
        },
        _focus: {
          outline: `none`,
        },
        _focusVisible: {
          outline: `none`,
          boxShadow: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
        },
        _active: {
          shadow: `lg`,
          bg: `grey.25`,
        },
      },
      body: {
        p: 2.5,
      },
      footer: {
        px: 2.5,
        py: 2.5,
      },
    },
  },
});
