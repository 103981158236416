import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { InvestorType, ListingYourActivityPostingCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";

import { ListingPostingCardBody } from "./ListingPostingCardBody";
import { ListingPostingCardBrokerBody } from "./ListingPostingCardBrokerBody";
import { ListingPostingCardHeader } from "./ListingPostingCardHeader";
import { ListingYourActivityPostingCardBrokerFooter } from "./ListingYourActivityPostingCardBrokerFooter";
import { ListingYourActivityPostingCardFooter } from "./ListingYourActivityPostingCardFooter";

type ListingYourActivityPostingCardProps = {
  listing: ListingYourActivityPostingCardFragment;
};

function ListingYourActivityBrokerPostingCard({
  listing,
}: ListingYourActivityPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url}>
      <ListingPostingCardHeader listing={listing} />
      <ListingPostingCardBrokerBody listing={listing} />
      <ListingYourActivityPostingCardBrokerFooter listing={listing} />
    </PostingCard>
  );
}

function ListingYourActivityTraderPostingCard({
  listing,
}: ListingYourActivityPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url}>
      <ListingPostingCardHeader listing={listing} />
      <ListingPostingCardBody listing={listing} />
      <ListingYourActivityPostingCardFooter listing={listing} />
    </PostingCard>
  );
}

export function ListingYourActivityPostingCard({
  listing,
}: ListingYourActivityPostingCardProps) {
  const { investorType } = useCurrentActor();
  return match(investorType)
    .with(InvestorType.Broker, () => (
      <ListingYourActivityBrokerPostingCard listing={listing} />
    ))
    .otherwise(() => (
      <ListingYourActivityTraderPostingCard listing={listing} />
    ));
}
