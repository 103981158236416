import { datadogRum } from "@datadog/browser-rum";
import { useOrganization } from "@frigade/react";
import { useEffect } from "react";

import { useToken } from "@/hooks";

import { AuthenticatedLayoutHookProps } from "./types";

// functionality sets the institution for frigade modals and sets datadog user for logging
export const useIdentifyUser = ({ actor }: AuthenticatedLayoutHookProps) => {
  const token = useToken();
  const { setOrganizationIdWithProperties } = useOrganization();

  useEffect(() => {
    if (actor?.institution) {
      setOrganizationIdWithProperties(actor.institution.id, {
        name: actor.institution.legalName,
        membershipAgreementSigned: actor.institution.membershipAgreementSigned,
      });
    }
  }, [
    actor?.institution?.membershipAgreementSigned,
    actor?.institution?.legalName,
  ]);

  useEffect(() => {
    if (actor?.id != null) {
      datadogRum.setUser({
        id: actor?.id,
        institution_id: actor?.institutionId,
        investor_type: actor?.investorType,
        is_hiive_user: actor?.isHiiveUser === true,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [token, actor]);
};
