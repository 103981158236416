import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader, Text, VStack } from "@chakra-ui/react";

import {
  BuyerDetails,
  SellerDetails,
  TransactionSummaryCard,
  TransactionDocuments,
  TransactionExecutionPageInfo,
} from "@/components/transactions";
import { TransactionExecutionPageTransactionFragment } from "@/gql";
import { useTransactionDetails } from "@/hooks";

import { TransactionExecutionPageSummaryCardBody } from "./TransactionExecutionPageSummaryCardBody";

const TransactionExecutionPageCompletedCard = ({
  transaction,
}: {
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { isBuyer } = useTransactionDetails();

  const { t } = useTranslation(`execution`);
  const hasEntities = transaction?.buyerEntity || transaction?.sellerEntity;

  return (
    <Card variant="no-border-radius-base" borderBottomRadius="md">
      <CardHeader bg="teal.50" borderBottomColor="teal.100" />
      <TransactionExecutionPageSummaryCardBody transaction={transaction} />
      <CardBody p={6}>
        <TransactionExecutionPageInfo />
      </CardBody>
      {hasEntities && (
        <CardBody p={0} px={6} py={4}>
          {isBuyer(transaction!) ? <BuyerDetails /> : <SellerDetails />}
        </CardBody>
      )}
      <CardBody p={6} bgColor="grey.15">
        <TransactionSummaryCard transaction={transaction!} />
      </CardBody>
      <CardBody p={6}>
        <VStack alignItems="flex-start" spacing={4}>
          <Text textStyle="heading-md">{t(`documents`)}</Text>
          <TransactionDocuments transaction={transaction!} />
        </VStack>
      </CardBody>
    </Card>
  );
};

export default TransactionExecutionPageCompletedCard;
