import { ShieldCheck } from "@phosphor-icons/react";
import { Trans } from "react-i18next";

import { Text, HStack, Center } from "@chakra-ui/react";

import MTPrivacyPolicyLink from "./MTPrivacyPolicyLink";

const MTDisclaimer = () => (
  <HStack spacing={3}>
    <Center>
      <ShieldCheck size={24} />
    </Center>
    <Text textStyle="text-xs" mt={2} color="grey.700">
      <Trans
        i18nKey="bank_country_disclaimer"
        components={{ a: <MTPrivacyPolicyLink /> }}
        ns="execution"
      />
    </Text>
  </HStack>
);

export default MTDisclaimer;
