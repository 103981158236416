import { Warning } from "@phosphor-icons/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import { UserWithInstitutionFragment, ValidTransferMethodV2 } from "@/gql";
import { useColors } from "@/hooks";
import useTransactionExecution from "@/hooks/useTransactionExecution";

import StepTabs from "./StepTabs";
import { WorkflowConditionCard } from "./WorkflowConditionCard";
import { getTransactionIsBuySide } from "./utils";

const TransactionTasksCard = withCurrentActor(
  ({ actor }: { readonly actor: UserWithInstitutionFragment }) => {
    const { t } = useTranslation(`execution`);
    const [yellow600] = useColors([`yellow.600`]);

    const transaction = useTransactionExecution()!;
    const workflow = transaction?.workflow;

    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    const isInReview =
      transaction.transferMethod === ValidTransferMethodV2.Unknown && !workflow;

    const isWorkflowActionable =
      !isInReview &&
      !transaction.issuerApprovalDeclinedAt &&
      !transaction.cancelledAt &&
      (!transaction.rofrAt || !isBuySide);

    return (
      <VStack spacing={4}>
        {isInReview && (
          <Card borderColor="yellow.600" w="full">
            <CardHeader bg="yellow.100" borderBottom={0} py={4}>
              <HStack>
                <Warning
                  width="24"
                  height="24"
                  color={yellow600}
                  weight="fill"
                />
                <Text textStyle="heading-md">{t(`in_review_title`)}</Text>
              </HStack>
            </CardHeader>
            <CardBody>{t(`in_review_description`)}</CardBody>
          </Card>
        )}
        {workflow?.conditions.map(({ condition }) => (
          <Fragment key={condition}>
            <WorkflowConditionCard
              condition={condition}
              isBuySide={isBuySide}
              variant="full"
            />
          </Fragment>
        ))}
        {isWorkflowActionable && (
          <StepTabs
            workflow={workflow}
            isBuySide={isBuySide}
            transaction={transaction}
          />
        )}
      </VStack>
    );
  },
);

export default TransactionTasksCard;
