import { useTranslation } from "react-i18next";

import { SensitiveText } from "@/components/common";
import { StandingBidPostingCardBodyFragment } from "@/gql";
import { formatShares } from "@/utils";

type StandingBidPostingCardBodyAcceptedSharesProps = {
  standingBid: StandingBidPostingCardBodyFragment;
};

export function StandingBidPostingCardBodyAcceptedShares({
  standingBid: { numSharesAccepted },
}: StandingBidPostingCardBodyAcceptedSharesProps) {
  const { t } = useTranslation(`standingBids`);

  if (numSharesAccepted < 1) {
    return null;
  }

  const numSharesAcceptedFormatted = formatShares(numSharesAccepted);

  return (
    <SensitiveText textStyle="text-xs" color="grey.700">
      {t(`standing_bid_shares_purchased`, {
        numSharesAccepted: numSharesAcceptedFormatted,
      })}
    </SensitiveText>
  );
}
