import { useTranslation } from "react-i18next";

import {
  Flex,
  HStack,
  VStack,
  OtherProps,
  Stack,
  Text,
  Box,
} from "@chakra-ui/react";

import { LongHyphen, SensitiveText } from "@/components/common";
import { AcceptedBidSharePrices } from "@/components/companies";
import { ActivityCardListingNumShareAndPriceDetailsFragment } from "@/gql";
import {
  formatPricePerShare,
  formatShares,
  lot,
  getIsPartiallySoldListing,
  getIsConditionallySoldListing,
  getListingNumSharesAvailable,
  getListingNumSharesOriginal,
  calculateListingNumSharesSoldConditionally,
} from "@/utils";

function ActivityCardListingNumShareAndPriceDetailsPerShareText() {
  const { t } = useTranslation();
  return (
    <Text as="span" fontWeight={400}>
      {t`per_share`}
    </Text>
  );
}

const ActivityCardListingNumShareAndPriceDetails = ({
  listing,
  textStyle = `activity-card-heading`,
}: {
  readonly listing: ActivityCardListingNumShareAndPriceDetailsFragment;
  readonly textStyle?: OtherProps["textStyle"];
  readonly noPricePerShareText?: string;
}) => {
  const { listingPricePerShare: pricePerShare, acceptedBidPrices } = listing;

  const numSharesOriginal = getListingNumSharesOriginal(listing);
  const numSharesAvailable = getListingNumSharesAvailable(listing);
  const numSharesSoldConditionally =
    calculateListingNumSharesSoldConditionally(listing);

  const hasPricePerShare = !!pricePerShare;

  if (getIsPartiallySoldListing(listing)) {
    return (
      <VStack align="flex-start" spacing={1}>
        <Stack
          alignItems={{
            base: `flex-end`,
            sm: `flex-end`,
          }}
          flexDirection={{
            base: `row`,
            sm: `row`,
          }}
          gap={{ base: 2, sm: 6 }}
          textStyle={textStyle}
        >
          <Flex wrap="wrap" columnGap={1.5}>
            <SensitiveText as="del" color="grey.200">
              {formatShares(numSharesOriginal)}
            </SensitiveText>
            <SensitiveText>{formatShares(numSharesAvailable)}</SensitiveText>
            {hasPricePerShare ? (
              <HStack spacing={6}>
                <SensitiveText>
                  @ {formatPricePerShare(pricePerShare)}
                  <ActivityCardListingNumShareAndPriceDetailsPerShareText />
                </SensitiveText>
              </HStack>
            ) : (
              <HStack>
                <SensitiveText>@</SensitiveText>
              </HStack>
            )}

            <Box>
              {hasPricePerShare ? (
                <SensitiveText>
                  {lot(numSharesAvailable, pricePerShare)}
                </SensitiveText>
              ) : (
                <SensitiveText>
                  <LongHyphen />
                </SensitiveText>
              )}
            </Box>
          </Flex>
        </Stack>

        <AcceptedBidSharePrices
          acceptedBidPrices={acceptedBidPrices}
          numSharesSold={numSharesSoldConditionally}
        />
      </VStack>
    );
  }

  if (getIsConditionallySoldListing(listing)) {
    return (
      <VStack align="flex-start" spacing={1}>
        <Stack
          alignItems={{
            base: `flex-end`,
            sm: `flex-end`,
          }}
          flexDirection={{
            base: `row`,
            sm: `row`,
          }}
          gap={{ base: 2, sm: 6 }}
          as="del"
          textStyle={textStyle}
          color="grey.200"
        >
          <Flex wrap="wrap" columnGap={1.5}>
            <SensitiveText>{formatShares(numSharesOriginal)}</SensitiveText>
            {hasPricePerShare ? (
              <HStack spacing={6}>
                <SensitiveText>
                  @ {formatPricePerShare(pricePerShare)}
                  <ActivityCardListingNumShareAndPriceDetailsPerShareText />
                </SensitiveText>
              </HStack>
            ) : (
              <HStack>
                <SensitiveText>@</SensitiveText>
              </HStack>
            )}

            <Box>
              {hasPricePerShare ? (
                <SensitiveText>
                  {lot(numSharesOriginal, pricePerShare)}
                </SensitiveText>
              ) : (
                <SensitiveText>
                  <LongHyphen />
                </SensitiveText>
              )}
            </Box>
          </Flex>
        </Stack>
        <AcceptedBidSharePrices
          acceptedBidPrices={acceptedBidPrices}
          numSharesSold={numSharesSoldConditionally}
        />
      </VStack>
    );
  }

  return (
    <Stack
      alignItems={{
        base: `flex-end`,
        sm: `flex-end`,
      }}
      flexDirection={{
        base: `row`,
        sm: `row`,
      }}
      gap={{ base: 2, sm: 6 }}
      textStyle={textStyle}
    >
      <Flex wrap="wrap" columnGap={1.5}>
        <SensitiveText>{formatShares(numSharesOriginal)}</SensitiveText>
        {hasPricePerShare ? (
          <HStack spacing={6}>
            <SensitiveText>
              @ {formatPricePerShare(pricePerShare)}
              <ActivityCardListingNumShareAndPriceDetailsPerShareText />
            </SensitiveText>
          </HStack>
        ) : (
          <HStack>
            <SensitiveText>@</SensitiveText>
          </HStack>
        )}

        <Box>
          {hasPricePerShare ? (
            <SensitiveText>
              {lot(numSharesOriginal, pricePerShare)}
            </SensitiveText>
          ) : (
            <SensitiveText>
              <LongHyphen />
            </SensitiveText>
          )}
        </Box>
      </Flex>
    </Stack>
  );
};

export default ActivityCardListingNumShareAndPriceDetails;
