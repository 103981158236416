import { Box, HStack } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import {
  ConditionallySoldOutBadge,
  OpenBidsBadge,
  PartiallySoldBadge,
  calculateTotalOpenBids,
} from "@/components/companies";
import { ListingPostingCardFooterFragment, ListingState } from "@/gql";

type ListingPostingCardFooterProps = {
  listing: ListingPostingCardFooterFragment;
};

export function ListingPostingCardFooter({
  listing: {
    acceptedBidPrices: { length: acceptedBidPricesLength },
    transferMethod,
    numActiveBids,
    numCounteredBids,
    state,
    bids,
  },
}: ListingPostingCardFooterProps) {
  const totalOpenBids = calculateTotalOpenBids(numActiveBids, numCounteredBids);

  const isInReview = state === ListingState.InReview;
  const isConditionallySold = state === ListingState.ConditionallySold;
  const isPartiallySold =
    state === ListingState.Open && acceptedBidPricesLength >= 1;

  const hasOpenBids = totalOpenBids >= 1;

  const showFooter =
    isInReview || isConditionallySold || isPartiallySold || hasOpenBids;

  if (!showFooter) {
    return null;
  }

  return (
    <PostingCard.Footer>
      <Box flexGrow={1} />

      <HStack wrap="wrap" align="flex-end" spacing={{ base: 2, md: 3 }}>
        {isConditionallySold && (
          <ConditionallySoldOutBadge transferMethod={transferMethod} />
        )}
        {isPartiallySold && (
          <PartiallySoldBadge transferMethod={transferMethod} />
        )}
        {hasOpenBids && (
          <OpenBidsBadge
            totalOpenBids={totalOpenBids}
            bids={bids}
            transferMethod={transferMethod}
          />
        )}
      </HStack>
    </PostingCard.Footer>
  );
}
