import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Stack, StackProps, Text } from "@chakra-ui/react";

import {
  ComplianceReminder,
  CTACard,
  MailtoLink,
  WithQuery,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  CompanyActionsCompanyFragment,
  InvestorType,
  ListingPermission,
  ListingState,
  useCompanyPageActionsCompanyByIdQuery,
  UserPermissionV2,
  UserWithInstitutionFragment,
} from "@/gql";
import { iHaveEntityPermission, useIHavePermission, useModal } from "@/hooks";
import { TradeIntentType } from "@/types";
import {
  getAvailableCompanyActions,
  getAvailableUserPermissions,
  getIsBroker,
  getIsInstitutionUser,
  getIsInstitutionViewer,
  getSecuritySpecialistContact,
  hasInvestorType,
  normalizeTruncatedListings,
} from "@/utils";

import { CompanyActionsCTACardGrid } from "./CompanyActionsCTACardGrid";
import CompanyActionsSkeleton from "./CompanyActionsSkeleton";
import { useCompanyActionsCTACardDirection } from "./util";

const ActionNotPermittedCard = ({
  children,
}: {
  readonly children: ReactNode;
}) => (
  <Card w="full" maxH={32}>
    <CardBody h="full" p={6}>
      <Stack gap={3}>{children}</Stack>
    </CardBody>
  </Card>
);

const BuyerNeedsCompanyApprovalCard = ({
  direction,
  company,
}: {
  readonly direction: StackProps["direction"];
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { t } = useTranslation();

  const securitySpecialistEmail = getSecuritySpecialistContact(company);

  return (
    <CTACard
      direction={direction}
      heading={t(`make_bid`)}
      description={
        <Trans
          i18nKey="must_be_approved_by_company"
          t={t}
          components={[
            <MailtoLink
              key="contact_for_buyer_approval"
              fontWeight={700}
              email={securitySpecialistEmail}
              subject={`Buy Approval on ${company.name}`}
            />,
          ]}
          values={{ companyName: company.name }}
        />
      }
      action={{ disabled: true }}
    />
  );
};

const ListForSaleCTACard = ({
  direction,
  company,
  actor,
}: {
  readonly direction: StackProps["direction"];
  readonly company: CompanyActionsCompanyFragment;
  readonly actor: UserWithInstitutionFragment;
}) => {
  const { t } = useTranslation(`listings`);
  const { modals, onOpenModal } = useModal();

  const { canCreateListing } = getAvailableUserPermissions(actor);
  const { canCreateListing: canCreateListingInCompany } =
    getAvailableCompanyActions(company);

  const handleClick = () => {
    onOpenModal(modals.createListingOnCompany(company))();
  };

  if (getIsBroker(actor)) {
    return (
      <CTACard
        direction={direction}
        heading={t(`submit_a_listing`)}
        description={t(`submit_listing_cta`)}
        action={{
          ctaText: t(`submit_a_listing`),
          onClick: onOpenModal(modals.brokerSubmitListingOnCompany(company)),
        }}
      />
    );
  }

  if (canCreateListing && !canCreateListingInCompany) {
    const securitySpecialistEmail =
      actor.investorType === InvestorType.UnaccreditedSeller
        ? company.securitySpecialist?.marketAnalyst?.email
        : company.securitySpecialist?.user.email;
    return (
      <ActionNotPermittedCard>
        <Text textStyle="heading-sm" textTransform="uppercase">
          {t`list_for_sale`}
        </Text>
        <Text>
          <Trans
            ns="listings"
            i18nKey="not_tradeable_sell_side"
            components={{
              mailto: (
                <MailtoLink
                  key="contact"
                  textDecoration="underline"
                  email={securitySpecialistEmail || ``}
                />
              ),
            }}
            values={{ email: securitySpecialistEmail, company: company.name }}
          />
        </Text>
      </ActionNotPermittedCard>
    );
  }

  return (
    <CTACard
      direction={direction}
      heading={t(`list_for_sale`)}
      description={t(`create_listing_cta`)}
      action={{
        ctaText: t(`sell`),
        disabled: !canCreateListing || !canCreateListingInCompany,
        onClick: handleClick,
      }}
      footer={
        !canCreateListing ? (
          <ComplianceReminder tradeIntent={TradeIntentType.Sell} />
        ) : null
      }
    />
  );
};

const BrokerMakeBidCTACard = ({
  handleClick,
  direction,
  company,
}: {
  readonly handleClick: () => void;
  readonly direction: StackProps[`direction`];
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { t } = useTranslation();

  const { canPlaceBid: iCanPlaceBidOnCompany } =
    getAvailableCompanyActions(company);
  const iHavePlaceBidPermission = useIHavePermission(UserPermissionV2.PlaceBid);

  const iNeedCompanyBuyerApproval =
    company.requiresApprovedBuyers &&
    !iCanPlaceBidOnCompany &&
    iHavePlaceBidPermission;

  if (iNeedCompanyBuyerApproval) {
    return (
      <BuyerNeedsCompanyApprovalCard direction={direction} company={company} />
    );
  }

  return (
    <CTACard
      direction={direction}
      heading={t(`submit_an_order`)}
      description={t(`submit_order_description`)}
      action={{
        ctaText: t(`submit_order_cta`),
        onClick: handleClick,
      }}
    />
  );
};

const MakeBidCTACard = ({
  direction,
  company,
}: {
  readonly direction: StackProps["direction"];
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { modals, onOpenModal } = useModal();
  const { t } = useTranslation();
  const {
    canPlaceBid: iCanPlaceBidOnCompany,
    canPlaceStandingBid: iCanPlaceStandingBidOnCompany,
  } = getAvailableCompanyActions(company);
  const iHavePlaceBidPermission = useIHavePermission(UserPermissionV2.PlaceBid);

  const iNeedCompanyBuyerApproval =
    iHavePlaceBidPermission &&
    company.requiresApprovedBuyers &&
    !iCanPlaceBidOnCompany;

  const listingsICanBidOn = ({
    activity: { othersListings: listings },
  }: CompanyActionsCompanyFragment) => {
    const othersListings = normalizeTruncatedListings(listings);

    return othersListings.filter(
      (listing) =>
        listing.state === ListingState.Open &&
        iHaveEntityPermission(listing, ListingPermission.PlaceBid),
    );
  };

  const handleClick = () => {
    if (listingsICanBidOn(company).length === 0) {
      onOpenModal(modals.placeStandingBid(company))();
      return;
    }
    onOpenModal(modals.placeBid(company))();
  };

  if (!iHavePlaceBidPermission) {
    return (
      <CTACard
        direction={direction}
        heading={t(`make_bid`)}
        description={t(`bid_on_existing_listing`)}
        action={{ disabled: true }}
        footer={<ComplianceReminder tradeIntent={TradeIntentType.Buy} />}
      />
    );
  }

  if (iNeedCompanyBuyerApproval) {
    return (
      <BuyerNeedsCompanyApprovalCard direction={direction} company={company} />
    );
  }

  if (!iCanPlaceStandingBidOnCompany) {
    return (
      <ActionNotPermittedCard>
        <Text textStyle="heading-sm" textTransform="uppercase">
          {t`make_bid`}
        </Text>
        <Text>
          <Trans
            ns="listings"
            i18nKey="company_standing_bid_not_enabled"
            values={{ company: company.name }}
          />
        </Text>
      </ActionNotPermittedCard>
    );
  }

  return (
    <CTACard
      direction={direction}
      heading={t(`make_bid`)}
      description={t(`bid_on_existing_listing`)}
      action={{ ctaText: t(`buy`), onClick: handleClick }}
    />
  );
};

const BrokerCompanyActions = ({
  actor,
  company,
  direction,
}: {
  readonly direction: StackProps[`direction`];
  readonly actor: UserWithInstitutionFragment;
  readonly company: CompanyActionsCompanyFragment;
}) => {
  const { modals, onOpenModal } = useModal();

  return (
    <CompanyActionsCTACardGrid numActionsAvailable={2}>
      <ListForSaleCTACard
        company={company}
        direction={direction}
        actor={actor}
      />
      <BrokerMakeBidCTACard
        direction={direction}
        company={company}
        handleClick={() => onOpenModal(modals.brokerSubmitBid(company))()}
      />
    </CompanyActionsCTACardGrid>
  );
};

export const CompanyActionsContent = withCurrentActor(
  ({
    actor,
    company,
  }: {
    readonly actor: UserWithInstitutionFragment;
    readonly company: CompanyActionsCompanyFragment;
  }) => {
    const isInstitutionUser = getIsInstitutionUser(actor);
    const isInstitutionViewer = getIsInstitutionViewer(actor);
    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isCompanyTradeable = company.tradeable;
    const isBroker = getIsBroker(actor);

    const numActionsBroker = 2;
    const directionBroker = useCompanyActionsCTACardDirection(numActionsBroker);

    const canViewMakeBidCTA = isInstitutionUser || isTrader;
    const numActionsAvailable = canViewMakeBidCTA ? 2 : 1;
    const direction = useCompanyActionsCTACardDirection(numActionsAvailable);

    if (isInstitutionViewer || !isCompanyTradeable) {
      return null;
    }

    if (isBroker)
      return (
        <BrokerCompanyActions
          direction={directionBroker}
          actor={actor}
          company={company}
        />
      );

    return (
      <CompanyActionsCTACardGrid numActionsAvailable={numActionsAvailable}>
        <ListForSaleCTACard
          company={company}
          direction={direction}
          actor={actor}
        />
        {canViewMakeBidCTA && (
          <MakeBidCTACard company={company} direction={direction} />
        )}
      </CompanyActionsCTACardGrid>
    );
  },
);

const CompanyActions = ({ companyId }: { readonly companyId: string }) => {
  const query = useCompanyPageActionsCompanyByIdQuery({
    variables: { id: companyId },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<CompanyActionsSkeleton />}>
      {({ data: { companyById: company } }) => (
        <CompanyActionsContent company={company} />
      )}
    </WithQuery>
  );
};

export default CompanyActions;
