import { useTranslation } from "react-i18next";

import { PostingCard } from "@/components/common";
import { ListingLatestPostingCardHeaderFragment } from "@/gql";

type ListingLatestPostingCardHeaderProps = {
  listing: ListingLatestPostingCardHeaderFragment;
};

export function ListingLatestPostingCardHeader({
  listing: {
    company: { name },
  },
}: ListingLatestPostingCardHeaderProps) {
  const { t } = useTranslation(`listings`);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="listing">
            {t(`listing`)}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{name}</PostingCard.HeaderTitle>
        </>
      }
    />
  );
}
