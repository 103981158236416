import dayjs, { Dayjs, OpUnitType, ManipulateType } from "dayjs";

export const hoursFromNow = (date: Date) => dayjs(date).diff(dayjs(), `hours`);

export const getHasExpired = (date: Date): boolean => {
  const now = dayjs();
  return dayjs(date).isBefore(now);
};

// The dayjs wrappers are needed because dayjs doesn't work with null dates, but their typespecs are incorrect.
export const format = (format: string, date: Date | string | Dayjs) =>
  dayjs(date).format(format);

export const endOf = (unit: OpUnitType, date: Date | string) =>
  dayjs(date).endOf(unit);

export const diff = (a: Date | string | Dayjs, b: Date | string | Dayjs) =>
  dayjs(a).diff(b);

export const getIsBefore = (
  a: Date | string | Dayjs,
  b: Date | string | Dayjs,
) => dayjs(a).isBefore(b);

export const add = (
  unit: ManipulateType,
  amount: number,
  date: Date | string | Dayjs,
) => dayjs(date).add(amount, unit);

export const toTimestamp = (date: Date | string | Dayjs) =>
  format(`Do MMM (h:mm A z)`, date);

export const toFullTimestamp = (date: Date | string | Dayjs) =>
  format(`Do MMM YYYY (h:mm A z)`, date);

export const toShortDate = (date: Date | string | Dayjs) =>
  format(`D, MMMM YYYY`, date);

export const toFullDate = (date: Date | string | Dayjs) =>
  format(`MMMM Do YYYY`, date);

export const toFormattedDate = (date: Date | string | Dayjs) =>
  format(`Do MMM, YYYY`, date);
