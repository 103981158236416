import { CaretDown, CaretRight } from "@phosphor-icons/react";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import {
  Card,
  Accordion,
  VStack,
  Text,
  Divider,
  HStack,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

import {
  ExecutionSendFundsTaskData,
  TransactionExecutionPageTaskFragment,
} from "@/gql";

import AmountDueCardHeader from "./AmountDueCardHeader";
import Payments from "./Payments";
import WireInformationAlert from "./WireInformationAlert";
import WireInformationCard from "./WireInformationCard";

type SendFundsTaskCardProps = {
  data: ExecutionSendFundsTaskData;
  task: TransactionExecutionPageTaskFragment;
  wireAmount: number;
};

const SendFundsTaskCard = ({
  data,
  task,
  wireAmount,
}: SendFundsTaskCardProps) => {
  const { t } = useTranslation(`execution`);
  const hasPayments = data.fundsReceipts.length > 0;
  const hasVirtualAccount = !!data.virtualAccount;
  const total = data.totalFundsSent;
  const isCompleted = !!task.completedAt;

  return (
    <>
      {hasPayments && (
        <Card borderColor="grey.100" p={0} w="full" variant="flat">
          <AmountDueCardHeader
            amount={wireAmount}
            completedAt={task.completedAt}
          />
          <Accordion
            defaultIndex={isCompleted ? undefined : 0}
            borderColor="transparent"
            allowToggle
          >
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    as={HStack}
                    h={12}
                    {...{
                      _hover: {
                        bg: `grey.15`,
                      },
                      _active: {
                        bg: `grey.25`,
                      },
                    }}
                  >
                    <HStack w="full" gap={4}>
                      <HStack w="full" justifyContent="space-between">
                        <Text textStyle="heading-md">{t(`payments`)}</Text>
                        <Text textStyle="heading-md">
                          {currency(total / 100).format()}
                        </Text>
                      </HStack>
                    </HStack>
                    {isExpanded ? <CaretDown /> : <CaretRight />}
                  </AccordionButton>
                  {!isExpanded && <Divider borderColor="grey.100" />}
                  <AccordionPanel p={4} pt={0}>
                    <Payments
                      fundsReceipts={data.fundsReceipts}
                      totalFundsSent={data.totalFundsSent}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
            <AccordionItem border="none">
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    as={HStack}
                    h={12}
                    {...{
                      _hover: {
                        bg: `grey.15`,
                      },
                      _active: {
                        bg: `grey.25`,
                      },
                    }}
                  >
                    <HStack w="full" gap={4}>
                      <Text textStyle="heading-md">
                        {t(`wire_instructions`)}
                      </Text>
                    </HStack>
                    {isExpanded ? <CaretDown /> : <CaretRight />}
                  </AccordionButton>
                  <AccordionPanel p={4} pt={0}>
                    <WireInformationCard
                      accountNumber={data?.virtualAccount?.accountNumber}
                      amount={wireAmount}
                      border="none"
                      bg="grey.75"
                      completedAt={task.completedAt}
                      isLoading={false}
                      routingNumber={data?.virtualAccount?.routingNumber}
                      showAmountDue={false}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Card>
      )}
      {!hasPayments && (
        <VStack gap={4}>
          <WireInformationCard
            accountNumber={data.virtualAccount?.accountNumber}
            amount={wireAmount}
            isLoading={!hasVirtualAccount}
            completedAt={task.completedAt}
            routingNumber={data.virtualAccount?.routingNumber}
          />
          {hasVirtualAccount && <WireInformationAlert />}
        </VStack>
      )}
    </>
  );
};

export default SendFundsTaskCard;
