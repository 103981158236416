import currency from "currency.js";
import isNil from "lodash/isNil";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useRouter } from "next/router";

import {
  GridItem,
  HStack,
  ModalBody,
  Radio,
  Show,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  HiiveButton,
  HiiveCancelButton,
  HiiveModalFooter,
  HiiveModalHeader,
  HiiveSubmitButton,
  ShareDetails,
  ShareDetailsStats,
} from "@/components/common";
import {
  CheckboxInput,
  FormNumberInput,
  FormRadioInput,
  FormSelectListingInput,
  MoneyInput,
  QuestionTooltip,
  StepPropsV2,
} from "@/components/form";
import { bidTimeLimitSchema } from "@/components/postings";
import { PlaceStandingBidTooltip } from "@/components/tooltip";
import {
  BrokerSubmitBidSequenceModalCompanyFragment,
  BrokerSubmitBidSequenceModalListingFragment,
  FormSelectListingInputListingFragment,
  ListingPermission,
  ListingState,
} from "@/gql";
import { iHaveEntityPermission, useModal, useStepValidator } from "@/hooks";
import {
  bidLabel,
  constants,
  fromBool,
  getNumOfShares,
  getNumSharesAvailableRounded,
  getPricePerShare,
  toBool,
  isHiiveFund,
  normalizeTruncatedListings,
} from "@/utils";

import { BrokerSubmitBidSequenceModalStepFormContext } from "./BrokerSubmitBidSequenceModalStepFormContext";
import { stepKeys, StepKeys } from "./steps";
import { BrokerSubmitBidSequenceModalFormValues } from "./types";

const createValidationSchema = ({
  selectedListing,
}: {
  readonly selectedListing?: BrokerSubmitBidSequenceModalListingFragment | null;
}) =>
  Yup.object().shape({
    listingId: Yup.string().required(`Must select a listing to bid on.`),
    pricePerShare: Yup.number()
      .nullable()
      .test(
        `min-bid-size`,
        `The bid size can't be less than ${currency(
          constants.min_bid_lot.number,
        ).format()}`,
        function test(value: number) {
          const { numShares } = this.parent;

          return value * numShares >= constants.min_bid_lot.number;
        },
      )
      .required(`Price per share is required`),
    numShares: !!selectedListing
      ? Yup.number()
          .nullable()
          .max(
            getNumSharesAvailableRounded(selectedListing),
            `Can't request more shares than a listing has`,
          )
          .required(`Required`)
      : Yup.number().nullable().required(`Required`),
    timeLimit: bidTimeLimitSchema,
    solicited: Yup.boolean().nullable().required(`Required`),
    affiliate: Yup.boolean().nullable().required(`Required`),
    confirmed: Yup.boolean().oneOf([true], `Required`),
  });

interface BrokerSubmitBidModalProps
  extends StepPropsV2<StepKeys, BrokerSubmitBidSequenceModalFormValues> {
  readonly company: BrokerSubmitBidSequenceModalCompanyFragment;
  readonly allowDirectBidPlacing?: boolean;
}

const getSelectedListing = (
  listingId: string,
  listingOptions: readonly BrokerSubmitBidSequenceModalListingFragment[],
) => {
  if (!listingId) return null;

  return listingOptions.find((listingOption) => listingOption.id === listingId);
};

const BrokerSubmitBidModalShareDetails = ({
  listing,
}: {
  readonly listing: BrokerSubmitBidSequenceModalListingFragment;
}) => {
  const numberOfShares = getNumOfShares(listing, true);
  const pricePerShare = getPricePerShare(listing);

  return (
    <ShareDetails variant="listing" title="Listing Details">
      <ShareDetailsStats
        numberOfShares={numberOfShares}
        pricePerShare={pricePerShare}
      />
    </ShareDetails>
  );
};

const BrokerSubmitBidModal = ({
  company,
  values,
  stepRouter,
  setFieldValue,
  allowDirectBidPlacing,
}: BrokerSubmitBidModalProps) => {
  const { stepControls } = stepRouter;
  const { t } = useTranslation();
  const { closeModal, modals, onOpenModal } = useModal();
  const router = useRouter();

  const listingsICanBidOn = ({
    activity: { othersListings: listings },
  }: BrokerSubmitBidSequenceModalCompanyFragment) => {
    const othersListings = normalizeTruncatedListings(listings);

    return othersListings.filter(
      (listing) =>
        listing.state === ListingState.Open &&
        iHaveEntityPermission(listing, ListingPermission.PlaceBid),
    );
  };

  const listingOptions = listingsICanBidOn(company);

  const handleClickPlaceStandingBid = () =>
    onOpenModal(modals.placeStandingBid(company))();

  const selectedListing = getSelectedListing(values.listingId, listingOptions);

  const handleClickViewFullListing = () => {
    if (!selectedListing) return;
    closeModal();
    router.push(`/listings/${selectedListing.id}`);
  };

  const onSuccess = () => stepControls.nextStep();

  const validationSchema = createValidationSchema({ selectedListing });

  useStepValidator({
    Context: BrokerSubmitBidSequenceModalStepFormContext,
    stepKey: stepKeys.brokerSubmitBid,
    validator: {
      validationSchema,
      onSuccess,
    },
    values,
  });

  const handleSelectListing = (
    listing: FormSelectListingInputListingFragment,
  ) => {
    const numShares = getNumSharesAvailableRounded(listing);
    const pricePerShareCents = getPricePerShare(listing);
    const pricePerShareDollars = !isNil(pricePerShareCents)
      ? pricePerShareCents / 100
      : ``;

    setFieldValue(`numShares`, numShares);
    setFieldValue(`pricePerShare`, pricePerShareDollars);
  };

  return (
    <>
      <HiiveModalHeader>Place Bid</HiiveModalHeader>

      <ModalBody borderBottomWidth={0} pb={3}>
        <HStack spacing={1}>
          <Text textStyle="text-base">
            <Trans
              i18nKey="select_listing_to_submit_bid_on"
              components={{
                button: (
                  <HiiveButton
                    observabilityLabel="[BrokerSubmitBid/PlaceStandingBid]"
                    variant="text-salmon"
                    p={0}
                    onClick={handleClickPlaceStandingBid}
                  />
                ),
              }}
            />
          </Text>
          <QuestionTooltip tooltipContent={<PlaceStandingBidTooltip />} />
        </HStack>
      </ModalBody>
      <ModalBody px={0} pt={0} data-testid="submit-bid-listings">
        <FormSelectListingInput
          onSelectListing={handleSelectListing}
          options={listingOptions}
        />
      </ModalBody>
      {!!selectedListing && (
        <>
          <ModalBody>
            <VStack alignItems="flex-start" spacing={5}>
              <BrokerSubmitBidModalShareDetails listing={selectedListing} />
              <div>
                To place {isHiiveFund(selectedListing) ? `an order` : `a bid`},
                {` `}
                <HiiveButton
                  onClick={handleClickViewFullListing}
                  observabilityLabel="[BrokerSubmitBid/ViewFullListing]"
                  variant="text-salmon"
                  p={0}
                >
                  view listing
                </HiiveButton>
              </div>
            </VStack>
          </ModalBody>
          {allowDirectBidPlacing && (
            <ModalBody>
              <SimpleGrid columns={2} columnGap={9} rowGap={6} w="full">
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <FormNumberInput name="numShares" label="Number of shares" />
                </GridItem>
                <GridItem colSpan={{ base: 2, md: 1 }}>
                  <MoneyInput name="pricePerShare" label="Price per share" />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormNumberInput
                    name="timeLimit"
                    label={t(`bid_valid_for_days`, {
                      bidLabel: bidLabel(selectedListing),
                    })}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormRadioInput
                    label={t(`broker_submit_bid_affiliate_question`, {
                      companyName: company.name,
                    })}
                    name="affiliate"
                    mapper={{ from: fromBool, to: toBool }}
                    data-testid="broker-affiliate-bid-radio-button"
                  >
                    <HStack spacing={12}>
                      <Radio variant="base" value="true">
                        Yes
                      </Radio>
                      <Radio variant="base" value="false">
                        No
                      </Radio>
                    </HStack>
                  </FormRadioInput>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormRadioInput
                    label={t(`broker_submit_bid_unsolicited_question`, {
                      companyName: company.name,
                    })}
                    name="solicited"
                    mapper={{ from: fromBool, to: toBool }}
                    data-testid="broker-solicited-bid-radio-button"
                  >
                    <HStack spacing={12}>
                      <Radio variant="base" value="false">
                        Yes
                      </Radio>
                      <Radio variant="base" value="true">
                        No
                      </Radio>
                    </HStack>
                  </FormRadioInput>
                </GridItem>
                <GridItem colSpan={2}>
                  <CheckboxInput
                    name="confirmed"
                    label={t(`broker_submit_bid_acknowledgement`)}
                  />
                </GridItem>
              </SimpleGrid>
            </ModalBody>
          )}
        </>
      )}
      {allowDirectBidPlacing && (
        <HiiveModalFooter>
          <Show above="md" ssr={false}>
            <HiiveCancelButton
              observabilityLabel="[BrokerSubmitBid/Cancel]"
              onCancel={closeModal}
            />
          </Show>
          <HiiveSubmitButton
            observabilityLabel="[BrokerSubmitBid/Submit]"
            submitText="Submit Bid"
            type="submit"
          />
        </HiiveModalFooter>
      )}
    </>
  );
};

export default BrokerSubmitBidModal;
