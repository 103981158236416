import { useTranslation } from "react-i18next";

import { HStack, Radio, Text } from "@chakra-ui/react";

import { CustomTooltip, FormRadioInput } from "@/components/form";

import { transferTypeChoices } from "./types";

type TransferTypeChoiceInputProps = {
  isRestricted?: boolean;
};

const TransferTypeChoiceInput = ({
  isRestricted,
}: TransferTypeChoiceInputProps) => {
  const { t } = useTranslation();

  return (
    <FormRadioInput
      label={t(`are_you_able_to_transfer`)}
      name="transferTypeChoice"
    >
      <HStack spacing={6} pt={5} data-testid="transfer-type-choice">
        <Radio variant="base" value={transferTypeChoices.Yes}>
          <CustomTooltip tooltipContent={t(`transfer_type_choice_direct`)}>
            <Text variant="tooltip">{t(`yes`)}</Text>
          </CustomTooltip>
        </Radio>
        <Radio variant="base" value={transferTypeChoices.Other}>
          <CustomTooltip tooltipContent={t(`transfer_type_choice_indirect`)}>
            <Text variant="tooltip">{t(`no`)}</Text>
          </CustomTooltip>
        </Radio>
        {!isRestricted && (
          <Radio variant="base" value={transferTypeChoices.DontKnow}>
            <CustomTooltip tooltipContent={t(`transfer_type_choice_unknown`)}>
              <Text variant="tooltip">{t(`i_dont_know`)}</Text>
            </CustomTooltip>
          </Radio>
        )}
      </HStack>
    </FormRadioInput>
  );
};

export default TransferTypeChoiceInput;
