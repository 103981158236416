import { sentenceCase } from "change-case";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { VStack } from "@chakra-ui/react";

import {
  FormTextInput,
  FormCountryCombobox,
} from "@/components/react-hook-form";
import { EntityType } from "@/gql";
import { useCurrentActor } from "@/hooks";

import EntityTypeSelect from "./EntityTypeSelect";

export type EntityFormValues = {
  legalName: string;
  jurisdictionOfFormationId: string;
  type: EntityType;
  otherType?: string;
  internal?: boolean;
};

const EntityForm = () => {
  const { t } = useTranslation(`account`);
  const actor = useCurrentActor();
  const { control, watch } = useFormContext<EntityFormValues>();

  const isInstitution = !!actor.institutionId;

  const excludeIndividualType = !actor.isHiiveUser;

  const typeValue = watch(`type`);

  return (
    <VStack w="full" spacing={4}>
      <EntityTypeSelect
        filterIndividualEntityType={excludeIndividualType}
        isInstitution={isInstitution}
      />
      {typeValue === EntityType.Other && (
        <FormTextInput
          control={control}
          placeholder={t(`entities.add_party_type`)}
          name="otherType"
          label={t(`entities.other_entity_type`)}
        />
      )}
      <FormTextInput
        control={control}
        name="legalName"
        label={t(`legal_name`, { ns: `translation` })}
      />
      <FormCountryCombobox
        control={control}
        placeholder={t(`entities.search_country`)}
        name="jurisdictionOfFormationId"
        label={sentenceCase(t(`entities.jurisdiction_of_formation`))}
        showSearchIcon
      />
    </VStack>
  );
};

export default EntityForm;
