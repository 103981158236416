import { Trans } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { FeeBreakdown } from "@/components/common";
import {
  TransactionFeeBreakdownTransactionFragment,
  TransactionState,
} from "@/gql";

const AdditionalDisclaimerText = () => (
  <Text align="left" mt={4} textStyle="text-xs">
    <Trans
      i18nKey="transaction_fee_breakdown_disclaimer_text"
      components={{ bold: <strong /> }}
    />
  </Text>
);

export const TransactionFeeBreakdown = ({
  transaction,
}: {
  readonly transaction: TransactionFeeBreakdownTransactionFragment;
}) => {
  const {
    numShares,
    pricePerShare,
    sellerCommission,
    state,
    feeDiscountApplications,
  } = transaction;

  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    sellerCommission || {};

  const pendingIssuerApproval =
    state === TransactionState.IssuerPendingApproval;

  return (
    <FeeBreakdown
      numShares={numShares}
      pricePerShare={pricePerShare}
      feeDiscountApplications={feeDiscountApplications}
      flatFeeAmount={flatFeeAmount}
      commissionAmount={commissionAmount}
      feeDiscountAmount={feeDiscountAmount}
      netFees={netFees}
      disclaimerVariant="seller"
      disclaimer={pendingIssuerApproval && <AdditionalDisclaimerText />}
    />
  );
};
