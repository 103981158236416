import { useTranslation } from "react-i18next";

import { PostingCard, PostingCardCompanyTile } from "@/components/common";
import { ListingYourActivityDashboardPostingCardHeaderFragment } from "@/gql";

type ListingYourActivityDashboardPostingCardHeaderProps = {
  listing: ListingYourActivityDashboardPostingCardHeaderFragment;
};

export function ListingYourActivityDashboardPostingCardHeader({
  listing: { displayId, company },
}: ListingYourActivityDashboardPostingCardHeaderProps) {
  const { t } = useTranslation(`listings`);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="listing">
            {t(`listing`)}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{displayId}</PostingCard.HeaderTitle>
        </>
      }
    >
      <PostingCardCompanyTile company={company} />
    </PostingCard.Header>
  );
}
