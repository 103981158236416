import { useTranslation } from "react-i18next";

import { FeeBreakdown } from "@/components/common";
import { CounterBidFeeBreakdownBidFragment } from "@/gql";

export const BrokerSellerCounterBidFeeBreakdown = ({
  bid,
}: {
  readonly bid: CounterBidFeeBreakdownBidFragment;
}) => {
  const { t } = useTranslation();

  const { counterBid } = bid;

  if (!counterBid) return null;

  const {
    numShares,
    pricePerShare,
    sellerCommission,
    feeDiscountApplications,
  } = counterBid;
  const { commissionAmount, flatFeeAmount, feeDiscountAmount, netFees } =
    sellerCommission || {};

  return (
    <FeeBreakdown
      numShares={numShares}
      pricePerShare={pricePerShare}
      flatFeeAmount={flatFeeAmount}
      commissionAmount={commissionAmount}
      feeDiscountAmount={feeDiscountAmount}
      netFees={netFees}
      feeDiscountApplications={feeDiscountApplications}
      combineHiiveFeesLabel={t(`hiive_co_broker_fee`)}
      combineHiiveFeesTooltip={t(`hiive_co_broker_fee_tooltip`)}
      netProceedsTitle={t(`broker_seller_proceeds`)}
      netProceedsSubtitle={t(`gross_of_your_brokerage_fees`)}
      netProceedsTooltip={t(`broker_seller_proceeds_tooltip`)}
      disclaimerVariant="brokerSeller"
    />
  );
};
