import {
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Text,
} from "@chakra-ui/react";

import {
  ActivityCard,
  ActivityCardBadge,
  ActivityCardFooterWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardPlacedByHiive,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTimestamp,
} from "@/components/common";
import {
  BidState,
  PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment,
} from "@/gql";
import {
  appendSellerCompanyIdToUrl,
  getBidNumSharesActual,
  bidLabel,
} from "@/utils";

const PastBidCardDetails = ({
  pastBid,
}: {
  readonly pastBid: PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment;
}) => {
  const numShares = getBidNumSharesActual(pastBid);

  return (
    <HStack justifyContent="space-between" spacing={6}>
      <ActivityCardPricingWrapper>
        <ActivityCardPricingNumSharesAndPrice
          numShares={numShares}
          price={pastBid.pricePerShare}
        />
        <ActivityCardPricingTotal
          numShares={numShares}
          price={pastBid.pricePerShare}
        />
      </ActivityCardPricingWrapper>
    </HStack>
  );
};

export const PastBidCard = ({
  pastBid,
}: {
  readonly pastBid: PostActivityUnaccreditedSellerDashboardByIdPagePastBidsPageBidFragment;
}) => {
  const isBidExpired = pastBid.state === BidState.Expired;
  const isBidWithdrawn = pastBid.state === BidState.Withdrawn;
  const title = bidLabel(pastBid.listing);

  const url = `/listings/bids/${pastBid.id}`;
  return (
    <ActivityCard href={appendSellerCompanyIdToUrl(url, pastBid.companyId)}>
      <CardHeader>
        <HStack justifyContent="space-between">
          <HStack opacity={0.4}>
            <ActivityCardBadge variant="bid" title={title} />
            <Text textStyle="text-lg">{pastBid.displayId}</Text>
          </HStack>
          {pastBid.fromHiive && <ActivityCardPlacedByHiive />}
        </HStack>
      </CardHeader>
      <CardBody>
        <PastBidCardDetails pastBid={pastBid} />
      </CardBody>
      <CardFooter>
        <ActivityCardFooterWrapper>
          <ActivityCardFooterStatusWrapper>
            <ActivityCardTimestamp title="Placed" date={pastBid.placedAt} />
            {isBidExpired && (
              <ActivityCardTimestamp title="Expired" date={pastBid.expireAt} />
            )}
            {isBidWithdrawn && (
              <ActivityCardTimestamp
                title="Withdrawn"
                date={pastBid.withdrawnAt}
              />
            )}
          </ActivityCardFooterStatusWrapper>
        </ActivityCardFooterWrapper>
      </CardFooter>
    </ActivityCard>
  );
};
