import { PostingCard } from "@/components/common";
import { StandingBidAvailablePostingCardFragment } from "@/gql";

import { StandingBidAvailablePostingCardFooter } from "./StandingBidAvailablePostingCardFooter";
import { StandingBidPostingCardBody } from "./StandingBidPostingCardBody";
import { StandingBidPostingCardHeader } from "./StandingBidPostingCardHeader";

type StandingBidAvailablePostingCardProps = {
  standingBid: StandingBidAvailablePostingCardFragment;
};

function StandingBidAvailablePostingCardBody({
  standingBid,
}: StandingBidAvailablePostingCardProps) {
  const url = `/standing-bids/${standingBid.id}`;
  return (
    <PostingCard href={url}>
      <StandingBidPostingCardHeader standingBid={standingBid} />
      <StandingBidPostingCardBody standingBid={standingBid} />
      <StandingBidAvailablePostingCardFooter standingBid={standingBid} />
    </PostingCard>
  );
}

export function StandingBidAvailablePostingCard({
  standingBid,
}: StandingBidAvailablePostingCardProps) {
  return <StandingBidAvailablePostingCardBody standingBid={standingBid} />;
}
