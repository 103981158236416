import { extendTheme, Theme } from "@chakra-ui/react";

import { colors } from "./colors";
import { components } from "./components";
import { layerStyles } from "./layer-styles";
import { textStyles } from "./text-styles";

const breakpoints = {
  sm: `30em`,
  banner: `46.18em`,
  md: `48em`,
  lg: `62em`,
  xl: `80em`,
  "2xl": `88em`,
  "3xl": `96em`,
  "4xl": `104em`,
  "5xl": `112em`,
};

const fonts = {
  heading: `ColfaxWeb`,
  body: `ColfaxWeb`,
  title: `Plantin`,
};

const fontSizes = {
  "3xs": `0.75rem`,
  "2xs": `0.813rem`,
  xs: `0.875rem`,
  base: `1rem`,
  lg: `1.125rem`,
  xl: `1.25rem`,
  "2xl": `1.375rem`,
  "3xl": `1.5rem`,
  "4xl": `2rem`,
};

const borders = {
  "grey-border": `1px solid #BCBDC0`,
  "light-grey-border": `1px solid #F5F5F5`,
  "dark-grey-border": `1px solid #404041`,
};

const styles = {};

const sizes = {
  "18": `4.5rem`,
  "22": `5.5rem`,
  "71": `17.75rem`,
  "74": `18.5rem`,
  "76": `19rem`,
  "88": `22rem`,
  "96": `24rem`,
  "112": `28rem`,
  "120": `30rem`,
  "138": `34.5rem`,
  "144": `36rem`,
  "160": `40rem`,
  "178": `44.5rem`,
  "278": `69.5rem`,
  "max-width-md": `1280px`,
  "max-width-lg": `1560px`,
  "max-width-xl": `1792px`,
  "mobile-header-height": `70px`,
  "desktop-header-height": `80px`,
  "mobile-navbar-height": `72px`,
  "desktop-navbar-height": `88px`,
  "desktop-admin-navbar-height": `64px`,
  "banner-spacer-base": `72px`,
  "banner-spacer-banner": `48px`,
  "mobile-tab-list-footer-height": `60px`,
  "desktop-full-height-minus-header": `calc(100vh - var(--chakra-sizes-desktop-header-height))`,
  "desktop-full-height-minus-navbar": `calc(100vh - var(--chakra-sizes-desktop-navbar-height))`,
};

export const shadows = {
  card: `0px 4px 10px 1px rgba(0, 0, 0, 0.05)`,
  focus: `0px 0px 5px 0.5px var(--chakra-colors-skyBlue-900)`,
};

const semanticTokens = {
  colors: {
    "chakra-body-text": `grey.900`,
    "chakra-body-bg": `grey.25`,
  },
};

export const radii = {
  sm: `4px`,
  md: `6px`,
  lg: `8px`,
};

const theme = extendTheme({
  colors: {
    ...colors,
    hiive: {
      listing: colors[`deprecated-olive-900`],
      bid: colors.plum[`900`],
      "bid--accepted": colors.teal[`900`],
      "standing-bid": colors.plum[`900`],
      discussion: colors.grey[`600`],
    },
  },
  fonts,
  fontSizes,
  textStyles,
  components,
  styles,
  borders,
  shadows,
  radii,
  breakpoints,
  layerStyles,
  sizes,
  semanticTokens,
}) as Theme;

// These are needed for storybook to compile
export { textTokens, headingTokens } from "./text-styles";
export { components } from "./components";
export default theme;
