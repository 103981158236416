import { useTranslation } from "react-i18next";

import { Text } from "@chakra-ui/react";

import { PostingCard } from "@/components/common";
import { ListingLatestPostingCardFooterFragment } from "@/gql";
import { toTimestamp } from "@/utils";

type ListingLatestPostingCardFooterProps = {
  listing: ListingLatestPostingCardFooterFragment;
};

export function ListingLatestPostingCardFooter({
  listing: { placedAt },
}: ListingLatestPostingCardFooterProps) {
  const { t } = useTranslation(`listings`);

  const placedAtFormatted = toTimestamp(placedAt);

  return (
    <PostingCard.Footer>
      <Text textStyle="text-sm" whiteSpace="nowrap">
        {t(`placed_timestamp`, {
          date: placedAtFormatted,
        })}
      </Text>
    </PostingCard.Footer>
  );
}
