import { useTranslation } from "react-i18next";

import { PostingCard } from "@/components/common";
import { StandingBidLatestPostingCardHeaderFragment } from "@/gql";

type StandingBidLatestPostingCardHeaderProps = {
  standingBid: StandingBidLatestPostingCardHeaderFragment;
};

export function StandingBidLatestPostingCardHeader({
  standingBid: {
    company: { name },
  },
}: StandingBidLatestPostingCardHeaderProps) {
  const { t } = useTranslation(`standingBids`);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="standing-bid">
            {t(`standing_bid`)}
          </PostingCard.Label>
          <PostingCard.HeaderTitle>{name}</PostingCard.HeaderTitle>
        </>
      }
    />
  );
}
