import { useTranslation } from "react-i18next";

import { Card, CardBody, Flex, HStack, Text, VStack } from "@chakra-ui/react";

import { HiiveButton } from "@/components/common";
import { OnboardingContainerV2 } from "@/components/onboarding-v2";
import { TileCheckboxInput } from "@/components/react-hook-form";
import {
  DEFAULT_TRUSTED_CONTACT,
  getTrustedContactFormSchema,
  mapTrustedContactVariables,
  TrustedContactForm,
} from "@/components/trusted-contact-form";
import {
  useTransitionCurrentStepMutation,
  useUpsertTrustedContactPersonMutation,
} from "@/gql";
import { useCurrentActor, useCustomToast, useMutationWithError } from "@/hooks";
import { useFormQL } from "@/hooks/react-hook-form";

const TrustedContactPage = () => {
  const { t } = useTranslation(`trustedContactPerson`);
  const { t: formT } = useTranslation();
  const { successToast } = useCustomToast();
  const upsertTrustedContact = useUpsertTrustedContactPersonMutation();

  const [transitionCurrentStepMutation, isTransitioningCurrentStep] =
    useMutationWithError(
      useTransitionCurrentStepMutation(),
      `transitionCurrentStep`,
    );

  const actor = useCurrentActor();
  const { trustedContactPerson } = actor;

  const isUpdating = actor.trustedContactPerson?.isContactProvided;

  const onSuccess = async () => {
    const successText = isUpdating
      ? t(`updated_trusted_contact_person`)
      : t(`saved_trusted_contact_person`);

    successToast(successText);
    await transitionCurrentStepMutation();
  };
  const trustedContactForm = useFormQL({
    mutation: upsertTrustedContact,
    initialValues: {
      ...(trustedContactPerson
        ? { ...trustedContactPerson.contactInfo }
        : { ...DEFAULT_TRUSTED_CONTACT }),
      collectTrustedContactPerson: trustedContactPerson?.isContactProvided,
    },

    mapVariables: mapTrustedContactVariables,
    validationSchema: getTrustedContactFormSchema(formT),
    mode: `onBlur`,
    onSuccess,
  });

  return (
    <OnboardingContainerV2
      canGoBack
      metaTitle={t(`add_trusted_contact_person`)}
    >
      <Flex direction="column" alignItems="center">
        <VStack
          spacing={8}
          alignItems="flex-start"
          mb={8}
          w={{ base: `full`, lg: `178` }}
        >
          <VStack spacing={2}>
            <Text color="grey.900" textStyle="heading-3xl">
              {t(`add_trusted_contact_person`)}
            </Text>
            <Text color="grey.900">{t(`can_complete_later`)}</Text>
          </VStack>
          <Text textStyle="text-sm" color="grey.900">
            {t(`who_is_a_trusted_contact_person`)}
          </Text>
        </VStack>
        <form
          autoComplete="off"
          onSubmit={trustedContactForm.handleSubmit}
          data-dd-privacy="mask"
        >
          <Card
            variant="darkened-footer"
            w={{ base: `full`, lg: `178` }}
            flex="1"
          >
            <CardBody p={{ base: 4, lg: 10 }}>
              <VStack alignItems="flex-start">
                <Text textStyle="heading-lg">
                  {t(`add_trusted_contact_person_question`)}
                </Text>
                <TileCheckboxInput.Tooltip>
                  <VStack alignItems="flex-start">
                    <Text textStyle="text-sm">{t`add_trusted_contact_person_disclaimer`}</Text>
                  </VStack>
                </TileCheckboxInput.Tooltip>
              </VStack>
              <TrustedContactForm
                trustedContactForm={trustedContactForm}
                width={{ base: `full`, lg: `85%` }}
              />
            </CardBody>
          </Card>

          <HStack p={2} mt={6} justifyContent="flex-end" w="full">
            <HiiveButton
              isLoading={isTransitioningCurrentStep}
              isDisabled={!trustedContactForm.formState?.isValid}
              maxW="auto"
              observabilityLabel="[TrustedContactForm] Next"
              size="xl"
              type="submit"
              variant="rounded-solid-salmon"
              w={{ base: `full`, sm: `auto` }}
            >
              {t(`next`)}
            </HiiveButton>
          </HStack>
        </form>
      </Flex>
    </OnboardingContainerV2>
  );
};

export default TrustedContactPage;
