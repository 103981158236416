import { WithQuery } from "@/components/common";
import { useCompanyPageMarketActivityCompanyByIdV2Query } from "@/gql";

import { MarketActivityContent } from "./MarketActivityContent";
import { MarketActivitySkeleton } from "./MarketActivitySkeleton";

export const MarketActivity = ({
  companyId,
}: {
  readonly companyId: string;
}) => {
  const query = useCompanyPageMarketActivityCompanyByIdV2Query({
    variables: {
      id: companyId,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<MarketActivitySkeleton />}>
      {({ data: { company } }) => <MarketActivityContent company={company} />}
    </WithQuery>
  );
};
