import {
  ActionsTile,
  ActionsTileButtonWrapper,
  HiiveButton,
  TileHeader,
} from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import {
  StandingBidActionsTileStandingBidFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useModal } from "@/hooks";
import { useRemoveClientWithdrawFunction } from "@/hooks/featureFlags";
import { getAvailableStandingBidActions } from "@/utils";
import { getIsBuyerForStandingBid } from "@/utils/standing-bid";

export const StandingBidActionsTile = withCurrentActor(
  ({
    standingBid,
    actor,
  }: {
    readonly standingBid: StandingBidActionsTileStandingBidFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { modals, onOpenModal } = useModal();

    const { canWithdrawStandingBid, canModifyStandingBid } =
      getAvailableStandingBidActions(standingBid);

    const { isSuperadmin } = actor;
    const isBuyer = getIsBuyerForStandingBid(actor, standingBid);

    const canWidthdrawAsSuperadmin =
      !isBuyer && isSuperadmin && canWithdrawStandingBid;

    const removeClientWithdrawFunctionEnabled =
      useRemoveClientWithdrawFunction();

    return (
      <ActionsTile>
        <TileHeader>
          {canWidthdrawAsSuperadmin
            ? `Admin Actions`
            : `Adjust your Standing Bid`}
        </TileHeader>
        <ActionsTileButtonWrapper>
          {canModifyStandingBid && (
            <HiiveButton
              size="md"
              variant="rounded-outline-grey"
              onClick={onOpenModal(modals.modifyStandingBid(standingBid))}
              observabilityLabel="[StandingBidActions] Modify Standing Bid"
            >
              Modify
            </HiiveButton>
          )}
          {!removeClientWithdrawFunctionEnabled && canWithdrawStandingBid && (
            <HiiveButton
              size="md"
              variant="rounded-outline-grey"
              onClick={onOpenModal(modals.withdrawStandingBid(standingBid))}
              observabilityLabel="[StandingBidActions] Withdraw Standing Bid"
            >
              Withdraw
            </HiiveButton>
          )}
        </ActionsTileButtonWrapper>
      </ActionsTile>
    );
  },
);
