import { Trans, useTranslation } from "react-i18next";

import { Card, CardBody, Flex, Text } from "@chakra-ui/react";

import { ActivityGroupEmptyState, HiiveButton } from "@/components/common";
import { CompanyMarketActivitySellersBadge } from "@/components/companies";
import { CompanyPageMarketActivityCompanyFragment } from "@/gql";
import { useCurrentActor, useModal } from "@/hooks";
import { getAvailableCompanyActions, getIsBroker } from "@/utils";

const EmptyState = ({
  company,
  numberOfStandingBids,
}: {
  readonly company: CompanyPageMarketActivityCompanyFragment;
  readonly numberOfStandingBids: number;
}) => {
  const { t } = useTranslation();
  const { t: companyT } = useTranslation(`company`);
  const { modals, onOpenModal } = useModal();

  const { canPlaceStandingBid } = getAvailableCompanyActions(company);

  const numberOfSellers = company.numHolders + company.numWatchers;

  const showStandingBidCTA =
    canPlaceStandingBid && numberOfSellers > 0 && numberOfStandingBids === 0;

  const actor = useCurrentActor();

  const copy = getIsBroker(actor)
    ? `broker_submit_standing_bid_to_notify_sellers`
    : `place_standing_bid_to_notify_sellers`;

  const buttonCopy = getIsBroker(actor)
    ? `submit_a_standing_bid`
    : `place_a_standing_bid`;

  return showStandingBidCTA ? (
    <Card w="full">
      <CardBody>
        <Flex direction="column" gap={5}>
          <Flex direction="column" gap={4}>
            <CompanyMarketActivitySellersBadge
              variant="rounded-olive"
              company={company}
            />
            <Text>
              <>
                {t(`potential_sellers_but_no_listings`, {
                  count: numberOfSellers,
                  companyName: company.name,
                })}
                {` `}
                <Trans
                  i18nKey={copy}
                  values={{ count: numberOfSellers }}
                  components={{
                    bold: <Text as="span" textStyle="heading-md" />,
                  }}
                />
              </>
            </Text>
          </Flex>
          <HiiveButton
            flex="none"
            p={0}
            maxWidth="fit-content"
            variant="text-salmon"
            onClick={() => onOpenModal(modals.placeStandingBid(company))()}
            observabilityLabel="[ListingEmptyState] Place Bid CTA"
          >
            {t(buttonCopy)}
          </HiiveButton>
        </Flex>
      </CardBody>
    </Card>
  ) : (
    <ActivityGroupEmptyState
      message={companyT(`no_active_listings_orderbook`, {
        companyName: company.name,
      })}
    />
  );
};

export const ListingsEmptyState = ({
  company,
  numberOfStandingBids,
}: {
  readonly company: CompanyPageMarketActivityCompanyFragment;
  readonly numberOfStandingBids: number;
}) => (
  <EmptyState company={company} numberOfStandingBids={numberOfStandingBids} />
);
