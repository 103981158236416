import {
  CompanyPageMarketActivityCompanyFragment,
  CompanyPageMarketActivityListingFragment,
  CompanyPageMarketActivityStandingBidFragment,
} from "@/gql";
import { useAnalytics, useCurrentActor } from "@/hooks";

type MarketActivityContentPostingsLengths<T = unknown[]> = {
  truncated: T;
  remaining: T;
};

export function getTotalPostingsCount({
  truncated: { length: truncatedLength },
  remaining: { length: remainingLength },
}: MarketActivityContentPostingsLengths) {
  return truncatedLength + remainingLength;
}

export function getLastTruncatedListingPricePerShare(
  truncatedListings: CompanyPageMarketActivityListingFragment[],
) {
  const { length: truncatedListingsLength } = truncatedListings;

  if (truncatedListingsLength < 1) {
    return null;
  }

  const { listingPricePerShare: lastTruncatedListingPricePerShare } =
    truncatedListings[truncatedListingsLength - 1];

  return lastTruncatedListingPricePerShare;
}

export function getLastTruncatedStandingBidPricePerShare(
  truncatedStandingBids: CompanyPageMarketActivityStandingBidFragment[],
) {
  const { length: truncatedStandingBidsLength } = truncatedStandingBids;

  if (truncatedStandingBidsLength < 1) {
    return null;
  }

  const { pricePerShare: lastTruncatedStandingBidPricePerShare } =
    truncatedStandingBids[truncatedStandingBidsLength - 1];

  return lastTruncatedStandingBidPricePerShare;
}

export function useTrackViewMorePostings() {
  const { track } = useAnalytics();
  const { id: userId, email, investorType } = useCurrentActor();

  return (title: string, company: CompanyPageMarketActivityCompanyFragment) => {
    const { id: companyId, name: companyName } = company;

    track(title, {
      userId,
      email,
      investorType,
      companyId,
      companyName,
    });
  };
}
