import { VStack } from "@chakra-ui/react";

import { FeeDiscountAlert, WithQuery } from "@/components/common";
import {
  CompanyActivityCompanyFragment,
  useCompanyPageActivityCompanyByIdQuery,
} from "@/gql";

import { CompanyActionsContent } from "./CompanyActions/CompanyActions";
import { CompanyActivitySkeleton } from "./CompanyActivitySkeleton";
import { MarketActivityContent } from "./MarketActivity/MarketActivityContent";
import { YourActivityContent } from "./YourActivity";
import useFeeDiscountAlert from "./useFeeDiscountAlert";

interface CompanyActivityContentProps {
  readonly company: CompanyActivityCompanyFragment;
}

function CompanyActivityContent({ company }: CompanyActivityContentProps) {
  const { feeDiscount, feeDiscountAlertType } = useFeeDiscountAlert({
    company,
  });

  return (
    <VStack alignItems="flex-start" spacing={8}>
      {feeDiscountAlertType && (
        <FeeDiscountAlert
          companyName={company.name}
          feeDiscount={feeDiscount}
          type={feeDiscountAlertType}
        />
      )}
      <CompanyActionsContent company={company} />
      <YourActivityContent company={company} />
      <MarketActivityContent company={company} />
    </VStack>
  );
}

export const CompanyActivity = ({
  companyId,
}: {
  readonly companyId: string;
}) => {
  const query = useCompanyPageActivityCompanyByIdQuery({
    variables: {
      id: companyId,
    },
    fetchPolicy: `network-only`,
  });

  return (
    <WithQuery query={query} fallback={<CompanyActivitySkeleton />}>
      {({ data: { companyById: company } }) => (
        <CompanyActivityContent company={company} />
      )}
    </WithQuery>
  );
};
