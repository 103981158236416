import { useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { Button, Card, HStack, Text, VStack, Flex } from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  EntityType,
  ExecutionCollectEntityTaskData,
  ExecutionTaskStatus,
  TransactingEntityItemEntityFragment,
  TransactionExecutionPageTaskFragment,
  UserWithInstitutionFragment,
  InstitutionEntityType,
  TransactionExecutionPageTransactionByIdDocument,
  useCompleteExecutionTaskMutation,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";
import {
  useCountryList,
  useModal,
  useMutationWithError,
  useTransactionDetails,
  useTranslatedEntityNames,
} from "@/hooks";
import useTransactionExecution from "@/hooks/useTransactionExecution";

import CompletedEntityCard from "./CompletedEntityCard";
import EntityCollectionHiiveTaskCard from "./EntityCollectionHiiveTaskCard";
import { EntitySelector, EntitySelectorItem } from "./EntitySelector";

const institutionTypeToEntityType = (
  institutionType: InstitutionEntityType,
): EntityType =>
  match(institutionType)
    .with(InstitutionEntityType.Corporation, () => EntityType.Corporation)
    .with(InstitutionEntityType.Llc, () => EntityType.Llc)
    .with(InstitutionEntityType.Partnership, () => EntityType.Partnership)
    .otherwise(() => EntityType.Other);

const SuggestedNoEntitySubCard = ({
  actor,
}: {
  actor: UserWithInstitutionFragment;
}) => {
  const { getFormattedEntityName } = useTranslatedEntityNames();
  const countryList = useCountryList();
  const name = actor.institutionId
    ? actor.institution?.legalName
    : `${actor.firstName} ${actor.lastName}`;

  const countryName = actor.institutionId
    ? actor.institution?.country?.name
    : actor.country?.name;

  const entityType = !!actor.institution?.entityType
    ? institutionTypeToEntityType(actor.institution.entityType)
    : EntityType.Individual;

  return (
    <Card
      w="full"
      borderColor="grey.75"
      bg="grey.25"
      variant="flat"
      py={3}
      px={4}
    >
      <HStack color="grey.500" justify="space-between">
        <HStack>
          <Text textStyle="heading-xs">{name}</Text>
          <Text textStyle="text-sm">{countryList.getName(countryName)}</Text>
        </HStack>
        <Text textStyle="heading-xs">{getFormattedEntityName(entityType)}</Text>
      </HStack>
    </Card>
  );
};

const SingleEntitySubCard = ({
  entity,
}: {
  entity: TransactingEntityItemEntityFragment;
}) => (
  <Card w="full" variant="flat" py={3} px={4}>
    <EntitySelectorItem entity={entity} />
  </Card>
);

const EntitySelectorWrapper = ({
  availableEntities,
  actor,
  currentEntityId,
  onEntitySelected,
}: {
  readonly availableEntities: TransactingEntityItemEntityFragment[];
  actor: UserWithInstitutionFragment;
  currentEntityId?: TransactingEntityItemEntityFragment["id"] | null;
  onEntitySelected: (entity: TransactingEntityItemEntityFragment) => void;
}) => {
  if (availableEntities.length === 0) {
    return <SuggestedNoEntitySubCard actor={actor} />;
  }
  if (availableEntities.length === 1) {
    return <SingleEntitySubCard entity={availableEntities[0]} />;
  }
  return (
    <EntitySelector
      availableEntities={availableEntities}
      currentEntityId={currentEntityId}
      onEntitySelected={onEntitySelected}
    />
  );
};

const firstEntityParams = (actor: UserWithInstitutionFragment) =>
  match(actor)
    .with({ institution: null }, (actor) => ({
      legalName: `${actor.firstName} ${actor.lastName}`,
      type: EntityType.Individual,
      jurisdictionOfFormationId: actor.country?.id,
    }))
    .otherwise((actor) => ({
      legalName: actor.institution?.legalName,
      type:
        actor.institution?.entityType &&
        institutionTypeToEntityType(actor.institution?.entityType),
      otherType:
        actor.institution?.entityType &&
        institutionTypeToEntityType(actor.institution?.entityType) ===
          EntityType.Other
          ? `unknown`
          : undefined,
      jurisdictionOfFormationId: actor.institution?.country?.id,
    }));

const EntityCollectionTaskCard = withCurrentActor(
  ({
    task,
    data,
    actor,
  }: {
    readonly task: TransactionExecutionPageTaskFragment;
    readonly data: ExecutionCollectEntityTaskData;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const [completeTask, isLoading] = useMutationWithError(
      useCompleteExecutionTaskMutation({
        refetchQueries: [TransactionExecutionPageTransactionByIdDocument],
      }),
      `completeExecutionTask`,
    );

    const availableEntities = !!actor.institutionId
      ? actor.institution?.entities || []
      : actor.entities;

    const [selectedEntityId, setSelectedEntityId] = useState<string | null>(
      availableEntities.length === 1 ? availableEntities[0].id : null,
    );

    const handleComplete = () => {
      const collectEntityParams =
        availableEntities.length === 0
          ? firstEntityParams(actor)
          : {
              entityId: selectedEntityId,
            };

      completeTask({
        variables: {
          taskId: task.id,
          input: {
            collectEntity: collectEntityParams,
          },
        },
      });
    };

    const { onOpenModal, modals } = useModal();

    const { t } = useTranslation();
    const transaction = useTransactionExecution()!;
    const { isBuyer } = useTransactionDetails();

    const isCompletionDisabled =
      availableEntities.length !== 0 && !selectedEntityId;

    const completed = task.status === ExecutionTaskStatus.Completed;

    const { entity } = data;

    if (completed && !!entity) return <CompletedEntityCard entity={entity} />;

    return (
      <Flex direction="column" gap={{ base: 4, md: 6 }}>
        <Card variant="flat" border={0}>
          <VStack w="full" alignItems="flex-start">
            <HStack>
              <Text textStyle="heading-md">
                {t(`the_transacting_party`, { ns: `execution` })}
              </Text>
            </HStack>
            <EntitySelectorWrapper
              actor={actor}
              currentEntityId={selectedEntityId}
              availableEntities={availableEntities}
              onEntitySelected={(entity) => setSelectedEntityId(entity.id)}
            />
          </VStack>
        </Card>
        <HStack w="full" justifyContent="space-between" mt={2}>
          <Flex
            direction={{
              base: `column`,
              md: `row`,
            }}
            gap={{
              base: 0,
              md: 1,
            }}
          >
            <Text
              textStyle={{ base: `text-xs`, md: `text-md` }}
              color="grey.500"
            >
              {t(
                isBuyer(transaction)
                  ? `need_to_add_a_new_buying_entity`
                  : `need_to_add_a_new_selling_entity`,
                {
                  ns: `execution`,
                },
              )}
            </Text>
            <Button
              p={0}
              fontWeight={500}
              fontSize="inherit"
              variant="text-grey"
              onClick={onOpenModal(modals.newEntity(task))}
            >
              <Text
                textStyle={{ base: `text-xs`, md: `text-md` }}
                fontSize={{ base: 12, md: `md` }}
                fontWeight={{ base: `inherit`, md: 500 }}
              >
                {t(`entities.create_entity`, {
                  ns: `account`,
                })}
              </Text>
            </Button>
          </Flex>
          <Button
            onClick={handleComplete}
            variant="rounded-solid-grey"
            size={{ base: `md`, md: `xl` }}
            isLoading={isLoading}
            isDisabled={isCompletionDisabled}
          >
            {t(`confirm`)}
          </Button>
        </HStack>
      </Flex>
    );
  },
);

const EntityCollectionTaskCardWrapper = ({
  task,
  data,
  transaction,
}: {
  readonly task: TransactionExecutionPageTaskFragment;
  readonly data: ExecutionCollectEntityTaskData;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const { isBuyer } = useTransactionDetails();

  const isRepresented = isBuyer(transaction)
    ? transaction.buyer.isHiiveUser
    : transaction.seller.isHiiveUser;

  return isRepresented ? (
    <EntityCollectionHiiveTaskCard task={task} />
  ) : (
    <EntityCollectionTaskCard task={task} data={data} />
  );
};

export default EntityCollectionTaskCardWrapper;
