import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useRouter } from "next/router";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Fade,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import {
  ActivityCardBadge,
  ActivityCardDisplayId,
  ActivityCardFooterCountsWrapper,
  ActivityCardFooterStatusWrapper,
  ActivityCardFooterWrapper,
  ActivityCardNewBadge,
  ActivityCardPricingNumSharesAndPrice,
  ActivityCardPricingTotal,
  ActivityCardPricingWrapper,
  ActivityCardTransactionStatus,
  HiiveButton,
} from "@/components/common";
import { MessagesBadge } from "@/components/companies";
import {
  ActiveTransactionItemStateBadge,
  TaskCountBadge,
} from "@/components/dashboard";
import { withCurrentActor } from "@/components/hoc";
import { useUnaccreditedSellerInlineNotifications } from "@/components/unaccredited-seller";
import {
  DocumentType,
  ExecutionMilestone,
  PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment,
  SigningProcedure,
  TransactionPermission,
  UserWithInstitutionFragment,
} from "@/gql";
import { iHaveEntityPermission, useSyncHeight } from "@/hooks";
import {
  appendSellerCompanyIdToUrl,
  getIsUnaccreditedSeller,
  getLongDocumentTitleByDocumentType,
  getTransactionDisplayId,
  getTransactionExpirationDate,
  getTransactionTitle,
} from "@/utils";
import { makeTransactionDocumentPageUrl, makeUrl } from "@/utils/schema";

const SignDocumentAlert = withCurrentActor(
  ({
    pendingSale,
    actor,
  }: {
    readonly pendingSale: PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const router = useRouter();
    const { t } = useTranslation();

    const isUnaccreditedSeller = getIsUnaccreditedSeller(actor);

    if (!pendingSale.document) return null;

    const documentTitle = getLongDocumentTitleByDocumentType(
      pendingSale.document?.type,
    );
    const companyName = pendingSale.bid.company.name;
    const expirationDate = getTransactionExpirationDate(pendingSale);

    const onClick = () => {
      if (isUnaccreditedSeller) {
        router.push(
          appendSellerCompanyIdToUrl(
            makeTransactionDocumentPageUrl(pendingSale.bid.id, pendingSale.id),
            pendingSale.company.id,
          ),
        );
        return;
      }
      router.push(
        makeTransactionDocumentPageUrl(pendingSale.bid.id, pendingSale.id),
      );
    };

    const description =
      pendingSale.document.type === DocumentType.Loi
        ? t(`sign_to_begin_transfer_loi`, {
            companyName,
            documentTitle,
            expirationDate,
            action: t(`sale`),
          })
        : t(`sign_to_begin_transfer_stn`, {
            companyName,
            documentTitle,
            expirationDate,
            action: t(`sale`),
          });

    return (
      <Card variant="teal" w="full">
        <CardBody>
          <VStack textAlign="start" alignItems="flex-start">
            <Text textStyle="heading-md">
              {t(`awaiting_your_signature`, {
                ns: `dashboard`,
                documentTitle,
              })}
            </Text>
            <Text>{description}</Text>
            <HiiveButton
              onClick={onClick}
              variant="text-salmon"
              px={0}
              observabilityLabel="[SignDocumentAlert] Review"
            >
              {t(`review_and_sign`, {
                ns: `dashboard`,
              })}
            </HiiveButton>
          </VStack>
        </CardBody>
      </Card>
    );
  },
);

export const PendingSaleCard = ({
  pendingSale,
}: {
  readonly pendingSale: PostActivityUnaccreditedSellerDashboardByIdPageTransactionsPageTransactionFragment;
}) => {
  const router = useRouter();
  const { getIsNewAcceptedBid, getInquiryNumNewMessages } =
    useUnaccreditedSellerInlineNotifications();

  const {
    bid: { discussion },
  } = pendingSale;

  const numNewMessages = !!discussion
    ? getInquiryNumNewMessages(discussion)
    : 0;

  const totalNumMessages = !!discussion ? discussion.totalNumMessages : 0;

  const hasMessages = totalNumMessages > 0;

  const title = getTransactionTitle(pendingSale);
  const displayId = getTransactionDisplayId(pendingSale);

  const { numShares, pricePerShare } = pendingSale;

  const isAwaitingMySignature =
    iHaveEntityPermission(
      pendingSale,
      TransactionPermission.SignTransactionDocument,
    ) && pendingSale.signingProcedure !== SigningProcedure.Manual;

  const signDocumentAlertRef = useRef<HTMLDivElement>(null);
  const signDocumentAlertSpacerRef = useRef<HTMLDivElement>(null);
  useSyncHeight(signDocumentAlertRef, signDocumentAlertSpacerRef);

  const isNewAcceptedBid = getIsNewAcceptedBid(pendingSale.bid);

  const showFooterCounts = isNewAcceptedBid || hasMessages;
  const isTexasEnabled = pendingSale.texasEnabled;
  const taskCount = pendingSale.myTasks?.length;
  const showTasksBadge =
    isTexasEnabled &&
    pendingSale.workflow?.lastCompletedMilestone !==
      ExecutionMilestone.Complete;

  return (
    <Card
      variant="activity"
      as="button"
      onClick={() =>
        router.push(
          appendSellerCompanyIdToUrl(
            makeUrl(pendingSale),
            pendingSale.company.id,
          ),
        )
      }
    >
      <CardHeader>
        <Flex alignItems="center" gap={2}>
          <ActivityCardBadge variant="transaction" title={title} />
          <ActivityCardDisplayId displayId={displayId} />
        </Flex>
      </CardHeader>
      <CardBody>
        <ActivityCardPricingWrapper>
          <ActivityCardPricingNumSharesAndPrice
            numShares={numShares}
            price={pricePerShare}
          />
          <ActivityCardPricingTotal
            numShares={numShares}
            price={pricePerShare}
          />
        </ActivityCardPricingWrapper>
      </CardBody>
      <CardFooter {...(isAwaitingMySignature && !isTexasEnabled && { pb: 4 })}>
        <VStack spacing={2} w="full" alignItems="flex-start">
          <ActivityCardFooterWrapper py={0}>
            <ActivityCardFooterStatusWrapper>
              {isTexasEnabled && (
                <ActiveTransactionItemStateBadge transaction={pendingSale} />
              )}
              {!isTexasEnabled && (
                <ActivityCardTransactionStatus
                  transaction={pendingSale}
                  company={pendingSale.bid.company}
                  bid={pendingSale.bid}
                />
              )}
            </ActivityCardFooterStatusWrapper>
            <HStack>
              {!showTasksBadge && showFooterCounts && (
                <ActivityCardFooterCountsWrapper>
                  <HStack>
                    {hasMessages && (
                      <MessagesBadge
                        numUnreadMessages={numNewMessages}
                        totalNumMessages={totalNumMessages}
                      />
                    )}
                    {isNewAcceptedBid && (
                      <Fade in>
                        <ActivityCardNewBadge />
                      </Fade>
                    )}
                  </HStack>
                </ActivityCardFooterCountsWrapper>
              )}
              {showTasksBadge && (
                <TaskCountBadge showCount={false} taskCount={taskCount} />
              )}
            </HStack>
          </ActivityCardFooterWrapper>
          {isAwaitingMySignature && !isTexasEnabled && (
            <SignDocumentAlert pendingSale={pendingSale} />
          )}
        </VStack>
      </CardFooter>
    </Card>
  );
};
