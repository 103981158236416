import { useApolloClient } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useLDClient } from "launchdarkly-react-client-sdk";
import queryString from "query-string";
import { useCallback, useContext } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";

import { useRouter } from "next/router";

import { FrontChatContext } from "@/components/providers";
import { reset as resetAuth } from "@/state/auth";
import { reset as resetPersona } from "@/state/persona";
import { getIsValidRedirectURL, ROUTES, constants } from "@/utils";

const { isAuth0Enabled } = constants;

export default function useSignOut() {
  const dispatch = useDispatch();
  const router = useRouter();
  const client = useApolloClient();
  const ldClient = useLDClient();
  const { ensureFrontChatIsShutdown } = useContext(FrontChatContext);

  return useCallback(
    ({
      signOutTo,
      redirectPath,
    }: { signOutTo?: string; redirectPath?: string } = {}) => {
      if (ensureFrontChatIsShutdown) {
        ensureFrontChatIsShutdown();
      }

      const loginPath = signOutTo || ROUTES.DEFAULT_LOGIN;

      const path =
        redirectPath && getIsValidRedirectURL(redirectPath)
          ? `${loginPath}?${queryString.stringify({ redirect: redirectPath })}`
          : loginPath;

      router.prefetch(path);

      ReactDOM.unstable_batchedUpdates(async () => {
        await client.clearStore();
        await ldClient?.identify({
          key: undefined,
          anonymous: true,
        });
        dispatch(resetPersona());
        sessionStorage.clear();
        datadogRum.clearUser();

        if (isAuth0Enabled) {
          return router.push(ROUTES.AUTH0_LOGOUT);
        }

        dispatch(resetAuth());
        return router.push(path);
      });
    },
    [dispatch, ensureFrontChatIsShutdown],
  );
}
