import { isEmpty } from "lodash";
import { useEffect } from "react";

import { useRouter } from "next/router";

import { canAccessPlatform } from "@/hooks";
import { ROUTES } from "@/utils";

import { AuthenticatedLayoutHookProps } from "./types";
import { useIsOnboarding, useVerifyIdentitySubscription } from "./utils";

// functionality is to check if user has been sent to reonboard/ hasn't completed onboarding
// listens to personia verification result where applicable
export const useCheckOnboarding = ({ actor }: AuthenticatedLayoutHookProps) => {
  useVerifyIdentitySubscription({ actor });
  const isOnboarding = useIsOnboarding();
  const hasActor = !isEmpty(actor);
  const { push } = useRouter();

  const shouldRedirectToOnboarding =
    hasActor &&
    !isOnboarding &&
    (!canAccessPlatform(actor) ||
      actor.institution?.onboarding.reonboardingStartedAt);

  useEffect(() => {
    if (shouldRedirectToOnboarding) {
      push(ROUTES.WELCOME);
    }
  }, [shouldRedirectToOnboarding, push]);

  return { redirectingToOnboarding: shouldRedirectToOnboarding };
};
