/* eslint-disable @typescript-eslint/no-non-null-assertion */
import isNil from "lodash/isNil";
import { match, P } from "ts-pattern";

import { ShareSeriesMakeupElement } from "@/components/postings";
import {
  BidState,
  DiscussionState,
  GetBidNumSharesActualBidFragment,
  GetListingNumOfSharesListingFragment,
  GetNumOfSharesBidFragment,
  GetNumOfSharesListingFragment,
  GetNumOfSharesStandingBidFragment,
  GetNumSharesAvailableRoundedListingFragment,
  GetPostingTitleBidFragment,
  GetPostingTitleDiscussionFragment,
  GetPostingTitleListingFragment,
  GetPostingTitleStandingBidFragment,
  GetPricePerShareBidFragment,
  GetPricePerShareListingFragment,
  GetPricePerShareStandingBidFragment,
  GetStandingBidLotStandingBidFragment,
  GetTransactionIsPurchaseTransactionFragment,
  GetTransactionIsPurchaseUserFragment,
  ListingState,
  MakeUrlBidFragment,
  MakeUrlCompanyFragment,
  MakeUrlDiscussionFragment,
  MakeUrlListingFragment,
  MakeUrlStandingBidFragment,
  MakeUrlTransactionExecutionFragment,
  MakeUrlTransactionFragment,
  StandingBidState,
} from "@/gql";
import { Nullable } from "@/utils";

import { lot } from "./translate";

export const getPricePerShare = (
  bidOrListing:
    | GetPricePerShareListingFragment
    | GetPricePerShareBidFragment
    | GetPricePerShareStandingBidFragment,
  showCounter = false,
): number | null | undefined =>
  match(bidOrListing)
    .with(
      { __typename: `Listing`, listingPricePerShare: P._ },
      ({ listingPricePerShare }) => listingPricePerShare,
    )
    .with(
      { __typename: `StandingBid`, pricePerShare: P._ },
      ({ pricePerShare }) => pricePerShare,
    )
    .with(
      { __typename: `Bid`, pricePerShare: P._ },
      ({ pricePerShare, counterPricePerShare }) =>
        showCounter && !isNil(counterPricePerShare)
          ? counterPricePerShare
          : pricePerShare,
    )
    .otherwise((value) => {
      throw new Error(`Unknown value: ${value}`);
    });

export const getNumOfShares = (
  order:
    | GetNumOfSharesListingFragment
    | GetNumOfSharesBidFragment
    | GetNumOfSharesStandingBidFragment,
  rounded = false,
  showCounter = false,
) =>
  match(order)
    .with(
      {
        __typename: `Listing`,
        numSharesOriginal: P._,
        state: P.when(
          (state) =>
            state === ListingState.Closed ||
            state === ListingState.ConditionallySold,
        ),
      },
      ({ numSharesOriginal, numSharesOriginalRounded }) =>
        rounded ? numSharesOriginalRounded : numSharesOriginal,
    )
    .with(
      { __typename: `Listing`, numSharesAvailable: P._ },
      ({ numSharesAvailable, numSharesAvailableRounded }) =>
        rounded ? numSharesAvailableRounded : numSharesAvailable,
    )
    .with(
      {
        __typename: `StandingBid`,
        numSharesOriginal: P._,
        state: P.when(
          (state) =>
            state === StandingBidState.Closed ||
            state === StandingBidState.ConditionallyCompleted,
        ),
      },
      ({ numSharesOriginal }) => numSharesOriginal,
    )
    .with(
      { __typename: `StandingBid`, numSharesAvailable: P._ },
      ({ numSharesAvailable }) => numSharesAvailable,
    )
    .with(
      {
        __typename: `Bid`,
        state: BidState.Countered,
        numShares: P._,
        counterNumShares: null,
      },
      ({ numShares, numSharesActual }) =>
        showCounter && !rounded ? numSharesActual : numShares,
    )
    .with(
      {
        __typename: `Bid`,
        state: BidState.Countered,
        numShares: P._,
        counterNumShares: P._,
      },
      ({ numShares, counterNumShares }) =>
        showCounter ? counterNumShares : numShares,
    )
    .with(
      { __typename: `Bid`, numShares: P._, numSharesActual: 0 },
      ({ numShares }) => numShares,
    )
    .with(
      { __typename: `Bid`, numShares: P._, numSharesActual: P._ },
      ({ numSharesActual }) => numSharesActual,
    )
    .with(P._, () => {
      throw new Error(`Unknown posting type`);
    })
    .exhaustive();

const discussionTopic = (discussion: MakeUrlDiscussionFragment) =>
  ((discussion.listingTopic as MakeUrlListingFragment).id
    ? discussion.listingTopic
    : discussion.standingBidTopic) as
    | MakeUrlStandingBidFragment
    | MakeUrlListingFragment;

export const makeTransactionDocumentPageUrl = (
  bidId: string,
  transactionId: string,
) => `/listings/bids/${bidId}/document/${transactionId}`;

export const makeUrl = (
  item:
    | MakeUrlTransactionFragment
    | MakeUrlTransactionExecutionFragment
    | MakeUrlBidFragment
    | MakeUrlListingFragment
    | MakeUrlStandingBidFragment
    | MakeUrlCompanyFragment
    | MakeUrlDiscussionFragment,
): string =>
  match(item)
    .with(
      { __typename: `Listing`, id: P._ },
      (listing) => `/listings/${listing.id}`,
    )
    .with(
      { __typename: `StandingBid`, id: P._ },
      (standingBid) => `/standing-bids/${standingBid.id}`,
    )
    .with(
      { __typename: `Transaction`, id: P._, texasEnabled: true },
      (transaction) => `/transactions/${transaction.id}/tasks`,
    )
    .with(
      { __typename: `Transaction`, id: P._ },
      (transaction) => `/transactions/${transaction.id}`,
    )
    .with({ __typename: `Bid`, id: P._ }, (bid) => `/listings/bids/${bid.id}`)
    .with(
      { __typename: `Discussion`, id: P._, state: DiscussionState.Active },
      (discussion) => `/discussions/${discussion.id}`,
    )
    .with(
      {
        __typename: `Discussion`,
        state: DiscussionState.Pending,
        standingBidTopic: P._,
        listingTopic: P._,
      },
      (discussion: MakeUrlDiscussionFragment) =>
        makeUrl(discussionTopic(discussion)),
    )
    .with(
      {
        __typename: `Discussion`,
        state: DiscussionState.Closed,
        topic: P._,
      },
      (discussion: MakeUrlDiscussionFragment) =>
        `/companies/${discussionTopic(discussion).companyId}`,
    )
    .with(
      { __typename: `Company`, id: P._ },
      (company) => `/companies/${company.id}`,
    )
    .with(P.nullish, () => {
      throw new Error(`nullish value passed to makeUrl`);
    })
    .otherwise((item) => {
      throw new Error(
        `Unhandled item type on makeUrl: ${JSON.stringify(item)}`,
      );
    });

export const getPostingTitle = (
  posting:
    | GetPostingTitleListingFragment
    | GetPostingTitleBidFragment
    | GetPostingTitleStandingBidFragment
    | GetPostingTitleDiscussionFragment,
) =>
  match(posting)
    .with({ __typename: `StandingBid` }, (_) => `Standing Bid`)
    .with({ __typename: `Listing` }, (_) => `Listing`)
    .with({ __typename: `Bid` }, (_) => `Bid`)
    .with({ __typename: `Discussion` }, (_) => `Discussion`)
    .otherwise(() => {
      throw new Error(
        `Unhandled posting in getPostingTitle: ${JSON.stringify(posting)}`,
      );
    });

export const getTransactionIsPurchase = (
  actor: GetTransactionIsPurchaseUserFragment,
  transaction: GetTransactionIsPurchaseTransactionFragment,
) =>
  transaction.bid.buyerId === actor.id ||
  (!!actor.institutionId &&
    actor.institutionId === transaction.bid.buyerInstitutionId);

export const getStandingBidLot = (
  standingBid: GetStandingBidLotStandingBidFragment,
) => {
  const numShares = getNumOfShares(standingBid);
  if (isNil(numShares)) {
    throw new Error(`Unknown number of shares in getStandingBidLot`);
  }

  return lot(numShares, standingBid.pricePerShare);
};

/**
 * @deprecated use getBidNumSharesActual in `/utils/bid/bid.ts`
 */
export const getBidNumSharesActual = (bid: GetBidNumSharesActualBidFragment) =>
  bid.actualNumSharesVisible ? bid.numSharesActual : bid.numShares;

export const getNumSharesAvailableRounded = (
  listing: GetNumSharesAvailableRoundedListingFragment,
) => listing.numSharesAvailableRounded;

export const getListingNumOfShares = (
  listing: GetListingNumOfSharesListingFragment,
  rounded = true,
) =>
  match(listing)
    .with(
      {
        numSharesOriginal: P._,
        state: P.when(
          (state) =>
            state === ListingState.Closed ||
            state === ListingState.ConditionallySold,
        ),
      },
      ({ numSharesOriginal, numSharesOriginalRounded }) =>
        rounded ? numSharesOriginalRounded : numSharesOriginal,
    )
    .with(
      { numSharesAvailable: P._ },
      ({ numSharesAvailable, numSharesAvailableRounded }) =>
        rounded ? numSharesAvailableRounded : numSharesAvailable,
    )
    .with(P._, () => {
      throw new Error(`Unknown listing number of shares`);
    })
    .exhaustive();

export const sumShareSeriesMakeup = (
  shareSeriesMakeup: readonly Nullable<ShareSeriesMakeupElement>[],
) =>
  shareSeriesMakeup.reduce(
    (prev, { numShares }) => (!!numShares ? numShares + prev : prev),
    0,
  );

export const appendSellerCompanyIdToUrl = (
  url: string,
  sellerCompanyId: string,
) => `${url}?sellerCompanyId=${sellerCompanyId}`;
