import { Trans, useTranslation } from "react-i18next";

import { VStack, Text } from "@chakra-ui/react";

import { MailtoLink, PostingCard } from "@/components/common";
import { ListingPostingCardBrokerBodyFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import {
  getSecuritySpecialistContact,
  getShowListingSolicitedState,
} from "@/utils";

import { ListingPostingCardBodyAcceptedShares } from "./ListingPostingCardBodyAcceptedShares";
import { ListingPostingCardBodyPricing } from "./ListingPostingCardBodyPricing";

type ListingPostingCardBrokerBodyProps = {
  listing: ListingPostingCardBrokerBodyFragment;
};

function ListingPostingCardBrokerSolicitedBody({
  listing: { company },
}: ListingPostingCardBrokerBodyProps) {
  const { t } = useTranslation();
  const email = getSecuritySpecialistContact(company);

  return (
    <PostingCard.Body>
      <VStack spacing={0}>
        <Text textStyle="heading-md" w="full">
          {t(`solicited_listing_activity_card_body`)}
        </Text>
        <Text w="full">
          <Trans
            i18nKey="solicited_listing_activity_card_contact"
            t={t}
            components={[
              <MailtoLink
                key="contact"
                textDecoration="underline"
                email={email}
                subject="Broker Interest in Solicited Listing"
              />,
            ]}
            values={{ email }}
          />
        </Text>
      </VStack>
    </PostingCard.Body>
  );
}

function ListingPostingCardBrokerUnsolicitedBody({
  listing,
}: ListingPostingCardBrokerBodyProps) {
  return (
    <PostingCard.Body>
      <ListingPostingCardBodyPricing listing={listing} />
      <ListingPostingCardBodyAcceptedShares listing={listing} />
    </PostingCard.Body>
  );
}

export function ListingPostingCardBrokerBody({
  listing,
}: ListingPostingCardBrokerBodyProps) {
  const actor = useCurrentActor();
  const isSolicited = getShowListingSolicitedState(listing, actor);

  if (isSolicited) {
    return <ListingPostingCardBrokerSolicitedBody listing={listing} />;
  }

  return <ListingPostingCardBrokerUnsolicitedBody listing={listing} />;
}
