import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FocusLock } from "@chakra-ui/focus-lock";
import { css, HStack, VStack } from "@chakra-ui/react";

import { InternalLink, Logo, TabProps } from "@/components/common";
import { withCurrentActor } from "@/components/hoc";
import { NotificationMenu } from "@/components/nav";
import { InvestorType, UserWithInstitutionFragment } from "@/gql";
import { useLockBodyScroll } from "@/hooks";
import { hasInvestorType } from "@/utils";
import config from "@/utils/constants";

import { AccreditedNavMenu } from "./AccreditedNavMenu";
import { MobileNavBarFooter } from "./MobileNavBarFooter";
import { MobileNavBarWrapper } from "./MobileNavBarWrapper";
import { NavMenuLink } from "./NavMenuLink";
import { SearchButton } from "./SearchButton";
import { UnaccreditedSellerNavFooter } from "./UnaccreditedSellerNavFooter";
import { XButton } from "./XButton";
import { TabKeys } from "./tabs";

export const NavigationTab = withCurrentActor(
  ({
    tabRouter: { tabControls },
    actor,
  }: { readonly actor: UserWithInstitutionFragment } & TabProps<TabKeys>) => {
    const { t } = useTranslation();
    const handleClick = () => tabControls.jumpToTab(`index`);
    const { onLock, onUnlock } = useLockBodyScroll();

    const backofficeUrl =
      config.back_office_url[process.env.NEXT_PUBLIC_INFRA_ENV || `local`];

    const isSeller = hasInvestorType(actor, InvestorType.Seller);
    const isTrader = hasInvestorType(actor, InvestorType.Trader);
    const isBroker = hasInvestorType(actor, InvestorType.Broker);

    const isUnaccreditedSeller = hasInvestorType(
      actor,
      InvestorType.UnaccreditedSeller,
    );

    useEffect(() => {
      onLock();
      return () => {
        onUnlock();
      };
    }, []);

    return (
      <FocusLock autoFocus>
        <VStack w="100vw" h="100vh" bg="white">
          <VStack
            css={css`
              min-height: -webkit-fill-available;
            `}
            w="100vw"
            position="absolute"
          >
            <MobileNavBarWrapper>
              <HStack spacing={0} gap={4}>
                <XButton onClick={() => tabControls.jumpToTab(`index`)} />
                {isBroker ? (
                  <InternalLink
                    href="/dashboard"
                    aria-label={t(`hiive_connect`)}
                  >
                    <Logo.ConnectGrey />
                  </InternalLink>
                ) : (
                  <InternalLink href="/dashboard" aria-label={t(`hiive`)}>
                    <Logo.Grey />
                  </InternalLink>
                )}
              </HStack>
              <HStack spacing={0} gap={4}>
                <NotificationMenu />
                {(isSeller || isTrader || isBroker) && (
                  <SearchButton
                    onClick={() => tabControls.jumpToTab(`search`)}
                  />
                )}
              </HStack>
            </MobileNavBarWrapper>
            <VStack
              w="full"
              spacing={0}
              justifyContent="space-between"
              alignItems="flex-start"
              flex="1"
              pb={5}
            >
              <VStack spacing={5} alignItems="flex-start" w="full">
                <VStack spacing={0} w="full">
                  {(isSeller || isTrader || isBroker) && (
                    <AccreditedNavMenu onClick={handleClick} />
                  )}
                </VStack>
                {actor.isSuperadmin && (
                  <VStack spacing={0} alignItems="flex-start" w="full">
                    <NavMenuLink onClick={handleClick} href={backofficeUrl}>
                      {t(`admin`)}
                    </NavMenuLink>
                  </VStack>
                )}
              </VStack>
              {isUnaccreditedSeller ? (
                <UnaccreditedSellerNavFooter onClick={handleClick} />
              ) : (
                <MobileNavBarFooter onClick={handleClick} />
              )}
            </VStack>
          </VStack>
        </VStack>
      </FocusLock>
    );
  },
);
