import { t } from "i18next";

import { Text, VStack } from "@chakra-ui/react";

import { useIsDesktop } from "@/hooks";
import { formatCurrency } from "@/utils";

type GrossProceedsProps = {
  counterpartyName: string;
  grossProceeds: number;
  isSellSide: boolean;
  netProceeds: number;
};

const GrossProceeds = ({
  counterpartyName,
  grossProceeds,
  isSellSide,
  netProceeds,
}: GrossProceedsProps) => {
  const isDesktop = useIsDesktop();
  const formattedNetProceeds = formatCurrency(netProceeds, {
    fromCents: true,
  });
  const formattedGrossProceeds = formatCurrency(grossProceeds, {
    fromCents: true,
  });

  return (
    <VStack
      gap={1}
      alignItems={isDesktop ? `flex-end` : `flex-start`}
      spacing={0}
    >
      <Text textStyle="heading-xl">
        {isSellSide ? formattedNetProceeds : formattedGrossProceeds}
      </Text>
      <Text textStyle="text-xs" color="grey.500">
        {t(`transaction_with`, { counterpartyName })}
      </Text>
    </VStack>
  );
};

export default GrossProceeds;
