import currency from "currency.js";
import { useTranslation } from "react-i18next";

import {
  CardHeader,
  HStack,
  VStack,
  Badge,
  Text,
  Divider,
} from "@chakra-ui/react";

import * as datetime from "@/utils/datetime";

import { WireInformationCardProps } from "./WireInformationCard";

type AmountDueCardHeaderProps = Pick<
  WireInformationCardProps,
  "amount" | "completedAt"
>;

const AmountDueCardHeader = ({
  amount,
  completedAt,
}: AmountDueCardHeaderProps) => {
  const { t } = useTranslation(`execution`);

  return (
    <>
      <CardHeader py={5} px={4} border="none">
        <HStack w="full" justifyContent="space-between">
          <VStack gap={1} alignItems="start">
            <Text color="grey.400" fontSize={12} fontWeight={500}>
              {t(`amount_due`)}
            </Text>
            <Text color="grey.900" textStyle="heading-xl">
              {currency(amount / 100).format()}
            </Text>
          </VStack>
          {!completedAt && (
            <Badge variant="status-pending">
              <Text textStyle="text-sm" lineHeight="unset" color="teal.900">
                {t(`pending`)}
              </Text>
            </Badge>
          )}
          {completedAt && (
            <Text textStyle="text-xs">
              {t(`completed_date`, {
                completedAt: datetime.format(`Do MMMM, YYYY`, completedAt),
              })}
            </Text>
          )}
        </HStack>
      </CardHeader>
      <Divider borderColor="grey.100" />
    </>
  );
};

export default AmountDueCardHeader;
