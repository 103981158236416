import React from "react";

import { Box } from "@chakra-ui/react";

import { ExecutionFileCard } from "@/components/transactions";
import {
  ExecutionWorkflowActionType,
  useExecutionWorkflowActionMutation,
  TransactionExecutionPageTransactionFragment,
} from "@/gql";
import { useMutationWithError } from "@/hooks";

const AnvilDownloadTaskCard = ({
  completedFileUploadId,
  transaction,
}: {
  readonly completedFileUploadId: string;
  readonly transaction: TransactionExecutionPageTransactionFragment;
}) => {
  const [executionWorkflowAction] = useMutationWithError(
    useExecutionWorkflowActionMutation(),
    `executionWorkflowAction`,
  );

  const handleDownload = async (fileUploadId: string) => {
    if (!transaction?.workflow?.id) return;

    const response = await executionWorkflowAction({
      variables: {
        id: transaction?.workflow.id,
        type: ExecutionWorkflowActionType.GenerateFileDownloadUrl,
        input: {
          generateFileDownloadUrl: {
            fileUploadId,
          },
        },
      },
    });

    const result = response?.executionWorkflowAction?.result;
    if (
      result?.__typename === `ExecutionWorkflowActionGenerateFileDownloadUrl` &&
      result.url
    ) {
      window.open(result.url, `_blank`);
    }
  };

  const fileUpload = transaction.fileUploads.find(
    (fileUpload) => fileUpload.id === completedFileUploadId,
  );

  if (!fileUpload) return null;

  return (
    <Box w="50%">
      <ExecutionFileCard file={fileUpload} onDownload={handleDownload} />
    </Box>
  );
};

export default AnvilDownloadTaskCard;
