import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PostingCard } from "@/components/common";
import { StandingBidPostingCardHeaderFragment } from "@/gql";
import { useIsDesktop } from "@/hooks";
import {
  acceptsAllTransferMethods,
  acceptsSharesToString,
  gqlTransferMethodOptionsToTemp,
} from "@/utils";
import { standingBidAcceptsTransferMethodOptionsToString } from "@/utils/translate";

type StandingBidPostingCardHeaderProps = {
  standingBid: StandingBidPostingCardHeaderFragment;
};

export function StandingBidPostingCardHeader({
  standingBid: { displayId, acceptsTransferMethods, acceptsShares },
}: StandingBidPostingCardHeaderProps) {
  const { t } = useTranslation(`standingBids`);
  const isDesktop = useIsDesktop();

  const shareTypeText = acceptsSharesToString(acceptsShares);

  const transferMethodText = useMemo(() => {
    const canAcceptAllTransferMethods = acceptsAllTransferMethods(
      acceptsTransferMethods,
    );

    if (canAcceptAllTransferMethods) {
      return t(`any`);
    }

    return gqlTransferMethodOptionsToTemp(acceptsTransferMethods)
      .map((shares) => standingBidAcceptsTransferMethodOptionsToString(shares))
      .join(`, `);
  }, [t, acceptsTransferMethods]);

  return (
    <PostingCard.Header
      title={
        <>
          <PostingCard.Label variant="standing-bid">
            {t(`standing_bid`)}
          </PostingCard.Label>
          {isDesktop && (
            <PostingCard.HeaderTitle>{displayId}</PostingCard.HeaderTitle>
          )}
        </>
      }
    >
      <PostingCard.Tile title={t(`transfer_type`)}>
        {transferMethodText}
      </PostingCard.Tile>
      <PostingCard.Tile title={t(`share_type`)}>
        {shareTypeText}
      </PostingCard.Tile>
    </PostingCard.Header>
  );
}
