export default {
  not_tradeable_sell_side: `To sell your {{company}} shares, contact <mailto>{{email}}</mailto>.`,
  company_standing_bid_not_enabled: `You can place bids on any current {{company}} listings.`,
  submit_a_listing: `Submit a Listing`,
  submit_listing_cta: `Submit a listing to sell your customer's shares. Your listing may be posted on the Hiive marketplace, enabling buyers to bid on it.`,
  list_for_sale: `List for Sale`,
  create_listing_cta: `Create a listing for shares you have the ability to sell, enabling buyers to bid directly on your listing.`,
  sell: `Sell`,
  listing_sold_at_range: `{{numShares}} {{listingFraction}} sold from <b>{{minPps}}/{{listingFractionAbbreviation}}<b/> to <b>{{maxPps}}/{{listingFractionAbbreviation}}</b>`,
  listing_sold_at: `{{numShares}} {{listingFraction}} sold at <b>{{pps}}/{{listingFractionAbbreviation}}</b>`,
  this_fund_is_organized_by_hiive: `This fund is organized by Hiive`,
  listing: `Listing`,
  your_inquiry: `Your inquiry on this listing`,
  transfer_method: `Transfer type`,
  share_type: `Share type`,
  placed_timestamp: `Placed {{date}}`,
  were_reviewing_your_listing: `We’re reviewing your {{ companyName }} listing!`,
  were_reviewing_your_listing_subheading: `{{ displayId }}: {{ numShares }} @ {{ pricePerShare }}/sh`,
  currently_reviewing_your_listing: `We’re currently reviewing your listing and will let you know when it’s live on the platform. A member of our team will reach out to you if we need more information.`,
  contact_company_security_specialist: `Contact {{ companyName }} Security Specialist`,
  to_support_your_listing: `For support with your listing, contact <0>{{ securitySpecialistEmail }}</0>`,
  okay: `Okay`,
  open_bids: `Open Bids`,
  past_bids: `Past Bids`,
  listing_made: `Listing made!`,
  listing_is_now_live_on_hiive: `Your {{ companyName }} listing for {{ numShares }} shares at {{ pricePerShare }}/sh is now live on Hiive!`,
  u16r_listing_modal_body: `We will notify potential buyers of your listing. Buyers can place a bid or request a discussion with you about the listing. You will receive notifications of any buyer interest.`,
  reach_out_to_market_analyst: `You can also reach out anytime to <0>{{ analystEmail }}</0> for assistance.`,
  contact_market_analyst: `Contact {{ companyName }} Specialist`,
  transfer_type_label: `{{ index }}. Transfer Type`,
};
