import { Flex, Text, FlexProps } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";

import { useCountryList, useTransactionDetails } from "@/hooks";
import useTransactionExecution from "@/hooks/useTransactionExecution";

type CounterpartyDetailsProps = FlexProps;

const CounterpartyDetails = ({ ...props }: CounterpartyDetailsProps) => {
  const transaction = useTransactionExecution();
  const { isBuyer } = useTransactionDetails();
  const { getName } = useCountryList();

  const counterParty = isBuyer(transaction!)
    ? transaction?.seller
    : transaction?.buyer;

  return (
    <Flex
      flexDirection={{ base: `column`, md: `row` }}
      textStyle="text-sm"
      gap={1}
      {...props}
    >
      <Text as={chakra.span}>
        {counterParty?.name}
        {counterParty?.country?.name &&
          `, ${getName(counterParty?.country?.name)}`}
      </Text>
    </Flex>
  );
};

export default CounterpartyDetails;
