import { match } from "ts-pattern";

import { PostingCard } from "@/components/common";
import { InvestorType, ListingPostingCardFragment } from "@/gql";
import { useCurrentActor } from "@/hooks";
import { appendSellerCompanyIdToUrl } from "@/utils";

import { ListingPostingCardBody } from "./ListingPostingCardBody";
import { ListingPostingCardBrokerBody } from "./ListingPostingCardBrokerBody";
import { ListingPostingCardBrokerFooter } from "./ListingPostingCardBrokerFooter";
import { ListingPostingCardFooter } from "./ListingPostingCardFooter";
import { ListingPostingCardHeader } from "./ListingPostingCardHeader";

type ListingPostingCardProps = {
  readonly listing: ListingPostingCardFragment;
};

function ListingBrokerPostingCard({ listing }: ListingPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url}>
      <ListingPostingCardHeader listing={listing} />
      <ListingPostingCardBrokerBody listing={listing} />
      <ListingPostingCardBrokerFooter listing={listing} />
    </PostingCard>
  );
}

function ListingUnaccreditedSellerPostingCard({
  listing,
}: ListingPostingCardProps) {
  const {
    id,
    company: { id: companyId },
  } = listing;

  const url = appendSellerCompanyIdToUrl(`/listings/${id}`, companyId);
  return (
    <PostingCard href={url}>
      <ListingPostingCardHeader listing={listing} />
      <ListingPostingCardBody listing={listing} />
      <ListingPostingCardFooter listing={listing} />
    </PostingCard>
  );
}

function ListingTraderPostingCard({ listing }: ListingPostingCardProps) {
  const { id } = listing;
  const url = `/listings/${id}`;

  return (
    <PostingCard href={url}>
      <ListingPostingCardHeader listing={listing} />
      <ListingPostingCardBody listing={listing} />
      <ListingPostingCardFooter listing={listing} />
    </PostingCard>
  );
}

export function ListingPostingCard({ listing }: ListingPostingCardProps) {
  const { investorType } = useCurrentActor();
  return match(investorType)
    .with(InvestorType.Broker, () => (
      <ListingBrokerPostingCard listing={listing} />
    ))
    .with(InvestorType.UnaccreditedSeller, () => (
      <ListingUnaccreditedSellerPostingCard listing={listing} />
    ))
    .otherwise(() => <ListingTraderPostingCard listing={listing} />);
}
