import { DotsThreeOutlineVertical, File } from "@phosphor-icons/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  Grid,
  GridItem,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";

import { DropzoneUpload } from "@/components/common";
import { ExecutionTaskActionType, useExecutionTaskActionMutation } from "@/gql";
import { useCustomToast, useS3SecureFileUpload } from "@/hooks";

import { Upload, UploadStatus } from "./types";

export function TaskFileUpload({
  taskId,
  uploads,
  onUploadChange,
  disabled = false,
  hideDropzone = false,
}: {
  taskId: string;
  uploads: Upload[];
  onUploadChange: (uploadUpdater: (prevUploads: Upload[]) => Upload[]) => void;
  disabled?: boolean;
  hideDropzone?: boolean;
}) {
  const [executionTaskAction] = useExecutionTaskActionMutation();
  const { upload } = useS3SecureFileUpload();
  const { errorToast, successToast } = useCustomToast();
  const { t } = useTranslation();

  const handleUploadError = useCallback(
    (id: string) => {
      onUploadChange((uploads) =>
        uploads.map((f) =>
          f.id === id ? { ...f, status: UploadStatus.Error } : f,
        ),
      );
      errorToast(t(`error_uploading_file`));
    },
    [errorToast, t],
  );

  const onSelectFilesSuccess = useCallback(
    async (files: File[]) => {
      await Promise.all(
        files.map(async (file) => {
          const id = uuidv4();

          onUploadChange((uploads) => [
            ...uploads,
            {
              id,
              status: UploadStatus.Uploading,
              name: file.name,
              type: file.type,
            },
          ]);

          const handleUploadComplete = async (fileUploadId: string) => {
            const { data: linkData } = await executionTaskAction({
              variables: {
                id: taskId,
                type: ExecutionTaskActionType.UploadFilesLinkFileUpload,
                input: {
                  uploadFilesLinkFileUpload: {
                    fileUploadId,
                  },
                },
              },
            });

            if (
              linkData?.executionTaskAction?.result?.__typename ===
                `ExecutionTaskActionUploadFilesLinkFileUpload` &&
              linkData?.executionTaskAction?.result?.task?.id
            ) {
              // replace temp id with the actual fileUpload.id and update status
              onUploadChange((uploads) =>
                uploads.map((f) =>
                  f.id === id
                    ? { ...f, id: fileUploadId, status: UploadStatus.Uploaded }
                    : f,
                ),
              );
            } else {
              handleUploadError(id);
            }
          };

          // try catch here is to handle any issues with the upload url
          try {
            const { data } = await executionTaskAction({
              variables: {
                id: taskId,
                type: ExecutionTaskActionType.UploadFilesGenerateUploadPolicy,
                input: {
                  uploadFilesGenerateUploadPolicy: {
                    filename: file.name,
                    contentType: file.type,
                  },
                },
              },
            });

            const uploadPolicy = data?.executionTaskAction?.result;

            if (
              uploadPolicy &&
              uploadPolicy.__typename ===
                `ExecutionTaskActionUploadFilesGenerateUploadPolicy`
            ) {
              const { url, formFields, objectKey } = uploadPolicy;

              const sanitizedFormFields = formFields.map(
                ({ __typename, ...field }) => field,
              );

              upload(
                file,
                { url, formFields: sanitizedFormFields, objectKey },
                {
                  onUploadComplete: async (fileUpload) => {
                    await handleUploadComplete(fileUpload.id);
                  },
                  onProgressUpdate: (progress) => {
                    onUploadChange((uploads) =>
                      uploads.map((f) =>
                        f.id === id ? { ...f, progress } : f,
                      ),
                    );
                  },
                  onError: () => {
                    handleUploadError(id);
                  },
                },
              );

              // if policy generation failed
            } else if (data?.executionTaskAction?.errors) {
              handleUploadError(id);
            }
          } catch (error) {
            handleUploadError(id);
          }
        }),
      );
    },
    [executionTaskAction, handleUploadError, taskId, upload],
  );

  const handleRemove = useCallback(
    async (upload: Upload) => {
      const { data } = await executionTaskAction({
        variables: {
          id: taskId,
          type: ExecutionTaskActionType.UploadFilesRemoveFileUpload,
          input: {
            uploadFilesRemoveFileUpload: {
              fileUploadId: upload.id,
            },
          },
        },
      });
      if (
        data?.executionTaskAction?.result?.__typename ===
          `ExecutionTaskActionUploadFilesRemoveFileUpload` &&
        data?.executionTaskAction?.result?.task?.id
      ) {
        successToast(t(`file_deleted`));
        onUploadChange((files) => files.filter((f) => f.id !== upload.id));
      } else {
        errorToast(t(`error_deleting_file`));
      }
    },
    [errorToast, executionTaskAction, successToast, taskId],
  );

  const handleDownload = useCallback(
    async (upload: Upload) => {
      const { data } = await executionTaskAction({
        variables: {
          id: taskId,
          type: ExecutionTaskActionType.UploadFilesGenerateDownloadUrl,
          input: {
            uploadFilesGenerateDownloadUrl: {
              fileUploadId: upload.id,
            },
          },
        },
      });
      if (
        data?.executionTaskAction?.result?.__typename ===
          `ExecutionTaskActionUploadFilesGenerateDownloadUrl` &&
        data?.executionTaskAction?.result?.url
      ) {
        window.open(data?.executionTaskAction?.result.url, `_blank`);
      } else {
        errorToast(t(`error_downloading_file`));
      }
    },
    [errorToast, executionTaskAction, taskId],
  );

  const handleDelete = useCallback(
    (upload: Upload) => {
      if (upload.status === UploadStatus.Uploaded) {
        handleRemove(upload);
      } else {
        onUploadChange((files) => files.filter((f) => f.id !== upload.id));
        successToast(t(`file_deleted`));
      }
    },
    [successToast],
  );

  const fileExtension = (fileName: string) => {
    const parts = fileName.split(`.`);
    return parts[parts.length - 1];
  };

  return (
    <VStack w="full" spacing={4}>
      {uploads.length > 0 && (
        <Grid templateColumns={{ sm: `1fr 1fr`, base: `1fr` }} gap={3} w="full">
          {uploads.map((upload) => (
            <GridItem key={upload.id}>
              <Box
                key={upload.id}
                borderRadius="md"
                background="grey.15"
                border="1px solid"
                borderColor="grey.100"
                w="full"
                p={2}
              >
                <HStack>
                  <Box>
                    <File size={32} />
                  </Box>
                  <VStack px={1} alignItems="start" gap={0} w="full">
                    <Text
                      w="full"
                      textStyle="heading-xs"
                      noOfLines={1}
                      wordBreak="break-all"
                    >
                      {upload.name}
                    </Text>
                    {match(upload.status)
                      .with(UploadStatus.Uploading, () => (
                        <Box height="full" width="full" py={2}>
                          <Progress
                            w="full"
                            size="xs"
                            value={upload.progress}
                          />
                        </Box>
                      ))
                      .with(UploadStatus.Uploaded, () => (
                        <Text
                          textStyle="text-sm"
                          color="grey.600"
                          textTransform="uppercase"
                        >
                          {fileExtension(upload.name)}
                        </Text>
                      ))
                      .with(UploadStatus.Error, () => (
                        <Text textStyle="text-sm" color="grey.600">
                          {t(`error_uploading_file`)}
                        </Text>
                      ))
                      .exhaustive()}
                  </VStack>
                  <Box w={6}>
                    {upload.status !== UploadStatus.Uploading && (
                      <Menu>
                        <MenuButton
                          width="24px"
                          height="24px"
                          p={0}
                          m={0}
                          border="none"
                          bg="grey.15"
                        >
                          <DotsThreeOutlineVertical
                            weight="fill"
                            size={14}
                            width="100%"
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              handleDownload(upload);
                            }}
                          >
                            <Text>{t(`download`)}</Text>
                          </MenuItem>
                          {!disabled && (
                            <MenuItem
                              onClick={() => {
                                handleDelete(upload);
                              }}
                            >
                              <Text>{t(`delete`)}</Text>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    )}
                  </Box>
                </HStack>
              </Box>
            </GridItem>
          ))}
        </Grid>
      )}
      {!hideDropzone && (
        <DropzoneUpload
          onSelectFilesSuccess={onSelectFilesSuccess}
          disabled={disabled}
        />
      )}
    </VStack>
  );
}
