import { useTranslation } from "react-i18next";

import {
  CardBody,
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";

import { withCurrentActor } from "@/components/hoc";
import {
  getTransactionIsBuySide,
  getTransactionIsSellSide,
  getTransactionCounterpartyName,
  MilestonesMobile,
} from "@/components/transactions";
import {
  TransactionExecutionPageTransactionFragment,
  UserWithInstitutionFragment,
} from "@/gql";
import { useIsDesktop } from "@/hooks";
import { formatCurrency, formatPricePerShare, formatShares } from "@/utils";

import GrossProceeds from "./GrossProceeds";

const TransactionDetailItem = ({
  title,
  value,
}: {
  readonly title: string;
  readonly value: string;
}) => (
  <HStack
    gap={2}
    w={{ base: `full`, lg: `auto` }}
    justifyContent={{ base: `space-between`, lg: `auto` }}
  >
    <Text color="grey.700" textStyle="heading-xs">
      {title}
    </Text>
    <Text color="grey.700" textStyle="text-sm">
      {value}
    </Text>
  </HStack>
);

export const TransactionExecutionPageSummaryCardBody = withCurrentActor(
  ({
    transaction,
    actor,
  }: {
    readonly transaction: TransactionExecutionPageTransactionFragment;
    readonly actor: UserWithInstitutionFragment;
  }) => {
    const { t } = useTranslation();

    const { netFees = 0 } = transaction.sellerCommission || {};

    const netProceeds = Math.max(transaction.grossProceeds - netFees, 0);

    const formattedGrossProceeds = formatCurrency(transaction.grossProceeds, {
      fromCents: true,
    });

    const formattedNetProceeds = formatCurrency(netProceeds, {
      fromCents: true,
    });

    const isSellSide = getTransactionIsSellSide({ actor, transaction });
    const isBuySide = getTransactionIsBuySide({ actor, transaction });

    const counterpartyName = getTransactionCounterpartyName({
      actor,
      transaction,
    });

    const isDesktop = useIsDesktop();

    const { workflow } = transaction;

    return (
      <CardBody
        position="relative"
        p={0}
        pt="44px"
        pb={6}
        borderBottomColor="grey.50"
      >
        {!!transaction.company.logoUrl && (
          <Center
            bg="white"
            w="14"
            h="14"
            borderWidth="0.5px"
            borderColor="grey.200"
            borderRadius="md"
            p={2}
            position="absolute"
            top="0"
            left={6}
            transform="auto"
            translateY="-50%"
          >
            <Image
              src={transaction.company.logoUrl}
              alt={transaction.company.name}
            />
          </Center>
        )}
        <Flex
          px={6}
          mb={4}
          w="full"
          gap={4}
          flexDirection={{ base: `column`, md: `row` }}
          justifyContent="space-between"
          alignItems={{ base: `auto`, md: `flex-end` }}
        >
          <VStack alignItems="flex-start" spacing={0}>
            <Text textStyle="heading-4xl">{transaction.company.name}</Text>
            <Text color="teal.900" textStyle="text-xs">
              <chakra.span fontWeight="medium" mr={1}>
                {t(`transaction`)}
              </chakra.span>
              {transaction.displayId}
            </Text>
          </VStack>
          <GrossProceeds
            counterpartyName={counterpartyName}
            isSellSide={isSellSide}
            grossProceeds={transaction.grossProceeds}
            netProceeds={netProceeds}
          />
        </Flex>
        {!!workflow && !isDesktop ? (
          <MilestonesMobile workflow={workflow} isBuySide={isBuySide} />
        ) : (
          <Box px={6}>
            <Divider mb={4} borderColor="grey.50" />
          </Box>
        )}
        <HStack px={6} gap={6} rowGap={2} flexWrap="wrap">
          <TransactionDetailItem
            title={t(`shares`)}
            value={`${formatShares(
              transaction.numShares,
            )} @ ${formatPricePerShare(transaction.pricePerShare)}`}
          />
          <TransactionDetailItem
            title={isBuySide ? t(`total`) : t(`gross_proceeds`)}
            value={formattedGrossProceeds}
          />
          {isSellSide && (
            <TransactionDetailItem
              title={t(`net_proceeds`)}
              value={formattedNetProceeds}
            />
          )}
        </HStack>
      </CardBody>
    );
  },
);
