import { Certificate } from "@phosphor-icons/react";

import { Card, Center, Circle, HStack, Text, VStack } from "@chakra-ui/react";

import { CompletedEntityCardEntityFragment } from "@/gql";
import { useCountryList, useTranslatedEntityNames } from "@/hooks";

const CompletedEntityCard = ({
  entity,
}: {
  readonly entity: CompletedEntityCardEntityFragment;
}) => {
  const { getFormattedEntityName } = useTranslatedEntityNames();
  const countryList = useCountryList();
  const countryName = countryList.getName(entity.jurisdictionOfFormation?.name);

  return (
    <Card variant="flat" px={4} py={3} bg="grey.25">
      <HStack gap={4}>
        <Center>
          <Certificate width="24px" height="24px" />
        </Center>
        <VStack alignItems="start" spacing={0}>
          <Text textStyle="heading-md">{entity.legalName}</Text>
          <HStack>
            <Text textStyle="text-xs" color="grey.700">
              {countryName}
            </Text>
            <Circle size="1" bg="grey.600" />
            <Text textStyle="text-xs" color="grey.700">
              {getFormattedEntityName(entity.type)}
            </Text>
          </HStack>
        </VStack>
      </HStack>
    </Card>
  );
};

export default CompletedEntityCard;
