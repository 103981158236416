export default {
  anvil: `Anvil`,
  information_collection: `Information Collection`,
  add_information: `Add information`,
  completed: `Completed`,
  pending: `Pending`,
  please_fill_information: `Please fill in your information for the document`,
  fill_document: `Fill document`,
  start_form: `Start form`,
  document_signing: `Document Signing`,
  document_signed: `Document Signed!`,
  please_complete_document: `Please complete the document by signing`,
  sign_document: `Sign Document`,
  signed: `Signed`,
  your_signature: `Your signature`,
  counterpartys_signature: `Counterparty's signature`,
  download: `Download`,
  decline: `Decline`,
  approval_status: `Approval Status`,
  approved: `Approved`,
  rejected: `Rejected`,
  your_approval: `Your Approval`,
  counterparty_approval: `Counterparty Approval`,
  gross_sale_amount: `Gross Sale Amount`,
  total_hiive_fees: `Total Hiive Fees`,
  net_proceeds: `Net Proceeds`,
  shares: `Shares`,
  discounts: `Discounts`,
  discount: `Discount`,
  base_fee: `Base Fee`,
  fee: `Fee`,
  total_discounts: `Total Discounts`,
  proceeds_description_collapsed: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee.`,
  proceeds_description_expanded: `This is the estimated price per share and net proceeds after deducting the Hiive Transaction Fee. There may be other charges that you will have to incur in order to complete this transaction. For example, some issuers charge fees to sellers in order to cover their legal and other costs of executing the sale transaction. As well, you may need to pay taxes, or fees to consultants such as lawyers or tax advisors. These charges are borne by you and not deducted from the purchase price for the purpose of calculating Hiive's transaction fee.<br/><br/>`,
  proceeds_description_terms: `To see how the Hiive Transaction Fee is calculated, consult the <a>Hiive Customer Terms & Conditions.</a><br/>`,
  fees: `Fees`,
  hiive_closing_fee: `Hiive Closing Fee`,
  hiive_commission: `Hiive Commission`,
  gross_value: `Gross Value`,
  read_more: `Read More`,
  see_less: `See Less`,
  accept: `Accept`,
  accept_proposed_modified_transaction: `Accept Proposed Modified Transaction`,
  accept_proposed_modified_transaction_title: `Are you sure you want to accept this modification?`,
  accept_proposed_modified_transaction_description: `Your may need to sign a new Share Transfer Notice.`,
  proposed_update: `Proposed update: {{shares}} shares of {{companyName}} @{{price}}/sh {{type}}.`,
  completed_date: `Completed {{completedAt}}`,
  completed_tasks: `Completed tasks`,
  confirm: `Confirm`,
  inviter_has_asked_to_fill: `{{name}} has asked you to fill out a document on their behalf.`,
  inviter_has_asked_to_sign: `{{name}} has asked you to sign a document on their behalf.`,
  in_review_title: `Confirming the type of shares`,
  in_review_description: `We need the type of shares to proceed with the transaction. Someone from Hiive will be in touch shortly to confirm.`,
  disclaimer: `disclaimer`,
  document_filled: `Document Filled!`,
  document_filled_description: `Thank you for filling on behalf of {{name}}`,
  document_signed_description: `Thank you for signing on behalf of {{name}}`,

  cancel: `Cancel`,
  contact_our_security_specialist: `If you have any questions, please contact our Security Specialist. <bold><a>{{email}}</a></bold>`,
  contact_our_email: `If you have any questions, please contact <bold><a>{{email}}</a></bold>`,
  reject_proposed_modified_transaction: `Reject Proposed Modified Transaction`,
  reject_proposed_modified_transaction_title: `Are you sure you want to reject this modification?`,
  reject_proposed_modified_transaction_description: `Rejecting the modification may impact the progress or completion of your transaction. For more details, please reach out to your Hiive contact or email <a>{{email}}.<a>`,
  no_completed_tasks: `No completed tasks, this is where they will appear.`,
  no_documents_uploaded: `No documents. All documents completed in the transaction will appear here.`,
  add_bank_account: `Add bank account`,
  add_bank_account_description: `Provide the bank details we should use for this transaction.`,
  add_account: `Add account`,
  add_new_account: `Add new account`,
  bank_details_for: `Bank details for`,
  select_bank_account: `Select bank account`,
  last_used_on_transaction: `Last used on transaction`,
  added: `Added`,
  transaction: `Transaction`,
  modern_treasury_disclaimer: `We use Modern Treasury to facilitate payments`,
  modern_treasury_feature_1: `Fully encrypted, your financial data is secure`,
  modern_treasury_feature_2: `Direct bank integration, for easy payment setup`,
  modern_treasury_feature_3: `Streamlined processing, for faster access to your funds`,
  modern_treasury_terms: `By clicking ’next’, you agree to Modern Treasury’s <italic>Privacy Policy.<italic>`,
  next: `Next`,
  your_bank_details: `Your bank details`,
  where_is_your_bank_located: `Where is your bank located?`,
  no_bank_available: `Can’t find your country in the list? Contact <a>execution@hiive.com.</a>`,
  bank_country_disclaimer: `Hiive uses a trusted external partner, <a>Modern Treasury</a> to securely and anonymously transact. Hiive doesn’t store your banking credentials.`,
  select_bank_location: `Select bank location`,
  usa_disclaimer: `For US accounts, we send payments through <u>Same-Day ACH</u>. You’ll need to provide your ACH routing number, which may be different from your wire routing number.`,
  add_your_account_details: `Add your account details`,
  account_details_disclaimer: `Before you submit, it is your responsibility to ensure these details are correct. Hiive will send funds to the account details provided.`,
  account_details_additional: `To provide any additional details, like For Further Credit instructions, contact <a>execution@hiive.com</a>.`,
  bank_account_added: `Bank account added`,
  bank_account_connected: `Your bank account is now connected to this transaction.`,
  next_step: `Next Step`,
  next_tasks: `Next tasks`,
  next_tasks_description: `You’ll need to complete these soon. We’ll let you know when.`,
  verify_your_bank_account: `Verify your bank account`,
  verify_your_account: `Verify your account`,
  verify_confirm_need: `We need to confirm we’ve got the right account details.`,
  verify_confirm_send_deposits: `In the next three days, we’ll send two micro-deposits to the details provided.`,
  verify_confirm_use_deposits: ` When these show in your account, you’ll use them to verify your details.`,
  account_pending_verification: `Account pending verification`,
  got_it: `Got it`,
  account_number: `Account number`,
  amount_due: `Amount due`,
  beneficiary: `Beneficiary`,
  payments: `Payments`,
  routing_number: `Routing number`,
  send_amount_due: `Send the amount due to the wire details provided. Please note, we cannot accept ACH payments.`,
  swift_code: `Swift code`,
  the_account_number: `The account number is unique to this transaction, please do not use it for subsequent transactions.`,
  transfer_funds: `Transfer funds`,
  wire_instructions: `Wire Instructions`,
  wire_details: `Wire details`,
  alternate: `Alternate`,
  you_invited_alternate: `You invited an alternate to fill out this document on your behalf`,
  you_invited_alternate_to_sign: `You invited an alternate to sign the document`,
  need_someone_else_to_do_this_task: `Need someone else to complete this task?`,
  send_an_invite: `Send an invite`,
  send_invite: `Send Invite`,
  want_to_complete_this_yourself: `Want to complete this yourself?`,
  cancel_invite: `Cancel invite`,
  remove_alternate_representative: `Remove alternate representative`,
  are_you_sure_remove_alternate: `Are you sure you want to remove the alternate representative?`,
  remove_alternate_modal_description: `You’ll need to complete this task yourself, or you can invite a new alternate to complete the task on your behalf.`,
  remove: `Remove`,
  invite_an_alternate_representative: `Invite an alternate representative`,
  provide_alternate_representative: `Provide your alternate representative’s details`,
  alternate_representative_instructions: `We’ll send them an email with instructions on how to complete this task.`,
  first_name: `First Name`,
  last_name: `Last Name`,
  gross_proceeds: `Gross Proceeds`,
  email: `Email`,
  received_by_hiive: `Received by Hiive`,
  total_paid: `Total paid`,
  accepted: `Accepted`,
  counter_party: `Counter Party`,
  share_type: `Share type`,
  account: `Account`,
  bank_details: `Bank Details`,
  my_details: `My Details`,
  transaction_summary: `Transaction Summary`,
  documents: `Documents`,
  listing: `Listing`,
  view_initial_listing: `View Initial Listing`,
  need_to_add_a_new_buying_entity: `Need to add a new buying entity?`,
  need_to_add_a_new_selling_entity: `Need to add a new selling entity?`,
  confirm_seller: `Confirm seller`,
  confirm_your_entity: `Confirm your entity`,
  entity_picker_label: `The entity for this transaction is:`,
  original_listing: `Original listing`,
  verify_bank_account_description: `We’ve sent two micro-deposits to your account. Deposits will be made by Hiive Markets Limited and each will be less than $1.`,
  hiive_markets_limited: `Hiive Markets Limited`,
  enter_micro_deposits: `Enter the micro-deposit amounts`,
  micro_deposits_description: `If the deposits do not show in your account after three days, please contact execution@hiive.com.`,
  verify_error_incorrect_amount_title: `Deposit amounts incorrect`,
  verify_error_incorrect_amount_description: `The amounts you entered do not match the amounts we sent.<br/>Check the amounts, then try again.<br/><br/>Need help with verification? Contact execution@hiive.com.`,
  verify_error_general_title: `Verification failed`,
  verify_error_general_description: `Your account could not be verified.<br/>A member of our team will contact you with next steps.`,
  try_again: `Try again`,
  okay: `Okay`,
  manual_verification_disclaimer: `Our team will be in touch to verify your account details.`,
  connect_bank_account: `Connect bank account`,
  connect_bank_account_description: `Easily connect your account via Modern Treasury.`,
  connect_account: `Connect account`,
  off_platform_generate_link_error: `Failed to generate link. Please try again.`,
  bank_account: `Bank account`,
  verify_account: `Verify account`,
  bank_account_verified: `Bank account verified`,
  bank_account_verified_description: `You’re all set to receive your funds when the transaction is complete.`,
  done: `Done`,
  alternate_party: `Alternate party`,
  upcoming_task: `Upcoming task`,
  review_and_sign: `Review and sign`,
  open_document: `Open document`,
  unverified: `Unverified`,
  verified: `Verified`,
  used_for_transaction: `Used for transaction`,
  your_information: `Your information`,
  counterpartys_information: `Counterparty's information`,
  confirm_transacting_party: `Confirm the transacting party`,
  confirm_transacting_party_p1: `The transacting party is the legal name of the buyer. If you are an individual, this is your legal name that will appear on the share certificates or other proof of ownership.`,
  confirm_transacting_party_p2: `If this transaction has multiple buying entities, contact support@hiive.com.`,
  need_to_add_new_selling_entity: `Need to add a new selling entity?`,
  need_to_add_new_buying_entity: `Need to add a new buying entity?`,
  search_for_party: `Search for party`,
  the_transacting_party: `The transacting party is:`,
  hiive: `Hiive`,
  accepted_milestone_description: `Buyer and seller provisionally agree a sale.`,
  approved_milestone_description: `Issuing company accepts the transfer. Transaction can proceed.`,
  confirmed_milestone_description: `Buyer and seller confirm terms and sign the Share Transfer Notice.`,
  submitted_milestone_description: `Issuing company reviews the transaction; transfer restrictions may apply.`,
  signed_milestone_description: `Buyer and seller sign the purchase agreement.`,
  complete_milestone_description: `Hiive distributes the funds. Then the issuing company transfers shares to the buyer.`,
  individual_entity_tooltip: `An individual transacts in their own name.`,
  other_entity_tooltip: `An entity could be a corporation, trust, or limited partnership.`,
  submitting_your_details: `Submitting your details. This may take a few minutes...`,
};
