import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormErrorMessage } from "@chakra-ui/react";

import { EntitiesOption } from "@/components/entities";
import { Combobox } from "@/components/form";
import {
  EntitySortField,
  InternalEntityFragment,
  useInternalEntityLazyQuery,
} from "@/gql";
import { useCombobox, useDebounce } from "@/hooks";

import InternalEntitiesSearchInput from "./InternalEntitiesSearchInput";
import { InternalEntityFormValues } from "./InternalEntityCard";

const getVariables = (searchText: string) => ({
  first: 20,
  orderBy: EntitySortField.LegalName,
  filterBy: searchText
    ? {
        searchText,
      }
    : undefined,
});

const InternalEntityCombobox = ({
  placeholder,
  hideDate = true,
}: {
  readonly placeholder?: string;
  readonly hideDate?: boolean;
}) => {
  const {
    formState: {
      errors: { entityId: entityIdError },
    },
    setValue,
    watch,
    resetField,
    register,
    trigger,
  } = useFormContext<InternalEntityFormValues>();
  const { debounce, isDebouncing } = useDebounce();
  const searchText = watch(`searchText`);
  const entityId = watch(`entityId`);

  const [loadEntities, { data: items, loading }] = useInternalEntityLazyQuery({
    fetchPolicy: `no-cache`,
  });

  const entities = useMemo(
    () =>
      items?.entities?.edges.map(
        (edge) => edge.node,
      ) as InternalEntityFragment[],
    [items],
  );

  useEffect(() => {
    loadEntities({
      variables: getVariables(``),
    });

    if (searchText?.length === 0) {
      resetField(`entityId`);
    }
  }, [searchText]);

  const onChangeInputValue = (inputValue: string) => {
    setValue(`searchText`, inputValue);
    debounce(() =>
      loadEntities({
        variables: getVariables(inputValue),
      }),
    );
  };

  const isLoading = loading || isDebouncing;

  // Need to register this field to be able to clear it https://www.react-hook-form.com/api/useform/resetfield/
  useMemo(() => {
    register(`entityId`);
  }, []);

  const { inputProps, menuProps, labelProps, itemProps } =
    useCombobox<InternalEntityFragment>({
      items: entities ?? [],
      itemToString: (item) => item.legalName,
      getItemKey: (item) => item.id,
      onSelectItem: (item) => {
        setValue(`searchText`, item?.legalName);
        setValue(`entityId`, String(item?.id));
        trigger();
      },
      onChangeInputValue,
      inputValue: searchText,
      isLoading,
    });

  const { t } = useTranslation(`transactions`);

  return (
    <>
      <Combobox.Label srOnly {...labelProps}>
        {t(`search`)}
      </Combobox.Label>
      <Combobox.Container>
        <InternalEntitiesSearchInput
          placeholder={placeholder ?? t(`search`)}
          onClear={() => onChangeInputValue(``)}
          {...inputProps.getInputProps({})}
        />
        <Combobox.Menu
          isLoading={isLoading}
          maxH={242}
          isLazy={false}
          {...menuProps}
        >
          {entities?.map((item, index) => (
            <Combobox.Item
              key={item.id}
              index={index}
              item={item}
              {...itemProps}
            >
              <EntitiesOption
                option={item}
                selected={entityId === item.id}
                hideDate={hideDate}
              />
            </Combobox.Item>
          ))}
        </Combobox.Menu>
        {entityIdError && (
          <FormErrorMessage>{entityIdError.message}</FormErrorMessage>
        )}
      </Combobox.Container>
    </>
  );
};

export default InternalEntityCombobox;
